import {Component, Input, TemplateRef, ViewChild} from '@angular/core';
import {ColumnDefinition} from '../../common/paginated-table/paginated-table.component';
import {VorgangRouteService} from '../../../services/vorgang-route.service';
import {AbstractRouteComponent} from './abstract-route.component';
import {NxDialogService, NxModalRef} from '@allianz/ngx-ndbx/modal';

@Component({
    selector: 'app-vorgang-makler',
    templateUrl: './vorgang-makler.component.html',
    styleUrls: ['./vorgang-makler.component.css']
})
export class VorgangMaklerComponent extends AbstractRouteComponent {

    @Input()
    buttonTemplate: TemplateRef<any>;

    selectedMaklerModalOpen = false;

    msnColumns: Array<ColumnDefinition> = [{key: 'value', headerName: 'MSN'}, {key: 'resolvedName', headerName: 'Name'}];
    vtnrColumns: Array<ColumnDefinition> = [{key: 'value', headerName: 'VTNR'}, {key: 'resolvedName', headerName: 'Name'}];

    @ViewChild('selectedMaklerModal') templateRef: TemplateRef<any>;
    selectedMaklerModal: NxModalRef<any>;

    constructor(protected readonly routeService: VorgangRouteService,
                public dialogService: NxDialogService) {
        super(routeService);
        this.filter = 'NAME';
    }


    showSelectedMaklerModal() {
        this.selectedMaklerModal = this.dialogService.open(this.templateRef, { ariaLabel: 'MSN / VTNR anzeigen', disableClose: true  });
        this.selectedMaklerModal.afterClosed().subscribe(result => {
           this.selectedMaklerModal.close();
        });

    }
}

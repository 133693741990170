import {MitarbeiterReferatMappingRestTO} from "./MitarbeiterReferatMappingRestTO";
import {GeproTypeRestTO} from "./GeproTypeRestTO";
import {StatusTypeRestTO} from "./StatusTypeRestTO";

export class AbstractType{

    public id?: number;
    public name: string;


    public static isAbstractType(object: number | string | Date | MitarbeiterReferatMappingRestTO | GeproTypeRestTO | StatusTypeRestTO): boolean {
        return object.hasOwnProperty("name");
    }
}

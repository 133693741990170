export class Endpoints {
    public static readonly LOGIN = 'oauth/login';
    public static readonly LOGOUT = 'oauth/logout';

    public static readonly GET_ALL_ADMINS = 'service/adminList';
    public static readonly GET_ALL_REDAKTEURE = 'service/redakteurList';
    public static readonly GET_ALL_MITARBEITER_ADMIN = 'service/adminMitarbeiterList';

    public static readonly VERSION = 'service/version';

    public static readonly GET_ALL_DOCUMENTS = 'service/getAllDocuments';
    public static readonly DOCUMENTS = 'service/document';

    public static readonly HINWEISE = 'service/getHinweise';
    public static readonly NOTIFICATION = 'service/getNotification';
    public static readonly NOTIFICATION_ALL_READ = 'service/${read}/readAllNotifications';
    public static readonly NOTIFICATION_AS_READ = 'service/${geproId}/${read}/${noteid}/setNotificationRead';
    public static readonly NOTIFICATION_DELETE = 'service/${geproId}/${id}/notificationDelete';
    public static readonly HINWEISE_ANWENDUNGSINFO = 'service/getHinweiseAnwendungsinfo';
    public static readonly HINWEISE_DOCUMENT = 'service/getHinweisDocument';
    public static readonly HINWEISE_DOCUMENTS = 'service/getHinweisDocuments';
    public static readonly HINWEISE_DOCUMENTS_VERSIONSINFO = 'service/getHinweisDocumentsVersionsinfo';
    public static readonly HINWEISE_REMOVE = 'service/removeHinweis';
    public static readonly HINWEISE_CHECK_DATE = 'service/dateCheck';
    public static readonly HINWEISE_DOCUMENT_REMOVE = 'service/removeUploadedDocumentHinweis';
    public static readonly HINWEISE_UPDATE = 'service/updateHinweis';
    public static readonly HINWEISE_UPDATE_MULTIPART = 'service/updateMultiPartHinweis';
    public static readonly HINWEISE_CREATE = 'service/createHinweis';
    public static readonly HINWEISE_UPLOAD_DOCUMENT = 'service/uploadDocumentHinweis';

    public static readonly SERVICE_CREATE_WIEDERVORLAGE = 'service/createWiedervorlage';
    public static readonly SERVICE_UPDATE_WIEDERVORLAGE = 'service/updateWiedervorlage';
    public static readonly SERVICE_REMOVE_WIEDERVORLAGE_DOCUMENT = 'service/removeWiedervorlagenDocuemtn';
    public static readonly SERVICE_REMOVE_WIEDERVORLAGE = 'service/removeWiedervorlage';
    public static readonly SERVICE_CHECK_DATE_WIEDERVORLAGE = 'service/dateCheckWiedervorlage';
    public static readonly SERVICE_GET_ALL_WIEDERVORLAGEN = 'service/getWiedervorlagen';
    public static readonly SERVICE_GET_WIEDERVORLAGE = 'service/getWiedervorlage';
    public static readonly SERVICE_GET_WIEDERVORLAGE_HISTORY = 'service/getWiedervorlageHistory';
    public static readonly SERVICE_UPLOAD_DOCUMENT_WIEDERVORLAGE = 'service/uploadDocumentWiedervorlage';
    public static readonly SERVICE_GET_ALL_WIEDERVORLAGEN_DOCUMENT = 'service/getWiedervorlagenDocument';
    public static readonly GEPROS = 'service/gepros';
    public static readonly GEPROS_CSV = 'service/geprosCSV';
    public static readonly GEPRO_BY_ID = 'service/gepros/${geproId}';
    public static readonly REPORTING = 'service/referatPresenting';
    public static readonly REPORTING_ALL_LSZ_YEAR = 'service/referatPresentingAllLSZYear';
    public static readonly REPORTING_EXLPLIZIT = 'service/referatExplizitPresenting';
    public static readonly SERVICE_REPORTING_ABGESCHLOSSEN_KW = 'service/referatPresentingKw';
    public static readonly SERVICE_REPORTING_ABGESCHLOSSEN_MONTHLY = 'service/referatPresentingMonthly';
    public static readonly REPORTING_ALL = 'service/referatPresentingAll';
    public static readonly REPORTING_COUNT = 'service/referatLszPresenting';
    public static readonly LSZ_GEPRO_BY_ID = 'service/geproslsz/${geproId}';
    public static readonly GEPRO_CLOSE_FOREVER_BY_ID = 'service/closeforever/${geproId}';
    public static readonly UNINDEFINED_LSZ_GEPRO_BY_ID = 'service/ungeproslsz/${geproId}';
    public static readonly GEPRO_COMMUNICATE_WITH_LSZ = 'service/geproslsz/${geproId}/communicateWithLsz';
    public static readonly UNDEFINEDLSZ = 'service/undefinedlsz';
    public static readonly GEPRO_SEARCH_BY_ID = 'service/gepros/${geproId}/search';
    public static readonly GEPRO_FREIGABE = 'service/gepros/${geproId}/freigabeinfo';
    public static readonly GEPRO_PDF = 'service/gepros/${geproId}/pdf';
    public static readonly GEPRO_PUBLISH_BY_ID = 'service/gepros/${geproId}/publish';
    public static readonly GEPRO_ZUSATZINFO_BY_ID = 'service/zusatzinfo/${geproId}';
    public static readonly AUTHORIZE_GEPROS = 'service/gepros/${geproId}/authorise';
    public static readonly CHANGE_GEPRO_REFERAT = 'service/gepros/${geproId}/changeGeproReferat';
    public static readonly GEPRO_CLOSE_BY_ID = 'service/gepros/${geproId}/close';
    public static readonly DEAUTHORIZE_GEPROS = 'service/gepros/${geproId}/deauthorise';
    public static readonly DELETE_GEPRO = 'service/gepros/${geproId}/delete';
    public static readonly GEPROS_DENY_BY_ID = 'service/gepros/${geproId}/deny';
    public static readonly GEPROS_FORRELEASE_BY_ID = 'service/gepros/${geproId}/forrelease';
    public static readonly GEPROS_RELEASE_BY_ID = 'service/gepros/${geproId}/release';
    public static readonly GEPROS_PROCESSRELEASE_BY_ID = 'service/gepros/${geproId}/processrelease';
    public static readonly GEPROS_DENY_FORRELEASE_BY_ID = 'service/gepros/${geproId}/denyforrelease';
    public static readonly GEPRO_ACTION_EDIT_BY_ID = 'service/gepros/${geproId}/edit';

    public static readonly GEPRO_MARK_BY_ID = 'service/gepros/${geproId}/mark';
    public static readonly GEPRO_ACTION_REJECT_BY_ID = 'service/gepros/${geproId}/reject';

    public static readonly GEPRO_FILES_BY_ID = 'service/gepros/${geproId}/files';
    public static readonly GEPRO_FILES_FILE_BY_ID = 'service/gepros/${geproId}/files/${fileId}';

    public static readonly GEPROS_FILTERSTATISTICS = 'service/gepros/filterStatistics';
    public static readonly GEPROS_TABSTATISTICS = 'service/gepros/tabStatistics';

    public static readonly GEPRO_MESSAGES_BY_ID = 'service/gepros/${geproId}/messages';
    public static readonly GEPRO_MESSAGES_BY_ID_STVUSER = 'service/gepros/${geproId}/${stvuser}/messages';

    public static readonly GEPRO_CHANGELOG_BY_ID = 'service/gepros/${geproId}/changelog?preview=${preview}';

    public static readonly GEPRO_ENQUIRIES_BY_ID = 'service/gepros/${geproId}/enquiries';

    public static readonly GEPRO_REPLIES_BY_ID = 'service/gepros/${geproId}/replies';

    public static readonly SERVICE_GEPRO_ENQUIRIES_CANCEL = 'service/gepros/${geproId}/enquiries/cancel';

    // type = {enquiries, reject, close}
    public static readonly SERVICE_NOTIFICATION =  'service/notification/${geproId}/${type}';

    public static readonly GEPRO_TYPE_LIST = 'service/geproTypeList';
    public static readonly SERVICE_GEPRO_TYPE_INFO = 'service/type-infos';
    public static readonly SERVICE_GEPRO_TYPE_INFO_BY_ID = 'service/type-infos/${typeId}';

    public static readonly MAKLERSTRUKTUR_TREE = 'service/maklerstruktur/tree';
    public static readonly MAKLERSTRUKTUR_VTNRS = 'service/maklerstruktur/vtnr';

    public static readonly REFERAT_LIST = 'service/referatList';
    public static readonly REFERAT_LIST_ALL = 'service/referatListAll';

    public static readonly NAME_ROUTING_LIST = 'service/nameRoutingList';
    public static readonly GET_ROUTING_RULES = 'service/routing/rules';
    public static readonly GET_ROUTING_RULESMB = 'service/routing/rulesMB';

    public static readonly ROUTING_UPLOAD = 'service/routing/upload';
    public static readonly ROUTING_MB_UPLOAD = 'service/routing/uploadMB';

    public static readonly SETTINGS = 'service/settings';

    public static readonly DEFAULT_ROUTING_REFERAT = 'service/defaultRoutingReferat';

    public static readonly REFERATE_LIST = 'service/stammdaten';
    public static readonly STAMMDATEN = 'service/stammdaten';
    public static readonly CAPACITY_SAVE = 'service/capacity';
    public static readonly CAPACITY_DELETE = 'service/capacityDelete';
    public static readonly CAPACITY_GET_ALL = 'service/capacityGetAll';

    public static readonly STATUS_TYPE_LIST = 'service/statusTypeList';
    public static readonly ASSIGNABLE_STATUS_TYPE_LIST = 'service/assignableStatusTypeList';

    public static readonly UPLOAD_FILE = 'service/gepros/${geproId}/uploadFile';
    public static readonly UPLOADED_FILES = 'service/gepros/${geproId}/uploadedFiles'; // unused

    public static readonly USER_INFO = 'service/userInfo';
    public static readonly USER = 'service/user';

    public static readonly GEPRO_ROUTES_BY_ID = 'service/gepros/${geproId}/routes';
    public static readonly GEPRO_ROUTES_BY_ID_LSZ = 'service/gepros/${geproId}/routesLsz';


    public static readonly  SERVICE_GET_ALL_AUSBLICK = 'service/getAllAusblick';
    public static readonly  SERVICE_UPDATE_AUSBLICK_SAVE = 'service/saveAusblick';
    public static readonly  SERVICE_UPDATE_AUSBLICK_PUBLISCH = 'service/publischAusblick';
    public static readonly  SERVICE_GET_PUBLISHED_AUSBLICK = 'service/getAusblick';

    public static readonly STELLVERTRETER_GET = 'service/allstellverteter';
    public static readonly STELLVERTRETER_GET_SELECTED = 'service/stellverteter';
    public static readonly STELLVERTRETER_REMOVE = 'service/stellvertreter/${bensl}/delete';
    public static readonly STELLVERTRETER_ADD = 'service/stellvertreter/${bensl}/add';
    public static readonly STELLVERTRETER_INFO_SELECTED_STV = 'service/stellvertreter/${bensl}/info';
    public static readonly LOGIN_INFO = 'service/loginInfo' ;
}

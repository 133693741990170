import {ActivatedRoute, Router} from '@angular/router';
import {GeproUploadComponent} from '../../common/gepro-upload-component/gepro-upload.component';
import {GeproService} from '../../index/service/gepro.service';
import {UserinfoService} from '../../index/service/userinfo.service';
import {GeproDetails} from '../../../interfaces/gepro.interfaces';
import {Component, EventEmitter, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {
    STATUS_NAME_FREIGEGEBEN, STATUS_NAME_LSZ_UNINDEFINED,
    STATUS_NAME_NACHBEARBEITUNG,
    STATUS_NAME_RUECKFRAGE_BEANTWORTET,
    STATUS_NAME_RUECKFRAGE_GESTELLT,
    STATUS_NAME_WIRD_BEARBEITET,
    STATUS_NAME_ZUR_FREIGABE
} from '../../../config/constants';
import {Subscription} from 'rxjs';
import {StatusTypeService} from '../../index/service/status-type.service';
import {StatusTypeRestTO} from '../../index/service/Model/StatusTypeRestTO';
import {DenyGeproService} from '../../work-list/deny-gepro/service/deny-gepro.service';
import {GeproListWithMessageRestTO} from '../../index/service/Model/GeproListWithMessageRestTO';
import {UserService} from '../../../services/user.service';
import {VisibilityService} from '../../../services/visibility.service';
import {NotificationService} from '../../index/service/notification.service';
import {StatesService} from '../../../services/states.service';
import {NxDialogService, NxModalRef} from '@allianz/ngx-ndbx/modal';
import {GeproTypeRestTO} from "../../index/service/Model/GeproTypeRestTO";
import {GeproTypeService} from "../../index/service/gepro-type.service";
import {StellvertreterTO} from "../../index/service/Model/StellvertreterTO";
import {ReferatRestTO} from "../../index/service/Model/ReferatRestTO";
import {FormControl, FormGroup} from "@angular/forms";
import {MaklerstrukturService} from "../../../services/maklerstruktur.service";
import {GeproMsnTreeChecklistComponent} from "../gepro-msn-tree-checklist/gepro-msn-tree-checklist.component";
import {VorgangRouteService} from "../../../services/vorgang-route.service";
import {GeproRestTO} from "../../index/service/Model/GeproRestTO";
import {GeproRouteRestTO} from "../../index/service/Model/gepro-route-rest.to";


@Component({
    selector: 'app-gepro-detail',
    templateUrl: './gepro-detail.component.html',
    styleUrls: ['./gepro-detail.component.scss']
})
export class GeproDetailComponent implements OnInit {
    @ViewChild(GeproMsnTreeChecklistComponent, {static: true})
    maklerstrukturTreeComponent: GeproMsnTreeChecklistComponent;
    geproId: string;
    geproDetails: GeproDetails;
    assignableStatusTypes: StatusTypeRestTO[];
    loading: boolean = true;
    geproServiceReturnedDetails: boolean = false;
    action = true;
    lszEmailExists = false;
    zusatzInfoModel: string;

    selectedCommTab = 0;
    communicationMode: string;

    private sendNotificationArray: Array<string> = new Array<string>('enquiries', 'reject', 'close');

    @ViewChild(GeproUploadComponent, {read: GeproUploadComponent, static: false}) geproUploadComponent: GeproUploadComponent;

    @ViewChild('answerQuestion') templateRef: TemplateRef<any>;
    answerQuestion: NxModalRef<any>;

    @ViewChild('mailErrorModal') templateMailRef: TemplateRef<any>;
    mailErrorModal: NxModalRef<any>;
    public geProList: Array<GeproTypeRestTO> = new Array<GeproTypeRestTO>();
    public geproLsz : string = '';
    public msnLsz : string = '';
    //MSN

    private readonly msnVtnrPattern = '^([\\d]{9})|^([\\d]{2}/[\\d]{3}/[\\d]{4})';
    private readonly msnFormatPattern = '^[FfVvKkPpIiAaSs]/?\\d{4}(/?\\d{2}){0,2}';
    private readonly vtnrFormatPattern = '^\\d.*';
    public msnVtnrFormGroup: FormGroup;
    private boundTextValue: string;

    private routes: Array<GeproRouteRestTO>;
    sucheBtDisabled: boolean;
    msnfound: boolean;
    private subscription: Subscription = new Subscription();
    showLoadingIndicator: boolean;
    private isMSNorVtnrTreeAvailable : boolean = false;
    constructor(private route: ActivatedRoute,
                private geproService: GeproService,
                private statusTypeService: StatusTypeService,
                private userData: UserinfoService,
                private userService: UserService,
                private statesService: StatesService,
                private denyGeproService: DenyGeproService,
                public visibilityService: VisibilityService,
                private vorgangRouteService: VorgangRouteService,
                private maklerstrukturService: MaklerstrukturService,
                private router: Router,
                private notificationService: NotificationService,
                private geproTypeService: GeproTypeService,
                public dialogService: NxDialogService) {
        this.msnVtnrFormGroup = new FormGroup({
            'msnVtnr': new FormControl(this.boundTextValue)
        });
    }

    ngOnInit() {
        this.isMSNorVtnrTreeAvailable=false;
        this.geproId = this.route.snapshot.paramMap.get('geproId');
        this.geproTypeService.queryGeproTypeList().subscribe(data => {
            this.geProList = data;
        });
        this.subscription.add(this.maklerstrukturService.maklerTreeFinishedSubject.subscribe(() => {
            this.showLoadingIndicator = false;
        }));
        this.subscription.add(this.maklerstrukturService.getMaklerTreeSubject().subscribe(tree => {
            if(tree == null){
                this.showLoadingIndicator = false;
            }
        }));
        this.sucheBtDisabled=true;
        this.loadGeproDetails();
    }

    async loadGeproDetails() {
        this.loading = true;
        const geproDetailsSubscription: Subscription = new Subscription();
        geproDetailsSubscription.add(this.geproService.loadGeproDetails(this.geproId).subscribe((geproDetails) => {
           if (geproDetails) {
               this.geproServiceReturnedDetails = true;
               geproDetailsSubscription.unsubscribe();
               this.geproDetails = geproDetails;
               this.zusatzInfoModel=geproDetails.zusatzInfo;
               this.communicationMode = this.isLszUnindefined(this.geproDetails?.statusType?.name) ? 'LSZ' : 'note';
               this.action = this.isNoteTabEnabled();

               this.statusTypeService.queryAssignableStatusTypes().subscribe(
                   assignableStatusTypes => {
                       this.assignableStatusTypes = assignableStatusTypes;
                       this.loading = false;
                       this.activateRelease();
                   }
               );
               // if(this.geproDetails.geproType.name==='Vertragsbearbeitung'){
               //     this.geproTypeService.queryGeproTypeList().subscribe(data => {
               //         this.geProList = data;
               //     });
               // }
           } else {
               this.loading = false;
               this.activateRelease();
           }
        }));
    }
    async loadGeproDetailsLsz(loading: boolean) {
        if(loading) {
            this.loading = loading;
            const geproDetailsSubscription: Subscription = new Subscription();
            geproDetailsSubscription.add(this.geproService.loadGeproDetails(this.geproId).subscribe((geproDetails) => {
                if (geproDetails) {
                    this.geproServiceReturnedDetails = true;
                    geproDetailsSubscription.unsubscribe();
                    this.geproDetails = geproDetails;
                    this.action = this.isNoteTabEnabled();

                    this.statusTypeService.queryAssignableStatusTypes().subscribe(
                        assignableStatusTypes => {
                            this.assignableStatusTypes = assignableStatusTypes;
                            this.loading = false;
                            this.activateRelease();
                        }
                    );
                    // if(this.geproDetails.geproType.name==='Vertragsbearbeitung'){
                    //     this.geproTypeService.queryGeproTypeList().subscribe(data => {
                    //         this.geProList = data;
                    //     });
                    // }
                } else {
                    this.loading = false;
                    this.activateRelease();
                }
            }));
        }
    }

    async changeZusatzInfo(){
        try {
            await this.geproService.saveGeproZusatzInfo(this.geproId, this.zusatzInfoModel).toPromise();
        } catch (error) {
            console.error(error);
        }
    }

    async changeLszGeproType(){
        try {
            await this.geproService.savelsz(this.geproId, this.userData.getCurrentUserData().bensl, this.geproLsz).toPromise();
            this.loadGeproDetails();
        } catch (error) {
            console.error(error);
        }
    }

    commentChanged(isInvalid: boolean, value: string): void {
        const errors = [];
        let zusatzinfo = value;
        if (isInvalid) {
            console.log("zusatzinfo undefined");
            zusatzinfo = undefined;
            errors.push({ error: true });
        }
        this.geproDetails.zusatzInfo = zusatzinfo;
        console.log(this.geproDetails.zusatzInfo.toString());
    }

    async changeLszEmailExists() {
        this.lszEmailExists = true;
    }

    async changeReferatAndMsn(){
        try {
            await this.geproService.saveUnindefinedlsz(this.geproId, this.userData.getCurrentUserData().bensl, this.msnLsz).toPromise();
            this.navigateToWorklist();
        } catch (error) {
            console.error(error);
        }
    }

    onTextChange($event: any): void {
        let text: string = $event;
        if (text) {
            text = text.toLocaleUpperCase().trim();
            if (text.match(this.msnFormatPattern)) {
                text = this.formatText(text, [1, 6, 9], 12);
            } else if (text.match(this.vtnrFormatPattern)) {
                text = this.formatText(text, [2, 6], 11);
            }
            if (text !== this.getValueControl().value) {
                this.getValueControl().setValue(text);
            }
        }
        if (this.msnVtnrFormGroup.valid) {
            this.sucheBtDisabled = false;
            this.msnLsz = text;
        } else {
            this.sucheBtDisabled = true;
        }
    }

    queryForMsnVtnr(): void {
        if (this.msnVtnrFormGroup.valid) {
            this.isMSNorVtnrTreeAvailable=true;
            let preparedMsnVtnr = this.prepareMsnVtnrForQuery(this.getValueControl().value);
            this.showLoadingIndicator = false;
            // this.maklerstrukturTreeComponent.resetTree();

            this.maklerstrukturService.queryMaklerStrukturTree(preparedMsnVtnr, true);

        }
    }

    private prepareMsnVtnrForQuery(text: string): string {
        if (text.match(this.vtnrFormatPattern)) {
            return text.replace(/\//g, '');
        }
        return text;
    }

    getValueControl() {
        return this.msnVtnrFormGroup.controls['msnVtnr'];
    }

    private formatText(text: string, positions: Array<number>, maxlength: number): string {
        let formattedString = text.replace(/\//g, '');
        for (let pos of positions) {
            formattedString = this.checkAddStringAtPosition(formattedString, pos, '/');
        }

        if (formattedString.length > maxlength) {
            formattedString = formattedString.slice(0, maxlength);
        }

        return formattedString;
    }
    private checkAddStringAtPosition(text: string, pos: number, str: string): string {
        if (text.length > pos && text.charAt(pos) != str) {
            return text.slice(0, pos) + str + text.slice(pos);
        }
        return text;
    }

    public changeGeproLsz(geproLsz: any) {
        this.geproLsz = geproLsz.name;
    }

    isLszAndGeproTypNameNotAdjusted(): boolean {
        return this.geproDetails?.erfasserName === 'LSZ'
            && this.isLszGeproToChange(this.geproDetails?.geproType?.name);
    }

    finishedLoading(): boolean {
        return !this.loading;
    }

    editAllowed() {
        if (!this.geproDetails) {
            return false;
        }
        const statusType = this.geproDetails.statusType.name;
        return statusType === STATUS_NAME_WIRD_BEARBEITET || statusType === STATUS_NAME_RUECKFRAGE_GESTELLT ||
            statusType === STATUS_NAME_RUECKFRAGE_BEANTWORTET || statusType === STATUS_NAME_FREIGEGEBEN || statusType === STATUS_NAME_LSZ_UNINDEFINED ||
            this.userData.isMaklerOrMaklerbetreuer();
    }

    editAllowedAndAllowedForRL() {
        if (!this.geproDetails) {
            return false;
        }
        const statusType = this.geproDetails.statusType.name;
        return statusType === STATUS_NAME_WIRD_BEARBEITET || statusType === STATUS_NAME_RUECKFRAGE_GESTELLT ||
            statusType === STATUS_NAME_RUECKFRAGE_BEANTWORTET || statusType === STATUS_NAME_FREIGEGEBEN || statusType === STATUS_NAME_LSZ_UNINDEFINED ||
            this.userData.isMaklerOrMaklerbetreuer()||this.userData.isRoleReferatsleiter();
    }

    documentsEditAllowed() {
        if (!this.geproDetails) {
            return false;
        }
        return this.visibilityService.isEnabled('download') ||
            this.visibilityService.isEnabled('upload');
    }

    documentsEditAllowedBeforWork() {
        if (!this.geproDetails) {
            return false;
        }
        return true;
    }

    isLszGeproToChange(value : any) : boolean{
        return value=='Vertragsbearbeitung' || value=='Postrückläufer LSZ';
    }

    isLszUnindefined(value: any): boolean{
        // console.log("isLszUnindefined: " + value)
        return value == 'LSZ_UNINDEFINED';
    }

    isMBK(): boolean{
        return this.userData.getCurrentUserData().roles[0].indexOf('mb-k')>=-1;
    }

    communicationEditAllowed() {
        if (!this.geproDetails) {
            return false;
        }
        return this.visibilityService.isEnabled('note') || //Notiz
            this.visibilityService.isEnabled('reply') ||   //Rückfrage beantworten
            this.visibilityService.isEnabled('enquiry');
    }

    upload() {
        this.geproUploadComponent.startUpload(this.geproId);
    }

    async startEdit() {
        try {
            await this.geproService.startEdit(this.geproId);
            this.loadGeproDetails();
        } catch (error) {
            throw error;
        }
    }



    async authorizeMe() {
        try {
            await this.geproService.authoriseGepros(this.geproId, this.userData.getCurrentUserData().bensl).toPromise();
            this.loadGeproDetails();
        } catch (error) {
            console.error(error);
        }
    }

     lszCloseForever() {
        const geproDetailsSubscription:Subscription = new Subscription();
        geproDetailsSubscription.add(this.denyGeproService.subscribeToCloseForeverSubject({
            next: request => {
                geproDetailsSubscription.unsubscribe();
                this.geproService.closeForever(this.geproId).subscribe(() => {
                    this.router.navigate(['/workList']);
                });
            }
            , error: error => console.error(error)
        }));
         // let geproListWithMessageRestTO: GeproListWithMessageRestTO = new GeproListWithMessageRestTO();
         // geproListWithMessageRestTO.geproIdList = new Array<string>();
         // geproListWithMessageRestTO.geproIdList.push(this.geproDetails.id);
        this.denyGeproService.closeForeverDialogOpened();
    }

    denyGepro() {
        const geproDetailsSubscription:Subscription = new Subscription();
        geproDetailsSubscription.add(this.denyGeproService.subscribeToDenyReasonSubject({
            next: request => {
                geproDetailsSubscription.unsubscribe();
                let geproListWithMessageRestTO: GeproListWithMessageRestTO = request;
                this.geproService.denyGepro(this.geproDetails.id, geproListWithMessageRestTO.reason).subscribe(() => {
                    this.router.navigate(['/workList']);
                });
            }
            , error: error => console.error(error)
        }));

        let geproListWithMessageRestTO: GeproListWithMessageRestTO = new GeproListWithMessageRestTO();
        geproListWithMessageRestTO.geproIdList = new Array<string>();
        geproListWithMessageRestTO.geproIdList.push(this.geproDetails.id);

        this.denyGeproService.publishDialogOpened(geproListWithMessageRestTO);
    }

    activateQuestion() {
        document.querySelector('#communication').scrollIntoView();
        this.selectedCommTab = 1;
        this.action = false;
        this.communicationMode = 'reply';
    }

    activateRelease() {
        const statusType = this.geproDetails?.statusType?.displayName;
        if (this.visibilityService.isEnabled('processrelease') || this.visibilityService.isEnabled('release') ||
            this.visibilityService.isEnabled('rejectrelease')) {
            document.querySelector('#communication').scrollIntoView();
            this.selectedCommTab = 2;
        } else {
            this.selectedCommTab = 0;
        }
    }


    isCreator(){
        return this.geproDetails && this.geproDetails.erfasserBensl === this.userData.getUser().bensl;
    }

    canSeeRueckfrageStellen() {
        return (this.visibilityService.isVisible('enquiry'));
    }

    canSeeRueckfrageJumpButton() {
        return this.canSeeRueckfrageStellen();
    }

    private showFreigabeTab(): boolean {
        return this.geproDetails && this.geproDetails.geproType.freigabeAmvb === 'FOUR_EYES' &&
            this.visibilityService.isVisible('releasetab');
    }

    isNoteTabEnabled(): boolean {
        return this.visibilityService.isEnabled('note');
    }

    isEnquiryEnabled(): boolean {
        return this.visibilityService.isEnabled('enquiry');
    }

    isNoteButtonFocussable() {
        return this.visibilityService.isVisible('enquiry', this.geproDetails) ? "0" : "-1";
    }

    isActionsTabEnabled() {
        return this.visibilityService.isVisible('note') || this.visibilityService.isVisible('enquiry');
    }

    isFreigabeTabEnabled() {
        return this.visibilityService.isEnabled('releasetab');
    }

    onKeyPress($event: KeyboardEvent, action: boolean) {
        if ($event.key === 'Enter') {
            this.action = action;
        }
    }

    createPdf(): void {
        this.geproService.createPdfFor(this.geproId);
    }

    navigateToWorklist() {
        this.router.navigate(['/workList']);
    }

    showMailErrorModal() {
        this.mailErrorModal = this.dialogService.open(this.templateMailRef, { ariaLabel: 'Fehler E-Mail Benachrichtigung', disableClose: true });
        this.mailErrorModal.afterClosed().subscribe(result => {
            this.mailErrorModal.close();
        });
    }


    loadGeproDetailsSendNotification(notificationType: string) {
        this.loadGeproDetails();
        this.sendNotification(notificationType);
    }

    async sendNotification(notificationType: string) {
        if (this.sendNotificationArray.includes(notificationType)) {
            this.notificationService.createNotification(this.geproId, notificationType).subscribe(() => {
                console.log('Email erstellt!');
            }, e => {
                console.log(e);
                this.showMailErrorModal();
            });
        }
    }

    getErfasserInfo(): string {
        let erfasserInfo = '';
        let erfasserAbteilung = (this.geproDetails!=null&&this.geproDetails?.erfasserAbteilung!=undefined&&this.geproDetails?.erfasserAbteilung!=null && this.geproDetails?.erfasserAbteilung.length > 0)?' (' +this.geproDetails.erfasserAbteilung+ ')':''
        if (this.geproDetails?.erfasserAbteilung && this.geproDetails?.erfasserAbteilung.length > 0&&this.geproDetails?.changeName==this.geproDetails?.erfasserName) {
            if(this.geproDetails?.stvCreatedBensl==this.geproDetails?.erfasserName||this.geproDetails?.stvCreatedBensl==null) {
                erfasserInfo = this.geproDetails?.erfasserName + erfasserAbteilung ;
            }
            else{
                erfasserInfo = 'Stellv. '+this.geproDetails?.stvCreatedBensl + ' für '+this.geproDetails?.erfasserName +erfasserAbteilung;
            }
        } else {
            if(this.geproDetails?.stvCreatedBensl==this.geproDetails?.erfasserName||this.geproDetails?.stvCreatedBensl==null) {
                erfasserInfo = this.geproDetails?.erfasserName+ erfasserAbteilung;
            }
            else{
                erfasserInfo = 'Stellv. '+this.geproDetails?.stvCreatedBensl + ' für '+this.geproDetails?.erfasserName+ erfasserAbteilung ;
            }
        }
        return erfasserInfo;
    }

    refreshGeproAfterDocUpload(docsUploaded: boolean) {
        if (docsUploaded && this.visibilityService.isEnabled('reply')) {
            this.answerQuestion = this.dialogService.open(this.templateRef, { ariaLabel: 'Rückfrage mit Upload beantwortet', disableClose: true });
            this.answerQuestion.afterClosed().subscribe(result => {
                if (result === 'Nein') {
                    this.loadGeproDetails();
                } else if (result === 'Ja') {
                    this.doAnswerQuestion();
                }
                this.answerQuestion.close();
            });
        } else {
            this.loadGeproDetails();
        }
    }

    doAnswerQuestion() {
        this.geproService.postAnswer('Die Rückfrage wurde durch einen Dokumentenupload beantwortet.',
            this.geproDetails.id, this.geproDetails.mitarbeiterBensl)
            .then((_: any) => {
                this.loadGeproDetails();
            }).catch((error) => {
            console.log('add answer error', error);
        });
    }

    getBadgeClass(geproType: StatusTypeRestTO): string {
        if (geproType?.displayName === 'Freigegeben') {
            return 'freigegeben';
        } else if (geproType?.displayName === 'Zur Freigabe') {
            return 'zurFreigabe';
        } else if (geproType?.displayName === 'Nachbearbeitung') {
            return 'nachbearbeitung';
        } else {
            return null;
        }
    }
}

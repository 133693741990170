import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class StringEncodeDecodeService {

    constructor() {}

    public encodeString(stringToEncode: string): string {
        const encodedMsg = window.btoa(unescape(encodeURIComponent(stringToEncode)));
        return encodedMsg;
    }

    public decodeString(stringToDecode: string): string {
        const decodedMsg = decodeURIComponent(escape(window.atob(stringToDecode)));
        return decodedMsg;
    }

    isBase64(str) {
        if (str === '' || str?.trim() === '') {
            return false;
        }
        try {
            return btoa(atob(str)) === str;
        } catch (err) {
            return false;
        }
    }
}

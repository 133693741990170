<div *ngIf="confirmedDocuments().length > 0"
     fxLayout="column"
     fxLayoutAlign="stretch stretch"
     class="container edit-exception">
    <div fxLayout="row" fxLayoutAlign="start center" class="title-container ">
        <nx-icon name="check-circle" size="s" class="check-icon"></nx-icon>
        <h4 nxHeadline="subsection-xsmall" class="nx-margin-left-s nx-margin-bottom-0">Bestätigte Inhalte</h4>
        <button nxIconButton="tertiary"
                type="button"
                class="nx-margin-left-s nx-margin-bottom-0"
                (click)="open = !open">
            <nx-icon [name]="open ? 'chevron-up' : 'chevron-down'" size="s"></nx-icon>
        </button>
    </div>
    <div *ngIf="open" class="divider" fxLayout="row wrap" fxLayoutAlign="start center">
        <div *ngFor="let document of confirmedDocuments()"
             fxLayout="row"
             fxLayoutAlign="start center"
             class="requirement">
            <nx-icon name="check" size="s" class="check-icon"></nx-icon>
            <span class="nx-margin-left-s grow-item font-medium">{{ document?.name }}</span>
        </div>
    </div>
</div>


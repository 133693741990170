import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {GeproService} from '../../index/service/gepro.service';
import {map, switchMap} from 'rxjs/operators';
import {Observable, of, ReplaySubject, Subject, Subscription} from 'rxjs';
import {GeproFile} from '../../../interfaces/gepro.interfaces';
import {GeproTypeRestTO} from '../../index/service/Model/GeproTypeRestTO';
import {DocumentService} from '../../create-gepro/documents/document-upload/services/document.service';

export interface ConfirmedDocument {
    documentId: number;
}

@Component({
    selector: 'app-vorgang-documents',
    templateUrl: './vorgang-documents.component.html',
    styleUrls: ['./vorgang-documents.component.css']
})
export class VorgangDocumentsComponent implements OnDestroy {

    @Input()
    geproType: GeproTypeRestTO;

    @Input()
    confirmedDocuments: Array<ConfirmedDocument>;

    @Output()
    readonly uploadClosedWithDocuments: EventEmitter<boolean> = new EventEmitter<boolean>();

    openModal: boolean;
    showCloseWarning: boolean;

    private readonly geproId$: Subject<string>;
    private readonly documents$: Observable<Array<GeproFile>>;
    private readonly restartUploadAfterRemovalSub: Subscription;
    private readonly closeWarningSub: Subscription;

    constructor(private readonly geproService: GeproService, private readonly documentService: DocumentService) {
        this.geproId$ = new ReplaySubject();
        this.documents$ = this.geproId$.pipe(
            switchMap(id => {
                if (id) {
                    return this.geproService.loadGeproFiles(id).pipe(map(files => files.content));
                }
                return of([]);
            }),
        );
        this.restartUploadAfterRemovalSub = this.documentService.onDocumentRemoved().subscribe(_ => { });
        this.closeWarningSub = this.documentService.onChanges().subscribe(_ => this.showCloseWarning = false);
    }

    ngOnDestroy(): void {
        this.restartUploadAfterRemovalSub.unsubscribe();
        this.closeWarningSub.unsubscribe();
    }

    @Input()
    set geproId(geproId: string) {
        this.documentService.reset();
        this.documentService.setVorgangId(geproId);
        this.documentService.setDeletableAfterUploadFlag(false); // Don't show x for successfully uploaded documents
        this.documentService.setUploadTriggerMode('manual');
        this.geproId$.next(geproId);
    }

    documents(): Observable<Array<GeproFile>> {
        return this.documents$;
    }

    onDownload(document: GeproFile): void {
        this.geproService.download(document);
    }

    documentIds(): Array<number> {
        const documents = this.confirmedDocuments || [];
        return documents.map(document => document.documentId);
    }

    onOpenUploadModal(): void {
        this.documentService.reset();
        this.openModal = true;
    }

    onCloseUploadModal(): void {
        if (!this.documentService.isRunning()) {
            this.showCloseWarning = false;
            this.openModal = false;
            if (this.hasUploadedDocuments())  {
                this.uploadClosedWithDocuments.emit( true);
            } else {
                this.uploadClosedWithDocuments.emit( false);
            }
        } else {
            this.showCloseWarning = true;
        }
    }

    uploadRestrictions(): string {
        return DocumentService.uploadRestrictions();
    }

    startUpload(): void {
        this.documentService.start();
    }

    hasUploadedDocuments(): boolean {
        return this.documentService.hasUploadedDocuments();
    }

    isRunning(): boolean {
        return this.documentService.isRunning();
    }


}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Endpoints} from '../../navigation/endpoints';
import {UserinfoService} from './userinfo.service';

@Injectable()
export class NotificationService {

    constructor(
        public http: HttpClient,
        public userInfoService: UserinfoService
    ) {
    }

    public createNotification(geproId: string, notificationType: string): Observable<any> {
        let url = Endpoints.SERVICE_NOTIFICATION
            .replace('${geproId}', geproId)
            .replace('${type}', notificationType);
        if (this.userInfoService.isCurrentUserStvUser() && !this.userInfoService.getIsWorkingOnRLTab()) {
            url = url.concat('?asStvVonBensl=', this.userInfoService.getCurrentStvUser().bensl);
        }
        return this.http.post(url, {});
    }
}

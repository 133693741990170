import {StatusTypeRestTO} from './StatusTypeRestTO';
import {GeproTypeRestTO} from './GeproTypeRestTO';
import {GeproRouteRestTO} from './gepro-route-rest.to';
import * as moment from 'moment';
import {TickedDocumentRestTO} from './TickedDocumentRestTO';

const DRAFT = 'VORBEREITUNG';

export class GeproRestTO {
    public static readonly FIELD_TRANSLATION_FILTER = 'Relationship';

    public static readonly FIELD_ERFASSER = 'createdBy';
    public static readonly FIELD_CREATED_TS = 'createdTs';
    public static readonly FIELD_LAST_CHANGE_TS = 'lastStatusChange';
    public static readonly FIELD_MITARBEITER = 'mitarbeiterBensl';
    public static readonly FIELD_GEPRO_TYPE = 'geproType';
    public static readonly FIELD_MSN6 = 'msn6';
    public static readonly FIELD_REFERAT = 'zugeordnetesReferat';
    public static readonly FIELD_STATUS_TYPE = 'statusType';
    public static readonly DRAFT_STATUS_TYPE = { name: DRAFT, displayName: '', badgeName: '' };

    id: string;
    geproType: GeproTypeRestTO;
    maklerName: string;
    stvCreatedBensl: string;
    strukturName: string;
    geproInfos: string;
    zusatzInfo: string;
    tickedDocuments: Array<TickedDocumentRestTO>;
    msn6: string;
    routes: Array<GeproRouteRestTO>;
    timestampUtc: moment.Moment;
    changeTimestamp: moment.Moment;
    publishTimestamp: moment.Moment;
    statusType: StatusTypeRestTO;
    mitarbeiterBensl: string;
    mitarbeiterName: string;
    erfasserBensl: string;
    meta: any;
    geproMarkedList: Array<any>;
    wiedervorlageMarked: string;
    wiedervorlageDate: string;
    wiedervorlageBeschreibung: string;
    wiedervorlageUpdated: string;

    public static isDraft(gepro: GeproRestTO): boolean {
        return gepro && gepro.statusType && gepro.statusType.name === DRAFT;
    }

    constructor(id?: string, geproType?: GeproTypeRestTO, maklerName?: string, stvCreatedBensl?: string, strukturName?: string, geproInfos?: string, zusatzInfo?:string, tickedDocuments?: Array<TickedDocumentRestTO>, msn6?: string, routes?: Array<GeproRouteRestTO>,
                timestampUtc?: moment.Moment, changeTimestamp?: moment.Moment, publishTimestamp?: moment.Moment, statusType: StatusTypeRestTO = GeproRestTO.DRAFT_STATUS_TYPE, mitarbeiterBensl?: string, mitarbeiterName?: string,
                erfasserBensl?: string, meta?: any, geproMarkedList?: Array<any>, wiedervorlageMarked?: string, wiedervorlageDate?: string, wiedervorlageBeschreibung?: string, wiedervorlageUpdated?: string) {
        this.id = id;
        this.geproType = geproType;
        this.maklerName = maklerName;
        this.stvCreatedBensl = stvCreatedBensl;
        this.strukturName = strukturName;
        this.geproInfos = geproInfos;
        this.zusatzInfo = zusatzInfo;
        this.tickedDocuments = tickedDocuments;
        this.msn6 = msn6;
        this.routes = routes;
        this.timestampUtc = timestampUtc;
        this.changeTimestamp = changeTimestamp;
        this.publishTimestamp = publishTimestamp;
        this.statusType = statusType;
        this.mitarbeiterBensl = mitarbeiterBensl;
        this.mitarbeiterName = mitarbeiterName;
        this.erfasserBensl = erfasserBensl;
        this.meta = meta;
        this.geproMarkedList = geproMarkedList;
        this.wiedervorlageMarked = wiedervorlageMarked;
        this.wiedervorlageDate = wiedervorlageDate;
        this.wiedervorlageBeschreibung = wiedervorlageBeschreibung;
        this.wiedervorlageUpdated = wiedervorlageUpdated;
    }

    static deserialize(input: any): GeproRestTO {
        return new GeproRestTO(input.id, input.geproType, input.maklerName, input.stvCreatedBensl, input.strukturName, input.geproInfos, input.zusatzInfo, input.routes, input.timestampUtc,
            input.changeTimestamp, input.publishTimestamp, input.statusType, input.mitarbeiterBensl, input.mitarbeiterName, input.erfasserBensl, input.meta,
            input.geproMarkedList, input.wiedervorlageMarked, input.wiedervorlageDate, input.wiedervorlageBeschreibung, input.wiedervorlageUpdated);
    }

    public getId(): string {
        return this.id;
    }
}

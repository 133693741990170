export type RoutingType = 'MSN' | 'NAME' | 'VTNR';

export class GeproRouteRestTO {

    id: number;
    routingType: RoutingType;
    value: string;
    root?: string;
    resolvedName?: string;

    // intended for requests to backend, in order to not have to
    includeChildren?:boolean = false;

    constructor(id: number, routingType: RoutingType, value: string, resolvedName?: string) {
        this.id = id;
        this.routingType = routingType;
        this.value = value;
        this.resolvedName = resolvedName;
    }

    static deserialize(input: any): GeproRouteRestTO {
        return new GeproRouteRestTO(input.id, input.routingType, input.value, input.resolvedName);
    }
}

import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';

const requestUrlFor = (endpoint: string): string => {
    return `${endpoint}`;
};

@Injectable({
    providedIn: 'root'
})
export class DefaultHttpService {

    constructor(public readonly httpClient: HttpClient) {}

    get<T>(endpoint: string, options?: any): Observable<any> {
        if (!options) {
            options = {};
        }
        const requestUrl = requestUrlFor(endpoint);
        return this.httpClient.get(requestUrl, options);
    }

    post(endpoint: string, body: any, options?: any): Observable<any> {
        if (!options) {
            options = {};
        }
        const requestUrl = requestUrlFor(endpoint);
        return this.httpClient.post(requestUrl, body, options);
    }

    delete<T>(endpoint: string, body: any, options?: any): Observable<any> {
        if (!options) {
            options = {};
        }
        options.body = body;
        const requestUrl = requestUrlFor(endpoint);
        return this.httpClient.delete(requestUrl, options);
    }

    put<T>(endpoint: string, body: any, options?: any): Observable<any> {
        if (!options) {
            options = {};
        }
        const requestUrl = requestUrlFor(endpoint);
        return this.httpClient.put(requestUrl, body, options);
    }
}

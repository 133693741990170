import {ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {DialogComponent} from './dialog.component';
import {GeproService} from '../../index/service/gepro.service';

const TIMESTAMP_FORMAT = 'DD.MM.YYYY';

@Component({
    selector: 'app-dms-link-dialog',
    templateUrl: './dms-link-dialog.component.html',
    styleUrls: ['./dms-link-dialog.component.css']
})
export class DmsLinkDialogComponent {

    dmslink: any;

    @Output()
    saveLink = new EventEmitter();

    @ViewChild(DialogComponent, {static: true})
    dialog: DialogComponent;

    constructor(private changeDetectorRef: ChangeDetectorRef, private geproService: GeproService) {

    }

    show(dmsLink: any): void {
        this.dmslink = dmsLink;
        this.dialog.show();
    }

    save() {
        this.dialog.close();
        this.saveLink.emit(this.dmslink);
    }

    label(): string {
        return 'Link zum DMS';
    }

}

import {AbstractType} from './AbstractType';

export class DocumentRestTO extends AbstractType {
    public static readonly TYPE_FORMULAR = 'FORMULAR';
    public static readonly TYPE_LINK = 'LINK';

    fileName: string;
    lastUpdated: any;
    doctype: string;
    valid: boolean;
}


<div style="padding-top: 15px; margin-bottom: 8px">
    <nx-radio-toggle nxStyle="small" [ngModel]="selectedCommunicationMode">
        <!--
        <nx-radio-toggle-button nxValue="note">Notiz</nx-radio-toggle-button>
        <nx-radio-toggle-button nxValue="enquiry">Rückfrage an Ersteller</nx-radio-toggle-button>
        <nx-radio-toggle-button nxValue="reply">Rückfrage beantworten</nx-radio-toggle-button>
        <nx-radio-toggle-button nxValue="involve-third-person">Kommunikation mit weiterer Person</nx-radio-toggle-button>
        -->
        <nx-radio-toggle-button
            *ngFor="let button of communicationButtons"
            [nxValue]="button.value"
            [nxDisabled]="!button.enabled"
            (change)="selectedCommunicationMode=button.value"
            [nxTooltip]="this.determineTooltip(button.value)"
        >
            {{button.label}}
        </nx-radio-toggle-button>
    </nx-radio-toggle>
</div>

<div *ngIf="selectedCommunicationMode==='reply'" class="highlight">
    <nx-icon [name]="geproHistoryService.smallestQuestion?.$icon" nxTooltip="{{geproHistoryService.smallestQuestion?.$iconExplanation}}"
             class="nx-margin-right-xs"
             size="auto"></nx-icon>
    <app-timestamp [timestamp]="geproHistoryService.smallestQuestion?.createdTs"></app-timestamp>
    {{printEventCreatorInfo(geproHistoryService.smallestQuestion)}}
    <div class="historyBody nx-margin-left-m">
        <div [innerHTML]="geproHistoryService.smallestQuestion?.text"
             class="event-stream--item-note d-inline-flex"></div>
    </div>
</div>

<div *ngIf="selectedCommunicationMode==='involve-third-person'">
    <nx-formfield>
        <input
            nxInput
            placeholder="Empfänger auswählen..."
            type="search"
            #recipientInput
            [nxAutocomplete]="auto1"
            [(ngModel)]="recipient"
            (input)="filter(recipientInput.value)"
        />
        <nx-autocomplete #auto1="nxAutocomplete">
            <nx-autocomplete-option
                *ngFor="let option of filteredOptions"
                [value]="option.fullName+' ('+option.department+')'"
            >
                {{ option.fullName }} ({{ option.department }})
            </nx-autocomplete-option>
        </nx-autocomplete>
    </nx-formfield>
    <nx-formfield class="thirdPersonMode">
        <nx-dropdown [(ngModel)]="thirdPersonCommMode">
            <nx-dropdown-item nxValue="note">Notiz</nx-dropdown-item>
            <nx-dropdown-item
                nxValue="enquiry" aria-selected="true"
                [disabled]="!visibilityService.isEnabled('enquiry')">
                Rückfrage
            </nx-dropdown-item>
        </nx-dropdown>
    </nx-formfield>
</div>

<div *ngIf="selectedCommunicationMode === 'LSZ'">
    <h5>Erläuterung: Bitte schicken Sie das Post-Schriftstück zu</h5>
</div>

<div class="nx-margin-bottom-m">
    <app-restricted-text-area #textArea
                              [maxLength]="determineTextAreaMaxLength()"
                              [(ngModel)]="message"
                              placeholder="{{determineTextAreaPlaceholder()}}">
    </app-restricted-text-area>
</div>
<button
    (click)="communicate()"
    class="js-reply-button-visibility sendBt"
    nxButton="primary medium"
    type="button"
    [disabled]="(this.message.trim().length == 0 && selectedCommunicationMode !== 'LSZ') || textArea.isInvalid() || recipientIsMissing()">
    Senden
</button>
<app-lsz-email-modal #lszEMailModal></app-lsz-email-modal>
<!--
<div>
    Recipient: {{recipient}} <br/>
    Communication mode: {{selectedCommunicationMode}}<br/>
    Third person mode: {{thirdPersonCommMode}}<br/>
</div>
-->

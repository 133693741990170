import {Component, Input, OnInit} from '@angular/core';
import {DocumentService} from '../../index/service/document.service';

@Component({
    selector: 'app-document-download-button',
    templateUrl: './document-download-button.component.html',
    styleUrls: ['./document-download-button.component.css']
})
export class DocumentDownloadButtonComponent {

    @Input()
    private documentId: number;

    @Input()
    public disabled: boolean;

    constructor(private documentService: DocumentService) {
    }

    public downloadDocument() {
        this.documentService.getDocument(this.documentId)
            .subscribe(
                data => {
                    this.downloadFile(data);
                }
            );
    }

    private downloadFile(data: any) {

        const element = document.createElement('a');
        element.href = URL.createObjectURL(data.file);
        element.download = data.filename;
        document.body.appendChild(element);
        element.click();
    }
}

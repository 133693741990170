<nx-card class="container">
    <nx-card-header>
        <div fxLayout="row" fxLayoutAlign="space-between center" class="header">
            <h3 nxHeadline="subsection-medium" class="nx-margin-bottom-0">{{ label }}</h3>
            <ng-template [ngTemplateOutlet]="buttonTemplate"></ng-template>
        </div>
        <hr>
    </nx-card-header>
    <div class="content">
        <ng-content></ng-content>
    </div>
</nx-card>

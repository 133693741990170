import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {VorgangDocumentsComponent} from './vorgang-documents.component';
import {ConfirmedDocumentsComponent} from './confirmed-documents/confirmed-documents.component';
import {DocumentListComponent} from './document-list/document-list.component';
import {NxButtonModule} from '@allianz/ngx-ndbx/button';
import {NxHeadlineModule} from '@allianz/ngx-ndbx/headline';
import {NxLinkModule} from '@allianz/ngx-ndbx/link';
import {NxMessageModule} from '@allianz/ngx-ndbx/message';
import {NxModalModule} from '@allianz/ngx-ndbx/modal';
import {NxIconModule} from '@allianz/ngx-ndbx/icon';
import {LabeledContainerModule} from '../../common/labeled-container/labeled-container.module';
import {NxTableModule} from '@allianz/ngx-ndbx/table';
import {FlexModule} from '@angular/flex-layout';
import {DiassCommonsModule} from '../../common/diass-commons.module';
import {TimestampModule} from '../../common/timestamp/timestamp.module';
import {DocumentUploadModule} from '../../create-gepro/documents/document-upload/document-upload.module';
import {NxTooltipModule} from '@allianz/ngx-ndbx/tooltip';

@NgModule({
    declarations: [VorgangDocumentsComponent, ConfirmedDocumentsComponent, DocumentListComponent],
    exports: [
        VorgangDocumentsComponent
    ],
    imports: [
        CommonModule,
        DiassCommonsModule,
        NxButtonModule,
        NxIconModule,
        LabeledContainerModule,
        NxHeadlineModule,
        NxTableModule,
        NxLinkModule,
        FlexModule,
        DiassCommonsModule,
        TimestampModule,
        NxModalModule,
        DocumentUploadModule,
        NxTooltipModule,
        NxMessageModule
    ]
})
export class VorgangDocumentsModule {
}

<app-dialog >
    <div>
        <div>
            <h3 nxHeadline="subsection-medium" class="u-text-align-left bold">
                Vorschau
            </h3>
        </div>
        <div class="vorschau" >
            <nx-expansion-panel id="versionshinweise" [expanded]="true">
                <nx-expansion-panel-header>
                    <nx-expansion-panel-title class="margin-left">Versionshinweise
                        vom {{ getVersionshinweisDatum(editedHinweise) }}</nx-expansion-panel-title>
                </nx-expansion-panel-header>

                <div class="hinweis">
                    <span [innerHTML]="editedHinweise?.beschreibung"></span>
                </div>
                <div *ngIf="editedHinweise?.tickedDocuments?.length>0">
                    <span style="margin-top: 15px"><b>Datei-Anhänge</b></span>
                </div>
                <div nxTableRow *ngFor="let document of editedHinweise.tickedDocuments">
                    <div id="tag" fxLayout="row" fxLayoutAlign="start center" class="tag nx-margin-top-2m">
                <span><nx-icon name="pdf" size='s' class="nx-margin-right-s pdficon"></nx-icon>
                    <nx-link (click)="downloadDocument(document)"> {{document.name}}</nx-link>
                </span>
                    </div>
                </div>
            </nx-expansion-panel>
        </div>
    </div>
    <div class="absolute_bottom">
        <div class="u-text-center">
            <button type="button" class="mar_left"
                    nxButton="small-medium"
                    (click)="close()" cdkFocusInitial>
                Schließen
            </button>
        </div>
    </div>
</app-dialog>



<button nxIconButton="tertiary middle"
        [nxContextMenuTriggerFor]="menu"
        [nxContextMenuTriggerData]="{item : selectedGepro}"
        scrollStrategy="reposition"
        type="button"
        [title]="'Kontextmenü öffnen'"
        class="nx-margin-bottom-0 vorgangContextMenu">
    <nx-icon aria-hidden="true" name="ellipsis-h"></nx-icon>
</button>

 <nx-context-menu #menu="nxContextMenu" [title]="'Kontextmenü'">
     <button *ngIf="isAnsehenAllowed()" nxContextMenuItem type="button" class="contextMenuGotoButton"
             (click)="goToGeproInAnsehenModus()">
         <nx-icon aria-hidden="true" name="arrow-right"></nx-icon>
         {{getAnsehenText()}}
     </button>
     <button *ngIf="isBearbeitenAllowed()" nxContextMenuItem type="button" class="contextMenuEditButton"
             (click)="goToGeproInBearbeitungModus()">
         <nx-icon aria-hidden="true" name="arrow-right"></nx-icon>
         Bearbeiten
     </button>
     <button *ngIf="isDeleteAllowed()" nxContextMenuItem type="button" class="contextMenuDeleteButton"
             (click)="abortModalComponent.show()">
         <nx-icon aria-hidden="true" name="arrow-right"></nx-icon>
         Löschen
     </button>
     <button *ngIf="isReturnAllowed()" nxContextMenuItem type="button"
             (click)="returnGepro(selectedGepro.id)">
         <nx-icon aria-hidden="true" name="arrow-right"></nx-icon>
         Zurückweisen
     </button>

 </nx-context-menu>

<app-abort-modal #abortModalComponent [gepro]="selectedGepro" [titles]="abortModalTitles"
                 (confirmed)="deleteGeproDraft()"></app-abort-modal>


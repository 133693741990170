import {EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {GeproRestTO} from '../../index/service/Model/GeproRestTO';
import {Subscription} from 'rxjs';
import {CreateGeproService} from '../../../services/create-gepro.service';

export class BaseComponent implements OnInit, OnDestroy {

    /*
    Contains current gepro and should be modified with the current data
     */
    public gepro: GeproRestTO;

    public subscription: Subscription = new Subscription();

    @Output()
    public saveSuccessful: EventEmitter<boolean> = new EventEmitter<boolean>();

    /*
    Should be overwritten with the name in CreateGeproService multiSteps
     */
    public pageName = 'specify';

    constructor(public createGeproService: CreateGeproService) {
    }

    /*Dont override this*/
    ngOnInit() {
        this.callGeproObservables();
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /*
    Subscribes to gepro changes and saves the gepro when saveIntedWas Triggered (Is triggered when "Weiter" is clicked)
     */
    public callGeproObservables() {
        this.subscription.add(this.createGeproService.geproObservable().subscribe(data => {
            this.gepro = data;
        }));

        this.subscription.add(this.createGeproService.saveIntentObservable().subscribe(() => {
            //this should work when the gepro was modified while the user was on page
            this.createGeproService.saveGepro(this.gepro);
        }));
    }


    public setNextDisabled(value: boolean) {
        this.createGeproService.setNextDisabled(value);
    }

    public getValidationErrors(): any[] {
        if (this.pageName != 'specify') {
            return this.createGeproService.getValidationErrors(this.pageName);
        } else {
            this.printPageNameNotSpecifiedError();
        }
    }

    public setValidationErrors(errors: any[]){
        if (this.pageName != 'specify') {
            this.createGeproService.setValidationErrors(this.pageName, errors);
        } else {
            this.printPageNameNotSpecifiedError();
        }
    }

    public setIsCompleted(completed: boolean){
        if (this.pageName != 'specify') {
            this.createGeproService.setIsCompleted(this.pageName, completed);
        } else {
            this.printPageNameNotSpecifiedError();
        }
    }

    private printPageNameNotSpecifiedError() {
        console.error('Page name not specified. Please Set pageName Variable');
    }


}

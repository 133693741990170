import {Component, OnInit} from '@angular/core';
import {UserinfoService} from '../../index/service/userinfo.service';

@Component({
    selector: 'app-dnd-sidebar',
    templateUrl: './dnd-sidebar.component.html',
    styleUrls: ['./dnd-sidebar.component.scss']
})
export class DndSidebarComponent implements OnInit {

    showSidebar: boolean = false;
    selectedCategory: string = 'employees';

    constructor(public userinfoService: UserinfoService) {
    }

    ngOnInit() {
    }

    toggleSidebar() {
        this.showSidebar = !this.showSidebar;
    }

    selectReferateCategory() {
        this.selectedCategory='referate';
    }

    selectEmployeesCategory() {
        this.selectedCategory='employees';
    }

}

import {Component, Input} from '@angular/core';
import {DocumentModel} from '../services/document.model';
import {DocumentService} from '../services/document.service';
import {GeproService} from '../../../../index/service/gepro.service';

@Component({
    selector: 'app-document-view',
    templateUrl: './document-view.component.html',
    styleUrls: ['./document-view.component.css']
})
export class DocumentViewComponent {

    @Input()
    deleteEnabled: boolean = true;

    constructor(private readonly documentService: DocumentService,
                private readonly geproService: GeproService) { }

    isLoading(): boolean {
        return this.documentService.isLoading();
    }

    documents(): Array<DocumentModel> {
        return this.documentService.documents();
    }

    remove(document: DocumentModel): void {
        this.documentService.remove(document);
    }

    download(documentToDownload: DocumentModel): void {
        this.geproService.download(documentToDownload.uploadedFile);
    }

    hasError(): boolean {
        return this.documentService.hasError();
    }

    errorMessage(): string {
        return this.documentService.errorMessage();
    }

    hasUploads(): boolean {
        return this.documents().length > 0;
    }

}

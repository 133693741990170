<nx-formfield id="filter-Auswahl" nxLabel={{name}}>
    <nx-dropdown #filterDropdown (selectionChange)="onSelectionChanged()" [(nxValue)]="selectedFilters" [nxFilterPlaceholder]="[name]+ ' suchen...'" [nxIsMultiselect]="true"
                 [nxShowFilter]="true"
                 [nxDisabled]="disabled"
                 id="geproSelection">
        <nx-dropdown-item *ngFor="let filterEntry of entries" [nxValue]="filterEntry.value"> {{filterEntry.displayName}} ({{filterEntry.count}})
        </nx-dropdown-item>
    </nx-dropdown>
    <nx-error nxFormfieldError>
        Bitte Status auswählen
    </nx-error>
</nx-formfield>

export class UserMessage {

    message: string;
    isError: boolean;

    constructor(message: string, isError: boolean) {
        this.message = message;
        this.isError = isError;
    }
}

<button title="Datei hochladen bzw. austauschen"
        [disabled]="disabled"
        (click)="openFileInputDialog()"
        nxIconButton="secondary" aria-label="Download" type="button" class="btDownload js-download">
    <nx-icon name="arrow-upload" size="auto"></nx-icon>
</button>
<input #fileInput
       type="file"
       accept=".pdf"
       class="hidden" (change)="onFileUpload($event.target.files); fileInput.value = null">

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Endpoints} from '../../navigation/endpoints';
import {UserTO} from './Model/UserTO';
import {AusblickTO} from './Model/AusblickTO';


@Injectable()
export class AusblickService {

    constructor(public http: HttpClient) {
    }

    public getPublishedAusblick(): Observable<any> {
        return this.http.get(Endpoints.SERVICE_GET_PUBLISHED_AUSBLICK);
    }

    public getAllAusblick(): Observable<any> {
        return this.http.get(Endpoints.SERVICE_GET_ALL_AUSBLICK);
    }

    public saveAusblick(ausblickText: AusblickTO): Promise<any> {
        return this.http.post( Endpoints.SERVICE_UPDATE_AUSBLICK_SAVE, ausblickText).toPromise();
    }

    public publishAusblick(ausblickText: AusblickTO): Promise<any> {
        return this.http.post(Endpoints.SERVICE_UPDATE_AUSBLICK_PUBLISCH, ausblickText).toPromise();
    }

}

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {GeproRestTO} from '../../index/service/Model/GeproRestTO';
import {STATUS_NAME_RUECKFRAGE_BEANTWORTET, STATUS_NAME_ZUGETEILT} from '../../../config/constants';
import {GeproService} from '../../index/service/gepro.service';
import {UserinfoService} from '../../index/service/userinfo.service';
import {VisibilityService} from '../../../services/visibility.service';
import {NxMessageToastService} from '@allianz/ngx-ndbx/message';
import {AbortModalTitles} from '../../create-gepro/abort-modal/abort-modal.component';
import {VorgangTabService} from '../../index/service/vorgang-tab.service';
import {Subscription} from 'rxjs';
import {GeproListWithMessageRestTO} from '../../index/service/Model/GeproListWithMessageRestTO';
import {DenyGeproService} from '../deny-gepro/service/deny-gepro.service';

@Component({
  selector: 'work-list-contextmenu-cell',
  templateUrl: './work-list-contextmenu-cell.component.html',
  styleUrls: ['./work-list-contextmenu-cell.component.scss']
})
export class WorkListContextmenuCellComponent implements OnInit, OnDestroy {

  @Input()
  selectedGepro;

  @Output()
  openGepro = new EventEmitter();

  abortModalTitles: AbortModalTitles = {
      abortButtonText: 'Abbrechen',
      confirmButtonText: 'Vorgang löschen',
      textHeadline1: 'Möchten Sie den Vorgang wirklich',
      textHeadline2: 'endgültig löschen?',
      textSubheadline: 'Diese Aktion kann nicht widerrufen werden.'
    };

  private readonly editPermissions = ['reply', 'enquiry', 'reject', 'close'];

  private subscription: Subscription = new Subscription();

  constructor(private geproService: GeproService,
              public userinfoService: UserinfoService,
              private visibilityService: VisibilityService,
              private messageToastService: NxMessageToastService,
              private vorgangTabService: VorgangTabService,
              private denyGeproService: DenyGeproService) { }

  ngOnInit() {
  }

  ngOnDestroy() {
      this.subscription.unsubscribe();
  }

    goToGeproInAnsehenModus()  {
      this.showGepro();
  }

  goToGeproInBearbeitungModus() {
        if (this.selectedGepro.statusType.name !== STATUS_NAME_ZUGETEILT &&
            this.selectedGepro.statusType.name !== STATUS_NAME_RUECKFRAGE_BEANTWORTET) {
            this.goToGeproAfterAuthorizeMe(this.selectedGepro);
        } else {
            this.geproService.startEdit(this.selectedGepro.id).then(
                _ => {
                    this.showGepro();
                }
            );
        }
    }

    goToGeproAfterAuthorizeMe(item: GeproRestTO) {
        this.geproService.authoriseGepros(item.id, this.userinfoService.getUser().bensl).subscribe(
            _ => {
                // das Blöde ist, dass man hier eine Liste bekommt ...
                // ich gehe mal davon aus, dass man hier nur hingelangt, wenn das Zuteilen für den Gepro geklappt hat
                this.selectedGepro.statusType.name = STATUS_NAME_ZUGETEILT;
                this.goToGeproInBearbeitungModus();
            }
        );
    }

    showGepro() {
        this.openGepro.emit(this.selectedGepro);
    }

    isAnsehenAllowed(): boolean {
        return this.visibilityService.isEnabled('view', this.selectedGepro);
    }

    isDeleteAllowed(): boolean {
        return this.visibilityService.isEnabled('delete', this.selectedGepro) &&
            this.userinfoService.isCurrentUserLoggedinUser();
    }

    isBearbeitenAllowed(): boolean {
      return (this.visibilityService.isEnabled('assign', this.selectedGepro) ||
          this.visibilityService.isEnabled('edit', this.selectedGepro));
    }

    isEditMode(): boolean {
      return this.editPermissions
          .map(functionName => this.visibilityService.isEnabled(functionName, this.selectedGepro))
          .filter(enabled => enabled).length > 0;
    }

    private getAnsehenText(): string {
        return (this.isEditMode() && this.selectedGepro.mitarbeiterName === this.userinfoService.getUser().name
            && this.selectedGepro.statusType.name !== STATUS_NAME_RUECKFRAGE_BEANTWORTET) ?
            'Bearbeiten' : 'Ansehen';
    }


    deleteGeproDraft(): void {
         this.subscription.add(this.geproService.deleteGepro(this.selectedGepro.id).subscribe(
            () => {
                this.messageToastService.open('Vorgang gelöscht.', {context: 'success', duration: 5000});
                this.vorgangTabService.tabChangedSubject.next();
            },
            _ => {
                this.messageToastService.open('Vorgang konnte nicht gelöscht werden.', {context: 'info', duration: 5000});
            }
        ));
    }

    isReturnAllowed() {
        return (this.visibilityService.isEnabled('return', this.selectedGepro));
  }

    returnGepro(selectedGeproId) {
        const geproListWithMessageRestTO: GeproListWithMessageRestTO = new GeproListWithMessageRestTO();
        geproListWithMessageRestTO.geproIdList = new Array<string>();
        geproListWithMessageRestTO.geproIdList.push(selectedGeproId);

        this.denyGeproService.publishDialogOpened(geproListWithMessageRestTO);
    }

}

import {Component, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, ChildActivationEnd, Router} from '@angular/router';
import {CreateGeproService, Step} from '../../../services/create-gepro.service';
import {Subscription} from 'rxjs';
import {GeproRestTO} from '../../index/service/Model/GeproRestTO';
import {HttpErrorResponse} from '@angular/common/http';
import {AbortModalComponent} from '../abort-modal/abort-modal.component';
import {ShellStepperComponent} from '../shell-stepper/shell-stepper.component';
import {GeproService} from '../../index/service/gepro.service';
import {NxMessageToastService} from '@allianz/ngx-ndbx/message';
import {Pages} from '../../navigation/pages';
import {MatHorizontalStepper} from '@angular/material/stepper';



@Component({
    selector: 'app-shell',
    templateUrl: './shell.component.html',
    styleUrls: ['./shell.component.scss']
})
export class ShellComponent implements OnInit {

    multiSteps: Step[] = [];
    currentStep: Step = null;

    nextDisabled = false;

    nextAction: any;

    lastSave: string | null = null;

    error: HttpErrorResponse | null = null;

    gepro: GeproRestTO;

    // stepper: NxMultiStepperComponent;
    stepper: MatHorizontalStepper;

    savingGepro = false;

    abortModalOpened = false;

    redirectUrl = "";
    redirecting = false;

    @ViewChild(AbortModalComponent, {static: false}) abortModalComponent: AbortModalComponent;

    @ViewChild(ShellStepperComponent, {static: false}) shellstepperComponent: ShellStepperComponent;

    private readonly subscription: Subscription = new Subscription();

    constructor(private router: Router,
                private route: ActivatedRoute,
                public createGeproService: CreateGeproService,
                private geproService: GeproService,
                private messageToastService: NxMessageToastService) {
        this.multiSteps = this.createGeproService.getAllSteps();
        this.currentStep = this.multiSteps[0];
    }


    ngOnInit() {
        this.autoLoadGeproDraft();
        this.listenToRouteChanges();
        this.subscribeToGeproChanges();
        this.subscribeToNavigation();
        this.subscribeToNextAllowed();
        this.subscribeToErrorOccured();
        this.subscribeToValidationErrors();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
        this.createGeproService.resetGeproObserable();
    }

    /*
        Methods to load draft or current gepro
     */
    private autoLoadGeproDraft() { // Maybe this is handled by listen to route changes as well
        this.createGeproService.resetGeproObservables();
        const geproId = this.getGeproIdFromUrl();
        if (geproId) {
            this.createGeproService.loadGeproById(geproId);
        } else {
            if (!this.savingGepro) {
                this.savingGepro = true;
                this.createGeproService.saveGepro(new GeproRestTO()).then(() => {
                    this.savingGepro = false;
                    this.selectCurrentStepByUrl();
                }).catch(() => {
                    this.savingGepro = false;
                });
            }
        }

    }

    private listenToRouteChanges() {
        this.subscription.add(this.router.events.subscribe(params => {
            if (params instanceof ChildActivationEnd) {
                this.selectCurrentStepByUrl();
                if (this.route.snapshot.firstChild) {
                    let geproId = this.route.snapshot.firstChild.url.toString();
                    geproId = geproId.split(',')[1];
                    if (this.gepro.id != geproId) {
                        this.autoLoadGeproDraft();
                    }
                }

            }

        }));
    }

    private selectCurrentStepByUrl() {
        const currentStep = this.createGeproService.getStepDataByRoute(this.getCurrentChild());
        if (currentStep) {
            this.currentStep = currentStep;
        }
    }

    /*
    method to do post save actions
     */
    private subscribeToGeproChanges() {
        this.subscription.add(this.createGeproService.geproObservable().subscribe(data => {
            if (data) {
                this.gepro = data;
                if (this.nextAction) {
                    this.nextAction();
                    this.nextAction = null;
                }
                if (this.gepro && this.gepro.id) {
                    this.router.navigate(['create-gepro/' + this.currentStep.route + '/' + this.gepro.id], {replaceUrl: true});
                    if (this.stepper) { this.stepper.selectedIndex = this.currentStep.index; }

                } else if (!this.getGeproIdFromUrl()) {
                    this.router.navigate(['create-gepro/' + this.multiSteps[0].route]);
                    if (this.stepper) { this.stepper.selectedIndex = 0; }
                }
            }
        }));
    }

    /*
    subscription to handle edit buttons on overview
     */
    private subscribeToNavigation() {
        let self = this;
        this.subscription.add(this.createGeproService.navigationIntentObservable().subscribe((data) => {
            setTimeout(() => {
                this.sendSaveIntent();
                this.nextAction = self.shellstepperComponent?.changeStep(data);
            });
        }));
    }

    /*
    subscriptions do handle current state
     */
    private subscribeToNextAllowed() {
        this.subscription.add(this.createGeproService.nextDisabledObservable().subscribe((value) => {
            setTimeout(() => {
                this.nextDisabled = value;
            });

        }));

    }

    private subscribeToErrorOccured() {
        this.subscription.add(this.createGeproService.errorOccuredObservable().subscribe((val) => {
            if (val instanceof HttpErrorResponse) {
                this.nextAction = null;
                this.error = val;
            } else {
                this.error = null;
            }
        }));
    }


    /*
    Template Buttons functions
     */


    next() {
        this.sendSaveIntent();
        this.nextAction = this.stepper.next.bind(this.stepper);
    }

    previous() {
        this.sendSaveIntent();
        this.nextAction = this.stepper.previous.bind(this.stepper);
    }

    send() {
        this.sendSendIntent();
    }

    private sendSaveIntent() {
        this.lastSave = this.getCurrentChild();
        this.createGeproService.sendSaveIntend();
    }

    isLastStep() {
        return (this.currentStep.index === this.multiSteps[this.multiSteps.length - 1].index);
    }

    isSecondStep() {
        console.log(this.gepro);
        return (this.currentStep.index === this.multiSteps[this.multiSteps.length - 3].index);
    }

    isThirdStep() {
        console.log("Anzahl von hinzugefügten documenten insgesamt: " + this.gepro.tickedDocuments.length + " , Anzahl von required documenten"+this.gepro.geproType.requiredDocuments.length);
        return (this.currentStep.index === this.multiSteps[this.multiSteps.length - 2].index);
    }

    private sendSendIntent() {
        this.lastSave = this.getCurrentChild();
        this.createGeproService.sendSendIntend();
    }


    /*
    Handler for Stepper clicked
     */
    stepChanged($event) {
        if (this.lastSave === null || this.getCurrentChild() != this.lastSave) { //Stepper can change when stepper is clicked or when route is changed.
            this.sendSaveIntent();
        }
        this.currentStep = $event;
    }

    gotoStep($event) {
        let idx = $event.index;
        if (idx && idx < 0) {
            idx = 0;
        }
        if (this.stepper) { this.stepper.selectedIndex = idx; }
    }


    /*
    Helper functions
     */
    setStepper($event: MatHorizontalStepper) {
        this.stepper = $event;
    }


    getCurrentChild() {
        if (this.router.url) {
            const url = this.router.url;
            const currentChild = url.split('/')[2];
            return currentChild;
        } else {
            return null;
        }

    }

    private getGeproIdFromUrl() {
        if (this.route.snapshot.firstChild) {
            let geproId = this.route.snapshot.firstChild.url.toString();
            geproId = geproId.split(',')[1];
            return geproId;
        } else {
            return null;
        }
    }

    getHasPrevious() {
        return (this.currentStep && this.currentStep.index != 0);
    }

    private subscribeToValidationErrors() {
        this.createGeproService.validationErrorsChangedObservable().subscribe();
    }

    deleteGepro(url: string): void {
        this.subscription.add(this.geproService.deleteGepro(this.gepro.id).subscribe(
            () => {
                this.messageToastService.open('Vorgang gelöscht.', {context: 'success', duration: 5000});
                this.redirectUrl = url;
                this.redirecting = true;
                this.navigateToRedirectUrl();
            },
            _ => {
                this.messageToastService.open('Vorgang konnte nicht gelöscht werden.', {context: 'info', duration: 5000});
            }
        ));
    }

    navigateToUrlAfterSave(url: string) {
        this.sendSaveIntent();
        this.redirectUrl = url;
        this.redirecting = true;
    }

    navigateToRedirectUrl() {
        if(this.redirectUrl) {
            this.router.navigate([this.redirectUrl]);
            this.redirectUrl = "";
        }
    }

    toggleAbortModalOpened() {
        this.abortModalComponent.redirectTo = Pages.PATH_WORKLIST;
        this.redirectUrl = Pages.PATH_WORKLIST;
    }

    /**
     * Fix one day and fetch definition from backend.
     */
    subtitle(): string {
        if (this.currentStep) {
            // Weitere Änderung / Diverses should not show subtitle
            const notSpecialType = this.gepro && this.gepro.geproType && this.gepro.geproType.id !== 28;
            const notDocumentStep = this.currentStep.name !== 'Dokumente';
            if (notDocumentStep || notSpecialType) {
                return this.currentStep.subtitle;
            }
        }
        return '';
    }

    onActivateRouter(componentReference) {

        //Below will subscribe to the saveSuccessful emitter
        if(componentReference && componentReference.saveSuccessful) {
            componentReference.saveSuccessful.subscribe((data) => {
                if(this.redirecting) {
                    this.navigateToRedirectUrl();
                    this.redirecting = false;
                }
            })
        }
    }
}

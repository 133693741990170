import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {StellvertreterTO} from './Model/StellvertreterTO';
import {Endpoints} from '../../navigation/endpoints';

@Injectable({
    providedIn: 'root'
})
export class StellvertreterService {

    constructor(public http: HttpClient) {
    }

    public getAllStellvertreter(): Observable<any> {
        return this.http.get(Endpoints.STELLVERTRETER_GET);
    }

    delete(stellvertreter: StellvertreterTO): Observable<any> {
        return this.http.post(Endpoints.STELLVERTRETER_REMOVE.replace('${bensl}', stellvertreter.bensl), '');
    }

    add(stellvertreter: StellvertreterTO): Observable<any> {
        return this.http.post(Endpoints.STELLVERTRETER_ADD.replace('${bensl}', stellvertreter.bensl), '');
    }

    public getSelectedStellvertreter(): Observable<any> {
        return this.http.get(Endpoints.STELLVERTRETER_GET_SELECTED);
    }

    public getInfosForSelectesStellvertreter(stvBensl: string): Observable<any> {
        return this.http.get(Endpoints.STELLVERTRETER_INFO_SELECTED_STV.replace('${bensl}', stvBensl));
    }
}

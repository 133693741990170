'use strict';

export const statusType_offen: number = 0;
export const statusType_vorgeschlagen: number = 1;
export const statusType_freigegeben: number = 2;
export const statusType_abgeschlossen: number = 3;
export const statusType_nichtGeroutet: number = 4;

export const STATUS_NAME_VORGESCHLAGEN: string = 'VORGESCHLAGEN';
export const STATUS_NAME_NICHT_ROUTBAR: string = 'NICHT_ROUTBAR';
export const STATUS_NAME_ZURUECKGEWIESEN: string = 'ZURUECKGEWIESEN';
export const STATUS_NAME_ZURUECKGEZOGEN: string = 'ZURUECKGEZOGEN';
export const STATUS_NAME_WIRD_BEARBEITET: string = 'WIRD_BEARBEITET';
export const STATUS_NAME_ABGELEHNT: string = 'ABGELEHNT';
export const STATUS_NAME_GESCHLOSSEN: string = 'ABGESCHLOSSEN';
export const STATUS_NAME_VORBEREITUNG: string = 'VORBEREITUNG';
export const STATUS_NAME_ZUGETEILT: string = 'ZUGETEILT';
export const STATUS_NAME_RUECKFRAGE_GESTELLT: string = 'RUECKFRAGE_GESTELLT';
export const STATUS_NAME_RUECKFRAGE_BEANTWORTET: string = 'RUECKFRAGE_BEANTWORTET';
export const STATUS_NAME_ZUR_FREIGABE: string = 'ZUR_FREIGABE';
export const STATUS_NAME_NACHBEARBEITUNG: string = 'NACHBEARBEITUNG';
export const STATUS_NAME_FREIGEGEBEN: string = 'FREIGEGEBEN';
export const STATUS_NAME_LSZ_UNINDEFINED: string = 'LSZ_UNINDEFINED';


export const HEADER_NAME_RELATIONSHIP = 'Relationship';




import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Endpoints} from '../../navigation/endpoints';
import {DefaultHttpService} from '../../../services/default-http.service';


@Injectable()
export class StatusTypeService {

    constructor(
        public http: DefaultHttpService,
    ) {
    }

    public queryStatusTypeList(): Observable<any> {
        return this.http.get(Endpoints.STATUS_TYPE_LIST);
    }

    public queryAssignableStatusTypes(): Observable<any> {
        return this.http.get(Endpoints.ASSIGNABLE_STATUS_TYPE_LIST);
    }
}

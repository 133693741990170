import {AbstractType} from './AbstractType';
import {DocumentRestTO} from './DocumentRestTO';

export type Category = 'NAME_MAKLER' | 'OTHER';

export class RequiredFieldsRestTO {
    infos: boolean;
    routes: boolean;
}

export class GeproTypeRestTO extends AbstractType {
    category?: Category;
    freigabeAmvb: string;
    requiredDocuments: Array<DocumentRestTO>;
    optionalDocuments: Array<DocumentRestTO>;
    requiredFields: RequiredFieldsRestTO;
    valid: boolean;
}

import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserMessage} from './entity/UserMessage';
import {UserMessagesService} from './service/user-messages.service';
import {NavigationStart, Router} from '@angular/router';
import {Subscription} from 'rxjs';

@Component({
    selector: 'user-messages-area',
    templateUrl: './user-messages-area.component.html',
    styleUrls: ['./user-messages-area.component.css']
})
export class UserMessagesAreaComponent implements OnInit, OnDestroy {

    private accumulatedSubscription: Subscription = new Subscription();

    public successMessages: Array<UserMessage>;
    public errorMessages: Array<UserMessage>;
    public infoMessages: Array<UserMessage>;

    constructor(private userMessagesService: UserMessagesService, private router: Router) {
    }

    ngOnInit() {
        this.successMessages = new Array<UserMessage>();
        this.errorMessages = new Array<UserMessage>();
        this.infoMessages = new Array<UserMessage>();
        this.accumulatedSubscription.add(this.userMessagesService.errorMessageSubject.subscribe((error) => this.errorArrived(error)));
        this.accumulatedSubscription.add(this.userMessagesService.successMessageSubject.subscribe((success) => this.successArrived(success)));
        this.accumulatedSubscription.add(this.userMessagesService.infoMessageSubject.subscribe((info) => this.infoArrived(info)));
        this.accumulatedSubscription.add(this.router.events.subscribe((event) => {
            if (event instanceof NavigationStart) {
                this.emptyErrorsArray();
                this.emptySuccessArray();
                this.emptyInfoArray();
            }
        }));
        this.accumulatedSubscription.add(this.userMessagesService.successAreaClosedSubject.subscribe(() => this.emptySuccessArray()));
        this.accumulatedSubscription.add(this.userMessagesService.errorAreaClosedSubject.subscribe(() => this.emptyErrorsArray()));
        this.accumulatedSubscription.add(this.userMessagesService.infoAreaClosedSubject.subscribe(() => this.emptyInfoArray()));    }

    ngOnDestroy(): void {
        this.accumulatedSubscription.unsubscribe();
    }

    public successClosed(): void {
        this.userMessagesService.successAreaClosedSubject.next();
        this.emptySuccessArray();
    }

    public errorClosed(): void {
        this.userMessagesService.errorAreaClosedSubject.next();
        this.emptyErrorsArray();
    }

    public infoClosed(): void {
        this.userMessagesService.infoAreaClosedSubject.next();
        this.emptyInfoArray();
    }

    private errorArrived(error: UserMessage): void {

        if (this.errorMessages.filter(
            function(message) {
                return message.message === error.message;
            }).length == 0) {
            this.errorMessages.push(error);
        }
    }

    private successArrived(success: UserMessage): void {
        if (this.successMessages.filter(
            function(message) {
                return message.message === success.message;
            }).length == 0) {
            this.successMessages.push(success);
        }
    }

    private infoArrived(info: UserMessage): void {
        if (this.infoMessages.filter(
            function(message) {
                return message.message === info.message;
            }).length == 0) {
            this.infoMessages.push(info);
        }
    }

    private emptyErrorsArray(): void {
        this.errorMessages = new Array<UserMessage>();
    }

    private emptySuccessArray(): void {
        this.successMessages = new Array<UserMessage>();
    }

    private emptyInfoArray(): void {
        this.infoMessages = new Array<UserMessage>();
    }
}

<ng-template #loadingTemplate>
    <nx-spinner nxSize="medium"></nx-spinner>
</ng-template>
<app-deny-gepro></app-deny-gepro>
<app-close-gepro-forever></app-close-gepro-forever>

<div *ngIf="!(!geproDetails && !loading)" class="spacer js-do-not-copy-container" role="Vorgangsdetails">
    <div [class.do-not-copy]="!editAllowed()">
        <nx-card class="gepro-header">
            <div class="gepro-title">
                <h1 *ngIf="!isLszGeproToChange(geproDetails?.geproType?.name)">{{geproDetails?.geproType?.name}}</h1>
                <div *ngIf="!isLszGeproToChange(geproDetails?.geproType?.name)">
                    <nx-badge
                        *ngIf="geproDetails?.meta['relationship']==='PAST_RECIPIENT'"
                        type="positive"
                        class="js-badge-display nx-margin-right-xs">Eingeschränkter Zugriff
                    </nx-badge>
                    <nx-badge
                        [type]="geproDetails?.statusType?.badgeName" [ngClass]="getBadgeClass(geproDetails?.statusType)"
                        class="js-badge-display">{{this.loading ? 'Lade...' : geproDetails?.statusType?.displayName}}</nx-badge>
                </div>

            </div>

            <div *ngIf="isLszGeproToChange(geproDetails?.geproType?.name) || isLszUnindefined(this.geproDetails?.statusType?.name)" fxLayout="column">
                <div *ngIf="isLszGeproToChange(geproDetails?.geproType?.name)" fxLayout="row">
                    <nx-formfield id="gepro-Auswahl" nxLabel="Vorgang wählen *" style="width: 300px;">
                        <nx-dropdown id="geproSelection"
                                     (nxValueChange)="changeGeproLsz($event)"
                                     [nxFilterPlaceholder]="'Vorgang suchen...'">
                            <nx-dropdown-item *ngFor="let gePro of geProList" [nxValue]="gePro"> {{gePro.name}}
                            </nx-dropdown-item>
                        </nx-dropdown>
                    </nx-formfield>
                    <button *ngIf="geproLsz!==''" style=" margin-left:20px; margin-top:26px"
                            nxButton="primary small" type="button"
                            (click)="changeLszGeproType()">
                        Speichern
                    </button>
                </div>
                <div *ngIf="isLszUnindefined(this.geproDetails?.statusType?.name)" >
                    <form #form [formGroup]="msnVtnrFormGroup" style="width: 300px">
                        <div fxLayout="row">
                            <nx-formfield nxLabel="Vermittler" style="flex: 1">
                                <input id="inMsnVtnr" nxInput type="text" (ngModelChange)="onTextChange($event)"
                                       (keydown.enter)="queryForMsnVtnr()"
                                       [placeholder]="'Bitte MSN oder VTNR eingeben'"
                                       formControlName="msnVtnr"/>
                            </nx-formfield>
                            <button nxButton="primary small" class="nx-margin-left-xs size-height-12 msnvtnrinputsuche" style=" margin-top:26px"
                                    type="button"
                                    [disabled]="sucheBtDisabled" (click)="queryForMsnVtnr()">
                                Suche
                            </button>

                        </div>
                        <button *ngIf="!sucheBtDisabled&&msnfound"
                                nxButton="primary small" type="button"
                                (click)="changeReferatAndMsn()">
                            Speichern
                        </button>
                    </form>
                    <gepro-msn6-tree-checklist [geproLsz]="geproLsz"
                        [geproId]="geproId" [msnLsz]="msnLsz" [callbackFunction]="loadGeproDetailsLsz(false)"></gepro-msn6-tree-checklist>
                </div>
            </div>

            <div class="gepro-cards" role="Vorgangs Eckdaten">
                <div>
                    <div style="font-size: 16px">
                        ID: {{geproId}}
                    </div>
                    <div>&nbsp;</div>
                    <div style="font-size: 14px">
                        <strong>Erstellung: </strong>
                        <app-timestamp [timestamp]="geproDetails?.publishTimestamp"></app-timestamp>
                        ,
                        <span *ngIf="getErfasserInfo()?.length > 25"><br>{{getErfasserInfo()}}</span>
                        <span *ngIf="getErfasserInfo()?.length <= 25">{{getErfasserInfo()}}</span>
                    </div>
                </div>

                <div>
                    <div>&nbsp;</div>
                    <div>&nbsp;</div>
                    <div class="content-align-right" style="font-size: 14px">
                        <strong>Letzte Änderung: </strong>
                        <app-timestamp [timestamp]="geproDetails?.changeTimestamp"></app-timestamp>
                        , {{geproDetails?.changeName}}
                    </div>
                </div>

            </div>
            <div class="gepro-actions" *ngIf="!loading; else loadingTemplate" role="Vorgangs Aktionen">
                <div><!--*ngIf="showDenyButton()" -->
                    <div>
                        <button (click)="denyGepro()"
                                *appVisibility="'return'"
                                appDisable="return"
                                class="edit-exception gepro-action-left js-return-button"
                                nxButton="tertiary small-medium">
                            <nx-icon name="close" size="s" class="gepro-icon-left"></nx-icon>
                            An Referatsleiter zurückweisen
                        </button>
                    </div>
                    <!--                        <div>-->
                    <!--                            <button *ngIf="canSeeRueckfrageJumpButton()"-->
                    <!--                                    class="gepro-action-left js-inquiry-button"-->
                    <!--                                    nxButton="tertiary small-medium"-->
                    <!--                                    (click)="activateQuestion()">-->
                    <!--                                <nx-icon name="arrow-left" class="gepro-icon-left"></nx-icon>-->
                    <!--                                Rückfrage-->
                    <!--                            </button>-->
                    <!--                        </div>-->
                </div>
                <div class="gepro-actions-right">
                    <button (click)="authorizeMe()" class="edit-exception js-assign-button" nxButton="primary medium"
                            appDisable="assign" *appVisibility="'assign'">Mir
                        zuweisen
                    </button><!---->
                    <button (click)="startEdit()"
                            *appVisibility="'edit'"
                            appDisable="edit"
                            class="js-start-bearbeitung edit-exception"
                            nxButton="secondary medium">
                        BEARBEITUNG BEGINNEN
                    </button>
                    <app-gepro-reject [geproId]="geproId"
                                      (refreshGepro)="loadGeproDetailsSendNotification('reject')"></app-gepro-reject>
                    <app-gepro-close [geproId]="geproId" [schouldBeDisabled]="isLszAndGeproTypNameNotAdjusted()"
                                     (refreshGepro)="loadGeproDetailsSendNotification('close')"></app-gepro-close>
                    <button *appVisibility="'pdf'"
                            appDisable="pdf"
                            nxIconButton="emphasis"
                            aria-label="generate pdf"
                            type="button"
                            nxTooltip="PDF erzeugen"
                            class="edit-exception"
                            (click)="createPdf()">
                        <nx-icon name="pdf"></nx-icon>
                    </button>
                </div>
            </div>
        </nx-card>
    </div>

    <div>
        <nx-card class="communication" tabindex="-1">

            <nx-tab-group (selectedIndexChange)="selectedCommTab = $event " [selectedIndex]="selectedCommTab"
                          id="communication" tabindex="-1">

                <nx-tab label="Historie" tabindex="-1" role="Vorgangshistorie">
                    <app-gepro-history (answerQuestionClicked)="activateQuestion()"
                                       [id]="geproId" tabindex="-1"
                                       (refreshGepro)="loadGeproDetails()" (lszEmailExists)="changeLszEmailExists()">
                    </app-gepro-history>
                </nx-tab>
                <!--
                <nx-tab [disabled]="!isActionsTabEnabled()" label="Kommunikation alt" role="Notiz / Rückfrage hinzufügen">
                    <div class="tab__content">
                        <div class="selection-cards">
                            <nx-selectable-card *appVisibility="'note'" [checked]="action"
                                                [disabled]="!isNoteTabEnabled()"
                                                (keypress)="onKeyPress($event, true)"
                                                (selectionChange)="action=true"
                                                [tabindex]="isNoteButtonFocussable()">
                                <div class="align">
                                    <nx-icon name="edit" size="s"></nx-icon>
                                    Notiz erfassen
                                </div>
                            </nx-selectable-card>

                            <nx-selectable-card [checked]="!action" [disabled]="action===false"
                                                (keypress)="onKeyPress($event, false)"
                                                (selectionChange)="action=false" *appVisibility="'reply'">
                                <div class="align">
                                    <nx-icon name="chat-o" size="s"></nx-icon>
                                    Rückfrage beantworten
                                </div>
                            </nx-selectable-card>

                            <nx-selectable-card (selectionChange)="action=false" [checked]="!action"
                                                (keypress)="onKeyPress($event, false)"
                                                *appVisibility="'enquiry'"
                                                [disabled]="!isEnquiryEnabled()">
                                <div class="align">
                                    <nx-icon name="speech-bubble-dots-o" size="s"></nx-icon>
                                    Rückfrage an Ersteller
                                </div>
                            </nx-selectable-card>
                        </div>
                        <app-gepro-note [id]="geproId"
                                        [hidden]="!action"
                                        (messageAdded)="loadGeproDetails()"></app-gepro-note>

                        <app-gepro-question [hidden]="action"
                                            (toggleMenu)="action=!action"
                                            (refreshGepro)="loadGeproDetailsSendNotification($event)"></app-gepro-question>
                    </div>
                </nx-tab>
                -->
                <nx-tab
                    label="Kommunikation"
                    tabindex="-1"
                    [disabled]="!isLszUnindefined(this.geproDetails?.statusType?.name) && !isActionsTabEnabled()"
                    [class.do-not-copy]="!isLszUnindefined(this.geproDetails?.statusType?.name) && !communicationEditAllowed() && !isFreigabeTabEnabled()">
                    <app-gepro-communication
                        (afterCommunication)="loadGeproDetails()"
                        (afterEnquiry)="sendNotification('enquiries')"
                        [id]="geproId"
                        [selectedCommunicationMode]="communicationMode"
                        [lszEmailExists]="lszEmailExists"
                        [communicationWithLszOnlyActive]="isLszUnindefined(this.geproDetails?.statusType?.name)">
                    </app-gepro-communication>
                </nx-tab>
                <nx-tab label="Freigabe" role="Zur Freigabe" *ngIf="showFreigabeTab() && finishedLoading()"
                        [disabled]="!isFreigabeTabEnabled()" [class.do-not-copy]="!communicationEditAllowed() && !isFreigabeTabEnabled()">
                    <app-gepro-freigabe [geproDetails]="this.geproDetails"
                                        [schouldBeDisabled]="isLszAndGeproTypNameNotAdjusted()"
                                        (refreshGepro)="loadGeproDetails()"></app-gepro-freigabe>
                </nx-tab>
            </nx-tab-group>
        </nx-card>
    </div>

    <div [class.do-not-copy]="!editAllowed()" role="Verknüpfter Makler">
        <app-vorgang-makler *ngIf="finishedLoading()"
                            [geproId]="geproId"></app-vorgang-makler>
    </div>

    <div style="margin-left: 1px; margin-right: 1px; margin-bottom: 20px" >
    <app-restricted-text-area #comment
                              [label]="'Zusatzinfo (optional)'"
                              [maxLength]="40"
                              (ngModelChange)="commentChanged(comment.isInvalid(), $event)"
                              [(ngModel)]="zusatzInfoModel"></app-restricted-text-area>
        <button (click)="changeZusatzInfo()" style="float:right" class="edit-exception js-assign-button" nxButton="primary medium"
                >Zusatzinfo Speichern
        </button>
    </div>

    <app-gepro-info *ngIf="finishedLoading()" [maxRowsInTextarea]="6"
                    [readonlyText]="geproDetails.geproInfos"></app-gepro-info>

    <div [class.do-not-copy]="!isLszUnindefined(this.geproDetails?.statusType?.name) && !documentsEditAllowedBeforWork()" role="Vorgangs Dokumente">
        <app-vorgang-documents *ngIf="finishedLoading()"
                               (uploadClosedWithDocuments)="refreshGeproAfterDocUpload($event)"
                               [geproId]="geproId"
                               [geproType]="geproDetails?.geproType"
                               [confirmedDocuments]="geproDetails?.tickedDocuments"></app-vorgang-documents>
    </div>
    <div *ngIf="isLszUnindefined(this.geproDetails?.statusType?.name)" fxLayout="column">
        <button (click)="lszCloseForever()" style="background-color: orangered; color: white;"
                class="js-start-bearbeitung edit-exception"
                title="Bei Betätigung diesen Buttons, werden Vorgänge unwiderruflich als GePro-Art Weiterleitung ohne eine gültige MSN/VTNR geschlossen. Bitte nutzen Sie diesen Button nur für Vorgänge, die nicht in DIASS gehören"
                nxButton="secondary medium">
            Vorgang unwiderruflich abschließen
        </button>
    </div>
</div>

<ng-template #noGeproModal>
    <div class="u-text-center">
        <h3 nxHeadline="subsection-large" class="nx-font-weight-light">Hinweis</h3>
        <p nxCopytext="normal">Der aufgerufene Vorgang {{geproId}} existiert nicht oder <br>Sie sind für den Vorgang
            nicht berechtigt.</p>
        <button appFocus="button" (click)="navigateToWorklist()" nxButton="primary medium" type="button"
                cdkFocusInitial>
            Weiter zur Arbeitsliste
        </button>
    </div>
</ng-template>
<nx-modal (nxClose)="navigateToWorklist()"
          *ngIf="!geproDetails && !loading"
          [nxBody]="noGeproModal"
          nxSize="fixed"
          [nxHideOnClickOutside]="false"
          [nxHideOnEsc]="false"
          [nxShowCloseIcon]="false">
</nx-modal>


<ng-template #mailErrorModal>
    <div>
        <h3 nxHeadline="subsection-large" class="nx-font-weight-light" class="u-text-center">Hinweis</h3>
        <p nxCopytext="normal">Leider ist eine E-Mail-Erstellung für MaklerbetreuerIn {{geproDetails?.erfasserName}}
            nicht möglich.<br><br>
            Bitte stellen Sie eine korrekte Zuordnung des Vorganges sicher und informieren im Fall eines
            Handlungsbedarfes den verantwortlichen Maklerbetreuer individuell.
        </p>
        <div class="u-text-center">
            <button appFocus="button" nxModalClose="OK" nxButton="primary medium" type="button">
                OK
            </button>
        </div>
    </div>
</ng-template>


<ng-template #answerQuestion>
    <div>
        <h3 nxHeadline="subsection-large" class="nx-font-weight-light u-text-left">Ist die Rückfrage mit dem
            hochgeladenen Dokument abgeschlossen?</h3>
        <p nxCopytext="normal" class="u-text-left">Falls "Nein" bearbeiten Sie die Rückfrage bitte über den Reiter
            "Kommunikation" oder über das Hochladen weiterer Dokumente.</p>
        <div class="u-text-center">
            <button appFocus="button" nxModalClose="Nein"
                    nxButton="medium secondary" type="button" class="nx-margin-right-2m">Nein
            </button>
            <button appFocus="button" nxModalClose="Ja" nxButton="medium" type="button" cdkFocusInitial>Ja
            </button>
        </div>
    </div>
</ng-template>




import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ReactiveFormsModule} from '@angular/forms';
import {MessageComponent} from './message.component';
import {NxFormfieldModule} from '@allianz/ngx-ndbx/formfield';
import {NxInputModule} from '@allianz/ngx-ndbx/input';
import {NxErrorModule} from '@allianz/ngx-ndbx/base';

@NgModule({
    declarations: [MessageComponent],
    exports: [
        MessageComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NxFormfieldModule,
        NxInputModule,
        NxErrorModule
    ]
})
export class MessageModule { }

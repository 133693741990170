import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject, Observable, Subscription} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class EnvironmentLoaderService implements OnDestroy {

    private configLocation = './environment.json';

    private subscription: Subscription = new Subscription();
    private configSubject: BehaviorSubject<EnvironmentConfig> = new BehaviorSubject<EnvironmentConfig>(null);

    constructor(private http: HttpClient) { }

    loadEnvironmentData(): Promise<any> {
        return new Promise<any>((resolve) => {
            this.http.get<EnvironmentConfig>(this.configLocation).toPromise()
                .then(env => {
                    this.configSubject.next(env);
                    console.log('got environment:', env);
                    resolve();
                })
                .catch(reason => {
                    console.log('environment not be resolved', reason);
                    resolve();
                });
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public getConfigObservable(): Observable<EnvironmentConfig> {
        return this.configSubject.asObservable();
    }
}

export interface EnvironmentConfig {
    loginTokenProviderUrl: string;
    apiHost: string;
    reporting: string;
}

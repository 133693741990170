import {RoutingType} from './gepro-route-rest.to';

export class MaklerStrukturNodeRestTO {
    strukturId: string;
    routingType: RoutingType;

    name: string;
    strasse: string;
    plz: string;
    ort: string;

    parent: MaklerStrukturNodeRestTO;
    // important, because children can actually be null, if stripped out to save traffic
    recursiveChildrenCount: number;
    children: Array<MaklerStrukturNodeRestTO>;

    public static deserializeTree(node: MaklerStrukturNodeRestTO, parent: MaklerStrukturNodeRestTO): MaklerStrukturNodeRestTO {
        let mappedNode = this.deserializeNodeWithoutChildren(node, parent);

        if (node && node.children) {
            for (let unmappedChild of node.children) {
                mappedNode.children.push(this.deserializeTree(unmappedChild, mappedNode));
            }
        }

        return mappedNode;
    }

    public static deserializeNodeWithoutChildren(node: MaklerStrukturNodeRestTO, parent: MaklerStrukturNodeRestTO): MaklerStrukturNodeRestTO {
        if (!node) {
            return null;
        }

        let mappedNode: MaklerStrukturNodeRestTO = new MaklerStrukturNodeRestTO();
        mappedNode.children = new Array<MaklerStrukturNodeRestTO>();
        mappedNode.parent = parent;

        mappedNode.strukturId = node.strukturId;
        mappedNode.routingType = node.routingType;
        mappedNode.recursiveChildrenCount = node.recursiveChildrenCount;
        mappedNode.name = node.name;
        mappedNode.strasse = node.strasse;
        mappedNode.plz = node.plz;
        mappedNode.strasse = node.strasse;
        mappedNode.ort = node.ort;

        return mappedNode;
    }

    public getFlatMapOfAllNodes(): Map<string, MaklerStrukturNodeRestTO> {
        let map = new Map<string, MaklerStrukturNodeRestTO>();
        let list = this.getListOfAllNodes();

        list.forEach(element => map.set(element.strukturId, element));
        return map;
    }

    public getListOfAllNodes(): Array<MaklerStrukturNodeRestTO> {
        let list = new Array<MaklerStrukturNodeRestTO>();
        list.push(this);

        if (this.children) {
            this.children.forEach(child => child.getListOfAllNodes().forEach(item => list.push(item)));
        }

        return list;
    }
}

import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {UserMessagesService} from '../common/messages-area/messages-area/service/user-messages.service';
import {LoginService} from '../index/service/login.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
    loginForm: FormGroup;
    loading = false;
    submitted = false;
    loginError = false;
    localLogin = false;

    public readonly userList: Array<string> = new Array<string>();

    constructor(private formBuilder: FormBuilder,
                private userMessagesService: UserMessagesService,
                public loginService: LoginService) { }

    async ngOnInit() {
        this.localLogin = !this.loginService.isStagedEnvironment();

        if (!this.localLogin) {
            console.log('LoginComponent: staged environment detected, forwarding to default page');
            setTimeout(() => this.loginService.forwardToDefaultPage(), 5000);
        } else {
            console.log('LoginComponent: local login detected');
            this.loginForm = this.formBuilder.group({
                username: ['', Validators.required],
                password: ['', Validators.required]
            });

            // other users
            this.userList.push("makler1", "mb1new");
            // Ref 1
            this.userList.push("eddsta", "jonsno", "daetar", "sansta", "tyrlan", "sancle");
            // admin as separator
            this.userList.push("admin1");
            // Ref 2
            this.userList.push("arysta", "lorvar", "jormor", "torgia", "samtar", "davsea", "hodhod");
        }
    }

    getForwardPage() {
        return this.loginService.getDefaultPageOrReturnUrl();
    }

    // convenience getter for easy access to form fields
    get f() {
        return this.loginForm.controls;
    }

    async onSubmit() {
        this.submitted = true;
        this.loginError = false;

        // stop here if form is invalid
        if (this.loginForm.invalid) {
            return;
        }

        this.loading = true;

        try {
            await this.loginService.localLoginByCredentials(this.loginForm.get('username').value, this.loginForm.get('password').value);
        } catch (error) {
            debugger;
            this.loginError = true;
            this.submitted = false;
            this.userMessagesService.addErrorMessageString(error);
        }
    }

    onSelectionChanged($event) {
        this.loginForm.get('username').setValue($event.value);
        this.loginForm.get('password').setValue($event.value);
    }
}

import {Injectable} from '@angular/core';
import {StatusTypeListRestTO} from '../components/index/service/Model/StatusTypeListRestTO';
import {StatusTypeService} from '../components/index/service/status-type.service';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {StatusTypeRestTO} from '../components/index/service/Model/StatusTypeRestTO';
import {AuthenticationService} from '../components/index/service/authentication-service';

@Injectable({
    providedIn: 'root'
})
export class StatesService {

    public statusTypesList: StatusTypeListRestTO = new StatusTypeListRestTO;

    public assignableStatusTypes: StatusTypeRestTO[];

    states$: Subject<StatusTypeListRestTO> = new BehaviorSubject<StatusTypeListRestTO>(null);

    constructor(private statusTypeService: StatusTypeService,
                private authenticationService: AuthenticationService) {
        this.authenticationService.isAuthenticated.subscribe(isAuthenticated => this.handleLoginLogout(isAuthenticated));
    }

    getStates(): Observable<StatusTypeListRestTO> {
        return this.states$.asObservable();
    }

    load() {
        this.statusTypeService.queryStatusTypeList().subscribe(
            statusTypesList => {
                this.statusTypesList = statusTypesList;
                this.states$.next(statusTypesList);
            });

        this.statusTypeService.queryAssignableStatusTypes().subscribe(
            assignableStatusTypes => {
                this.assignableStatusTypes = assignableStatusTypes;
            }
        );
    }

    public getStatesForStatusClosed(makeReadable?: boolean): string[] {
        return this.getDisplayNamesFromList(this.statusTypesList.forStatusClosed, makeReadable);
    }

    public getStatesForStatusInProgress(makeReadable?: boolean): string[] {
        return this.getDisplayNamesFromList(this.statusTypesList.forStatusInProgress, makeReadable);
    }

    public getStatesForStatusToAssign(makeReadable?: boolean): string[] {
        return this.getDisplayNamesFromList(this.statusTypesList.forStatusToAssign, makeReadable);
    }

    public getStatesFreigabeToRelease(makeReadable?: boolean): string[] {
        return this.getDisplayNamesFromList(this.statusTypesList.allVisibleStatusRelease, makeReadable);
    }

    public getStatesForStatusToDo(makeReadable?: boolean): string[] {
        return this.getDisplayNamesFromList(this.statusTypesList.forStatusToDo, makeReadable);
    }

    public getStatesForStatusSent(makeReadable?: boolean): string[] {
        return this.getDisplayNamesFromList(this.statusTypesList.forStatusSent, makeReadable);
    }

    public getAllVisibleStatesAsCreator(makeReadable?: boolean): string[] {
        return this.getDisplayNamesFromList(this.statusTypesList.allVisibleStatusCreator, makeReadable);
    }

    public getAllVisibleStatesAsAssigner(makeReadable?: boolean): string[] {
        return this.getDisplayNamesFromList(this.statusTypesList.allVisibleStatusAssigner, makeReadable);
    }

    public getAllUndefinedLszGeprosAsAssigner(makeReadable?: boolean): string[] {
        return this.getDisplayNamesFromList(this.statusTypesList.allUndefinedLszStatusAssigner, makeReadable);
    }

    public getAllVisibleStatesAsEditor(makeReadable?: boolean): string[] {
        return this.getDisplayNamesFromList(this.statusTypesList.allVisibleStatusEditor, makeReadable);
    }

    private getDisplayNamesFromList(list: Array<StatusTypeRestTO>, makeReadable?: boolean) {
        var filters: string[] = [];
        if (list) {
            list
                .filter(statusType => statusType.displayName)
                .forEach(statusType => {
                filters.push(statusType.displayName);
            });
        }

        return makeReadable ? this.makeReadable(filters) : filters;
    }


    private makeReadable(filters: string[]): string[] {
        const readableFilters = new Array<string>();
         for (let filter of filters) {
            // const parsedFilterName = filter.replace('_', ' ').toLocaleLowerCase();
            const parsedFilterName = filter.replace('_', ' ');
            readableFilters.push(parsedFilterName.charAt(0).toUpperCase() + parsedFilterName.slice(1));
        }

        return readableFilters;
    }

    private handleLoginLogout(isAuthenticated: boolean) {
        console.log("StatesService, changed login/logout state detected:", isAuthenticated ? 'login' : 'logout');
        if (isAuthenticated) {
            this.load();
        }
    }
}

import {Injectable} from '@angular/core';
import {Filter} from '../components/work-list/gepro-filter/gepro-filter.component';
import {GeproFilterRequest} from '../components/work-list/gepro-filter/entity/gepro-filter-request';
import {GeproRestTO} from '../components/index/service/Model/GeproRestTO';
import {UserinfoService} from '../components/index/service/userinfo.service';

@Injectable({
    providedIn: 'root'
})
export class FilterRequestBuilderService {

    constructor(private readonly userService: UserinfoService) {
    }

    public pushSingle(key: string, value: string, filters: Array<Filter>): void {
        if (key && value) {
            filters.push({key: key, value: value});
        }
    }

    public pushMultiple(key: string, values: Array<string>, filters: Array<Filter>): void {
        if (!key || !values || values.length == 0) {
            return;
        }
        let filterString = values.join(' OR ' + key + ':');
        filters.push({key: '(' + key, value: filterString + ')'});
    }

    public pushDate(key: string, minDate: Date, maxDate: Date, filters: Array<Filter>): void {
        if (minDate && maxDate) {
            let dateRangeString = minDate.toISOString() + ' AND ' + key + '<:' + maxDate.toISOString();
            filters.push({key: '(' + key + '>', value: dateRangeString + ')'});
        } else if (minDate) {
            filters.push({key: '(' + key + '>', value: minDate.toISOString() + ')'});
        } else if (maxDate) {
            filters.push({key: '(' + key + '<', value: maxDate.toISOString() + ')'});
        }
    }

    public applyDefaultFilter(selectedFilter: string, filters: Array<Filter>, isLszUnroutbar: boolean) {
        if (selectedFilter === GeproFilterRequest.FILTER_CREATOR && this.userService.getUser().bensl) {
            this.pushSingle(GeproRestTO.FIELD_ERFASSER, this.userService.getUser().bensl, filters);
            this.pushSingle(GeproRestTO.FIELD_TRANSLATION_FILTER, Relationship.CREATOR, filters);
        }
        if (selectedFilter === GeproFilterRequest.FILTER_ASSIGNEE) {
            filters.push({
                key: GeproRestTO.FIELD_MITARBEITER,
                value: `${this.userService.getUser().bensl}`
            });
            this.pushSingle(GeproRestTO.FIELD_TRANSLATION_FILTER, Relationship.EDITOR, filters);
        }

        if (selectedFilter === GeproFilterRequest.FILTER_NOT_ASSIGNED ||
            selectedFilter === GeproFilterRequest.FILTER_FREIGABE
        ) {
            const user = this.userService.getUser();
            if (user && user.referatAsLeiter) {
                const notAssignedFilter = `${user.referatAsLeiter.id}`;
                if(!isLszUnroutbar) {
                    filters.push({key: GeproRestTO.FIELD_REFERAT, value: notAssignedFilter});
                }
                else{
                    filters.push({key: GeproRestTO.FIELD_REFERAT, value: '-10'});
                }
            }
            else if(isLszUnroutbar){
                filters.push({key: GeproRestTO.FIELD_REFERAT, value: '-10'});
            }

            this.pushSingle(GeproRestTO.FIELD_TRANSLATION_FILTER, Relationship.ASSIGNER, filters);
        }
    }
}

export class Relationship {
    public static readonly ASSIGNER = 'ASSIGNER';
    public static readonly CREATOR = 'CREATOR';
    public static readonly EDITOR = 'EDITOR';
    public static readonly RECIPIENT = 'RECIPIENT'; // unused yet
}

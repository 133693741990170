import {Injectable, OnDestroy} from '@angular/core';
import {GeproDetails} from '../interfaces/gepro.interfaces';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {GeproRestTO} from '../components/index/service/Model/GeproRestTO';
import {GeproService} from '../components/index/service/gepro.service';
import {HttpErrorResponse} from '@angular/common/http';
import cloneDeep from 'lodash/cloneDeep';
import {GeproRouteRestTO} from '../components/index/service/Model/gepro-route-rest.to';
import {VorgangRouteService} from './vorgang-route.service';
import {NxMessageToastService} from '@allianz/ngx-ndbx/message';
import {DocumentRestTO} from '../components/index/service/Model/DocumentRestTO';

export interface Step {
    index: number,
    name: string,
    completed: boolean,
    route: string,
    title: string,
    titleNeurevers?: string,
    subtitle: string,
    validationErrors: Array<any>,
    errorMsgStepper: string;
}

@Injectable({
    providedIn: 'root'
})
export class CreateGeproService implements OnDestroy {

    static multiStepsTemplate: Step[] = [
        {
            index: 0,
            name: 'Vorgangsart',
            completed: false,
            route: 'type',
            title: 'Welchen Vorgang möchten Sie anlegen?',
            subtitle: '',
            validationErrors: [],
            errorMsgStepper: 'Bitte überprüfen Sie die Vorgangsart.'
        },
        {
            index: 1,
            name: 'Vermittler',
            completed: false,
            route: 'broker',
            title: 'Für welchen Vermittler sollen Änderungen durchgeführt werden?',
            titleNeurevers: 'Welchen Vermittler wollen Sie neu anlegen?',
            subtitle: '',
            validationErrors: [],
            errorMsgStepper: 'Bitte überprüfen Sie die Eingabe.'
        },
        {
            index: 2,
            name: 'Inhalte',
            completed: false,
            route: 'documents',
            title: 'Nachricht hinzufügen',
            subtitle: '',
            validationErrors: [],
            errorMsgStepper: 'Bitte überprüfen Sie die Eingabe.'
        },
        {
            index: 3,
            name: 'Übersicht',
            completed: false,
            route: 'overview',
            title: 'Vorgangsübersicht',
            subtitle: '',
            validationErrors: [],
            errorMsgStepper: 'Bitte überprüfen Sie die Eingabe.'
        }
    ];

    private multiSteps: Step[] = null;

    private routeSubscription: Subscription;

    constructor(private geproService: GeproService, private readonly routeService: VorgangRouteService,
                private messageToastService: NxMessageToastService) {
        this.multiSteps = cloneDeep(CreateGeproService.multiStepsTemplate);
    }

    ngOnDestroy(): void {
        this.unsubscribeRoutes();
    }

    // Delivers current gepro
    private $geproObservable: BehaviorSubject<GeproRestTO> = new BehaviorSubject<GeproRestTO>(new GeproRestTO());

    // Communicates changes in errors on any page
    private $validationErrorsChanged: BehaviorSubject<true> = new BehaviorSubject<true>(true);


    private $multistepsObservable: BehaviorSubject<Step[]> = new BehaviorSubject<Step[]>(CreateGeproService.multiStepsTemplate);

    // allows pages to disable next button
    private $nextDisabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);


    // communicates Error State
    private $errorOccured: Subject<HttpErrorResponse | null> = new Subject<HttpErrorResponse | null>();

    /*
     allows page to receive the next/prev button click.
     Page currently HAS TO save the gepro, otherwise navigation will not go on.
     */
    private $saveIntend: Subject<true> = new Subject<true>();

    private $sendIntend: Subject<true> = new Subject<true>();

    private $stepIntend: Subject<number> = new Subject<number>();

    private subscription: Subscription = new Subscription();
    errorState = null;

    /*
    loads gepro for given id, does trigger gepro observable to inform all watchers instead of returning it.
     */
    public loadGeproById(geproId: string) {
        if (geproId) {
            this.subscription = this.geproService.loadGeproDetails(geproId).subscribe(async (gepro: GeproDetails) => {
                if (gepro && gepro.id === geproId) {
                    const transferobj: GeproRestTO = gepro as unknown as GeproRestTO;
                    await this.enrichWithRoutes(geproId, transferobj);
                    this.$geproObservable.next(transferobj);
                }
            });
        }
    }

    public resetGeproObservables() {
        this.multiSteps = cloneDeep(CreateGeproService.multiStepsTemplate);
        this.$multistepsObservable.next(this.multiSteps);
        this.$validationErrorsChanged.next(true);
        this.$geproObservable.next(new GeproRestTO());
        this.subscription.unsubscribe();
    }

    /**
     * TODO: Can load 2000 routes only... has to be changed to pagination, when component ist developed. SPARTA-1261?
     * @param geproId
     * @param gepro
     */
    private async enrichWithRoutes(geproId: string, gepro: GeproRestTO) {
        this.unsubscribeRoutes();
        return this.routeSubscription = await this.routeService.findAllBy(geproId, undefined, {number: 0, size: 2000})
            .subscribe(routes => {
                if (gepro == null) {
                    return;
                }
                gepro.routes = routes.content;
            });
    }

    public initEmptyGepro(): void {
        this.$geproObservable.next(new GeproRestTO());
    }

    /*
    returns step data for given route string
     */
    public getStepDataByRoute(route: string): Step {
        let returnVal: Step = null;
        this.multiSteps.forEach(item => {
            if (route == item.route) {
                returnVal = item;
            }
        });
        return returnVal;
    }

    /*
    returns step data for given route name
     */
    private getStepDataByName(name): Step {
        let returnVal = null;
        this.multiSteps.forEach(item => {
            if (name == item.name) {
                returnVal = item;
            }
        });
        return returnVal;
    }

    /*
    returns gepro Observable with current Gepro Data for create pages
     */
    geproObservable(): Observable<GeproRestTO> {
        return this.$geproObservable.asObservable();
    }

    errorOccuredObservable(): Observable<HttpErrorResponse | null> {
        return this.$errorOccured.asObservable();
    }


    multiStepObservable(): Observable<Step[]> {
        return this.$multistepsObservable.asObservable();
    }

    /*
    returns observable which is triggered when either "weiter" or "zurück" is clicked
     */
    saveIntentObservable(): Observable<true> {
        return this.$saveIntend.asObservable();
    }

    navigationIntentObservable(): Observable<number> {
        return this.$stepIntend.asObservable();
    }

    /*
    returns observable which is triggered when "absenden" is clicked
     */
    sendIntentObservable(): Observable<true> {
        return this.$sendIntend.asObservable();
    }

    validationErrorsChangedObservable(): Observable<true> {
        return this.$validationErrorsChanged.asObservable();
    }

    nextDisabledObservable(): Observable<boolean> {
        return this.$nextDisabled.asObservable();
    }

    /*This enables two features in the shell component:
        1) It disables the "Weiter" Button
        2) It enables "linear mode" in the ndbx progress stepper. this disables all stepps after (last checked step + 1). Demo of functionality on progress stepper page: https://api-test.allianz.com/ngx-ndbx-dev/my-viewer/documentation/progress-stepper/overview#expert%253A-multi-indicator-vertical
     */
    setNextDisabled(value: boolean) {
        this.$nextDisabled.next(value);
    }

    getAllSteps() {
        return this.multiSteps;
    }

    sendNavigationIntend(step: number) {
        this.$stepIntend.next(step);
    }

    sendSaveIntend() {
        this.$saveIntend.next(true);
    }

    sendSendIntend() {
        this.$sendIntend.next(true);
    }

    getValidationErrors(stepName: string): Array<any> {
        const step = this.getStepDataByName(stepName);
        if (step) {
            return step.validationErrors;
        } else {
            return null;
        }

    }

    setValidationErrors(stepName: string, errors: Array<any>): void {
        const step = this.getStepDataByName(stepName);
        if (step) {
            step.validationErrors = errors;
            this.$validationErrorsChanged.next(true);
        } else {
            console.error('No Step found with that name.');
        }

    }

    hasValidationErrors(): boolean {

        let result = false;
        this.multiSteps.forEach(item => {
            if (item.validationErrors.length > 0) {
                result = true;
            }
        });
        return result;
    }

    allStepsCompleted(): boolean {
        let result = true;
        this.multiSteps.forEach(item => {
            if (item.name !== 'Übersicht' && !item.completed) {
                result = false;
            }
        });
        return result;
    }

    getIsCompleted(stepName: string): boolean {
        return this.getStepDataByName(stepName).completed;
    }

    setIsCompleted(stepName: string, value: boolean): void {
        this.getStepDataByName(stepName).completed = value;
    }

    publishGepro(gepro: GeproRestTO) {
        if (!gepro.routes) { //tiny hack server cannot accept null
            gepro.routes = [];
        }
        this.geproService.publish(gepro).toPromise().then(result => {
            this.$geproObservable.next(result);
            this.$errorOccured.next(null);
        }, error => {
            this.errorState = error;
            this.$errorOccured.next(error);
            throw(error);
        });
    }

    async saveGepro(gepro: GeproRestTO) { //TODO: Evaluate if the component should modify the current gepro and save over the stuff, or if ther should be a review mechanism
        if (!gepro.routes) { //tiny hack server cannot accept null
            gepro.routes = [];
        }
        /*const geproCopy=cloneDeep(gepro);
         geproCopy.timestampUtc=null;
         geproCopy.changeTimestamp=null;

         const currentStringGepro = JSON.stringify(geproCopy);
         if(currentStringGepro===this.lastSaveObj){
             this.$geproObservable.next(gepro);
             this.$errorOccured.next(null);
             debugger;
             return;
         }*/
        let promise: Promise<GeproRestTO>;
        if (gepro.id) {
            promise = this.geproService.update(gepro).toPromise();
        } else {
            promise = this.geproService.create(gepro).toPromise();
        }
        return promise.then(async result => {
            const result2 = await this.saveRoutes(result, gepro.routes);
            let successMsg = gepro.id ? 'Vorgang gespeichert.' : 'Vorgang angelegt.';
            gepro = result;
            if (gepro.id) {
                await this.enrichWithRoutes(gepro.id, result);
            }

            //this.lastSaveObj = JSON.stringify(geproCopy);
            //this.messageToastService.open(successMsg, {context: 'success', duration: 5000});
            this.$geproObservable.next(result);
            this.$errorOccured.next(null);
            return true;
        }, error => {
            this.errorState = error;
            this.$errorOccured.next(error);
            throw(error);
        });
    }

    resetGeproObserable() {
        this.$geproObservable.next(null);
    }

    private async saveRoutes(gepro: GeproRestTO, routes: Array<GeproRouteRestTO>) {
        this.unsubscribeRoutes();
        if (routes) {
            const result = await this.routeService.save(gepro.id, routes).toPromise();
            if (result) {
                gepro.routes = result;
            }
        } else {
            console.error('NO ROUTES GIVEN. Should they be deleted? Skipping saveRoutes');

        }
        return true;
    }

    private unsubscribeRoutes() {
        if (this.routeSubscription) {
            this.routeSubscription.unsubscribe();
        }
    }

    public validateTypeData(gepro: GeproRestTO): void {
        this.setIsCompleted('Vorgangsart', gepro.geproType?.valid === true);
        if (gepro.geproType?.valid === false) {
            this.setValidationErrors('Vorgangsart', [{error: true}]);
        } else {
            this.setValidationErrors('Vorgangsart', []);
        }
    }

    public validateBrokerData(gepro: GeproRestTO): void {
        if (gepro.geproType?.requiredFields?.routes && (!gepro || !gepro.routes || gepro.routes.length === 0)) {
            this.setValidationErrors('Vermittler', [{error: true}]);
            this.setIsCompleted('Vermittler', false);
        } else if (gepro.geproType?.category === 'NAME_MAKLER' && (!gepro.maklerName || gepro.maklerName.length === 0)) {
            this.setValidationErrors('Vermittler', [{error: true}]);
            this.setIsCompleted('Vermittler', false);
        } else {
            this.setValidationErrors('Vermittler', []);
            this.setIsCompleted('Vermittler', true);
        }
    }

    public validateDocumentData(gepro: GeproRestTO, docListLength: number): void {
        if (gepro.geproType?.requiredFields?.infos && (!gepro.geproInfos || gepro.geproInfos ? gepro.geproInfos.trim().length === 0 : true)) {
            this.setValidationErrors('Inhalte', [{error: true}]);
            this.setIsCompleted('Inhalte', false);
        } else if (this.validDocuments(gepro.geproType?.requiredDocuments).length > 0) {
            let tickedDocsOK = true;
            this.validDocuments(gepro?.geproType?.requiredDocuments).forEach(document => {
                if (!gepro.tickedDocuments.some(ticked => ticked.documentId === document.id)) {
                    tickedDocsOK = false;
                }
            })
            let uploadedDoc = true;
            if (docListLength === 0) { uploadedDoc = false; }
            if (tickedDocsOK  && uploadedDoc) {
                this.setValidationErrors('Inhalte', []);
                this.setIsCompleted('Inhalte', true);
            } else {
                this.setValidationErrors('Inhalte', [{error: true}]);
                this.setIsCompleted('Inhalte', false);
            }
        } else {
            this.setValidationErrors('Inhalte', []);
            this.setIsCompleted('Inhalte', true);
        }
    }

    public validDocuments(documentList: Array<DocumentRestTO>): Array<DocumentRestTO> {
        const filteredList =  documentList?.filter(document => document.valid === true);
        return filteredList;
    }
}

/*Interface Design Child Komponenten:

  Output:
  Fehler Ja/Nein
  Erledigt Ja/Nein
  -> Outputs können im ngOnDestroy gesetzt werden.

  Input: Current Gepro ID /Gepro

   */

<ng-template #geproDenyReasonTemplate>
    <div nxModalContent class="modal-content u-text-center">
        <h3 nxHeadline="subsection-large" class="nx-font-weight-light">Begründung</h3>
        <div class="nx-margin-bottom-m">
            <app-restricted-text-area #reasonText
                                      label="Bitte geben Sie an, warum Sie diesen Vorgang zurückweisen"
                                      [maxLength]="4000"
                                      [(ngModel)]="reason"></app-restricted-text-area>
        </div>
        <button nxModalClose="Cancel" nxButton="medium secondary">Abbrechen</button>
        <button nxModalClose="Zurueckweisen" nxButton="medium" [disabled]="isMoveButtonDisabled() || reasonText.isInvalid()">
            Zurückweisen
        </button>
    </div>
</ng-template>


<div>
    <div #fileUpload id="fileUpload">
    </div>
    <button (click)="createNewUploadInput()"
            [disabled]="!isLastUploadFieldFilled() || isDisabled" class="js-addupload" nxButton='tertiary small-medium' type="button">Weiteren Upload
        hinzufügen...
    </button>
    <br>
    <br>
</div>

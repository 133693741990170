import {AbstractType} from './AbstractType';
import {GeproTypeRestTO} from './GeproTypeRestTO';
import {MitarbeiterReferatMappingRestTO} from './MitarbeiterReferatMappingRestTO';

export class StatusTypeRestTO extends AbstractType {

    public badgeName: string;
    public displayName: string;

    public static isStatusType(object: number | string | Date | MitarbeiterReferatMappingRestTO | GeproTypeRestTO | StatusTypeRestTO): boolean {
        return object.hasOwnProperty("badgeName");
    }
}

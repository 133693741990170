import {DocumentIterator} from './document-upload.service';
import {DocumentModel} from '../document.model';
import {DocumentStorageService} from './document-storage.service';

export class DocumentUploadIterator implements DocumentIterator {

    constructor(private readonly documentStorage: DocumentStorageService) { }

    hasNext(): boolean {
        return this.hasNoFailure() && this.hasWaitingDocument();
    }

    next(): DocumentModel {
        return this.waitingDocument();
    }

    private hasNoFailure(): boolean {
        return this.documentStorage.findDocumentByStatus('FAILURE') == null;
    }

    private hasWaitingDocument(): boolean {
        return this.waitingDocument() != null;
    }

    private waitingDocument(): DocumentModel {
        return this.documentStorage.findDocumentByStatus('WAITING');
    }
}

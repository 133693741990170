import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {HttpParams} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class SetFilterCountService {

    private waitingForInitialFilters: boolean = false;
    private initialFilters: Map<string, string> = new Map();

    constructor() {
    }

    private setFilterCountSubject: Subject<number> = new Subject();

    public getFilterCountObservable(): Observable<number> {
        return this.setFilterCountSubject.asObservable();
    }

    public filterParamsSet(params: HttpParams): void {
        this.countAndEmitSetFilters(params);
    }

    private setInitialFilters(params: HttpParams): void {
        this.initialFilters.clear();
        params.keys().forEach(key => {
            if (key === 'statusType') {
                this.initialFilters.set(this.cleanse(key), this.cleanse(params.get(key)));
            }
        });

        this.waitingForInitialFilters = false;
        this.setFilterCountSubject.next(0);
    }

    private cleanse(s: string): string {
        const cleansed =  s.split("(").join("").split(")").join("").split("statusType:").join("");
        if (cleansed.indexOf(" OR ") > -1) {
            return cleansed.split(" OR ").map(disjunctionPart => disjunctionPart.trim()).sort().join(" OR ");
        }
        return cleansed;
    }

    private countAndEmitSetFilters(params: HttpParams): void {
        var counter = 0;
        params.keys().forEach(key => {
            if ( key === '(createdTs>' || key === '(lastStatusChange>'
                || key === '(geproType' || key === '(statusType'
                || key === '(msn6' || key === '(mitarbeiterBensl') {
                var cleansedKey = this.cleanse(key);
                counter = !this.initialFilters.has(cleansedKey) ?
                    counter + 1 : counter;
                counter = this.initialFilters.has(cleansedKey) && this.initialFilters.get(cleansedKey) !== this.cleanse(params.get(key)) ?
                    counter + 1 : counter;
            }
        });

        this.setFilterCountSubject.next(counter);
    }

    public initialFiltersSet(params: HttpParams): void {
        this.setInitialFilters(params);
    }
}

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {VisibilityService} from '../../../services/visibility.service';
import {GeproService} from '../../index/service/gepro.service';
import {combineLatest, Subscription} from 'rxjs';
import {LoginInfo, LoginInfoService} from '../../../services/login-info.service';
import {ChangelogEntry, GeproHistoryService} from '../../../services/gepro-history.service';
import {GeproDetails, GeproFiles} from '../../../interfaces/gepro.interfaces';
import {UserMessagesService} from '../../common/messages-area/messages-area/service/user-messages.service';
import * as moment from 'moment';
import {take} from 'rxjs/operators';
import {LszEmailModalComponent} from '../../common/modals/lsz-email-modal/lsz-email-modal.component';

@Component({
  selector: 'app-gepro-communication',
  templateUrl: './gepro-communication.component.html',
  styleUrls: ['./gepro-communication.component.css']
})
export class GeproCommunicationComponent implements OnInit, OnDestroy {

  communicationButtons: Array<CommunicationMode> = new Array<CommunicationMode>();
  message = '';

  recipient: '';
  thirdPersonCommMode = 'enquiry';
  options: Array<LoginInfo>;
  filteredOptions: Array<LoginInfo>;
  is20DaysNotExpired: boolean;
  isLSZDocumentPDF = false;
  @ViewChild(LszEmailModalComponent, {read: LszEmailModalComponent, static: true}) lszEMailModal: LszEmailModalComponent;
  @Input() id: number | string;
  @Input() selectedCommunicationMode: string;
  @Input() lszEmailExists: boolean;
  @Input() communicationWithLszOnlyActive: boolean;

  @Output() readonly afterCommunication = new EventEmitter();
  @Output() readonly afterEnquiry = new EventEmitter();

  private subscription: Subscription = new Subscription();
  private geproDetails: GeproDetails;

  constructor(private readonly geproService: GeproService,
              private readonly loginInfoService: LoginInfoService,
              private userMessagesService: UserMessagesService,
              public geproHistoryService: GeproHistoryService,
              public visibilityService: VisibilityService) {}

  filter(searchTerm: string) {
      const regex = RegExp(searchTerm, 'gi');
      this.filteredOptions = this.options.filter(loginInfo => regex.test(loginInfo.bensl) || regex.test(loginInfo.fullName));
  }

    printEventCreatorInfo(streamObj: ChangelogEntry) {
        let result: string;
        if (!streamObj || !streamObj.creator || !streamObj.creator.fullName) {
            return '';
        }

        result = ', ' + streamObj.creator.fullName;

        return result;
    }

    communicate() {
      if (this.selectedCommunicationMode === 'note'
          || (this.selectedCommunicationMode === 'involve-third-person' && this.thirdPersonCommMode === 'note')) {

          const recipientBensl = (this.selectedCommunicationMode === 'involve-third-person')
              ? this.options.find(loginInfo =>  this.recipient.startsWith(loginInfo.fullName)).bensl
              : null;

          this.geproService.addNote(this.message, this.id, recipientBensl)
              .then(() => this.resetForm())
              .catch((error) =>
                  this.userMessagesService.addErrorMessageString('Erstellen der Notiz nicht erfolgreich: '
                      + error.error.message));
      } else if (this.selectedCommunicationMode === 'enquiry'
          || (this.selectedCommunicationMode === 'involve-third-person' && this.thirdPersonCommMode === 'enquiry')) {

          const recipientBensl = (this.selectedCommunicationMode === 'involve-third-person')
              ? this.options.find(loginInfo =>  this.recipient.startsWith(loginInfo.fullName)).bensl
              : this.geproDetails.erfasserBensl;

          this.geproService.postQuestion(this.message, this.geproDetails.id, recipientBensl)
              .then(() => this.resetForm())
              .then(() => this.afterEnquiry.emit())
              .catch((error) =>
                  this.userMessagesService.addErrorMessageString('Erstellen der Rückfrage nicht erfolgreich: '
                      + error.error.message));
      } else if (this.selectedCommunicationMode === 'reply') {
          this.geproService.postAnswer(this.message, this.geproDetails.id, this.geproDetails.mitarbeiterBensl)
              .then(() => this.resetForm())
              .catch((error) =>
                  this.userMessagesService.addErrorMessageString('Die Rückfrage wurde zurückgezogen, ' +
                      'Sie können nicht mehr antworten. Bitte aktualisieren Sie die Seite.'));
      } else if (this.selectedCommunicationMode === 'LSZ') {
          if (this.lszEmailExists === true) {
              this.lszEMailModal.show();
              this.lszEMailModal.modalResult().pipe(take(1)).subscribe(action => {
                  if (action === 'Save') {
                      this.communicateWithLSZ();
                  }
              });
          } else {
              this.communicateWithLSZ();
          }
      }
    }

    private communicateWithLSZ() {
        this.geproService.communicateWithLSZ(this.message, this.geproDetails.id)
            .then(() => this.resetForm())
            .catch((error) =>
                this.userMessagesService.addErrorMessageString('Beim Senden der E-Mail ist ein Fehler aufgetreten: '
                    + error.error.message));
    }

    private resetForm(): void {
        this.message = '';
        this.recipient = '';
        this.afterCommunication.emit();
    }

  ngOnInit(): void {
      this.subscription.add(combineLatest([this.geproService.subscribeToGeproDetails(), this.geproService.subscribeToGeproFiles()])
          .subscribe((data) => {
              if (data) {
                  this.geproDetails = data[0];
                  this.updateButtons(data[0], data[1]);
              }
          })
      );

      this.loginInfoService.getAll().subscribe(li => {
          this.options = li;
          this.filteredOptions = this.options.slice();
      });
  }

  updateButtons(geproDetails: GeproDetails, geproFiles: GeproFiles) {
      this.communicationButtons.length = 0;
      this.communicationButtons.push({
          enabled: !this.communicationWithLszOnlyActive,
          label: 'Notiz',
          value: 'note',
          preselcted :false
      });
      if (this.visibilityService.isVisible('enquiry')) {
          this.communicationButtons.push({
              enabled: geproDetails.erfasserName !== 'LSZ' && this.visibilityService.isEnabled('enquiry'),
              label: 'Rückfrage an Ersteller',
              value: 'enquiry',
              preselcted: false
          });
      } else if (this.visibilityService.isVisible('reply')) {
          this.communicationButtons.push({
              enabled: geproDetails.erfasserName !== 'LSZ' && this.visibilityService.isEnabled('reply'),
              label: 'Rückfrage beantworten',
              value: 'reply',
              preselcted: false
          });
      }
      this.communicationButtons.push({
          enabled: !this.communicationWithLszOnlyActive,
          label: 'Kommunikation mit weiterer Person',
          value: 'involve-third-person',
          preselcted:true
      });
      this.is20DaysNotExpired = moment().subtract(20, 'days').isBefore(moment(geproDetails.publishTimestamp));
      this.isLSZDocumentPDF = geproFiles?.content?.[0]?.type === 'application/pdf';
      this.communicationButtons.push({
          enabled: geproDetails.erfasserName === 'LSZ' && this.is20DaysNotExpired && this.isLSZDocumentPDF,
          label: 'Kommunikation mit LSZ',
          value: 'LSZ',
          preselcted: false
      });
  }

  determineTextAreaPlaceholder() {
      if (this.selectedCommunicationMode === 'note') {
          return 'Notiz erfassen';
      } else if (this.selectedCommunicationMode === 'enquiry') {
          return 'Rückfrage stellen';
      } else if (this.selectedCommunicationMode === 'reply') {
          return 'Rückfrage beantworten';
      } else if (this.selectedCommunicationMode === 'involve-third-person') {
          if (this.thirdPersonCommMode === 'note') {
              return 'Notiz erfassen';
          } else if (this.thirdPersonCommMode === 'enquiry') {
              return 'Rückfrage stellen';
          }
      } else if (this.selectedCommunicationMode === 'LSZ') {
          return 'Notiz hinzufügen';
      }
      // fallback
      return 'Text erfassen';
  }

  recipientIsMissing() {
      return this.selectedCommunicationMode === 'involve-third-person' && !this.recipient;
  }

  selectValue(val: string) {
      console.log(val);
  }

  ngOnDestroy() {
      if (this.subscription) {
          this.subscription.unsubscribe();
      }
  }

  determineTextAreaMaxLength(): number {
      if (this.selectedCommunicationMode === 'LSZ') {
           return 3800;
      }
      return 4000;
  }

  determineTooltip(buttonValue: string): string | undefined {
      if (buttonValue !== 'LSZ') {
          return undefined;
      }
      if (this.geproDetails.erfasserName === 'LSZ' && !this.isLSZDocumentPDF) {
          return 'Bei diesem Vorgang handelt es sich um kein Papierpost-Schriftstück sondern eine Nachricht, die per E-Mail eingegangen ist.  Die ursprüngliche Nachricht befindet sich im Anhang. Falls Rückfragen bestehen, wenden Sie sich bitte direkt an den Absender.';
      }
      if (this.geproDetails.erfasserName === 'LSZ' && !this.is20DaysNotExpired) {
          return 'Die Aufbewahrungsfrist des Papierpost-Schriftstück beträgt 20 Tage. Diese Frist ist verstrichen. Das originale Dokument kann daher nicht mehr angefordert werden.';
      }
      return undefined;
  }
}

export interface CommunicationMode {
    enabled: boolean;
    label: string;
    value: string;
    preselcted: boolean;
}

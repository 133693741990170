import {AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {CreateGeproService} from '../../../services/create-gepro.service';
import {BaseComponent} from '../base/base.component';
import {FormBuilder, FormGroup} from '@angular/forms';
import {GeproRestTO} from '../../index/service/Model/GeproRestTO';
import {GeproTypeRestTO} from '../../index/service/Model/GeproTypeRestTO';
import * as moment from 'moment';
import {DocumentRestTO} from '../../index/service/Model/DocumentRestTO';
import {Subscription} from 'rxjs';
import {TickedDocumentRestTO} from '../../index/service/Model/TickedDocumentRestTO';
import {DocumentUploadComponent} from './document-upload/document-upload.component';
import {DocumentService} from './document-upload/services/document.service';
import {NxDialogService, NxModalRef} from '@allianz/ngx-ndbx/modal';

@Component({
    selector: 'app-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.css']
})
export class DocumentsComponent extends BaseComponent implements OnInit, AfterViewInit, AfterViewChecked {

    next = false;
    completed = false;
    pageName = 'Inhalte';
    geproInfosModel: string;

    ERROR_DOK_OK_CHEK_NOK: string = 'Bitte markieren Sie bei der Checkliste Pflichtdokumente Ihre hochgeladenen Inhalte.';
    ERROR_DOK_NOK_CHEK_OK: string = 'Bitte laden Sie alle benötigten Pflichtinhalte hoch.';
    ERROR_DOK_NOK_CHEK_NOK: string = 'Bitte markieren Sie bei der Checkliste Pflichtdokumente Ihre hochgeladenen Inhalte oder laden Sie sie ggf. hoch.';
    ERROR_INFO_NOK: string = 'Bitte geben Sie eine Nachricht ein.'

    @ViewChild('uploadChecks', {read: FormGroup, static: false}) uploadChecks: FormGroup;
    @ViewChild('uploadChecksOptional', {read: FormGroup, static: false}) uploadChecksOptional: FormGroup;
    @ViewChild('documentUpload', {read: DocumentUploadComponent, static: true}) documentUpload: DocumentUploadComponent;

    documentsForm: FormGroup;

    private documentsComponentSubscription: Subscription = new Subscription();
    showCheckboxError: boolean = false;
    showUploadError: boolean = false;
    errorMessage: string = '';
    errorMessageInhalt: string = '';

    @ViewChild('amvbAdressModal') templateRef: TemplateRef<any>;
    amvbAdressModal: NxModalRef<any>;

    constructor(public createGeproService: CreateGeproService,
                private cdRef: ChangeDetectorRef,
                public dialogService: NxDialogService) {
        super(createGeproService);
        this.initEmptyGepro();

        this.documentsForm = new FormBuilder().group({
            uploadChecks: [''],
            uploadChecksOptional: ['']
        });
    }

    ngOnInit() {
        super.ngOnInit();
        this.createGeproService.validateTypeData(this.gepro);
        this.createGeproService.validateBrokerData(this.gepro);
        this.cdRef.detectChanges();
        this.validateDataOnInit();
    }

    ngAfterViewInit(): void {
        this.cdRef.detectChanges();
    }

    ngAfterViewChecked(): void {
        let errors: boolean = this.getValidationErrors() && this.getValidationErrors().length > 0;
        let documents: boolean = this.documentUpload.hasUploadedDocuments();

        let allTicked = true;
        this.validDocuments(this.gepro.geproType.requiredDocuments).forEach(document => {
            if(!this.gepro.tickedDocuments.some(ticked => ticked.documentId == document.id)){
                allTicked = false;
            }
        });
        this.setIsCompleted(!errors && documents && allTicked);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        this.performValidation();
        if (this.getValidationErrors().length === 0) {
            this.createGeproService.setIsCompleted(this.pageName, true);
        }
        this.documentsComponentSubscription.unsubscribe();
    }

    public validateDataAfterLoad() {
        setTimeout(() => {
        });
        this.validateDataOnInit();
        this.cdRef.detectChanges();
    }

    // override because we need another behaviour here
    public callGeproObservables() {
        this.subscription.add(this.createGeproService.geproObservable().subscribe(_data => {
            if (_data && _data.geproType) {
                this.gepro = _data;
                this.geproInfosModel = this.gepro ? this.gepro.geproInfos : undefined;
            }
        }));

        this.subscription.add(this.createGeproService.saveIntentObservable().subscribe(() => {
            //this should work when the gepro was modified while the user was on page
            this.createGeproService.saveGepro(this.gepro).then(() => {
                this.saveSuccessful.emit(true);
            });
        }));
    }

    private validateDataOnInit(): void {
        if (this.getValidationErrors().length > 0) {
            this.performValidation();
            this.cdRef.detectChanges();
        }
    }

    public isChecked(documentId: number): boolean {
        return this.gepro.tickedDocuments.findIndex((doc) => doc.documentId == documentId) > -1;
    }

    public checkboxChange(documentId: number, isChecked: boolean) {
        if (isChecked) {

            let tickedDocumentRestTO = new TickedDocumentRestTO();
            tickedDocumentRestTO.documentId = documentId;
            tickedDocumentRestTO.geproId = this.gepro.id;
            this.gepro.tickedDocuments.push(tickedDocumentRestTO);
        } else {
            this.gepro.tickedDocuments = this.gepro.tickedDocuments.filter(doc => doc.documentId !== documentId);
        }
        this.performValidation();
    }

    private initEmptyGepro(): void {
        this.gepro = new GeproRestTO();
        this.gepro.geproType = new GeproTypeRestTO();
        this.gepro.geproType.requiredDocuments = new Array<DocumentRestTO>();
        this.gepro.geproType.optionalDocuments = new Array<DocumentRestTO>();
    }

    public performValidation(): void {
        let isCheckboxesValid = this.validateCheckboxes();
        let isUploadsValid = this.validateUploads();
        let isRequiredInfosValid = true;

        this.errorMessage = '';
        if (isCheckboxesValid) {
            if (!isUploadsValid) {
                this.errorMessage = this.ERROR_DOK_NOK_CHEK_OK;
            }
        } else {
            isUploadsValid ? this.errorMessage = this.ERROR_DOK_OK_CHEK_NOK
                :  this.errorMessage = this.ERROR_DOK_NOK_CHEK_NOK;
        }
        this.errorMessageInhalt = '';
        if (this.requiresInfos()) {
            if (!this.hasInfos()) {
                this.errorMessageInhalt = this.ERROR_INFO_NOK;
                isRequiredInfosValid = false;
            }
        }
        this.setValidationErrors(isCheckboxesValid && isUploadsValid && isRequiredInfosValid ? [] : [{error: true}]);
    }

    private validateUploads(): boolean {
        const uploadedDocuments = this.documentUpload.hasUploadedDocuments();
        const result = this.validDocuments(this.gepro.geproType.requiredDocuments).length === 0 || uploadedDocuments;
        this.showUploadError = !result && this.documentUpload.hasQueuedDocuments() == null;

        return result;
    }

    isFormular(document: DocumentRestTO): boolean {
        return document && document.doctype === DocumentRestTO.TYPE_FORMULAR;
    }

    isLink(document: DocumentRestTO): boolean {
        return document && document.doctype === DocumentRestTO.TYPE_LINK;
    }

    private isDefaultDate(lastUpdated: moment.Moment): boolean {
        return moment(lastUpdated).isSame(moment('1900-01-01T00:00:00.00Z', 'YYYY-MM-DDTHH:mm:ssZ'));
    }

    private isDownloadDisabled(document: DocumentRestTO) {
        return this.isDefaultDate(document.lastUpdated) || !document.fileName || document.fileName === null;
    }

    private isDocumentLinkDisabled(document: DocumentRestTO) {
        return !document || !document.fileName || document.fileName === null;
    }

    private validateCheckboxes() {
        if (this.gepro.tickedDocuments) {
            let tickedRequiredDocuments: Array<TickedDocumentRestTO> =
                this.gepro.tickedDocuments.filter(
                    tickedDoc => this.validDocuments(this.gepro.geproType.requiredDocuments).findIndex(requiredDoc => requiredDoc.id === tickedDoc.documentId) > -1);

            if (this.validDocuments(this.gepro.geproType.requiredDocuments).length === tickedRequiredDocuments.length) {
                this.showCheckboxError = false;
                return true;
            } else {
                this.documentsForm.controls.uploadChecks.setErrors({'incorrect': true});
                this.documentsForm.controls.uploadChecks.markAsTouched();
                this.showCheckboxError = true;
                return false;
            }
        }

        this.documentsForm.controls.uploadChecks.setErrors({'incorrect': true});
        this.documentsForm.controls.uploadChecks.markAsTouched();
        this.showCheckboxError = true;

        return false;
    }

    subtitle(): string {
        return DocumentService.uploadRestrictions();
    }

    subtitleDocuments(): string {
        const notSpecialType = this.gepro && this.gepro.geproType && this.gepro.geproType.id !== 28;
        if (notSpecialType) {
            return 'Bitte laden Sie erforderliche Pflichtinhalte hoch und bestätigen Sie das Vorhandensein der entsprechenden Inhalte in der Checkliste.';
        }
        return '';
    }

    showAmvbAddressIcon(document: DocumentRestTO): boolean {
        const amvbAdressDocuments: string[] = ['Bürgschaftserklärung (Anlage zur VSV)','Bürgschaftserklärung'];

        if (amvbAdressDocuments.includes(document.name)) {
            return true;
        }
        return false;
    }

    commentChanged(isInvalid: boolean, value: string): void {
        const errors = [];
        let geproInfos = value;
        if (isInvalid) {
            geproInfos = undefined;
            errors.push({ error: true });
        }
        this.gepro.geproInfos = geproInfos;
        this.setValidationErrors(errors);
    }

    requiresInfos(): boolean {
        return this.gepro
            && this.gepro.geproType
            && this.gepro.geproType.requiredFields
            && this.gepro.geproType.requiredFields.infos;
    }

    private hasInfos(): boolean {
        return this.gepro && this.gepro.geproInfos && this.gepro.geproInfos.trim().length > 0;
    }

    public validDocuments(documentList: Array<DocumentRestTO>): Array<DocumentRestTO> {
        const filteredList =  documentList.filter(document => document.valid === true);
        return filteredList;
    }

    showAmvbAdressModal() {
        this.amvbAdressModal = this.dialogService.open(this.templateRef, { ariaLabel: 'Vorgangsart ändern', disableClose: true });
        this.amvbAdressModal.afterClosed().subscribe(result => {
             this.amvbAdressModal.close();
        });
    }
}

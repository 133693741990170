import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject, Subscription} from 'rxjs';
import {MaklerStrukturNodeRestTO} from '../components/index/service/Model/MaklerStrukturNodeRestTO';
import {DefaultHttpService} from './default-http.service';
import {Endpoints} from '../components/navigation/endpoints';
import {MaklerstrukturNodeContentComponent} from '../components/create-gepro/broker/maklerstruktur/maklerstruktur-node-content/maklerstruktur-node-content.component';
import {CreateGeproService} from './create-gepro.service';
import {GeproRestTO} from '../components/index/service/Model/GeproRestTO';
import {GeproRouteRestTO} from '../components/index/service/Model/gepro-route-rest.to';
import {MsntreeNodeContentComponent} from '../components/create-gepro/broker/maklerstruktur/maklerstruktur-node-content/msntree-node-content.component';

@Injectable({
    providedIn: 'root'
})
export class MaklerstrukturService {

    private maklerTree: MaklerStrukturNodeRestTO;
    private selectedGepro: GeproRestTO;
    private searchValue: string;

    private initSetupComplete = false;
    private setupTimeout;

    // for more efficient access to selected gepro routes
    private geproRoutesSet: Set<string> = new Set<string>();

    private msnNodeClickedSet: Set<string> = new Set<string>();

    private maklerTreeSubject: Subject<MaklerStrukturNodeRestTO> = new Subject<MaklerStrukturNodeRestTO>();
    maklerTreeFinishedSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

    private subscription: Subscription = new Subscription();

    private createdComponents: Array<MaklerstrukturNodeContentComponent> = [];
    private searchedVtnrComponent: MaklerstrukturNodeContentComponent;
    private searchedVtnrTreeComponent: MsntreeNodeContentComponent;

    private createdCounter = 0;

    constructor(private httpService: DefaultHttpService,
                private createGeproService: CreateGeproService) {
        this.subscription.add(createGeproService.geproObservable().subscribe(gepro => {
            this.msnNodeClickedSet.clear();

            this.selectedGepro = gepro;
            if (this.selectedGepro && this.selectedGepro.routes) {
                this.geproRoutesSet.clear();
                this.selectedGepro.routes.forEach(route => this.geproRoutesSet.add(route.value));
            }
        }));
    }

    public getMaklerTreeObservable(): Observable<MaklerStrukturNodeRestTO> {
        return this.maklerTreeSubject.asObservable();
    }

    public getMaklerTreeSubject(): Subject<MaklerStrukturNodeRestTO> {
        return this.maklerTreeSubject;
    }

    public queryMaklerStrukturTree(msnOrVtnr: string, queryVtnrs?: boolean): void {
        this.resetTree();

        this.searchValue = msnOrVtnr;

        if (queryVtnrs == null) {
            queryVtnrs = this.searchValue.indexOf('/') == -1;
            if (this.selectedGepro && this.selectedGepro.routes) {
                this.selectedGepro.routes.forEach(route => queryVtnrs = queryVtnrs || route.value.indexOf('/') == -1);
            }
        }
        this.httpService.get(Endpoints.MAKLERSTRUKTUR_TREE + '/' + msnOrVtnr + '?includeVtnrs=' + queryVtnrs).subscribe(tree => {
            this.maklerTree = MaklerStrukturNodeRestTO.deserializeTree(tree, null);
            this.maklerTreeSubject.next(this.maklerTree);

            // Route hinzufügen - SPARTA-2036 (automatische Auswahl der VTNR bei VTNR-Suche)
            if (this.isSearchValueVTNR() && this.maklerTree) {
                let geproRouteRestTO = new GeproRouteRestTO(-1, 'VTNR', this.searchValue);
                geproRouteRestTO.root = this.maklerTree.strukturId;
                if (this.selectedGepro&&this.selectedGepro.routes&&(this.selectedGepro.routes.length === 0 ||
                    this.selectedGepro.routes.findIndex(route => route.value === this.searchValue) === -1)) {
                    if (this.selectedGepro.routes.filter(route => route.root === this.maklerTree.strukturId).length === 0
                        && this.selectedGepro.routes.length > 1) {
                        // keine Route hinzufügen wenn schon für eine andere MSN6 etwas hinterlegt ist
                    } else {
                        this.selectedGepro.routes.push(geproRouteRestTO);
                    }
                }
            }
        });
    }

    public async queryMaklerStrukturTreeLszUnindefined(msnOrVtnr: string, queryVtnrs?: boolean) {
        this.resetTree();

        this.searchValue = msnOrVtnr;
        let value: boolean = false;
        if (queryVtnrs == null) {
            queryVtnrs = this.searchValue.indexOf('/') == -1;
            if (this.selectedGepro && this.selectedGepro.routes) {
                this.selectedGepro.routes.forEach(route => queryVtnrs = queryVtnrs || route.value.indexOf('/') == -1);
            }
        }
        await this.httpService.get(Endpoints.MAKLERSTRUKTUR_TREE + '/' + msnOrVtnr + '?includeVtnrs=' + queryVtnrs).subscribe(tree => {
            this.maklerTree = MaklerStrukturNodeRestTO.deserializeTree(tree, null);
            this.maklerTreeSubject.next(this.maklerTree);
            console.log("im here")
            value = true;
            // Route hinzufügen - SPARTA-2036 (automatische Auswahl der VTNR bei VTNR-Suche)
            if (this.isSearchValueVTNR() && this.maklerTree) {
                value = true;
            }
        });
        return value;
    }

    public queryMaklerStrukturVtnrsFor(msn12: string): Observable<any> {
        return this.httpService.get(Endpoints.MAKLERSTRUKTUR_VTNRS + '/' + msn12);
    }

    public  openAllChildNodes(component: MaklerstrukturNodeContentComponent): void{
            if (component.node.strukturId.substring(0, 2).match(new RegExp('^[FfVvKkPpIiAaSs]/'))) {
                component.elementRef.nativeElement.click();

                setTimeout(() => {
                    this.searchedVtnrComponent.elementRef.nativeElement.scrollIntoView();
                    this.maklerTreeFinishedSubject.next(true);
                }, 1000);
            }
    }


    public nodeContentComponentCreatedTree(component: MsntreeNodeContentComponent) {
        if (component.node.item === this.searchValue) {
            this.searchedVtnrTreeComponent = component;
        }
    }

    public nodeContentComponentCreated(component: MaklerstrukturNodeContentComponent): void {
        this.createdCounter++;

        if (component.node.strukturId === this.searchValue) {
            this.searchedVtnrComponent = component;
        }

        if(this.isSearchValueVTNR()) {
            this.openAllChildNodes(component);
            if (!component.isMsn && !this.msnNodeClickedSet.has(component.node.parent.strukturId)) {

                this.msnNodeClickedSet.add(component.node.parent.strukturId);
                component.highlight = true;
            }
        }

        else {
            // click opened msns during search
            if (component.isMsn
                && this.searchValue.indexOf(component.node.strukturId) > -1
                && !this.msnNodeClickedSet.has(component.node.strukturId)
                && component.node.strukturId != this.searchValue) {

                this.msnNodeClickedSet.add(component.node.strukturId);

                setTimeout(() => {
                    component.elementRef.nativeElement.click();
                });

            } else if (component.isMsn
                && this.searchValue == component.node.strukturId) {
                component.highlight = true;

                setTimeout(() => {
                    this.searchedVtnrComponent.elementRef.nativeElement.scrollIntoView();
                    this.maklerTreeFinishedSubject.next(true);
                }, 1000);
            }

            //if (this.createdCounter >= this.maklerTree.getListOfAllNodes().length) { Due to virtual scrolling this is not added correctly.it works without the registration of each component. the setTimeout should handle that properly!
            if (!this.initSetupComplete) {
                setTimeout(() => {
                    this.createdComponents.filter(comp => {
                        return comp.recursivelySelectedCount > 0;
                    }).forEach(comp => {
                        comp.elementRef.nativeElement.click();
                    });
                });


                clearTimeout(this.setupTimeout);
                setTimeout(() => {
                    this.setupTimeout = this.initSetupComplete = true;
                }, 3000);
            }


        }

        if (component.isMsn) {
            this.createdComponents.push(component);
        }
    }

    private resetTree() {
        this.msnNodeClickedSet.clear();
        this.createdComponents = [];
        this.createdCounter = 0;
    }

    public isSearchValueVTNR(): boolean {
        return this.searchValue.match(/^\d/) != null;
    }

    isHighlighted(msnNode: MaklerStrukturNodeRestTO): boolean {
        return msnNode.strukturId === this.searchValue;
    }

    public getSearchValue(): string {
        return this.searchValue;
    }

    public getSearchedVtnrTreeComponent(): MsntreeNodeContentComponent {
        return this.searchedVtnrTreeComponent;
    }
}

import {AfterContentInit, Component, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {Observable, Subject, Subscription} from 'rxjs';
import {UserinfoService} from '../../index/service/userinfo.service';
import {GeproRestTO} from '../../index/service/Model/GeproRestTO';
import {GeproTypeRestTO} from '../../index/service/Model/GeproTypeRestTO';
import {FilterEntry} from './entity/filterEntry';
import * as moment from 'moment';
import {Moment} from 'moment';
import {WorkListService} from '../../../services/work-list.service';
import {FilterDropdownComponent} from './filter-dropdown/filter-dropdown.component';
import {FilterDateComponent} from './filter-date/filter-date.component';
import {NxRadioGroupComponent} from '@allianz/ngx-ndbx/radio-button';
import {GeproFilterStatisticsInterface, GeproFilterStatisticsRestTO} from '../../index/service/Model/GeproFilterStatisticsRestTO';
import {GeproTypeService} from '../../index/service/gepro-type.service';
import {GeproFilterVisibilityService} from './gepro-filter-visibility.service';
import {GeproFilterRequest} from './entity/gepro-filter-request';
import {FilterRequestBuilderService} from '../../../services/filter-request-builder.service';

export interface Filter {
    key: string;
    value: string;
}

@Component({
    selector: 'app-gepro-filter',
    templateUrl: './gepro-filter.component.html',
    styleUrls: ['./gepro-filter.component.css']
})
export class GeproFilterComponent implements OnInit, OnDestroy, AfterContentInit {

    @Output()
    filterChanged: Subject<Array<Filter>>;

    @Input()
    filterStatisticsObservable: Observable<Array<GeproFilterStatisticsInterface>>;

    @Input() // observe filters changed e.g. by the tabs
    setFilterObservable: Observable<GeproFilterRequest>;

    @ViewChild('defaultFilter', {read: NxRadioGroupComponent, static: false}) defaultFilter;
    @ViewChild('statusDropdown', {read: FilterDropdownComponent, static: false}) statusDropdown;
    @ViewChild('geproDropdown', {read: FilterDropdownComponent, static: false}) geproDropdown;
    @ViewChild('msn6Dropdown', {read: FilterDropdownComponent, static: false}) msn6Dropdown;
    @ViewChild('createDateChooser', {read: FilterDateComponent, static: false}) createDateChooser;
    @ViewChild('modifiedDateChooser', {read: FilterDateComponent, static: false}) modifiedDateChooser;
    @ViewChild('bearbeiterDropdown', {read: FilterDropdownComponent, static: false}) bearbeiterDropdown;

    private subscription: Subscription = new Subscription();

    minCreatedDate: Moment;
    maxCreatedDate: Moment;
    minModifiedDate: Moment;
    maxModifiedDate: Moment;

    selectedDefaultFilter: string;
    filterStatusType: Array<string> = new Array<string>();
    filterGeproType: Array<GeproTypeRestTO>;
    filterMsn6: Array<string>= new Array<string>();
    filterBearbeiterName: Array<string>;

    // not all status filters are allowed, it depends on the tab
    private allowedStatusFilters: Array<string> = new Array<string>();

    public statusFilterEntries: Array<FilterEntry> = new Array<FilterEntry>();
    public geproTypeFilterEntries: Array<FilterEntry> = new Array<FilterEntry>();
    public msn6FilterEntries: Array<FilterEntry> = new Array<FilterEntry>();
    public bearbeiterFilterEntries: Array<FilterEntry> = new Array<FilterEntry>();
    public filter_createdMinDate: Date;
    public filter_createdMaxDate: Date;
    public filter_modifiedMinDate: Date;
    public filter_modifiedMaxDate: Date;

    public geproTypeMap: Map<string, number> = new Map<string, number>();

    constructor(public readonly userService: UserinfoService,
                private worklistService: WorkListService,
                private geproTypeService: GeproTypeService,
                public filterVisibilityService: GeproFilterVisibilityService,
                private requestBuilderService: FilterRequestBuilderService) {
        this.filterChanged = new Subject();
        this.defaultFilter = '';
    }

    ngOnInit(): void {

        this.filterGeproType = JSON.parse(sessionStorage.getItem("filterGeproTypesessionStorage"));
        if(this.filterGeproType==null||this.filterGeproType.length<=0){
            this.filterGeproType = new Array<GeproTypeRestTO>()
        }

        this.filterMsn6 = JSON.parse(sessionStorage.getItem("filterMsn6sessionStorage"));
        if(this.filterMsn6==null||this.filterMsn6.length<=0){
            this.filterMsn6 = new Array<string>();
        }

        this.filterBearbeiterName = JSON.parse(sessionStorage.getItem("filterBearbeiterNamesessionStorage"));
        if(this.filterBearbeiterName==null||this.filterBearbeiterName.length<=0){
            this.filterBearbeiterName = new Array<string>();
        }

        let dateString: string = JSON.parse(sessionStorage.getItem("filter_createdMinDatesessionStorage"));
        if (dateString !== null && dateString.length > 0) {
            this.filter_createdMinDate = new Date(dateString);
        }

        dateString = JSON.parse(sessionStorage.getItem("filter_createdMaxDatesessionStorage"));
        if (dateString !== null && dateString.length > 0) {
            this.filter_createdMaxDate = new Date(dateString);
        }

        dateString = JSON.parse(sessionStorage.getItem("filter_modifiedMinDatesessionStorage"));
        if (dateString !== null && dateString.length > 0) {
            this.filter_modifiedMinDate = new Date(dateString);
        }

        dateString = JSON.parse(sessionStorage.getItem("filter_modifiedMaxDatesessionStorage"));
        if (dateString !== null && dateString.length > 0) {
            this.filter_modifiedMaxDate = new Date(dateString);
        }


        this.selectedDefaultFilter = '';
        this.statusFilterEntries = new Array<FilterEntry>();
        this.geproTypeFilterEntries = new Array<FilterEntry>();
        this.msn6FilterEntries = new Array<FilterEntry>();
        this.bearbeiterFilterEntries = new Array<FilterEntry>();
        (this.geproTypeService.queryGeproTypeList().subscribe(data => {
            data.forEach(geproType => {
                this.geproTypeMap.set(geproType.name, geproType.id);
            });
        }));

        this.subscription.add(this.filterStatisticsObservable.subscribe(_data => this.setFilterStatistics(_data)));
        this.subscription.add(this.setFilterObservable.subscribe(_data => this.handleSetFilterRequest(_data)));
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    ngAfterContentInit(): void {
        if (this.userService.isRoleReferatsleiter() && !this.filterVisibilityService.isAllocatorDisabled()) {
            this.selectedDefaultFilter = GeproFilterRequest.FILTER_NOT_ASSIGNED;
        } else if (this.userService.isRoleAmvbMA() && !this.filterVisibilityService.isAssigneeDisabled()) {
            this.selectedDefaultFilter = GeproFilterRequest.FILTER_ASSIGNEE;
        }

        // tabs trigger the search now
        // this.onChange();
    }

    private setFilterStatistics(statisticsArray: Array<GeproFilterStatisticsInterface>): void {
        for (const filterStatistic of statisticsArray) {
            const parsedFilterStatistic: GeproFilterStatisticsRestTO = GeproFilterStatisticsRestTO.deserialize(filterStatistic);

            if (parsedFilterStatistic.filterType === GeproFilterStatisticsRestTO.FILTER_EINGANGSDATUM) {
                this.minCreatedDate = moment(parsedFilterStatistic.valueToCountMap.get(GeproFilterStatisticsRestTO.KEY_MIN));
                this.maxCreatedDate = moment(parsedFilterStatistic.valueToCountMap.get(GeproFilterStatisticsRestTO.KEY_MAX));
            } else if (parsedFilterStatistic.filterType === GeproFilterStatisticsRestTO.FILTER_GEPRO_ART) {
                this.geproTypeFilterEntries = new Array<FilterEntry>();
                parsedFilterStatistic.valueToCountMap.forEach((value: number, key: string) => {
                    this.geproTypeFilterEntries.push(new FilterEntry(key, this.geproTypeMap.get(key), value));
                });
            } else if (parsedFilterStatistic.filterType === GeproFilterStatisticsRestTO.FILTER_STATUS) {
                this.processFilterStatisticsForStatus(parsedFilterStatistic);
            } else if (parsedFilterStatistic.filterType === GeproFilterStatisticsRestTO.FILTER_BEARBEITER) {
                this.bearbeiterFilterEntries = new Array<FilterEntry>();
                parsedFilterStatistic.valueToCountMap.forEach((value: number, key: string) => {
                    // Parse BENSL from key
                    let displayName = key;
                    const matches = key.match(/\[([^\]]+)]/);
                    if (matches && matches.length > 1) { // First match group contains BENSL
                        displayName = matches[1] || key;
                    }
                    this.bearbeiterFilterEntries.push(new FilterEntry(key, displayName, value));
                });
            } else if (parsedFilterStatistic.filterType === GeproFilterStatisticsRestTO.FILTER_MAKLERHAUS) {
                this.msn6FilterEntries = new Array<FilterEntry>();
                parsedFilterStatistic.valueToCountMap.forEach((value: number, key: string) => {
                    this.msn6FilterEntries.push(new FilterEntry(key, key.split(' ', 1)[0], value));
                });
            } else if (parsedFilterStatistic.filterType === GeproFilterStatisticsRestTO.FILTER_LETZTE_AENDERUNG) {
                this.minModifiedDate = moment(parsedFilterStatistic.valueToCountMap.get(GeproFilterStatisticsRestTO.KEY_MIN));
                this.maxModifiedDate = moment(parsedFilterStatistic.valueToCountMap.get(GeproFilterStatisticsRestTO.KEY_MAX));
            }
            this.sortFilterData();
        }
    }

    private processFilterStatisticsForStatus(parsedFilterStatistic: GeproFilterStatisticsRestTO) {
        this.statusFilterEntries = new Array<FilterEntry>();
        parsedFilterStatistic.valueToCountMap.forEach((value: number, key: string) => {
            if (this.allowedStatusFilters.indexOf(key) > -1) {
                this.statusFilterEntries.push(new FilterEntry(key, key, value));
            }
        });
        this.allowedStatusFilters
            .filter(allowedStatusFilter => this.statusFilterEntries.findIndex(sfe => sfe.displayName == allowedStatusFilter) == -1)
            .forEach(notContainedStatusFilter => this.statusFilterEntries.push(new FilterEntry(notContainedStatusFilter, notContainedStatusFilter, 0)));

    }

    private sortFilterData(): void {
        this.sortStates();
        this.geproTypeFilterEntries = this.geproTypeFilterEntries.sort((a, b) => a.displayName.localeCompare(b.displayName));
        this.msn6FilterEntries = this.msn6FilterEntries.sort((a, b) => a.displayName.localeCompare(b.displayName));
        this.bearbeiterFilterEntries = this.bearbeiterFilterEntries.sort((a, b) => a.displayName.localeCompare(b.displayName));
    }

    public resetSelectedFilter(): void {
        this.resetFilterValues();

        this.onChange();
    }

    statusTypeFilterChanged($event): void {
        this.filterStatusType = $event;
        this.onChange();
    }

    geproTypeFilterChanged($event): void {
        this.filterGeproType = $event;
        this.onChange();
    }

    msnFilterChanged($event): void {
        this.filterMsn6 = $event;
        this.onChange();
    }

    createDateRangeChanged($event: Date[]) {
        this.filter_createdMinDate = $event[0];
        this.filter_createdMaxDate = $event[1];
        this.filter_createdMaxDate.setHours(23);
        this.filter_createdMaxDate.setMinutes(59);
        this.filter_createdMaxDate.setSeconds(59);
        this.filter_createdMaxDate.setMilliseconds(999);

        this.onChange();
    }

    modifiedDateRangeChanged($event: Date[]) {
        this.filter_modifiedMinDate = $event[0];
        this.filter_modifiedMaxDate = $event[1];
        this.filter_modifiedMaxDate.setHours(23);
        this.filter_modifiedMaxDate.setMinutes(59);
        this.filter_modifiedMaxDate.setSeconds(59);
        this.filter_modifiedMaxDate.setMilliseconds(999);
        this.onChange();
    }

    bearbeiterFilterChanged($event): void {
        this.filterBearbeiterName = $event;
        this.onChange();
    }

    private onChange(): void {
        const filters = [];
        this.requestBuilderService.applyDefaultFilter(this.selectedDefaultFilter, filters, false);

        if (this.filterStatusType && this.filterStatusType.length > 0) {
            const statusFilterDropDown = this.filterStatusType.map(statusType => statusType);
            this.requestBuilderService.pushMultiple(GeproRestTO.FIELD_STATUS_TYPE, statusFilterDropDown, filters);
            sessionStorage.setItem("filterStatusTypesessionStorage", JSON.stringify(this.filterStatusType));
        }
        else{
            this.filterStatusType = new Array<string>();
        }

        if (this.filterGeproType && this.filterGeproType.length > 0) {
            this.requestBuilderService.pushMultiple(GeproRestTO.FIELD_GEPRO_TYPE, this.filterGeproType.map(geproType => geproType + ''), filters);
            sessionStorage.setItem("filterGeproTypesessionStorage", JSON.stringify(this.filterGeproType));
        }
        else{
            this.filterGeproType = new Array<GeproTypeRestTO>();
        }

        if (this.filterMsn6 && this.filterMsn6.length > 0) {
            this.requestBuilderService.pushMultiple(GeproRestTO.FIELD_MSN6, this.filterMsn6.map(msnType=>msnType), filters);
            sessionStorage.setItem("filterMsn6sessionStorage", JSON.stringify(this.filterMsn6));
        }
        else{
            this.filterMsn6 = new Array<string>();
        }

        if (this.filter_createdMinDate || this.filter_createdMaxDate) {
            this.requestBuilderService.pushDate(GeproRestTO.FIELD_CREATED_TS, this.filter_createdMinDate, this.filter_createdMaxDate, filters);
            sessionStorage.setItem("filter_createdMinDatesessionStorage", JSON.stringify(this.filter_createdMinDate.toString()));
            sessionStorage.setItem("filter_createdMaxDatesessionStorage", JSON.stringify(this.filter_createdMaxDate.toString()));
        }
        else{
            this.filter_createdMinDate=null;
            this.filter_createdMaxDate=null;
        }

        if (this.filter_modifiedMinDate || this.filter_modifiedMaxDate) {
            this.requestBuilderService.pushDate(GeproRestTO.FIELD_LAST_CHANGE_TS, this.filter_modifiedMinDate, this.filter_modifiedMaxDate, filters);
            sessionStorage.setItem("filter_modifiedMinDatesessionStorage", JSON.stringify(this.filter_modifiedMinDate.toString()));
            sessionStorage.setItem("filter_modifiedMaxDatesessionStorage", JSON.stringify(this.filter_modifiedMaxDate.toString()));
        }
        else{
            this.filter_modifiedMinDate=null;
            this.filter_modifiedMaxDate=null;
        }

        if (this.filterBearbeiterName && this.filterBearbeiterName.length > 0) {
            this.requestBuilderService.pushMultiple(GeproRestTO.FIELD_MITARBEITER, this.filterBearbeiterName, filters);
            sessionStorage.setItem("filterBearbeiterNamesessionStorage", JSON.stringify(this.filterBearbeiterName));
        }
        else{
            this.filterBearbeiterName = new Array<string>();
        }

        this.filterChanged.next(filters);
    }

    public transformMomentToString(moment: Moment): { month: number; year: number; day: number } {
        if (moment == null) {
            return null;
        }

        const date = new Date(moment.toString());

        return {year: date.getFullYear(), month: (date.getMonth() + 1), day: date.getDate()};
    }

    private sortStates() {
        this.statusFilterEntries = this.statusFilterEntries.sort((a, b) => a.displayName.localeCompare(b.displayName));
    }

    public onDefaultFilterChange(selectedFilter: any) {
        this.selectedDefaultFilter = selectedFilter;
        this.onChange();
    }

    private handleSetFilterRequest(filterRequest: GeproFilterRequest): void {

        this.filterStatusType = JSON.parse(sessionStorage.getItem("filterStatusTypesessionStorage"));
        var filterStatusReferatsSicht : Array<string>  = filterRequest.getStatusTypeFilters();
        if(sessionStorage.getItem("filterStatusTypesessionStorage")==null) {
            this.filterStatusType= [];
            for (var i = 0; i < filterStatusReferatsSicht.length; i++) {
                this.filterStatusType.push(filterStatusReferatsSicht[i]);
            }
            this.allowedStatusFilters = filterStatusReferatsSicht.slice(0);
            this.resetFilterValues();
            if (filterRequest.getRelationshipFilter() === 'filterNotAssigned') {
                this.filterStatusType = this.filterStatusType.filter(item => item !== 'Abgeschlossen');
                this.filterStatusType = this.filterStatusType.filter(item => item !== 'Abgelehnt');
            }
        }
        this.allowedStatusFilters = filterStatusReferatsSicht.slice(0);

        this.onDefaultFilterChange(filterRequest.getRelationshipFilter());
    }



    public resetFilterValues(): void {
        if (this.statusDropdown) {
            this.statusDropdown.resetValue();
        }
        this.filterStatusType = this.allowedStatusFilters.slice(0);
        if (this.geproDropdown) {
            this.geproDropdown.resetValue();
        }
        if (this.msn6Dropdown) {
            this.msn6Dropdown.resetValue();
        }
        this.filterGeproType = new Array<GeproTypeRestTO>();
        this.filterMsn6 = new Array<string>();
        if (this.createDateChooser) {
            this.createDateChooser.resetValues();
        }
        this.filter_createdMinDate = null;
        this.filter_createdMaxDate = null;
        if (this.modifiedDateChooser) {
            this.modifiedDateChooser.resetValues();
        }
        this.filter_modifiedMinDate = null;
        this.filter_modifiedMaxDate = null;
        if (this.bearbeiterDropdown) {
            this.bearbeiterDropdown.resetValue();
        }
        this.filterBearbeiterName = new Array<string>();

    }

}

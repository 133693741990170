<div class="event-stream--container" tabindex="-1">
    <span *ngFor="let streamObj of visibleStream; let $index = index"
          class="item-wrapper js-event-item-wrapper js-event-item-{{$index}}"
          [class.isLast]="visibleStream.length===($index+1)"
          [class.event-stream--has-more-events]="hasMoreElements()">
        <ng-container
            *ngIf="streamObj.entryType=='ANSWER' || streamObj.entryType=='CANCEL' || streamObj.entryType=='RELEASE' ||
                streamObj.entryType=='RELEASEPROCESS' || streamObj.entryType=='RELEASEREQUEST' ||
                streamObj.entryType=='RELEASEDRAFT' || streamObj.entryType=='RELEASECANCEL'; then answer; else standard">
        </ng-container>
        <ng-template #standard >
            <app-gepro-history-item [item]="streamObj" >
                <app-gepro-history-rueckfrage (cancelQuestionClicked)="cancelQuestionSuccessful()"
                                              (answerQuestionClicked)="answerQuestion($event)"
                                              [geproId]="id"
                                              [item]="streamObj" ></app-gepro-history-rueckfrage>
            </app-gepro-history-item>
        </ng-template>

        <ng-template #answer>
            <ng-container *ngIf="streamObj.entryType=='RELEASE' || streamObj.entryType=='RELEASEPROCESS' ||
                          streamObj.entryType=='RELEASECANCEL' || streamObj.entryType=='RELEASEREQUEST' || streamObj.entryType=='RELEASEDRAFT'">
                <app-gepro-history-freigabe [item]="streamObj"></app-gepro-history-freigabe>
            </ng-container>
            <ng-container *ngIf="streamObj.entryType=='ANSWER'">
                <app-gepro-history-answer [item]="streamObj"></app-gepro-history-answer>
            </ng-container>
            <ng-container *ngIf="streamObj.entryType=='CANCEL'">
                <app-gepro-history-rueckfrage-cancel [item]="streamObj"></app-gepro-history-rueckfrage-cancel>
            </ng-container>
        </ng-template>
    </span>
    <div class="event-stream--footer edit-exception" [class.hasMore]="hasMoreElements()">
        <nx-link>
            <a class="js-event-show-more-button"
               (click)="showMore()" (keydown.enter)="showMore()"
               tabindex="0">
                <div *ngIf="previewMode; else showLess" fxLayout="row" fxLayoutAlign="space-between center">
                    <nx-icon aria-hidden="true"  [name]="'chevron-down'" size="s"></nx-icon>
                    <span class="nx-margin-left-xs">Alle anzeigen</span>
                </div>
                <ng-template #showLess>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <nx-icon aria-hidden="true"  [name]="'chevron-up'" size="s"></nx-icon>
                        <span class="nx-margin-left-xs">Weniger anzeigen</span>
                    </div>
                </ng-template>
            </a>
        </nx-link>
    </div>
</div>

<ng-template #zurueckziehenSuccessModalBody>
    <div class="u-text-center">
        <h3 nxHeadline="subsection-large" class="nx-font-weight-light">Die Rückfrage wurde zurückgezogen.</h3>
        <div class="buttonSection">
            <button nxModalClose="CancelDialog" nxButton="primary medium">Schließen</button>
        </div>
    </div>
</ng-template>

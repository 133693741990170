<div *ngIf="!maklerStrukturTree && maklerStrukturChanged; else legitSearch">
    <nx-message showIcon="true" nxContext="error">
        Zu Ihrer Suche konnte keine Maklerstruktur ermittelt werden
    </nx-message>
</div>
<ng-template #legitSearch>
    <div *ngIf="rootElementViolation">
        <nx-message showIcon="true" nxContext="error">
            Zu dem Vorgang sind bereits Daten zu Struktur {{selectedGepro?.routes[0].root}}  hinterlegt.
            Wenn Sie die hinterlegte Struktur ändern möchten, rufen Sie diese über die Suche erneut auf und
            entfernen Ihre Auswahl. Anschließend können Sie eine Suche mit der gewünschten neuen Strukturnummer
            (MSN/VTNR) durchführen.
        </nx-message>
    </div>
    <div *ngIf="!rootElementViolation">
        <div *ngIf="maklerStrukturTree">
            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" >
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding >
                    <span class="vtnrWrapper" [ngClass]="{'vtnrWrapper-search': isHighlighted(node)}">
                        <nx-checkbox [checked]="checklistSelection.isSelected(node)"
                                 (change)="todoLeafItemSelectionToggle(node)"
                                 (keydown.enter)="doNothing($event)">
                            <app-msntree-node-content [isMsn]="false" [node]="node">
                            </app-msntree-node-content>
                        </nx-checkbox>
                    </span>
                </mat-tree-node>

                <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding class="treeNodeWithChild" >
                    <span class="msnWrapper nx-font-weight-bold" [ngClass]="{'msnWrapper-search': isHighlighted(node)}">
                        <button mat-icon-button matTreeNodeToggle
                                [attr.aria-label]="treeControl.isExpanded(node) ? 'Zuklappen ' + node.item : 'Aufklappen ' + node.item ">
                            <mat-icon class="mat-icon-rtl-mirror">
                                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                            </mat-icon>
                        </button>
                        <nx-checkbox [checked]="checklistSelection.isSelected(node)"
                                     (change)="todoItemSelectionToggle(node)"
                                     (keydown.enter)="doNothing($event)"
                                     class="checkboxWithChild">
                            <app-msntree-node-content [isMsn]="true" [node]="node"
                                 [recursivelySelectedCount]="getSelectedSubNodesCount(node) ">
                            </app-msntree-node-content>
                        </nx-checkbox>
                    </span>
                </mat-tree-node>
            </mat-tree>
        </div>
    </div>
</ng-template>


export class VorgangIdService {

    private id: string;

    set currentId(currentId: string) {
        this.id = currentId;
    }

    get currentId(): string {
        return this.id;
    }
}

import {Component, OnInit} from '@angular/core';
import {Hinweis, HinweisService} from '../../components/index/service/hinweis.service';
import * as moment from 'moment';

const TIMESTAMP_FORMAT = 'DD.MM.YYYY';

@Component({
    selector: 'app-versionsinfo-component',
    templateUrl: './versionsinfo.component.html',
    styleUrls: ['./versionsinfo.component.scss']
})
export class VersionsinfoComponent implements OnInit {
    editedHinweise: Hinweis[];
    constructor(private hinweiseService: HinweisService) {

    }

    ngOnInit() {
        this.hinweiseService.getAllAnwendungsinfo().subscribe(
            res=>
            {
                this.editedHinweise = res;
                for(var i=0; i<this.editedHinweise.length; i++) {
                    this.loadDocuments(this.editedHinweise[i].id, i)
                }
            }
        );
    }

    private loadDocuments(id: number, i: number) {
        this.hinweiseService.getHinweisDocumentsVersionsinfo(id)
            .subscribe(
                documentList => {
                    this.editedHinweise[i].tickedDocuments = documentList;
                    this.editedHinweise[i].tickedDocuments.sort((doc1, doc2) => (doc1.name > doc2.name ? 1 : -1));
                },
                error => {
                    console.log(error);
                }
            );
    }

    public downloadDocument(hin: any) {
        this.hinweiseService.getHinweisDocument(hin.id)
            .subscribe(
                data => {
                    this.downloadFile(data, hin);
                }
            );
    }

    private downloadFile(data: any, hin: any) {
        const element = document.createElement('a');
        element.href = URL.createObjectURL(data.file);
        element.download = hin.name;
        document.body.appendChild(element);
        element.click();
    }

    getVersionshinweisDatum(hinweis: Hinweis): string {
        let fillIn = ' - ';
        if (hinweis && hinweis.updated !== null) {
            const dateAsString = moment(hinweis.updated).format(TIMESTAMP_FORMAT);
            fillIn = dateAsString;
        }
        return fillIn;
    }
}

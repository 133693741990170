import {Injectable} from '@angular/core';
import {DocumentModel, Status} from '../document.model';
import {merge, Observable, Subject} from 'rxjs';
import {GeproService} from '../../../../../index/service/gepro.service';
import {VorgangIdService} from './vorgang-id.service';

@Injectable()
export class DocumentStorageService {

    private readonly inserted$: Subject<void>;
    private readonly removed$: Subject<void>;
    private readonly changed$: Observable<void>;

    private documentCache: Array<DocumentModel>;
    private deletableFlag: boolean;

    constructor(private readonly geproService: GeproService,
                private readonly vorgangIdService: VorgangIdService) {
        this.inserted$ = new Subject();
        this.removed$ = new Subject();
        this.changed$ = merge(this.onInserted(), this.onRemoved());
        this.documentCache = [];
        this.deletableFlag = true;
    }

    reset(): void {
        this.documentCache = [];
    }

    setDeletableAfterUploadFlag(deletable: boolean): void {
        this.deletableFlag = deletable;
        this.updateDeletableAfterUploadFlag();
    }

    private updateDeletableAfterUploadFlag(): void {
        this.documents().forEach(document => document.isDeletableAfterUpload = this.deletableFlag);
    }

    setDocuments(documents: Array<DocumentModel>): void {
        this.documentCache = documents;
        this.updateDeletableAfterUploadFlag();
    }

    documents(): Array<DocumentModel> {
        return this.documentCache || [];
    }

    findDocumentByStatus(status: Status): DocumentModel {
        return this.documents().find(upload => upload.status === status);
    }

    addAll(documents: FileList): void {
        this.prepare(documents);
        this.inserted$.next(); // Only trigger, if documents isn't empty?
    }

    private prepare(fileList: FileList): void {
        const vorgangId = this.vorgangIdService.currentId;
        for (let i = 0; i < fileList.length; i++) {
            const file = fileList.item(i);
            this.documentCache.push({
                vorgangId: vorgangId,
                file: file,
                status: 'WAITING',
                isDeletableAfterUpload: this.deletableFlag
            });
        }
    }

    remove(document: DocumentModel): void {
        this.deleteFileInBackend(document);

        const index = this.documentCache.indexOf(document);
        if (index > -1) {
            this.documentCache.splice(index, 1);
        }
        this.removed$.next();
    }

    private deleteFileInBackend(document: DocumentModel): void {
        if (document.uploadedFile) {
            this.geproService.deleteGeproFile(document.uploadedFile);
        }
    }

    /**
     * Reacts to onInsert() and onRemove().
     */
    onChanged(): Observable<void> {
        return this.changed$;
    }

    onInserted(): Observable<void> {
        return this.inserted$.asObservable();
    }

    onRemoved(): Observable<void> {
        return this.removed$.asObservable();
    }
}

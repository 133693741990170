import {ErrorHandler, Injectable, Injector} from '@angular/core';
import {UserMessagesService} from '../../components/common/messages-area/messages-area/service/user-messages.service';
import {AuthenticationService} from '../../components/index/service/authentication-service';
import {Pages} from '../../components/navigation/pages';
import {Router} from '@angular/router';

const UNCAUGHT_RESPONSE_ERROR = 'Uncaught (in promise): HttpErrorResponse: ';
const DEFAULT_MESSAGE = 'Es ist ein Fehler aufgetreten.';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    constructor(private userMessagesService: UserMessagesService,
                private authService: AuthenticationService,
                private injector: Injector) { }

    handleError(error: any) {
        if(error.status == 401){
            let router = this.injector.get(Router);
            if(router.url.toString() !== "/"+Pages.PATH_LOGIN){

                window.location.href = "/"+Pages.PATH_INDEX;
                this.authService.logout();
            }
        }

        console.error(error);
        this.show(error);
    }

    private show(error: any): void {
        const errorMessage = error.message || '';
        if (errorMessage.startsWith(UNCAUGHT_RESPONSE_ERROR)) {
            error = JSON.parse(errorMessage.replace(UNCAUGHT_RESPONSE_ERROR, ''));
        }

        let message: string;
        if (error.name === 'HttpErrorResponse') {
            const msg = error.error.message || DEFAULT_MESSAGE;
            const url = error.url ? `[URL: ${error.url}]` : '';
            message = `${error.status} ${error.statusText}. ${msg} ${url}`;
        } else if (errorMessage.length > 0) {
            message = errorMessage;
        } else {
            message = DEFAULT_MESSAGE;
        }
        this.userMessagesService.addErrorMessageString(message.trim());
    }
}

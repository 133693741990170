import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DialogComponent} from './dialog.component';
import {NxButtonModule} from '@allianz/ngx-ndbx/button';
import {NxCopytextModule} from '@allianz/ngx-ndbx/copytext';
import {NxDropdownModule} from '@allianz/ngx-ndbx/dropdown';
import {NxHeadlineModule} from '@allianz/ngx-ndbx/headline';
import {NxModalModule} from '@allianz/ngx-ndbx/modal';
import {NxInputModule} from '@allianz/ngx-ndbx/input';
import {NxIconModule} from '@allianz/ngx-ndbx/icon';
import {NxFormfieldModule} from '@allianz/ngx-ndbx/formfield';
import {NxLinkModule} from "@allianz/ngx-ndbx/link";
import {NxAccordionModule} from "@allianz/ngx-ndbx/accordion";
import {DmsLinkDialogComponent} from "./dms-link-dialog.component";
import {NdbxDependenciesModule} from "../../../ndbx-dependencies/ndbx-dependencies.module";
import {EncodedTextAreaModule} from '../../common/encoded-text-area/encoded-text-area.module';

@NgModule({
    declarations: [
        DialogComponent,
        DmsLinkDialogComponent,
    ],
    exports: [
        DialogComponent,
        NxModalModule,
        DmsLinkDialogComponent,
    ],
    imports: [
        CommonModule,
        NxModalModule,
        NxHeadlineModule,
        NxCopytextModule,
        NxFormfieldModule,
        NxInputModule,
        NxDropdownModule,
        NxButtonModule,
        NxIconModule,
        NxLinkModule,
        NxAccordionModule,
        NdbxDependenciesModule,
        EncodedTextAreaModule
    ]
})
export class DialogDms {
}

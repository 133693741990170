import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Endpoints} from '../../navigation/endpoints';
import {map} from 'rxjs/operators';

export interface Hinweis {
    id: number;
    bensl: string;
    beschreibung: string;
    updated: Date;
    tickedDocuments: any;
}

export interface MultiPartFileObject{
    hinweis: Hinweis;
    files: FormData;
}

export interface Hinweise {
    hinweis: Hinweis;
}

@Injectable()
export class HinweisService {

    constructor(
        public http: HttpClient
    ) {
    }

    public getAll(): Observable<any> {
        return this.http.get(Endpoints.HINWEISE);
    }

    public getAllAnwendungsinfo(): Observable<any> {
        return this.http.get(Endpoints.HINWEISE_ANWENDUNGSINFO);
    }

    public getHinweisDocuments(hinweisId: number): Observable<any> {
        return this.http.get(Endpoints.HINWEISE_DOCUMENTS+'/'+hinweisId);
    }

    public getHinweisDocumentsVersionsinfo(hinweisId: number): Observable<any> {
        return this.http.get(Endpoints.HINWEISE_DOCUMENTS_VERSIONSINFO+'/'+hinweisId);
    }

    public getHinweisDocument(documentid: number): Observable<any> {
        const endpoint = Endpoints.HINWEISE_DOCUMENT;

        let headers = new HttpHeaders({'Content-Type': 'application/pdf'});
        let params = new HttpParams();
        params = params.append('id', documentid + '');

        return this.http.get(endpoint+'/'+documentid, {observe: 'response', headers: headers, responseType: 'blob'})
            .pipe(map((res) => {
                let data = {
                    file: new Blob([res.body], {type: res.headers.get('Content-Type')}),
                    filename: res.headers.get('File-Name')
                };
                return data;
            }));
    }

    delete(hinweis: Hinweis): Observable<any> {
        return this.http.post(Endpoints.HINWEISE_REMOVE+'/'+hinweis.id,'');
    }

    checkDate(hinweis: Hinweis): Observable<any> {
        return this.http.post(Endpoints.HINWEISE_CHECK_DATE, hinweis);
    }

    removeDocument(docu: any): Observable<any> {
        return this.http.post(Endpoints.HINWEISE_DOCUMENT_REMOVE+'/'+docu.id,'');
    }

    update(hinweis: Hinweis): Observable<any> {
        return this.http.post(Endpoints.HINWEISE_UPDATE, hinweis);
        // return this.http.post(Endpoints.HINWEISE_UPDATE, formData);
    }

    uploadDocument(id : any, file: File): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('id', id);
        formData.append('file', file);
        formData.append('name', file.name);
        return this.http.post(Endpoints.HINWEISE_UPLOAD_DOCUMENT, formData);
    }


    updateTry(hinweis: Hinweis, id : any, file: File): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('id', id);
        formData.append('file', file);
        formData.append('name', file.name);
        var multifile : MultiPartFileObject;
        multifile= {
            hinweis : hinweis,
            files : formData,
        }
        return this.http.post(Endpoints.HINWEISE_UPDATE_MULTIPART, multifile);
    }

    updateTry2(hinweis: Hinweis, id : any, file: File, nu: number): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('id', hinweis.id+'');
        formData.append('updated', hinweis.updated+'');
        formData.append('beschreibung', hinweis.updated+'');
        const formData2: FormData = new FormData();
        formData2.append('id', id);
        formData2.append('file', file);
        formData2.append('name', file.name);
        var multifile = new Array<FormData>();
        multifile.push(formData);
        multifile.push(formData2);
        return this.http.post(Endpoints.HINWEISE_UPDATE_MULTIPART, multifile);
    }

    create(hinweis: Hinweis): Observable<any> {
        return this.http.post(Endpoints.HINWEISE_CREATE, hinweis);
    }

}

import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {BaseComponent} from '../base/base.component';
import {CreateGeproService} from '../../../services/create-gepro.service';
import {GeproRouteRestTO} from '../../index/service/Model/gepro-route-rest.to';
import {Router} from '@angular/router';
import {FileService} from '../../index/service/file.service';
import {NxDialogService, NxModalRef} from '@allianz/ngx-ndbx/modal';

@Component({
    selector: 'app-overview',
    templateUrl: './overview.component.html',
    styleUrls: ['./overview.component.css']
})
export class OverviewComponent extends BaseComponent implements OnInit {

    private geproSent: boolean = false;

    @ViewChild('saveSuccessfulModal') templateRef: TemplateRef<any>;
    saveSuccessfulModal: NxModalRef<any>;

    constructor(public createGeproService: CreateGeproService,
                private router: Router,
                private readonly fileService: FileService,
                public dialogService: NxDialogService) {
        super(createGeproService);
        this.pageName = 'Übersicht';
    }



    ngOnInit(): void {
        super.ngOnInit();
    }

    hasDocumentStepErrors(): boolean {
        return (this.createGeproService.getValidationErrors('Inhalte') &&
            this.createGeproService.getValidationErrors('Inhalte').length > 0);
    }

    hasBrokerStepErrors(): boolean {
        return (this.createGeproService.getValidationErrors('Vermittler') &&
            this.createGeproService.getValidationErrors('Vermittler').length > 0);
    }

    hasTypeStepErrors(): boolean {
        return (this.createGeproService.getValidationErrors('Vorgangsart') &&
            this.createGeproService.getValidationErrors('Vorgangsart').length > 0);
    }

    getRoutes(): Array<GeproRouteRestTO> {
        if (this.gepro.routes) {
            return this.gepro.routes.sort((routeA, routeB) => routeA.value > routeB.value ? 1 : -1);
        }
    }

    isDocumentChecked(id: number): boolean {
        if (this.gepro.tickedDocuments) {
            return !!this.gepro.tickedDocuments.find((doc) => doc.documentId === id);
        }
    }

    public callGeproObservables() {
        this.subscription.add(this.createGeproService.geproObservable().subscribe(data => {
            this.gepro = data;

            if (this.geproSent) {
                this.saveSuccessfulModal = this.dialogService.open(this.templateRef, { ariaLabel: 'Vorgang gespeichert', disableClose: true  });
                this.saveSuccessfulModal.afterClosed().subscribe(result => {
                    if (result === 'navigateToWorklist') {
                        this.navigateToWorklist();
                    }
                    this.saveSuccessfulModal.close();
                });
            }
        }));

        this.subscription.add(this.createGeproService.saveIntentObservable().subscribe(() => {
            this.createGeproService.saveGepro(this.gepro).then(() => {
                this.saveSuccessful.emit(true);
            });
        }));

        this.subscription.add(this.createGeproService.sendIntentObservable().subscribe(() => {
            // this should work when the gepro was modified while the user was on page
            this.gepro.statusType = null;
            this.geproSent = true;
            this.createGeproService.publishGepro(this.gepro);
        }));

        this.subscription.add(this.fileService.getAllBy(this.gepro.id).subscribe(data => {
            this.createGeproService.validateTypeData(this.gepro);
            this.createGeproService.validateBrokerData(this.gepro);
            this.createGeproService.validateDocumentData(this.gepro, data?.content?.length);
        }));
    }

    navigateToType() {
        this.createGeproService.sendNavigationIntend(0);
    }

    navigateToBroker() {
        this.createGeproService.sendNavigationIntend(1);
    }

    navigateToDocuments() {
        this.createGeproService.sendNavigationIntend(2);
    }

    navigateToWorklist() {
        this.router.navigate(['/workList']);
    }

    private getBrokerErrorText(): string {
        return (this.gepro && this.gepro.geproType && (this.gepro.geproType.category === 'NAME_MAKLER')) ?
            'Bitte geben Sie einen Vermittlernamen ein.' :
            'Bitte wählen Sie einen Vermittler oder ein Maklerhaus aus.';
    }

    private getInhalteErrorText(): string {
        return (this.gepro && this.gepro.geproType && this.gepro.geproType.requiredFields.infos) ?
            'Bitte geben Sie eine Nachricht ein.' :
            'Bitte markieren Sie hochgeladene Pflichtinhalte oder laden Sie sie ggf. hoch.';
    }
}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Endpoints} from '../../navigation/endpoints';
import {DefaultHttpService} from '../../../services/default-http.service';
import {Setting} from './Model/Setting';
import {HttpParams} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class SettingsService {

    constructor(public http: DefaultHttpService) {}

    public getSetting(key: string): Observable<Setting> {
        const httpParams = new HttpParams().append('key', key);
        return this.http.get(Endpoints.SETTINGS, {params: httpParams});
    }

    public saveSetting(key: string, value: string) {
        const setting = new Setting();
        setting.key = key;
        setting.value = value;
        return this.http.post(Endpoints.SETTINGS, setting);
    }
}

import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {GeproRestTO} from '../../index/service/Model/GeproRestTO';
import {GeproService} from '../../index/service/gepro.service';
import {StatusTypeRestTO} from '../../index/service/Model/StatusTypeRestTO';
import * as Constants from '../../../config/constants';
import {SelectionModel} from '@angular/cdk/collections';
import {SortDirection, SortEvent} from '@allianz/ngx-ndbx/table';
import {AbstractType} from '../../index/service/Model/AbstractType';
import {WorkListMitarbeiterService} from '../../index/service/work-list-mitarbeiter.service';
import {Mitarbeiter} from '../work-list-employees/work-list-employees.component';
import {VisibilityService} from '../../../services/visibility.service';
import {Pages} from '../../navigation/pages';
import {Router} from '@angular/router';

@Component({
    selector: 'app-mitarbeiter-worklist',
    templateUrl: './mitarbeiter-worklist.component.html',
    styleUrls: ['./mitarbeiter-worklist.component.scss']
})
export class MitarbeiterWorklistComponent implements OnInit, OnChanges {

    @Input()
    public isDialogOpen: boolean;

    @Input()
    public mitarbeiter: Mitarbeiter;

    @Output()
    worklistClosed: EventEmitter<boolean> = new EventEmitter<boolean>();

    private mitarbeiterGepros: GeproRestTO[];
    public selectedGepros = new SelectionModel<GeproRestTO>(true, []);

    constructor(private geproService: GeproService,
                private  workListMitarbeiterService: WorkListMitarbeiterService,
                private visibilityService: VisibilityService,
                private router: Router) {
    }

    ngOnInit() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['mitarbeiter']) {
            let variableChange = changes['mitarbeiter'];
            //do something with variableChange.currentValue
            if (this.mitarbeiter != null) {
                this.geproService.getGeprosForUser(this.mitarbeiter.bensl).subscribe(
                    geproList => {
                        this.mitarbeiterGepros = geproList;
                    }
                );
            }
        }
    }

    closeWorklistDialog() {
        this.isDialogOpen = false;
        this.worklistClosed.emit(true);
        this.selectedGepros.clear();
    }

    public isStatusTypeUnrouted(statusType: StatusTypeRestTO): boolean {
        return statusType.id == Constants.statusType_nichtGeroutet;
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selectedGepros.selected.length;
        const numRows = this.mitarbeiterGepros.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    toggleAll() {
        this.isAllSelected() ?
            this.selectedGepros.clear() :
            this.mitarbeiterGepros.forEach(row => this.selectedGepros.select(row));
    }

    public deauthoriseSelectedGepros() {
        for (let geproRestTO of this.selectedGepros.selected) {
            this.geproService.deauthoriseSelectedGepros(geproRestTO.id).subscribe(
                geproList => {
                    this.geproService.getGeprosForUser(this.mitarbeiter.bensl).subscribe(
                        geproList => {
                            this.mitarbeiterGepros = geproList;
                        }
                    );
                    this.workListMitarbeiterService.updateMitarbeiterList();
                }
            );
        }
        this.selectedGepros.clear();
    }

    /**
     * Sorts the table data by a certain category.
     */
    public sortTable(sort: SortEvent) {
        this.mitarbeiterGepros = this.mitarbeiterGepros.sort((a, b) => {
            if (sort.active in a && sort.active in b) {
                return this.compare(a, b, sort.active, sort.direction);
            }
            return 0;
        });
    }

    private compare(
        obj1: any,
        obj2: any,
        sortColumnName: string,
        direction: SortDirection) {

        let comparedValueA;
        let comparedValueB;
        let field1 = obj1[sortColumnName];
        let field2 = obj2[sortColumnName];

        if (!field1) {
            field1 = '';
        }
        if (!field2) {
            field2 = '';
        }

        if (StatusTypeRestTO.isStatusType(field1)) {
            comparedValueA = (<StatusTypeRestTO> field1).displayName;
            comparedValueB = (<StatusTypeRestTO> field2).displayName;
        } else if (sortColumnName == 'maklerName') {
            comparedValueA = (<GeproRestTO> <unknown> obj1).maklerName ? (<GeproRestTO> <unknown> obj1).maklerName : (<GeproRestTO> <unknown> obj1).strukturName;
            comparedValueB = (<GeproRestTO> <unknown> obj2).maklerName ? (<GeproRestTO> <unknown> obj2).maklerName : (<GeproRestTO> <unknown> obj2).strukturName;
        } else if (field1.hasOwnProperty('name')) {
            comparedValueA = (<AbstractType> field1).name;
            comparedValueB = (<AbstractType> field2).name;
        } else {
            comparedValueA = field1;
            comparedValueB = field2;
        }

        if (isNaN(comparedValueA) && isNaN(comparedValueB)) {
            comparedValueA = comparedValueA.toLowerCase();
            comparedValueB = comparedValueB.toLowerCase();
        }

        return (comparedValueA < comparedValueB ? -1 : 1) * (direction === 'asc' ? 1 : -1);
    }

    public isDeauthoriseButtonDisabled() {
        if (this.selectedGepros.isEmpty()) {
            return true;
        }
        let isDisabled = false;
        this.selectedGepros.selected.forEach(selectedGepro => {
            if (!this.visibilityService.isVisible('revoke', selectedGepro) ||
                !this.visibilityService.isEnabled('revoke', selectedGepro)) {
                isDisabled = true;
            }
        });
        return isDisabled;
    }

    goToGepro(item: GeproRestTO) {
        let commands: Array<any>;
        commands = ['/' + Pages.PATH_GEPRO_DETAILS, item.id];
        this.router.navigate(commands);
    }

    getBadgeClass(geproType: StatusTypeRestTO): string {
        if (geproType.displayName === 'Freigegeben') {
            return 'freigegeben';
        } else if (geproType.displayName === 'Zur Freigabe') {
            return 'zurFreigabe';
        } else if (geproType.displayName === 'Nachbearbeitung') {
            return 'nachbearbeitung';
        } else {
            return null;
        }
    }
}

import {Injectable} from '@angular/core';
import {UserinfoService} from './userinfo.service';
import {AuthenticationService} from './authentication-service';
import {UserInfo} from './Model/UserInfo';

@Injectable({
    providedIn: 'root'
})
export class InitialUserDataLoadingService {

    constructor(
        private userinfoService: UserinfoService,
        private authenticationService: AuthenticationService
    ) {
    }

    tryToLoadUserDataOnAppInit(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
            this.userinfoService.getUserInformation().toPromise().then(userdata => {
                this.userinfoService.setCurrentUserData(userdata as UserInfo);
                this.authenticationService.autologinDetected();
                console.log('userdata successfully resolved');
                resolve();
            }).catch(reason => {
                console.log('userdata could not be resolved', reason);
                resolve();
            });
        });
    }
}

<div class="wrapper" style="text-align: center">
    <br>
    <app-restricted-text-area #comment
                              [label]="requiresInfos() ? 'Ihre Nachricht (Pflichteingabe)' : 'Ihre Nachricht (optional)'"
                              [maxLength]="4000"
                              [minLines]="5"
                              (ngModelChange)="commentChanged(comment.isInvalid(), $event)"
                              [(ngModel)]="geproInfosModel"></app-restricted-text-area>
    <nx-message *ngIf="errorMessageInhalt.length > 0"
                id="errorMsgInhalt"
                nxContext="error"
                showIcon="true">
        <strong>Fehler:</strong> {{ errorMessageInhalt }}
    </nx-message>
    <br>
    <h1 class="title">Dokumente hochladen</h1>
    <h3 class="subtitle nx-margin-top-m">{{ subtitleDocuments() }}</h3>
    <div class="subtitle">{{ subtitle() }}</div>
    <div class="h3-spacer"></div>
    <app-document-upload #documentUpload
                         appFocus="button"
                         (onChanges)="performValidation()"
                         [initWithVorgangId]="gepro?.id"
                         [customErrorMessage]="errorMessage"></app-document-upload>
    <div #form [formGroup]="documentsForm" class="nx-margin-top-m">
        <span *ngIf="gepro?.geproType?.requiredDocuments?.length > 0">
            <!--h4 nxHeadline="subsection-xsmall"><b>Checkliste Pflichtdokumente</b></h4-->
            <nx-checkbox-group name="uploadChecks" formControlName="uploadChecks">
                 <table nxTable class="nx-margin-bottom-m">
                    <thead>
                        <tr nxTableRow>
                            <th nxHeaderCell class="cell-doc-header"><br>Checkliste Pflichtdokumente</th>
                            <th nxHeaderCell class="last-update-info">Letzte Template-<br>Aktualisierung</th>
                            <th nxHeaderCell class="cell-downloadBt">Download<br> Template</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr nxTableRow *ngFor="let document of validDocuments(gepro.geproType.requiredDocuments)">
                            <td nxTableCell class="cell-doc">
                                <nx-checkbox (checkedChange)="checkboxChange(document.id, $event)"
                                             [checked]="isChecked(document.id)">{{document.name}}
                                    <button *ngIf="showAmvbAddressIcon(document)"
                                            class="nx-margin-0" nxIconButton="tertiary small-medium"
                                            (click)="showAmvbAdressModal()" type="button">
                                        <nx-icon class="nx-margin-right-2xs" name="mail-o"></nx-icon>
                                     </button>
                                </nx-checkbox>
                            </td>
                            <td nxTableCell  class="last-update-info">
                                <div *ngIf="isFormular(document)">
                                        <span>Stand: </span>
                                        <app-timestamp class="nx-margin-right-xs" [timestamp]="document?.lastUpdated"></app-timestamp>
                                </div>
                            </td>
                            <td nxTableCell class="cell-downloadBt">
                                <div *ngIf="isFormular(document)" >
                                        <app-document-download-button
                                            [disabled]="isDownloadDisabled(document)"
                                            [documentId]="document.id"></app-document-download-button>
                                </div>
                                <div *ngIf="isLink(document)">
                                        &nbsp; <app-document-link-button
                                        [disabled]="isDocumentLinkDisabled(document)"
                                        [documentLink]="document.fileName"></app-document-link-button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </nx-checkbox-group>
        </span>
        <span *ngIf="gepro.geproType.optionalDocuments?.length > 0">
            <nx-checkbox-group formControlName="uploadChecksOptional" name="uploadChecksOptional">
                <table nxTable>
                    <thead>
                        <tr nxTableRow>
                            <th nxHeaderCell class="cell-doc-header"><br>Optionale Dokumente</th>
                            <th nxHeaderCell class="last-update-info">Letzte Template-<br>Aktualisierung</th>
                            <th nxHeaderCell class="cell-downloadBt">Download<br> Template</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr nxTableRow *ngFor="let document of validDocuments(gepro.geproType.optionalDocuments)">
                            <td nxTableCell class="cell-doc">
                                <nx-checkbox (checkedChange)="checkboxChange(document.id, $event)"
                                             [checked]="isChecked(document.id)">{{document.name}}
                                    <button *ngIf="showAmvbAddressIcon(document)"
                                            class="nx-margin-0" nxIconButton="tertiary small-medium"
                                            (click)="showAmvbAdressModal()" type="button">
                                        <nx-icon class="nx-margin-right-2xs" name="mail-o"></nx-icon>
                                     </button>
                                </nx-checkbox>
                            </td>
                            <td nxTableCell class="last-update-info">
                                <div *ngIf="isFormular(document)">
                                    <span>Stand: </span>
                                    <app-timestamp class="nx-margin-right-xs"
                                                   [timestamp]="document?.lastUpdated"></app-timestamp>
                                </div>
                            </td>
                            <td nxTableCell class="cell-downloadBt">
                                <div *ngIf="isFormular(document)">
                                    <app-document-download-button [disabled]="isDownloadDisabled(document)"
                                                                  [documentId]="document.id"></app-document-download-button>
                                </div>
                                <div *ngIf="isLink(document)">
                                    &nbsp; <app-document-link-button [disabled]="isDocumentLinkDisabled(document)"
                                                                     [documentLink]="document.fileName"></app-document-link-button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </nx-checkbox-group>
        </span>
    </div>
</div>


<ng-template #amvbAdressModal>
    <div class="u-text-center">
        <h3 nxHeadline="subsection-large" class="nx-font-weight-light">
            <strong>Informationen zum Postversand</strong>
        </h3>
        <p nxCopytext="normal">
            <br><b>Persönlich / Vertraulich</b>
            <br>Allianz Versicherungs-AG
            <br><b>S-MVSt-VR - Bürgschaftserklärung</b>
            <br>Postfach 44 05 45
            <br>12005 Berlin
            <br><br>
            <button nxModalClose="Close" class="nx-margin-bottom-xs" nxButton="primary medium" type="button" cdkFocusInitial>Schliessen</button>
        </p>
    </div>
</ng-template>

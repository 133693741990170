import {AfterViewInit, Component, OnDestroy, OnInit, Output} from '@angular/core';
import {UserinfoService} from '../../index/service/userinfo.service';
import {WorkListService} from '../../../services/work-list.service';
import {StatesService} from '../../../services/states.service';
import {Subject, Subscription} from 'rxjs';
import {GeproFilterRequest} from '../gepro-filter/entity/gepro-filter-request';
import {VorgangTabService} from '../../index/service/vorgang-tab.service';
import {FilterRequestBuilderService} from '../../../services/filter-request-builder.service';
import {GeproRestTO} from '../../index/service/Model/GeproRestTO';
import {Filter} from '../gepro-filter/gepro-filter.component';
import isEqual from 'lodash/isEqual';
import {GeproService} from "../../index/service/gepro.service";

@Component({
    selector: 'app-work-list-tabs',
    templateUrl: './work-list-tabs.component.html',
    styleUrls: ['./work-list-tabs.component.css']
})
export class WorkListTabsComponent implements OnInit, OnDestroy, AfterViewInit {

    _tabValue: number;
    activeTabs: Map<number, TabItem> = new Map<number, TabItem>();
    private tabIndices: Array<number>;

    private subscription: Subscription = new Subscription();

    private tabStatistics: Map<number, number> = new Map();

    private lastEmittedFilters: GeproFilterRequest = null;

    private lastQueriedStatisticsForTab = new Array<number>();

    @Output()
    setFilterRequested: Subject<GeproFilterRequest>;

    constructor(public userinfoService: UserinfoService,
                public workListService: WorkListService,
                public geproService: GeproService,
                private statesService: StatesService,
                private requestBuilderService: FilterRequestBuilderService,
                private vorgangTabService: VorgangTabService) {
        this.setFilterRequested = new Subject();
    }

    ngOnInit() {
        this.createTabs();
        this._tabValue = 0;
        this.subscription.add(this.vorgangTabService.tabChangedSubject.asObservable()
            .subscribe(_ => this.refreshAllStatisticsAndContentForCurrentTab(true)));
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    ngAfterViewInit(): void {
        this.subscription.add(this.statesService.getStates().subscribe(states => {
            if (states) {
                const tabsIndices = new Array<number>();
                this.activeTabs.forEach((value, key) => tabsIndices.push(key));
                // stateService can give us old values, we must proceed the new ones
                this.queryStatisticsForTabs(tabsIndices, true);
                if(sessionStorage.getItem("_tabValuesessionStorage")==null) {
                    this.activeTabs.get(this._tabValue).execFunc();
                }
                else{
                    this._tabValue=JSON.parse(sessionStorage.getItem("_tabValuesessionStorage"));
                    this.activeTabs.get(this._tabValue).execFunc();
                }
                this.isWorkingOnRLTab();
            }
        }));
    }

    set tabValue(val: string) {
        if (this._tabValue !== +val) {
            this.resetFilterSessionStorage();
        }
        this._tabValue = +val;
        this.userinfoService.setTabValue(this._tabValue.toString());
        sessionStorage.setItem("_tabValuesessionStorage", JSON.stringify(this._tabValue));
        this.isWorkingOnRLTab();
        this.refreshStatisticsAndContentForCurrentTab();
    }

    get tabValue() {
        return this._tabValue + '';
    }

    private refreshStatisticsAndContentForCurrentTab(force?: boolean) {
        this.queryStatisticsForTabs([this._tabValue], true);
        this.activeTabs.get(this._tabValue).execFunc(force);
    }

    private refreshAllStatisticsAndContentForCurrentTab(force?: boolean) {
        this.queryStatisticsForTabs(this.tabIndices, true);
        this.activeTabs.get(this._tabValue).execFunc(force);
    }

    createTabs() {
        let counter = -1;
        this.activeTabs.clear();


        if (this.userinfoService.isRoleReferatsleiter()) {
            this.activeTabs.set(++counter, {
                label: 'Zur Zuteilung',
                count: this.getCountFor.bind(this, counter),
                execFunc: this.showFor.bind(this, counter),
                queryFunc: this.getQueryToAssign.bind(this),
                assignerTab: true
            });
        }
        if (this.userinfoService.isRoleReferatsleiter()) {
            this.activeTabs.set(++counter, {
                label: 'Zur Freigabe',
                count: this.getCountFor.bind(this, counter),
                execFunc: this.showFor.bind(this, counter),
                queryFunc: this.getQueryToFreigabe.bind(this),
                assignerTab: true
            });
        }
        if (this.userinfoService.isRoleReferatsleiter()) {
            this.activeTabs.set(++counter, {
                label: 'Referatssicht',
                count: this.getCountFor.bind(this, counter),
                execFunc: this.showFor.bind(this, counter),
                queryFunc: this.getQueryReferat.bind(this),
                assignerTab: true
            });
        }

        if (this.userinfoService.isRoleReferatsleiter()||this.userinfoService.isAMVB()) {
            this.activeTabs.set(15, {
                label: 'LSZ zum Routen',
                count: this.getCountFor.bind(this, 15),
                execFunc: this.showFor.bind(this, 15),
                queryFunc: this.getUndefinedLszGepros.bind(this),
                assignerTab: true
            });
        }

        if (this.userinfoService.isAMVB()) {
            this.activeTabs.set(++counter, {
                label: 'Offen',
                count: this.getCountFor.bind(this, counter),
                execFunc: this.showFor.bind(this, counter),
                queryFunc: this.getQueryToDo.bind(this)
            });
            this.activeTabs.set(++counter, {
                label: 'In Bearbeitung',
                count: this.getCountFor.bind(this, counter),
                execFunc: this.showFor.bind(this, counter),
                queryFunc: this.getQueryProgressAmvb.bind(this)
            });
            this.activeTabs.set(++counter, {
                label: 'Geschlossen',
                count: this.getCountFor.bind(this, counter),
                execFunc: this.showFor.bind(this, counter),
                queryFunc: this.getQueryClosedAmvb.bind(this)
            });
            this.activeTabs.set(++counter, {
                label: 'Alle',
                count: this.getCountFor.bind(this, counter),
                execFunc: this.showFor.bind(this, counter),
                queryFunc: this.getQueryAllMineAsEditor.bind(this)
            });
            this.activeTabs.set(++counter, {
                label: 'Von mir erstellt',
                count: this.getCountFor.bind(this, counter),
                execFunc: this.showFor.bind(this, counter),
                queryFunc: this.getQueryCreatedByMe.bind(this)
            });
        } else if (this.userinfoService.isMaklerOrMaklerbetreuer()) {
            this.activeTabs.set(++counter, {
                label: 'Meine ToDos',
                count: this.getCountFor.bind(this, counter),
                execFunc: this.showFor.bind(this, counter),
                queryFunc: this.getQueryProgressM_MB.bind(this)
            });
            this.activeTabs.set(++counter, {
                label: 'Versendet',
                count: this.getCountFor.bind(this, counter),
                execFunc: this.showFor.bind(this, counter),
                queryFunc: this.getQuerySent.bind(this)
            });
            this.activeTabs.set(++counter, {
                label: 'Geschlossen',
                count: this.getCountFor.bind(this, counter),
                execFunc: this.showFor.bind(this, counter),
                queryFunc: this.getQueryClosedM_MB.bind(this)
            });
        }
        //
        // if (this.userinfoService.isAMVB()||this.userinfoService.isRoleReferatsleiter()) {
        //
        // }

        this.tabIndices = new Array<number>();
        for (let i = 0; i <= counter; i++) {
            this.tabIndices.push(i);
        }
    }

    private showFor(tabIndex: number, force?: boolean): void {
        const geproFilterRequest: GeproFilterRequest = this.activeTabs.get(tabIndex).queryFunc();
        this.emitFilterRequestIfFilterChanged(geproFilterRequest, force);
    }

    private getCountFor(tabIndex: number): number {
        if (this.tabStatistics.get(tabIndex)) {
            return this.tabStatistics.get(tabIndex);
        }

        return 0;
    }

    private getQueryClosedAmvb(): GeproFilterRequest {
        const states = this.statesService.getStatesForStatusClosed();

        return new GeproFilterRequest(GeproFilterRequest.FILTER_ASSIGNEE, states);
    }

    private getQueryClosedM_MB(): GeproFilterRequest {
        const states = this.statesService.getStatesForStatusClosed();

        return new GeproFilterRequest(GeproFilterRequest.FILTER_CREATOR, states);
    }

    private getQueryProgressAmvb(): GeproFilterRequest {
        const states = this.statesService.getStatesForStatusInProgress();

        return new GeproFilterRequest(GeproFilterRequest.FILTER_ASSIGNEE, states);
    }

    private getQueryProgressM_MB(): GeproFilterRequest {
        const states = this.statesService.getStatesForStatusInProgress();

        return new GeproFilterRequest(GeproFilterRequest.FILTER_CREATOR, states);
    }

    private getQueryToAssign(): GeproFilterRequest {
        const states = this.statesService.getStatesForStatusToAssign();

        return new GeproFilterRequest(GeproFilterRequest.FILTER_NOT_ASSIGNED, states);
    }

    private getQueryToFreigabe(): GeproFilterRequest {
        const states = this.statesService.getStatesFreigabeToRelease();

        return new GeproFilterRequest(GeproFilterRequest.FILTER_FREIGABE, states);
    }

    private getQueryToDo(): GeproFilterRequest {
        const states = this.statesService.getStatesForStatusToDo();

        return new GeproFilterRequest(GeproFilterRequest.FILTER_ASSIGNEE, states);
    }

    private getQuerySent(): GeproFilterRequest {
        const states = this.statesService.getStatesForStatusSent();

        return new GeproFilterRequest(GeproFilterRequest.FILTER_CREATOR, states);
    }

    private getQueryCreatedByMe(): GeproFilterRequest {
        const states = this.statesService.getAllVisibleStatesAsCreator(true);

        return new GeproFilterRequest(GeproFilterRequest.FILTER_CREATOR, states);
    }

    private getQueryAllMineAsEditor(): GeproFilterRequest {
        const states = this.statesService.getAllVisibleStatesAsEditor(true);

        return new GeproFilterRequest(GeproFilterRequest.FILTER_ASSIGNEE, states);
    }

    private getQueryReferat(): GeproFilterRequest {
        const states = this.statesService.getAllVisibleStatesAsAssigner(true);

        return new GeproFilterRequest(GeproFilterRequest.FILTER_NOT_ASSIGNED, states);
    }

    private getUndefinedLszGepros(): GeproFilterRequest {
        const states = this.statesService.getAllUndefinedLszGeprosAsAssigner(true);
        return new GeproFilterRequest(GeproFilterRequest.FILTER_NOT_ASSIGNED, states);
    }

    // setter or click events are emitted twice, so we make sure we emit changed filters only once
    private emitFilterRequestIfFilterChanged(filterRequest: GeproFilterRequest, force?: boolean): void {
        if (force || !GeproFilterRequest.equals(this.lastEmittedFilters, filterRequest)) {
            this.lastEmittedFilters = filterRequest;
            this.setFilterRequested.next(filterRequest);
        }
    }

    private queryStatisticsForTabs(tabsIndices: number[], force: boolean): void {
        if (force || !isEqual(this.lastQueriedStatisticsForTab, tabsIndices)) {
            this.lastQueriedStatisticsForTab = tabsIndices;
            const requestMap: Map<string, string> = new Map();

            tabsIndices.forEach(index => {
                const tab = this.activeTabs.get(index);
                if(this.activeTabs.get(index).label==='LSZ zum Routen'){
                    requestMap.set(index + '', this.getQueryFromFilterRequest(tab.queryFunc(), true));
                }
                else {
                    requestMap.set(index + '', this.getQueryFromFilterRequest(tab.queryFunc(), false));
                }
            });
            this.subscription.add(this.vorgangTabService.queryTabStatistics(requestMap).subscribe(result => {
                requestMap.forEach((value, key) => this.tabStatistics.set(+key, result[key]));
            }));
        }
    }

    private getQueryFromFilterRequest(req: GeproFilterRequest, isLszUnroutbar: boolean): string {
        const filters = new Array<Filter>();
        this.requestBuilderService.applyDefaultFilter(req.getRelationshipFilter(), filters, isLszUnroutbar);
        this.requestBuilderService.pushMultiple(GeproRestTO.FIELD_STATUS_TYPE, req.getStatusTypeFilters(), filters);

        return filters.map(filter => filter.key + ':' + filter.value).join(' AND ');
    }

    getAssignerTabsForAssigner(): Map<number, TabItem> {
        const tabs: Map<number, TabItem> = new Map();
        this.activeTabs.forEach(((value, key) => {
            if (value.assignerTab) {
                tabs.set(key, value);
            }
        }));

        return tabs;
    }

    getCountOfAssignerTabsForAssigner(): number {
        let count = 0;
        this.activeTabs.forEach(((value, key) => {
            if (value.assignerTab) {
                count++;
            }
        }));

        return count;
    }

    getEditorTabsForAssigner(): Map<number, TabItem> {
        const tabs: Map<number, TabItem> = new Map();
        this.activeTabs.forEach(((value, key) => {
            if (!value.assignerTab) {
                tabs.set(key, value);
            }
        }));

        return tabs;
    }

    private resetFilterSessionStorage() {
        sessionStorage.removeItem('filterStatusTypesessionStorage');
        sessionStorage.removeItem('filterGeproTypesessionStorage');
        sessionStorage.removeItem('filterMsn6sessionStorage');
        sessionStorage.removeItem('filter_createdMinDatesessionStorage');
        sessionStorage.removeItem('filter_createdMaxDatesessionStorage');
        sessionStorage.removeItem('filter_modifiedMinDatesessionStorage');
        sessionStorage.removeItem('filter_modifiedMaxDatesessionStorage');
        sessionStorage.removeItem('filterBearbeiterNamesessionStorage');
    }

    public refreshTabsAfterStvChange() {
        sessionStorage.removeItem('_tabValuesessionStorage');
        this.resetFilterSessionStorage();
        this.createTabs();
        this._tabValue = 0;
        sessionStorage.setItem("_tabValuesessionStorage", JSON.stringify(this._tabValue));
        this.isWorkingOnRLTab();
        this.userinfoService.getNotes();
        setTimeout(() => {
            this.refreshAllStatisticsAndContentForCurrentTab(true);
        }, 0);
    }

    public isWorkingOnRLTab() {
        if (this._tabValue === 0 || this._tabValue === 1 || this._tabValue === 2) {
            if ((this.userinfoService.getCurrentUserData().referatAsLeiter !== null &&
                    this.userinfoService.getCurrentStvUser() === null) ||
                (this.userinfoService.getCurrentUserData().referatAsLeiter !== null &&
                    this.userinfoService.getCurrentStvUser() != null &&
                    this.userinfoService.getCurrentStvUser().referatAsLeiter !== null) ) {
                this.userinfoService.setIsWorkingOnRLTab(true);
                this.userinfoService.setIsWorkingOnNewVorgang(false);
            } else {
                this.userinfoService.setIsWorkingOnRLTab(false);
                this.userinfoService.setIsWorkingOnNewVorgang(false);
            }
        }
        else {
            this.userinfoService.setIsWorkingOnRLTab(false);
            this.userinfoService.setIsWorkingOnNewVorgang(false);
        }
    }

}

export interface TabItem {
    label: string;
    count: Function;
    execFunc: Function;
    queryFunc: Function;
    assignerTab?: boolean;
}

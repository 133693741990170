import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree} from '@angular/router';
import {Observable} from 'rxjs';
import {UserinfoService} from '../components/index/service/userinfo.service';
import {LoginService} from '../components/index/service/login.service';

@Injectable({
    providedIn: 'root'
})
export class LoginActivationGuard implements CanActivate {

    constructor(private userinfoService: UserinfoService,
                private loginService: LoginService) { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (this.userinfoService.getCurrentUserData()) {
            this.loginService.forwardToDefaultPage();
            return false;
        }

        return true;
    }

}

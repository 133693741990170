<ng-template #uploadButton>
    <button id="openModalBtn" nxButton="tertiary small" type="button" *appVisibility="'upload'" appDisable="upload"
            class="nx-margin-bottom-0"
            (click)="onOpenUploadModal()">
        <nx-icon name="arrow-upload" class="nx-margin-right-2xs"></nx-icon>Dokumente hinzufügen
    </button>
</ng-template>
<app-labeled-container label="Dokumente" [buttonTemplate]="uploadButton">
    <app-document-list #documentList
                       [documents]="(documents() | async)"
                       (documentDownload)="onDownload($event)"></app-document-list>
    <div *ngIf="documentList.documentsPresent()" class="nx-margin-top-s">
        <app-confirmed-documents [geproType]="geproType"
                                 [confirmedDocumentIds]="documentIds()"></app-confirmed-documents>
    </div>
</app-labeled-container>
<nx-modal *ngIf="openModal" id="uploadModal" [nxHideOnClickOutside]="false"
          [nxShowCloseIcon]="false" (nxClose)="onCloseUploadModal()">
    <div class="nx-margin-m" fxLayout="column" fxLayoutAlign="start center">
        <nx-icon name="product-pages-documents" outline="true" size="m" class="nx-margin-bottom-s"></nx-icon>
        <h3 nxHeadline="subsection-medium">Fügen Sie weitere Dokumente hinzu</h3>
        <h5 class="upload-restriction nx-margin-bottom-s">{{ uploadRestrictions() }}</h5>
        <app-document-upload #uploadComponent class="full-width" buttonText="Dokumente auswählen"></app-document-upload>
        <nx-message *ngIf="showCloseWarning" id="warningMsg" showIcon="true" nxContext="warning">
            Eine Datei wird momentan hochgeladen. Das Fenster kann erst nach Abschluss dieser Operation geschlossen werden.
        </nx-message>
        <div fxLayout="row" fxLayoutAlign="space-evenly center" class="full-width nx-margin-top-m">
            <button id="closeBtn"
                    nxButton="secondary medium"
                    type="button"
                    class="nx-margin-bottom-0"
                    (click)="onCloseUploadModal()">{{ hasUploadedDocuments() ? 'Schließen' : 'Abbrechen' }}</button>
            <button id="startBtn"
                    nxButton="primary medium"
                    type="button"
                    class="nx-margin-bottom-0"
                    nxTooltip="Ausgewählte Dokumente hochladen"
                    [disabled]="!uploadComponent.hasQueuedDocuments() || uploadComponent.hasError() || isRunning()"
                    (click)="startUpload()">Hochladen</button>
        </div>
    </div>
</nx-modal>

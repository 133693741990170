import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NxFormfieldModule} from '@allianz/ngx-ndbx/formfield';
import {NxInputModule} from '@allianz/ngx-ndbx/input';
import {NxErrorModule} from '@allianz/ngx-ndbx/base';
import {EncodedTextAreaComponent} from './encoded-text-area.component';
import {TextFieldModule} from '@angular/cdk/text-field';

@NgModule({
    declarations: [
        EncodedTextAreaComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NxFormfieldModule,
        NxInputModule,
        NxErrorModule,
        TextFieldModule,
    ],
    exports: [
        FormsModule,
        EncodedTextAreaComponent
    ]
})
export class EncodedTextAreaModule {
}

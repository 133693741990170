import {Injectable} from '@angular/core';
import {DefaultHttpService} from './default-http.service';
import {Observable} from 'rxjs';
import {Endpoints} from '../components/navigation/endpoints';

export interface User {
    bensl: string;
    fullName?: string;
    referatId?: number;
}

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(private readonly httpService: DefaultHttpService) {}

    findBy(bensl: string): Observable<User> {
        const url = `${Endpoints.USER}?bensl=${bensl}`;
        return this.httpService.get(url);
    }
}

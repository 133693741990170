<!--nx-multi-stepper currentStepLabel="Step" [selectedIndex]="currentStep?.index" [linear]="nextDisabled" appPearlError
                  (selectionChange)="stepperChange($event)" role="tab">
    <ng-container *ngFor="let step of multiSteps">
        <nx-step [label]="step.name" [completed]="step.completed">
            <div class="nx-margin-top-s">
            </div>
        </nx-step>

    </ng-container>
</nx-multi-stepper-->


<mat-horizontal-stepper labelPosition="bottom" #stepper (selectionChange)="stepperChange($event)" [linear]="nextDisabled"
                        [ngClass]="{'classGrey': nextDisabled, 'classBlue': !nextDisabled}">

    <ng-template matStepperIcon="edit">
        <mat-icon>check</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="done">
        <mat-icon>check</mat-icon>
    </ng-template>
    <ng-template matStepperIcon="number">
        <mat-icon>edit</mat-icon>
    </ng-template>

    <mat-step *ngFor="let step of multiSteps" [label]="step.name" [completed]="step.completed"
              #step [hasError]="step.validationErrors.length >0" [errorMessage]="step.errorMsgStepper">
    </mat-step>
</mat-horizontal-stepper>



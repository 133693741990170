import {Injectable, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Endpoints} from '../../navigation/endpoints';
import {DefaultHttpService} from '../../../services/default-http.service';
import {UserInfo} from './Model/UserInfo';
import {GeproService} from "./gepro.service";
import {AuthenticationService} from "./authentication-service";

@Injectable({
    providedIn: 'root'
})
export class UserinfoService implements OnInit {

    username: string;

    private currentUser: UserInfo = null;
    private currentUserAsStv: UserInfo = null;
    private isWorkingOnRLTab: boolean = false;
    private isWorkingOnNewVorgang: boolean = false;
    private tabvalue : any;

    public static readonly role_admin: string = 'amvb-admin';
    public static readonly role_redakteur: string = 'redakteur';
    public static readonly role_referatsLeiter: string = 'amvb-rl';
    public static readonly role_maklerBetreuer: string = 'mb';
    public static readonly role_makler: string = 'm';
    public static readonly role_amvbMitarbeiter: string = 'amvb-ma';
    public static readonly mandant_common: string = 'common';
    public static readonly mandant_sachLeben: string = 'SL';
    public static readonly mandant_kranken: string = 'K';
    public notification : any[] = [];
    public isDialogOpen : boolean = false;
    constructor(
        public http: DefaultHttpService,

    ) {
        this.ngOnInit();
    }

    async ngOnInit() {

    }

    public getMappedUserRoles(): string[] {
        let roles = this.getCurrentUserData().roles;
        let roleMap = new Map();
        roleMap.set("amvb-admin", "Admin");
        roleMap.set("amvb-ma", "MA");
        roleMap.set("amvb-rl", "RL");
        let mappedRoles : string[];
        mappedRoles = [];

        roles.forEach(function (value) {
            if (roleMap.get(value) != null) {
                mappedRoles.push(roleMap.get(value));
            } else {
                mappedRoles.push(value);
            }
        });

        return mappedRoles;

    }

    public getUserInformation(): Observable<object> {
        const endpoint = Endpoints.USER_INFO;
        return this.http.get(endpoint);
    }

    public setUserInformationForReleaseNote() {
        const endpoint = Endpoints.USER_INFO;
        this.http.post(endpoint, '').subscribe(user => {
            this.setCurrentUserData(user);
        }, error1 => {
            console.log('ReleaseNote-Timestamp nicht aktualisert - Fehler: ' + error1.error);
        });
    }

    getAllNotifications(benslToAuthorise: string): Observable<any> {
        return this.http.post(Endpoints.NOTIFICATION, { bensl: benslToAuthorise });
    }

    getNotes(){
        var user = this.currentUserAsStv != null && this.currentUserAsStv.bensl != null ? this.currentUserAsStv.bensl : this.currentUser!=null?this.currentUser.bensl:null;
        console.log('Notification für User: ' + user + ' current userstv ist: ' +this.currentUserAsStv + ' current benutzer ist: ' + this.currentUser );
        if(user!=null) {
            this.getAllNotifications(user).subscribe(
                res => {
                    if (res != null) {
                        this.notification = [];
                        for (var i = 0; i < res.length; i++) {
                            if (res[i].gelesen == 'false') {
                                this.notification.push(res[i]);
                            }
                        }
                    }
                });
        }
    }

    public getNotificationRead() : string{
        return this.notification.length.toString();
    }

    public setCurrentUserData(data: UserInfo) {
        this.currentUser = data;
    }

    public setTabValue(data : string){
        this.tabvalue = data;
    }

    public getTabValue(){
        return this.tabvalue;
    }

    public removeCurrentUserData() {
        this.currentUser = null;
    }

    public getCurrentUserData(): UserInfo {
        return this.currentUser;
    }

    isNewReleaseNotes(): boolean {
        return this.currentUser?.newReleaseNotes;
    }

    isRoleReferatsleiter(): boolean {
        return this.hasAllowedRole([UserinfoService.role_referatsLeiter]);
    }

    isAMVB(): boolean {
        return this.hasAllowedRole([UserinfoService.role_amvbMitarbeiter, UserinfoService.role_referatsLeiter]);
    }

    isMaklerOrMaklerbetreuer(): boolean {
        return this.hasAllowedRole([UserinfoService.role_makler, UserinfoService.role_maklerBetreuer]);
    }

    isNotMakler(): boolean {
        return this.hasAllowedRole([ UserinfoService.role_maklerBetreuer, UserinfoService.role_amvbMitarbeiter,
                UserinfoService.role_referatsLeiter]);
    }

    private hasAllowedRole(allowedRoles: string[]): boolean{

        var roleExisting = false;

        let curUser = this.getUser();

        if(!curUser) {
            return false;
        }

        allowedRoles.forEach(function(role) {
            if(curUser.roles.indexOf(role) != -1){
                roleExisting = true;
            }
        });

        return roleExisting;
    }

    isRoleAmvbMA(): boolean {
        return this.hasAllowedRole([UserinfoService.role_amvbMitarbeiter]);
    }

    isRoleAdministrator(): boolean {
       return this.hasAllowedRole([UserinfoService.role_admin]);
    }

    isRoleMakler(): boolean {
        return this.hasAllowedRole([UserinfoService.role_makler]);
    }

    isRoleMaklerBetreur(): boolean {
        return this.hasAllowedRole([UserinfoService.role_maklerBetreuer]);
    }

    public setCurrentUserStvInfo(loadedStvInfos: UserInfo) {
        this.currentUserAsStv = loadedStvInfos;
    }

    public resetCurrentUserStvInfo() {
        this.currentUserAsStv = null;
    }

    public getCurrentStvUser(): UserInfo {
        return this.currentUserAsStv;
    }

    public isCurrentUserLoggedinUser() {
        return this.currentUserAsStv === null;
    }

    public isCurrentUserStvUser() {
        return this.currentUserAsStv !== null;
    }

    public getUser() {
        return this.currentUserAsStv === null ? this.currentUser : this.currentUserAsStv;
    }

    public getIsWorkingOnRLTab(): boolean {
        return this.isWorkingOnRLTab;
    }

    public setIsWorkingOnRLTab(rlTab: boolean) {
        this.isWorkingOnRLTab = rlTab;
    }

    public getIsWorkingOnNewVorgang(): boolean {
        return this.isWorkingOnNewVorgang;
    }

    public setIsWorkingOnNewVorgang(rlTab: boolean) {
        this.isWorkingOnNewVorgang = rlTab;
    }
}


<div fxLayout="column" fxLayoutAlign="space-between stretch" class="container fix_footor">
    <div ngClass="{{appNotice.indexOf('Wichtige Ankündigung')>-1?'notice-banner_orange':'notice-banner'}}" *ngIf="appNotice">
        <nx-icon name="exclamation-triangle" size="s" style="padding-left: 10px;"></nx-icon>
        <span style="padding-left: 10px;" >{{appNotice}}</span>
    </div>
    <div id="nav" class="header">
        <app-navigation (openHinweiseModal)="hinweisepopup.show()" (openNotificationModal)="notificationpopup.show()"  (closeNotificationModal)="notificationpopup.close()"></app-navigation>
    </div>
    <div id="main" class="grow-item overflow" fxLayout="column" fxLayoutAlign="space-between stretch">
        <user-messages-area></user-messages-area>
        <div class="grow-item">
            <main>
                <section>
                    <router-outlet></router-outlet>
                </section>
            </main>
        </div>
        <footer nx-footer>
            <nx-footer-copyright>
                Allianz &copy; 2021 DIASS
            </nx-footer-copyright>
            <nx-footer-copyright class="footer-version"  *ngIf="backendInformation">
                <div class="mat-caption" style="font-family: 'Allianz Neo', sans-serif;">Frontend version: {{frontendVersion}}</div>
                <div class="mat-caption" style="font-family: 'Allianz Neo', sans-serif;">Backend version: {{backendInformation.appVersion}}</div>
                <div class="mat-caption" style="font-family: 'Allianz Neo', sans-serif;">Backend build timestamp: {{backendBuildDate}}</div>
                <div class="mat-caption" style="font-family: 'Allianz Neo', sans-serif;">Git information: branch: {{backendInformation.gitBranch}}, commit: {{backendInformation.gitCommit}}</div>
            </nx-footer-copyright>
        </footer>
    </div>
</div>
<hinweisepopup #hinweisepopup></hinweisepopup>
<notificationpopup #notificationpopup></notificationpopup>

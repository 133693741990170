import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {GeproCloseModalComponent} from '../../common/modals/gepro-close-modal/gepro-close-modal.component';
import {GeproRestTO} from '../../index/service/Model/GeproRestTO';
import {switchMap, take} from 'rxjs/operators';
import {GeproService} from '../../index/service/gepro.service';
import {EMPTY, Subscription} from 'rxjs';
import {VisibilityService} from '../../../services/visibility.service';

@Component({
    selector: 'app-gepro-close',
    templateUrl: './gepro-close.component.html',
    styleUrls: ['./gepro-close.component.css']
})
export class GeproCloseComponent implements AfterViewInit {

    @Input() geproId;

    @Input() schouldBeDisabled: boolean;

    @Output()
    refreshGepro = new EventEmitter();

    @ViewChild(GeproCloseModalComponent, {read: GeproCloseModalComponent, static: true}) geproCloseModal: GeproCloseModalComponent;

    private rejectSubscription: Subscription;

    constructor(private geproService: GeproService, private visibilityService: VisibilityService) {
    }

    ngAfterViewInit(): void {

        this.rejectSubscription = this.geproCloseModal.modalResult().pipe(take(1)).subscribe(action => {
            if (action === 'Save') {
                this.doCloseGepro(this.geproId);
            }
        });
    }

    ngOnDestroy(): void {
        if (this.rejectSubscription) {
            this.rejectSubscription.unsubscribe();
        }
    }


    isButtonDisabled(): boolean {
        return this.schouldBeDisabled || !this.visibilityService.isEnabled('close');
    }


    private doCloseGepro(geproId: string) {
        this.geproService.closeGepro(geproId).subscribe(
            data => {
                this.geproService.$geproDetailsObservable.next(data);
                this.refreshGepro.emit();
            }
        );
    }


}

import {Injectable} from '@angular/core';
import {DefaultHttpService} from '../../../services/default-http.service';
import {Observable, Subject} from 'rxjs';
import {Endpoints} from '../../navigation/endpoints';

@Injectable({
  providedIn: 'root'
})
export class VorgangTabService {

    private _tabChangedSubject: Subject<any> = new Subject<any>();

    constructor(private http: DefaultHttpService) { }

    get tabChangedSubject(): Subject<any> {
        return this._tabChangedSubject;
    }

    public queryTabStatistics(idToQueryMap: Map<string, string>): Observable<Map<string, number>> {
        const convMap = {};
        idToQueryMap.forEach((val: string, key: string) => {
            convMap[key] = val;
        });

        return this.http.post(Endpoints.GEPROS_TABSTATISTICS, convMap);
    }

    public queryLszForRoute(): Observable<Map<string, number>> {
        return this.http.post(Endpoints.GEPROS_TABSTATISTICS, "{\n" +
            "  \"15\": \"zugeordnetesReferat:-10 AND Relationship:ASSIGNER\"\n" +
            "}");
    }
}

import {Component, Input, Output} from '@angular/core';
import {Observable} from 'rxjs';
import {DocumentService} from './services/document.service';

@Component({
    selector: 'app-document-upload',
    templateUrl: './document-upload.component.html',
    styleUrls: ['./document-upload.component.css']
})
export class DocumentUploadComponent {

    @Input()
    buttonText: string = 'Dokumente hochladen';

    @Input()
    uploadEnabled: boolean = true;

    constructor(private readonly documentService: DocumentService) { }

    /**
     * Initializes {@link DocumentService}. In case you wish to circumvent the whole setup, interact with
     * {@link DocumentService} directly.
     * @param vorgangId
     */
    @Input()
    set initWithVorgangId(vorgangId: string) {
        this.documentService.setDeletableAfterUploadFlag(true);
        this.documentService.setUploadTriggerMode('auto');
        this.documentService.setVorgangId(vorgangId);
        this.documentService.initialize();
    }

    @Input()
    set customErrorMessage(message: string) {
        this.documentService.setCustomErrorMessage(message);
    }

    @Output()
    get onChanges(): Observable<void> {
        return this.documentService.onChanges();
    }

    acceptedFileTypes(): string {
        return DocumentService.acceptedFileTypes();
    }

    queueAll(files: FileList): void {
        this.documentService.addAll(files);
    }

    hasUploadedDocuments(): boolean {
        return this.documentService.hasUploadedDocuments();
    }

    hasQueuedDocuments(): boolean {
        return this.documentService.hasQueuedDocuments();
    }

    hasError(): boolean {
        return this.documentService.hasError();
    }


    public onDrop(event: any): void {
        event.dataTransfer.dropEffect = 'copy';
        event.preventDefault();
        event.stopPropagation();

        const files = event.dataTransfer.files;
        if (files.length > 0) {
            this.documentService.addAll(files);
        }
    }

    public onDragOver(event: any): void {
        event.dataTransfer.dropEffect = 'copy';
        event.preventDefault();
        event.stopPropagation();
    }

    public onDragLeave($event): void {
        $event.preventDefault();
        $event.stopPropagation();
    }
}

import {Component, TemplateRef, ViewChild} from '@angular/core';
import {NxDialogService, NxModalRef} from '@allianz/ngx-ndbx/modal';

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.css']
})
export class DialogComponent {

    @ViewChild('modalDialog') templateRef: TemplateRef<any>;
    modalDialog: NxModalRef<any>;

    constructor(public dialogService: NxDialogService) {
    }


    show(): void {
        this.modalDialog = this.dialogService.open(this.templateRef, { ariaLabel: '', disableClose: true  });
        this.modalDialog.afterClosed().subscribe(result => {
            this.modalDialog.close();
        });
    }

    close(): void {
        this.modalDialog.close();
    }
}

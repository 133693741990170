<header nx-header>
    <nx-header-row>
        <nx-header-brand>
            <nx-link (click)="navigateToIndex()">
                <a tabindex="-1">
                    <figure nxFigure class="img-logo">
                        <img *ngIf="isChristmas() || isCarnival() else other"
                             [src]="isChristmas() ? 'assets/img/logo_xmas.svg' : 'assets/img/logo_karneval.svg'"
                             alt="Allianz Global Brand Logo">
                        <ng-template #other>
                            <img src="https://www.allianz.com/content/dam/onemarketing/system/allianz-logo.svg"
                                 alt="Allianz Global Brand Logo">
                        </ng-template>
                    </figure>
                </a>
            </nx-link>
        </nx-header-brand>

        <nx-header-actions *ngIf="authService.loggedIn">
            <div class="separator">
                    <button *ngIf="userAuth.maySelectReport()"
                            class="nx-margin-bottom-0 bt-berichtswesen nx-font-weight-regular"
                            nxIconButton="tertiary small" title="Berichtswesen" type="button"
                            (click)="goToBerichtswesen()">Berichtswesen
                        <nx-icon name="plus-circle-o" role="button" size="s" class="nx-margin-left-2xs"></nx-icon>
                    </button>
            </div>
            <div class="separator">
                <button class="wrapper nx-margin-bottom-0" nxIconButton="tertiary small"
                        title="Allgemeine Anwendungshinweise" tabindex="0" type="button"
                    (click)="callReleasenotes()"
                    (keypress)="onKeyPress($event)">
                    <span *ngIf="userinfoService.isNewReleaseNotes()">
                        <nx-icon name="dot" size="m" class="releaseNotesDot"></nx-icon>
                    </span>
                    <span>
                        <nx-icon name="info-circle-o" role="button" size="s" class="releaseNotesInfo"></nx-icon>
                    </span>
                </button>
            </div>


            <div class="separator">
                <button
                    nxIconButton="tertiary small-medium"
                    aria-label="Nachrichten"
                    type="button"
                    (click)="callNotifications()"
                    (keypress)="onKeyPress($event)"
                    class="wrapper nx-margin-bottom-0 "
                >
                    <span *ngIf="userinfoService.getNotificationRead()>0">
                        <nx-icon name="dot" size="s" style="margin-left: -3px;
                        margin-top: 6px; z-index: 1;
                        font-size: 43px !important;" class="releaseNotesDot"></nx-icon>
                    </span>
                    <nx-icon name="bell-o" style="color: #006192;"></nx-icon>
                    <nx-indicator  *ngIf="userinfoService.getNotificationRead()>0" position="over-icon" [ngStyle]="{'margin-left' : (userinfoService.getNotificationRead()>=10) ? '-11px' : '-8px'}" style="font-size:11px; margin-top:-17px; z-index: 2; color:white"
                        >{{userinfoService.getNotificationRead()}}</nx-indicator>
                </button>
            </div>

                <span class="span-left-padding">{{userinfoService?.getCurrentUserData()?.name}}</span>
                <div class="icon-wrapper">
                        <nx-context-menu #menu="nxContextMenu">
                            <a nxContextMenuItem routerLink='/myProfile'>Einstellungen</a>
                            <button nxContextMenuItem id="btLogout" class="nx-margin-top-m" (click)="logout()"><b>Logout</b>
                            </button>
                        </nx-context-menu>
                </div>
                <button class="nx-margin-bottom-0" nxIconButton="tertiary small"
                           [nxContextMenuTriggerFor]="menu"
                            aria-label="Benutzereinstellungen öffnen"
                            type="button">
                        <nx-icon name="chevron-down"></nx-icon>
                </button>
        </nx-header-actions>
    </nx-header-row>
    <nx-header-row *ngIf="showHeaderNavigation">
        <nx-header-navigation>
            <nx-header-navigation-item *ngIf="userAuth.maySelectWorkList()">
                <a routerLink="./{{PATH_WORKLIST}}"
                   routerLinkActive="is-active"
                   nxHeaderLink>Arbeitsliste
                </a>
            </nx-header-navigation-item>
            <nx-header-navigation-item *ngIf="userAuth.hasAdminRights()">
                <a routerLink="./{{PATH_ADMINISTRATION}}"
                   [routerLinkActiveOptions]="{exact: false}"
                   routerLinkActive="is-active"
                   nxHeaderLink>Administration
                </a>
            </nx-header-navigation-item>
            <nx-header-navigation-item *ngIf="userAuth.hasRedakteurRights()">
                <a routerLink="./{{PATH_ANWENDUNGSINFO}}"
                    [routerLinkActiveOptions]="{exact: false}"
                    routerLinkActive="is-active"
                    nxHeaderLink>Anwendungsinfo
                </a>
            </nx-header-navigation-item>
        </nx-header-navigation>

        <nx-header-actions>
            <button *ngIf="userAuth.maySelectNewGepro() && !isAdminOrInfo()" nxButton="primary small" class="new-vorgang" type="button"
                    routerLink="./{{PATH_CREATE_GEPRO}}" >
                <nx-icon name="pen" class="nx-margin-right-2xs"></nx-icon>
                Neuer Vorgang
            </button>
        </nx-header-actions>
    </nx-header-row>
    <nx-header-row *ngIf="showHeaderAdminNavigation">
        <nx-header-navigation>
            <nx-header-navigation-item *ngIf="userAuth.maySelectNameRoutingAdministration()">
                <a routerLink="./{{PATH_NAME_ROUTING}}"
                   [routerLinkActiveOptions]="{exact: true}"
                   routerLinkActive="is-active"
                   nxHeaderLink>Routing Name
                </a>
            </nx-header-navigation-item>
            <nx-header-navigation-item *ngIf="userAuth.maySelectMBRoutingAdministration()">
                <a routerLink="./{{PATH_MB_ROUTING}}"
                   [routerLinkActiveOptions]="{exact: true}"
                   routerLinkActive="is-active"
                   nxHeaderLink>Routing MB
                </a>
            </nx-header-navigation-item>
            <nx-header-navigation-item *ngIf="userAuth.maySelectMSNRoutingAdministration()">
                <a routerLink="./{{PATH_MSN_ROUTING}}"
                   routerLinkActive="is-active"
                   nxHeaderLink>Routing MSN
                </a>
            </nx-header-navigation-item>
            <nx-header-navigation-item *ngIf="userAuth.maySelectNotRoutableAdministration()">
                <a routerLink="./{{PATH_NOT_ROUTABLE}}"
                   routerLinkActive="is-active"
                   nxHeaderLink>Nicht routbar
                </a>
            </nx-header-navigation-item>
            <nx-header-navigation-item *ngIf="userAuth.maySelectReferatDataAdministration()">
                <a routerLink="./{{PATH_REFERAT_DATA}}"
                   [routerLinkActiveOptions]="{exact: true}"
                   routerLinkActive="is-active"
                   nxHeaderLink>Referatstammdatenpflege
                </a>
            </nx-header-navigation-item>
            <nx-header-navigation-item *ngIf="userAuth.maySelectDocumentAdministration()">
                <a routerLink="./{{PATH_DOCUMENT_ADMINISTRATION}}"
                   [routerLinkActiveOptions]="{exact: true}"
                   routerLinkActive="is-active"
                   nxHeaderLink>Dokument-Verwaltung
                </a>
            </nx-header-navigation-item>
            <nx-header-navigation-item *ngIf="userAuth.maySelectAdminPflegeAdministration()">
                <a routerLink="./{{PATH_ADMIN_ADMINISTRATION}}"
                   [routerLinkActiveOptions]="{exact: true}"
                   routerLinkActive="is-active"
                   nxHeaderLink>Berechtigung Verwaltung
                </a>
            </nx-header-navigation-item>
            <nx-header-navigation-item *ngIf="userAuth.maySelectAdminPflegeAdministration()">
                <a routerLink="./{{PATH_NAME_REPORTING}}"
                   [routerLinkActiveOptions]="{exact: true}"
                   routerLinkActive="is-active"
                   nxHeaderLink>Repoting Tool
                </a>
            </nx-header-navigation-item>
        </nx-header-navigation>
    </nx-header-row>
    <nx-header-row *ngIf="showHeaderAnwendungsinfoNavigation">
        <nx-header-navigation>
            <nx-header-navigation-item *ngIf="userAuth.maySelectAnwndungsinfoAdministration()">
                <a routerLink="./{{PATH_ANWENDUNGSINFO_VERSIONSHINWEISE}}"
                        [routerLinkActiveOptions]="{exact: true}"
                        routerLinkActive="is-active"
                        nxHeaderLink>Versionshinweise
                </a>
            </nx-header-navigation-item>
            <nx-header-navigation-item *ngIf="userAuth.maySelectAnwndungsinfoAdministration()">
                <a routerLink="./{{PATH_ANWENDUNGSINFO_AUSBLICK}}"
                        routerLinkActive="is-active"
                        nxHeaderLink>Ausblick
                </a>
            </nx-header-navigation-item>
        </nx-header-navigation>
    </nx-header-row>
</header>



import {UserinfoService} from '../../index/service/userinfo.service';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {WorkListService} from '../../../services/work-list.service';
import {ReferateService} from '../../index/service/referate.service';
import {IPaginationTexts, NX_PAGINATION_TEXTS} from '@allianz/ngx-ndbx/pagination';
import {WorkListMitarbeiterService} from '../../index/service/work-list-mitarbeiter.service';
import {Subscription} from 'rxjs';
import {VisibilityService} from '../../../services/visibility.service';

const myPaginationTexts: IPaginationTexts = {
    previous: '',
    next: '',
    ofLabel: 'von',
    ariaLabel: 'Blättern'
};

@Component({
    selector: 'app-work-list-employees',
    templateUrl: './work-list-employees.component.html',
    styleUrls: ['./work-list-employees.component.scss'],
    providers: [
        {provide: NX_PAGINATION_TEXTS, useValue: myPaginationTexts}
    ]
})
export class WorkListEmployeesComponent implements OnInit, OnDestroy {

    public isMitarbeiterALDialogOpen: boolean = false;

    public selectedMitarbeiter: Mitarbeiter;

    public mitarbeiterList: Mitarbeiter[];

    private subscription: Subscription;

    constructor(private userinfoService: UserinfoService,
                private referateService: ReferateService,
                private workListService: WorkListService,
                private workListMitarbeiterService: WorkListMitarbeiterService,
                private visibilityService:VisibilityService) {
    }

    ngOnInit() {
        this.subscription = this.workListMitarbeiterService.getMitarbeiterListObservable().subscribe((data) => {
            this.mitarbeiterList = (data || []).sort((a, b) => {
                if (a && b) {
                    if (a.fullName < b.fullName) {
                        return -1;
                    } else if (a.fullName > b.fullName) {
                        return 1;
                    }
                }
                return 0;
            });
        });

        this.workListMitarbeiterService.updateMitarbeiterList();
    }

    ngOnDestroy(){
        this.subscription.unsubscribe();
    }

    public showMitarbeiterALButtonClicked(mitarbeiter: Mitarbeiter): void {
        this.isMitarbeiterALDialogOpen = true;
        this.selectedMitarbeiter = mitarbeiter;
    }

    public hasFullname(mitarbeiter: Mitarbeiter): boolean {
        return mitarbeiter.fullName ? true : false;
    }

    public getDisplayName(mitarbeiter: Mitarbeiter): string {
        if (this.hasFullname(mitarbeiter)) {
            let displayName = mitarbeiter.fullName;
            if (mitarbeiter.fullName.length > 25) {
                displayName = mitarbeiter.fullName.substring(0, 22) + '...';
            }
            return displayName;
        }
        return '';
    }

    public showFullnameTooltip(mitarbeiter: Mitarbeiter): string {
        if (this.hasFullname(mitarbeiter)) {
            if (mitarbeiter.fullName.length > 25) {
                return mitarbeiter.fullName;
            }
        } else {
            const tooltip = 'Der Bensl ' + mitarbeiter.bensl + ' existiert nicht mehr. Bitte wenden Sie sich an die Admin-KollegINNEN.';
            return tooltip;
        }
        return '';
    }

    public onWorklistClosed(isClosed: boolean) {
        if (isClosed) {
            this.isMitarbeiterALDialogOpen = false;
            this.selectedMitarbeiter = null;
            this.workListService.loadGeproForCurrentUserWithLastParams();
        }
    }

    public isFlyoutVisible(): boolean {
        return this.visibilityService.isVisibleForCurrentUser('flyout');
    }

    public isFlyoutDisabled(): boolean {
        return !this.visibilityService.isEnabledForCurrentUser('flyout');
    }
}

export interface Mitarbeiter {
    bensl: string,
    fullName: string,
    id: number,
    referatId: number,
    role: string,
    statistics: {
        openGeproCount: number
    }
}

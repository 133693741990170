import {Observable} from 'rxjs';
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot} from '@angular/router';
import {ShellComponent} from '../../components/create-gepro/shell/shell.component';
import {Inject, Injectable} from '@angular/core';
import {DOCUMENT} from '@angular/common';

@Injectable()
export class CanDeactivateShellGuard implements CanDeactivate<ShellComponent> {

    private createGeproUrlRegEx = RegExp('^/create-gepro/.+/.+');

    constructor(@Inject(DOCUMENT) private document: HTMLDocument) {
    }


    canDeactivate(
        component: ShellComponent,
        route: ActivatedRouteSnapshot,
        currentState: RouterStateSnapshot,
        nextState?: RouterStateSnapshot
    ): Observable<boolean> | boolean {
        // you can just return true or false synchronously
        const item = this.document.getElementsByClassName('save-successful');
        const success_window_open = item.length > 0;
        if (component.abortModalComponent.modalOpen || success_window_open || this.createGeproUrlRegEx.test(nextState.url)) { //assumes that the modal window triggered the navigation!
            if(nextState.url == "/create-gepro/type"){
                component.abortModalComponent.close();
            }
            return true;
        } else {
            component.abortModalComponent.redirectTo = nextState.url;
            component.abortModalComponent.show();
            return false;
        }
    }
}

<ng-template #noDocuments>
    <h4 nxHeadline="subsection-xsmall" class="u-text-center nx-margin-bottom-0">
        Momentan sind keine Dokumente vorhanden.
    </h4>
</ng-template>
<table *ngIf="documentsPresent() else noDocuments" nxTable nxSort appDisable="download"
       [direction]="'desc'"
       [active]="'createdTs'"
       (sortChange)="sortTable($event)">
    <thead>
    <tr nxTableRow>
        <th nxHeaderCell nxSortHeaderCell="fileName" class="header-name">
            Dokumentname
        </th>
        <th nxHeaderCell nxSortHeaderCell="createdTs" class="header-date">
            Datum
        </th>
        <th nxHeaderCell nxSortHeaderCell="fullName" class="header-creator" colspan="2">
            Hochgeladen von
        </th>
    </tr>
    </thead>
    <tbody>
    <tr nxTableRow *ngFor="let document of documents" style="cursor: default">
        <td nxTableCell>
            <nx-link appDisable="download"
                     aria-label="download"
                     class="js-download-link file-name"
                     (click)="download(document)">
                {{ document?.fileName }}
            </nx-link>
        </td>
        <td nxTableCell><app-timestamp [timestamp]="document?.createdTs"></app-timestamp></td>
        <td nxTableCell>{{ creatorOf(document) }}</td>
        <td nxTableCell class="column-button">
            <button nxIconButton="tertiary" aria-label="download" type="button"
                    (click)="download(document)"
                    *appVisibility="'download'"
                    appDisable="download"
                    class="js-download-button nx-margin-bottom-0">
                <nx-icon name="download"></nx-icon>
            </button>
        </td>
    </tr>
    </tbody>
</table>

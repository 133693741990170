import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {GeproTypeRestTO} from '../../index/service/Model/GeproTypeRestTO';
import {GeproTypeService, VorgangTypeInfo} from '../../index/service/gepro-type.service';
import {CreateGeproService} from '../../../services/create-gepro.service';
import {BaseComponent} from '../base/base.component';
import {GeproRestTO} from '../../index/service/Model/GeproRestTO';
import {GeproService} from '../../index/service/gepro.service';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {catchError, switchMap, tap} from 'rxjs/operators';
import {NxDialogService, NxModalRef} from '@allianz/ngx-ndbx/modal';

@Component({
    selector: 'app-type',
    templateUrl: './type.component.html',
    styleUrls: ['./type.component.css']
})
export class TypeComponent extends BaseComponent implements OnInit, OnDestroy {

    ERROR_TYPE_INVALID_29: string = 'Die für den Vorgang hinterlegte Vorgangsart „Zuschüsse“ existiert nicht mehr und wurde ersetzt durch';
    ERROR_TYPE_INVALID_29_1: string = '„Zuschüsse unter 1000 Euro“';
    ERROR_TYPE_INVALID_29_2: string = '„Zuschüsse über 1000 Euro“.';
    ERROR_TYPE_INVALID_29_3: string = 'Bitte wählen Sie die passende neue Vorgangsart.';

    private readonly selectedType: Subject<GeproTypeRestTO>;
    private readonly typeInfos: Observable<Array<VorgangTypeInfo>>;
    private readonly selectedTypeInfo: Observable<VorgangTypeInfo>;
    public geProList: Array<GeproTypeRestTO> = new Array<GeproTypeRestTO>();
    public validGeProList: Array<GeproTypeRestTO> = new Array<GeproTypeRestTO>();
    geproTypeChanged: boolean = false;
    pageName = 'Vorgangsart';
    errorLoadingDescriptions: boolean;
    errorMessageType: string = '';
    errorMessageType_line2: string = '';
    errorMessageType_line3: string = '';
    errorMessageType_line4: string = '';

    private originalGeproType: GeproTypeRestTO;

    @ViewChild('modalGeproTypeChanged') templateRef: TemplateRef<any>;
    showGeproTypeChangedModal: NxModalRef<any>;

    @ViewChild('infoModal') templateInfoRef: TemplateRef<any>;
    infoModal: NxModalRef<any>;

    constructor(private geproTypeService: GeproTypeService,
                private geproService: GeproService,
                public createGeproService: CreateGeproService,
                public dialogService: NxDialogService
    ) {
        super(createGeproService);
        this.selectedType = new BehaviorSubject(undefined);
        this.typeInfos = this.geproTypeService.vorgangTypeInfos().pipe(
            tap(() => {
                this.errorLoadingDescriptions = false;
            }),
            catchError(_ => {
                this.errorLoadingDescriptions = true;
                return of([]);
            })
        );
        this.selectedTypeInfo = this.selectedType.asObservable().pipe(
            switchMap(type => {
                if (type) {
                    return geproTypeService.vorgangTypeInfoBy(type.id);
                }
                return this.undefinedVorgangTypeInfo();
            })
        );
    }

    ngOnInit() {

        this.callGeproObservables();



        this.subscription.add(this.geproTypeService.queryGeproTypeList().subscribe(data => {
            this.geProList = data;
            this.validGeProList = this.geProList.filter(geproType => geproType.valid === true);
        }));

        this.subscription.add(this.createGeproService.geproObservable().subscribe(data => {
            setTimeout(() => {
                if(data == null) {
                    return;
                }
                this.gepro = data;
                this.createGeproService.setNextDisabled(!this.gepro.geproType);
                this.createGeproService.validateTypeData(this.gepro);
                this.checkErrorMsg();
            }, 0);
        }));

    }

    public callGeproObservables() {
        this.subscription.add(this.createGeproService.geproObservable().subscribe(data => {
            if(data == null) {
                return;
            }
            this.gepro = data;
            this.originalGeproType = this.gepro.geproType;
            this.showNote(this.gepro.geproType);
        }));

        this.subscription.add(this.createGeproService.saveIntentObservable().subscribe(() => {
                this.createGeproService.saveGepro(this.gepro).then(() => {
                    this.saveSuccessful.emit(true);
                });
        }));
    }

    geproTypeComparator = function(firstItem, secondItem) {
        const idOf = (item: GeproTypeRestTO): number => item ? item.id : -1;
        return idOf(firstItem) === idOf(secondItem);
    };

    valueChanged() {
        this.geproTypeChanged = this.gepro.id && this.originalGeproType && this.originalGeproType.id != this.gepro.geproType.id;
        this.setIsCompleted(true);
        this.createGeproService.setNextDisabled(false);
        if (this.geproTypeChanged) {
            this.showGeproTypeChangedModal = this.dialogService.open(this.templateRef, { ariaLabel: 'Vorgangsart ändern', disableClose: true });
            this.showGeproTypeChangedModal.afterClosed().subscribe(result => {
                if (result === 'Cancel') {
                    this.resetGepro();
                } else if (result === 'Continue') {
                    this.resetAndSaveGepro();
                }
                this.showGeproTypeChangedModal.close();
            });
        }
    }

    resetAndSaveGepro() {
        let geproId = this.gepro.id;
        let geproType = this.gepro.geproType;
        this.gepro = new GeproRestTO();
        this.gepro.id = geproId;
        this.gepro.geproType = geproType;
        this.gepro.tickedDocuments = []; // Reset tickedDocuments
        this.createGeproService.saveGepro(this.gepro);
        this.geproService.deleteAllGeproFiles(geproId);
    }

    resetGepro() {
        this.createGeproService.loadGeproById(this.gepro.id);
    }

    vorgangTypeInfos(): Observable<Array<VorgangTypeInfo>> {
        return this.typeInfos;
    }

    selectedVorgangTypeInfo(): Observable<VorgangTypeInfo> {
        return this.selectedTypeInfo;
    }

    showNote(newValue: GeproTypeRestTO) {
        this.selectedType.next(undefined); // Reset message box
        this.createGeproService.setValidationErrors(this.pageName,  []);
        this.errorMessageType = '';
        this.errorMessageType_line2 = '';
        this.errorMessageType_line3 = '';
        this.errorMessageType_line4 = '';
        this.selectedType.next(newValue);
    }

    private undefinedVorgangTypeInfo(): Observable<VorgangTypeInfo> {
        return of(undefined);
    }

    public checkErrorMsg() {
        this.errorMessageType = '';
        this.errorMessageType_line2 = '';
        this.errorMessageType_line3 = '';
        this.errorMessageType_line4 = '';
        if (this.gepro.geproType?.valid === false) {
            if (this.gepro.geproType?.valid === false) {
                if (this.gepro.geproType?.id === 29) {
                    this.errorMessageType = this.ERROR_TYPE_INVALID_29;
                    this.errorMessageType_line2 = this.ERROR_TYPE_INVALID_29_1;
                    this.errorMessageType_line3 = this.ERROR_TYPE_INVALID_29_2;
                    this.errorMessageType_line4 = this.ERROR_TYPE_INVALID_29_3;
                } else {
                    this.errorMessageType = 'Die für den Vorgang hinterlegte Vorgangsart "' + this.gepro.geproType.name + '" existiert nicht mehr. Bitte wählen Sie eine andere Vorgangsart.';
                }
            }
        }
    }


    validTypeInfos( typeInfos: Array<VorgangTypeInfo>): Array<VorgangTypeInfo> {
        console.log("typeinfo" + typeInfos[0].name)
        return typeInfos.filter(typeinfo => typeinfo.valid === true);
    }


    openInfoDialog() {
        this.infoModal = this.dialogService.open(this.templateInfoRef, { ariaLabel: 'Übersicht Vorgangsarten', disableClose: true });
        this.infoModal.afterClosed().subscribe(result => {
             this.infoModal.close();
        });
    }

    getTypeInfoNote(typeInfo: VorgangTypeInfo) {
        return typeInfo?.note ?  typeInfo?.note : 'Kein Hinweistext vorhanden.';
    }
}

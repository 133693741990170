import {Injectable} from '@angular/core';
import {GeproDetails} from '../interfaces/gepro.interfaces';
import {GeproService} from '../components/index/service/gepro.service';
import {UserinfoService} from '../components/index/service/userinfo.service';
import {GeproRestTO} from '../components/index/service/Model/GeproRestTO';

@Injectable({
    providedIn: 'root'
})
export class VisibilityService {

    gepro: GeproDetails;

    constructor(private geproService: GeproService,
                private userInfoService: UserinfoService) {
        this.geproService.subscribeToGeproDetails().subscribe(data => {
            this.gepro = data;
        });
    }

    isVisible(functionName: string, gepro?: GeproDetails | GeproRestTO): boolean {
        return this.getFunctionValueFromGepro(functionName, gepro ? gepro : this.gepro, 'visible');
    }

    isVisibleForCurrentUser(functionName: string): boolean {
        if (!this.userInfoService.getUser() || !this.userInfoService.getUser().visibleFeatures) {
            return false;
        }
        return this.getFunctionValueFromMetadata(functionName, this.userInfoService.getUser().visibleFeatures, 'visible');
    }

    isEnabled(functionName: string, gepro?: GeproDetails | GeproRestTO) {
        return this.getFunctionValueFromGepro(functionName, gepro ? gepro : this.gepro, 'enabled');
    }

    isEnabledForCurrentUser(functionName: string): boolean {
        if (!this.userInfoService.getUser() || !this.userInfoService.getUser().visibleFeatures) {
            return false;
        }
        return this.getFunctionValueFromMetadata(functionName, this.userInfoService.getUser().visibleFeatures, 'enabled');
    }

    private getFunctionValueFromGepro(functionName: string,  gepro: GeproDetails | GeproRestTO, attribute: string): boolean {
        if (!gepro || !gepro.meta) {
            return false;
        }
        return this.getFunctionValueFromMetadata(functionName, gepro.meta, attribute);
    }

    private getFunctionValueFromMetadata(functionName: string, metaData: any, attribute: string): boolean {
        if (!metaData[functionName]) {
            // Due to whitelist concept, non declared functions will be forbidden
            return false;
        }
        // contained functions are at least visible
        return attribute === 'visible' || metaData[functionName].enabled;
    }
}

<div class="event-stream--item {{item?.entryType}} js-event-item">
   <div [class.highlight]="true">
        <div *ngIf="item?.question != null"  class="{{item?.question?.entryType}}" [ngClass]="'freigabe--item--noline'">
            <div *ngIf="item.entryType == 'RELEASECANCEL'" fxLayout="row-reverse" fxLayoutAlign="start center">
                <nx-badge class="badge">Freigabeanforderung zurückgezogen</nx-badge>
            </div>
            <div class="freigabe">
                <nx-icon class="align-icon" name="{{item.question?.entryType == 'RELEASEREQUEST' || item.question?.entryType == 'RELEASEDRAFT'
                        ? 'speech-bubble-dots-o' : 'chat-o'}}"
                    size="s" [title]="item?.question?.entryType">
                </nx-icon>
                <div>
                    <div class="freigabe--item-date d-inline-flex" style="vertical-align: 0">
                        {{geproHistoryService.printEventCreatorInfo(item?.question)}}, <app-timestamp [timestamp]="item?.question?.createdTs"></app-timestamp>
                    </div>
                    <div class="d-flex"></div>
                    <div class="historyBody">
                        <div [innerHTML]="item?.question?.text" class="freigabe--item-note d-inline-flex"></div>
                    </div>
                </div>
            </div>
        </div>
       <div *ngIf="item.entryType != 'RELEASECANCEL'" class="{{item?.entryType}}"  [ngClass]="item?.question == null? 'freigabe--item--noline': 'freigabe--item'">
           <div class="freigabe">
               <nx-icon class="align-icon" name="{{item.entryType == 'RELEASEREQUEST' || item.entryType == 'RELEASEDRAFT'
                        ? 'speech-bubble-dots-o' : 'chat-o'}}"
                        size="s" [title]="item.entryType">
               </nx-icon>
               <div>
                   <div class="freigabe--item-date d-inline-flex" style="vertical-align: 0">
                       {{geproHistoryService.printEventCreatorInfo(item)}}, <app-timestamp [timestamp]="item?.createdTs"></app-timestamp>
                   </div>
                   <div class="d-flex"></div>
                   <div class="historyBody">
                       <div [innerHTML]="item?.text" class="freigabe--item-note d-inline-flex"></div>
                   </div>
               </div>
           </div>
       </div>
   </div>
</div>

import {Component, Input, TemplateRef} from '@angular/core';

@Component({
    selector: 'app-labeled-container',
    templateUrl: './labeled-container.component.html',
    styleUrls: ['./labeled-container.component.css']
})
export class LabeledContainerComponent {

    @Input()
    label: string;
    @Input()
    buttonTemplate: TemplateRef<any>;

    constructor() {}
}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Endpoints} from '../../navigation/endpoints';
import {DefaultHttpService} from '../../../services/default-http.service';

@Injectable({
    providedIn: 'root'
})
export class ReferatDataService {

    constructor(public http: DefaultHttpService) { }

    public queryReferatList(): Observable<any> {
        return this.http.get(Endpoints.REFERAT_LIST);
    }

    public queryReferatListAll(): Observable<any> {
        return this.http.get(Endpoints.REFERAT_LIST_ALL);
    }
}

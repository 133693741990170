import {Component, ComponentRef, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {GeproFile} from '../../../interfaces/gepro.interfaces';
import {GeproService} from '../../index/service/gepro.service';

@Component({
    selector: 'app-upload-button',
    templateUrl: './upload-button.component.html',
    styleUrls: ['./upload-button.component.scss']
})
export class UploadButtonComponent implements OnInit {

    @ViewChild('realInput', {read: ElementRef, static: false}) realInput: ElementRef;
    @ViewChild('browseBtn', {read: ElementRef, static: false}) browseBtn: ElementRef;
    @ViewChild('fileInfo', {read: ElementRef, static: true}) fileInfo: ElementRef;

    @Output()
    fileUploadRemoved = new EventEmitter();

    @Output()
    multipleFilesSelected = new EventEmitter();

    public ownComponentRef: ComponentRef<UploadButtonComponent>;

    public filesSelected: Array<File> = new Array<File>();
    public fileToUpload: File = null;
    public fileTooLarge: boolean;
    public fileError: boolean;

    public uploadProgress: number = 0;
    public statusWaiting: boolean = false;
    public statusUploading: boolean = false;
    public statusProcessing: boolean = false;
    public statusFinished: boolean = false;

    private maxFileSizeInByte: number = 100000000; //100mb
    private storedFile: GeproFile;

    constructor(private readonly geproService: GeproService) {
    }

    ngOnInit() {
    }

    setExisting(file: GeproFile): void {
        if (file) {
            this.statusFinished = true;
            this.storedFile = file;
            this.fileInfo.nativeElement.textContent = file.fileName;
        }
    }

    readFile(fileEvent: any) {
        const files = fileEvent.target.files;
        if(files.length > 1){

            for(var index:number = 1; index<files.length; index++){
                this.filesSelected.push(files[index])
            }

            this.multipleFilesSelected.emit(this.filesSelected);
        }

        if(files[0] == null){
            return;
        }

        this.setFileInUI(fileEvent.target.files[0])
    }

    public setFileInUI(file: File){
        this.fileToUpload = file;

        if(file.size > this.maxFileSizeInByte){
            this.fileTooLarge = true;
        }
        else{
            this.fileTooLarge = false;
        }

        let fileName = file.name;
        if (fileName.length > 24) {
            const indexOfSeparator = file.name.lastIndexOf(".");
            const nameStart = file.name.substr(0, 20);
            const nameEnd = file.name.substr(indexOfSeparator);

            fileName = nameStart + "[...]" + nameEnd;
        }
        this.fileInfo.nativeElement.textContent = fileName;
    }

    cancelSelection() {
        this.realInput.nativeElement.value = null;
        this.fileInfo.nativeElement.textContent = "Datei auswählen...";
        this.fileToUpload = null;
    }

    removeUploadField() {
        if (this.storedFile) { // TODO: Vermutlich wäre ein Hinweis ganz nett ...
            this.geproService.deleteGeproFile(this.storedFile);
        }
        this.fileUploadRemoved.emit();
    }

    passClickEvent() {
        this.realInput.nativeElement.click();
    }

}

import {Component, OnInit} from '@angular/core';
import {StellvertreterService} from '../../index/service/stellvertreter.service';
import {UserMessagesService} from '../../common/messages-area/messages-area/service/user-messages.service';
import {StellvertreterTO} from '../../index/service/Model/StellvertreterTO';

@Component({
    selector: 'app-my-stellvertreter',
    templateUrl: './my-stellvertreter.component.html',
    styleUrls: ['./my-stellvertreter.component.css']
})
export class MyStellvertreterComponent implements OnInit {
    allStvList: StellvertreterTO[];
    stvListDropdown: StellvertreterTO[];
    selectedStvList: StellvertreterTO[];

    constructor(public stvService: StellvertreterService,
                public userMessagesService: UserMessagesService) {
    }

    ngOnInit() {
        this.loadData();
    }

    private loadData() {
        this.stvService.getAllStellvertreter().subscribe(stvList => {
            this.handleCallResult(stvList);
        },
        error => {
            this.userMessagesService.addErrorMessageString('Fehler beim Laden der Stellvertreter-Daten. ' + error.error.message);
        });
    }

    private handleCallResult(stvList: StellvertreterTO[]) {
        this.allStvList = stvList;
        this.stvListDropdown = stvList.filter(stv => stv.selectedAsStv === false);
        this.selectedStvList = stvList.filter(stv => stv.selectedAsStv === true);
    }

    removeStv(stv: StellvertreterTO) {
        this.stvService.delete(stv).subscribe(stvList => {
            this.handleCallResult(stvList);
        },
        error => {
            this.userMessagesService.addErrorMessageString('Fehler beim Löschen des Stellvertreters '
                + stv.fullname + '[' + error.error.message + ']');
        });
    }

    addStv(selectedStv: StellvertreterTO) {
        this.stvService.add(selectedStv).subscribe(stvList => {
            this.handleCallResult(stvList);
        },
        error => {
            this.userMessagesService.addErrorMessageString('Fehler beim Hinzufügen des Stellvertreters '
                    + selectedStv.fullname + '[' + error.error.message + ']');
        });
    }
}

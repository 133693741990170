import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NdbxModule} from '@allianz/ngx-ndbx';
import {NxAutocompleteModule} from '@allianz/ngx-ndbx/autocomplete';
import {NxButtonModule} from '@allianz/ngx-ndbx/button';
import {NxCardModule} from '@allianz/ngx-ndbx/card';
import {NxCheckboxModule} from '@allianz/ngx-ndbx/checkbox';
import {NxDropdownModule} from '@allianz/ngx-ndbx/dropdown';
import {NxLinkModule} from '@allianz/ngx-ndbx/link';
import {NxMessageModule} from '@allianz/ngx-ndbx/message';
import {NxModalModule, NxModalService} from '@allianz/ngx-ndbx/modal';
import {NxPopoverModule} from '@allianz/ngx-ndbx/popover';
import {NxSpinnerModule} from '@allianz/ngx-ndbx/spinner';
import {NxIconModule} from '@allianz/ngx-ndbx/icon';
import {NxBadgeModule} from '@allianz/ngx-ndbx/badge';
import {NxFormfieldModule} from '@allianz/ngx-ndbx/formfield';
import {NxErrorModule} from '@allianz/ngx-ndbx/base';
import {NxTooltipModule} from '@allianz/ngx-ndbx/tooltip';
import {NxInputModule} from '@allianz/ngx-ndbx/input';
import {NxSidebarModule} from '@allianz/ngx-ndbx/sidebar';
import {NxTableModule} from '@allianz/ngx-ndbx/table';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';

@NgModule({
    declarations: [],
    providers: [NxModalService],
    imports: [
        CommonModule,
        NdbxModule,
        NxCardModule,
        NxIconModule,
        NxBadgeModule,
        NxFormfieldModule,
        NxErrorModule,
        NxLinkModule,
        NxTooltipModule,
        NxInputModule,
        NxSidebarModule,
        NxMessageModule,
        NxDropdownModule,
        NxTableModule,
        NxPopoverModule,
        NxModalModule,
        FormsModule,
        ReactiveFormsModule,
        NxCheckboxModule,
        NxButtonModule,
        NxSpinnerModule
    ],
    exports: [
        NdbxModule,
        NxCardModule,
        NxIconModule,
        NxBadgeModule,
        NxFormfieldModule,
        NxErrorModule,
        NxLinkModule,
        NxTooltipModule,
        NxInputModule,
        NxSidebarModule,
        NxMessageModule,
        NxDropdownModule,
        NxTableModule,
        NxPopoverModule,
        NxModalModule,
        FormsModule,
        ReactiveFormsModule,
        NxCheckboxModule,
        NxButtonModule,
        NxSpinnerModule,
        NxAutocompleteModule
    ]
})
export class NdbxDependenciesModule {
}

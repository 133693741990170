<app-dialog >
    <div class="container">
        <div>
            <h3 nxHeadline="subsection-medium" class="u-text-align-left bold">
                DMS-Link Hinzufügen
            </h3>
        </div>
        <div fxLayout="column" fxLayoutAlign="space-between stretch">
            <!--nx-formfield nxLabel="Link zum DMS" id="updatedatum" style="float:left">
                <input nxInput [(ngModel)]="dmslink"/>
            </nx-formfield-->
            <app-encoded-text-area #dmsLink
                                   [label]="label()"
                                   [maxLength]="4000"
                                   [minLines]="5"
                                   [(ngModel)]="dmslink"></app-encoded-text-area>
            <button type="button" style="float:right;margin-top: 28px;
    margin-left: 10px;"
                    nxButton="small-medium"
                    (click)="save()">
                Hinzufügen
            </button>
        </div>
    </div>
<!--    <div class="absolute_bottom">-->
<!--        <div class="u-text-center">-->
<!--            <button type="button" class="mar_left"-->
<!--                    nxButton="small-medium"-->
<!--                    (click)="close()">-->
<!--                Hinzufügen-->
<!--            </button>-->
<!--        </div>-->
<!--    </div>-->
</app-dialog>


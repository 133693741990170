<div *ngIf="!userinfoService.isRoleReferatsleiter() else refLeiter">
    <nx-radio-toggle [nxStyle]="'small'" [(ngModel)]="tabValue">
        <nx-radio-toggle-button nxValue="{{item.key}}" *ngFor="let item of activeTabs | keyvalue" class="js-tab-{{item.value.label}}">{{item.value.label}} {{'(' + item.value.count()+ ')'}}</nx-radio-toggle-button>
    </nx-radio-toggle>
</div>

<ng-template #refLeiter>
    <div class="wrapper">
        <nx-radio-toggle [nxStyle]="'small'" [(ngModel)]="tabValue">
            <nx-icon name="user-business-o" size="s"></nx-icon>
            <nx-radio-toggle-button *ngFor="let item of getAssignerTabsForAssigner() | keyvalue; let i = index"
                                    nxValue="{{item.key}}" [class.buttonRightBorderHack]="i+1 === getCountOfAssignerTabsForAssigner()">
                {{item.value.label}} {{item.value.label === 'Referatssicht' ? '' : '(' + item.value.count() + ')'}}
            </nx-radio-toggle-button>
            <nx-icon name="user-o" size="s" class="beginRight"></nx-icon>
            <nx-radio-toggle-button *ngFor="let item of getEditorTabsForAssigner() | keyvalue; let i = index"
                                    nxValue="{{item.key}}" [class.buttonLeftBorderHack]="i === 0">
                {{item.value.label}} ({{item.value.count()}})
            </nx-radio-toggle-button>
        </nx-radio-toggle>
    </div>
</ng-template>


import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule, Type} from '@angular/core';
// Imports third-party shared modules (angular, angular materials,..)
import {SharedModule} from './shared/shared.module';
// App Components
import {AppIndexComponent} from './components/index/app.index.component';
import {AppPageNotFoundComponent} from './components/pagenotfound/app.pagenotfound.component';
import {BaseComponent} from './components/gePro/base/base.component';
// App Events
import {IEvent} from './config/interfaces';
import {SidebarNavEvent} from './config/events/sidebarNav.event';
// App Global service
import {UserData} from './config/globals/userData.global';
// App Routing
import {AppRoutingModule} from './app-routing.module';
// App Interceptor
import {SideScrollingComponent} from './components/index/tables/side-scrolling/side-scrolling.component';
import {UserinfoService} from './components/index/service/userinfo.service';
import {UmlautPipe} from './config/pipes/UmlautPipe';
import {AppIndexDiassComponent} from './components/index/diass/app-index-diass.component';
import {HTTP_INTERCEPTORS} from '@angular/common/http';


import {registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import {NavigationComponent} from './components/navigation/navigation.component';
import {NxExpertModule} from '@allianz/ngx-ndbx/config';
import {NxButtonModule} from '@allianz/ngx-ndbx/button';
import {NxCardModule} from '@allianz/ngx-ndbx/card';
import {NxCheckboxModule} from '@allianz/ngx-ndbx/checkbox';
import {NxCopytextModule} from '@allianz/ngx-ndbx/copytext';
import {NxDropdownModule} from '@allianz/ngx-ndbx/dropdown';
import {NxDynamicTableModule} from '@allianz/ngx-ndbx/dynamic-table';
import {NxHeadlineModule} from '@allianz/ngx-ndbx/headline';
import {NxImageModule} from '@allianz/ngx-ndbx/image';
import {NxLinkModule} from '@allianz/ngx-ndbx/link';
import {NxListModule} from '@allianz/ngx-ndbx/list';
import {NxIconModule} from '@allianz/ngx-ndbx/icon';
import {NxProgressbarModule} from '@allianz/ngx-ndbx/progressbar';
import {NxInputModule} from '@allianz/ngx-ndbx/input';
import {NxMaskModule} from '@allianz/ngx-ndbx/mask';
import {NxModalModule, NxModalService} from '@allianz/ngx-ndbx/modal';
import {NxPaginationModule} from '@allianz/ngx-ndbx/pagination';
import {NxPopoverModule} from '@allianz/ngx-ndbx/popover';
import {NxRadioModule} from '@allianz/ngx-ndbx/radio-button';
import {NxSpinnerModule} from '@allianz/ngx-ndbx/spinner';
import {NxSwitcherModule} from '@allianz/ngx-ndbx/switcher';
import {NxTabsModule} from '@allianz/ngx-ndbx/tabs';
import {NxErrorModule, NxLabelModule} from '@allianz/ngx-ndbx/base';
import {WiedervorlageDialog, WorkListComponent} from './components/work-list/work-list.component';
import {NxTableModule} from '@allianz/ngx-ndbx/table';
import {NxBadgeModule} from '@allianz/ngx-ndbx/badge';
import {NxSidebarModule} from '@allianz/ngx-ndbx/sidebar';
import {NxActionModule} from '@allianz/ngx-ndbx/action';
import {NxMessageModule} from '@allianz/ngx-ndbx/message';
import {NxGridModule} from '@allianz/ngx-ndbx/grid';
import {GeproService} from './components/index/service/gepro.service';
import {GeproTypeService} from './components/index/service/gepro-type.service';
import {StartPageComponent} from './components/index/start_page/start-page.component';
import {FileService} from './components/index/service/file.service';
import {GeproRoutingService} from './components/index/service/gepro-routing.service';
import {EditableTableNewEntryService} from './components/common/editable-table/service/editable-table-new-entry.service';
import {ApplicationInfoService} from './components/index/service/application-info.service';
import {NxFooterModule} from '@allianz/ngx-ndbx/footer';
import {NxHeaderModule} from '@allianz/ngx-ndbx/header';
import {NxFormfieldModule} from '@allianz/ngx-ndbx/formfield';
import {StatusTypeService} from './components/index/service/status-type.service';
import {NameRoutingEditableTableDataWrapper} from './components/admin/routing/name/entity/NameRoutingEditableTableDataWrapper';
import {GlobalErrorHandler} from './config/interceptors/global-error-handler';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {LoginComponent} from './components/login/login.component';
import {AuthenticationService} from './components/index/service/authentication-service';
import {AuthInterceptor} from './config/interceptors/auth.interceptor';
import {EnvironmentLoaderService} from './services/environment-loader.service';
import {LoginService} from './components/index/service/login.service';
import {DocumentService} from './components/index/service/document.service';
import {NxTooltipModule} from '@allianz/ngx-ndbx/tooltip';
import {GeproMessageModule} from './gepro-message.module';
import {SaveDataModalModule} from './components/common/modals/save-data-modal/save-data-modal.module';
import {FlexModule} from '@angular/flex-layout';
import {GeproModule} from './components/gePro/gepro.module';
import {DiassCommonsModule} from './components/common/diass-commons.module';
import {GeproCloseModalModule} from './components/common/modals/gepro-close-modal/gepro-close-modal.module';
import {NxContextMenuModule} from '@allianz/ngx-ndbx/context-menu';
import {MyProfileModule} from './components/my-profile/my-profile.module';
import {AdminService} from './components/index/service/admin.service';
import {GeproFilterModule} from './components/work-list/gepro-filter/gepro-filter.module';
import {WorkListModule} from './components/work-list/work-list.module';
import {ReferateService} from './components/index/service/referate.service';
import {WorkListMitarbeiterService} from './components/index/service/work-list-mitarbeiter.service';
import {CreateGeproModule} from './components/create-gepro/create-gepro.module';
import {NgbDateFormatPipe} from './components/work-list/gepro-filter/filter-date/filter-date.component';
import {CanDeactivateShellGuard} from './config/activators/CanDeactivateShell.guard';
import {TimestampModule} from './components/common/timestamp/timestamp.module';
import {InitialUserDataLoadingService} from './components/index/service/initial-user-data-loading.service';
import {LogoutComponent} from './components/logout/logout.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NotificationService} from './components/index/service/notification.service';
import {HinweiseComponent} from './hinweisepopup/hinweisepopup';
import {KontaktComponent} from './hinweisepopup/kontakt/kontakt.component';
import {VersionsinfoComponent} from './hinweisepopup/versionsinfo/versionsinfo.component';
import {AusblickComponent} from './hinweisepopup/ausblick/ausblick.component';
import {AusblickService} from './components/index/service/ausblick.service';
import {HinweisService} from './components/index/service/hinweis.service';
import {NxAccordionModule} from '@allianz/ngx-ndbx/accordion';
import {VersionshinweiseVorschauDialogComponent} from './components/anwendungsinfo/versionshinweise-management/dialoge/versionshinweise-vorschau-dialog/versionshinweise-vorschau-dialog.component';
import {StellvertreterService} from './components/index/service/stellvertreter.service';
import {DmsLinkDialogComponent} from './components/gePro/dms-link-dialog/dms-link-dialog.component';
import {NotificationpopupComponent} from './hinweisepopup/notificationpopup';
import {NxDatefieldModule} from '@allianz/ngx-ndbx/datefield';
import {WiedervorlageService} from './components/index/service/wiedervorlage.service';
import {AppWeiterleitenComponent} from "./components/weiterleiten/app.weiterleiten.component";
import {CsvUtils} from "./csvUtils.global";
import {GeproServiceScv} from "./components/index/service/geprocsv.service";
import {LszEmailModalModule} from './components/common/modals/lsz-email-modal/lsz-email-modal.module';
import {MatTreeModule} from "@angular/material/tree";
import {ChartModule} from "primeng/chart";


registerLocaleData(localeDe, 'de');

const eventsProviders: Type<IEvent>[] = [
    SidebarNavEvent,
];

const globalsProviders: Type<any>[] = [
    UserData, CsvUtils
];

@NgModule({
    imports: [
        BrowserAnimationsModule,
        AppRoutingModule,
        GeproMessageModule,
        NxExpertModule, NxFormfieldModule,
        NxButtonModule, NxIconModule, NxRadioModule, NxListModule, NxLinkModule,
        NxHeadlineModule, NxProgressbarModule, NxInputModule, NxMaskModule,
        NxSwitcherModule, NxPaginationModule, NxCheckboxModule, NxTableModule,
        NxSidebarModule, NxMessageModule, NxIconModule, NxTooltipModule, NxDynamicTableModule, NxContextMenuModule,
        NxDropdownModule,
        WorkListModule,
        DragDropModule,
        SharedModule,
        GeproModule,
        NxLabelModule, NxBadgeModule, NxActionModule, NxErrorModule, NxSpinnerModule, NxModalModule, NxPopoverModule,
        NxCardModule, NxFooterModule, NxHeaderModule, NxTabsModule, NxGridModule, SaveDataModalModule, FlexModule,
        DiassCommonsModule, GeproCloseModalModule, NxContextMenuModule, NxImageModule, NxContextMenuModule,
        MyProfileModule, GeproFilterModule, CreateGeproModule, TimestampModule, NxCopytextModule, NxAccordionModule,
        NxDatefieldModule, LszEmailModalModule,
        NxDatefieldModule, MatTreeModule, ChartModule
    ],
    declarations: [
        AppIndexComponent,
        AppPageNotFoundComponent,
        AppWeiterleitenComponent,
        SideScrollingComponent,
        UmlautPipe,
        AppIndexDiassComponent,
        NavigationComponent,
        WorkListComponent,
        StartPageComponent,
        LoginComponent,
        NgbDateFormatPipe,
        WiedervorlageDialog,
        HinweiseComponent,
        NotificationpopupComponent,
        KontaktComponent,
        VersionsinfoComponent,
        AusblickComponent,
        LogoutComponent
    ],
    entryComponents: [
        BaseComponent,
        HinweiseComponent,
        NotificationpopupComponent
    ],
    providers: [
        {provide: LOCALE_ID, useValue: 'de-DE'},
        eventsProviders,
        globalsProviders,
        {provide: ErrorHandler, useClass: GlobalErrorHandler},
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
        UserinfoService,
        GeproService,
        GeproServiceScv,
        GeproTypeService,
        EnvironmentLoaderService,
        GeproRoutingService,
        StatusTypeService,
        FileService,
        ApplicationInfoService,
        NxModalService,
        ReferateService,
        WorkListMitarbeiterService,
        LoginService,
        HinweisService,
        WiedervorlageService,
        WorkListComponent,
        VersionshinweiseVorschauDialogComponent,
        DmsLinkDialogComponent,
        {
            provide: APP_INITIALIZER,
            useFactory: (initialDataLoadingService: InitialUserDataLoadingService) =>
                () => initialDataLoadingService.tryToLoadUserDataOnAppInit(),
            deps: [InitialUserDataLoadingService, UserinfoService, AuthenticationService],
            multi: true
        },
        {
            provide: APP_INITIALIZER,
            useFactory: (environmentLoaderService: EnvironmentLoaderService) =>
                () => environmentLoaderService.loadEnvironmentData(),
            deps: [EnvironmentLoaderService],
            multi: true
        },
        EditableTableNewEntryService,
        AuthenticationService,
        DocumentService,
        AdminService,
        NameRoutingEditableTableDataWrapper,
        CanDeactivateShellGuard,
        NotificationService,
        AusblickService,
        StellvertreterService
    ],

    bootstrap: [AppIndexComponent]
})
export class AppModule {
}

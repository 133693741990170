import {Component, Input, TemplateRef, ViewChild} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {NxDialogService, NxModalRef} from '@allianz/ngx-ndbx/modal';

export type ModalSelectionType = 'rejected' | 'aborted';

export interface ModalSelection {
    type: ModalSelectionType;
    reason?: string;
}

@Component({
    selector: 'app-reject-modal',
    templateUrl: './reject-modal.component.html',
    styleUrls: ['./reject-modal.component.css']
})
export class RejectModalComponent {

    @Input()
    reason: string;

    private readonly selection$: Subject<ModalSelection>;

    @ViewChild('modalRejectGepro') templateRef: TemplateRef<any>;
    showRejectGeproModal: NxModalRef<any>;

    constructor(public dialogService: NxDialogService) {
        this.selection$ = new Subject();
    }


    result(): Observable<ModalSelection> {
        return this.selection$.asObservable();
    }

    aborted(): void {
        this.selection$.next({ type: 'aborted' });
    }

    rejected(): void {
        this.selection$.next({ type: 'rejected', reason: this.reason });
    }

    show(): void {
            this.showRejectGeproModal = this.dialogService.open(this.templateRef, { ariaLabel: 'Vorgang ablehnen', disableClose: true  });
            this.showRejectGeproModal.afterClosed().subscribe(result => {
                if (result === 'Aborted') {
                    this.aborted();
                } else if (result === 'Rejected') {
                    this.rejected();
                }
                this.showRejectGeproModal.close();
            });
    }

}

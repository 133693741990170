import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DocumentService} from '../../index/service/document.service';
import {HttpEventType, HttpResponse} from '@angular/common/http';

@Component({
    selector: 'document-delete-button',
    templateUrl: './document-delete-button.component.html',
    styleUrls: ['./document-delete-button.component.css']
})
export class DocumentDeleteButtonComponent {

    @Input()
    private documentId: number;

    @Input()
    public disabled: boolean;

    @Output()
    documentDeleted = new EventEmitter();

    constructor(private documentService: DocumentService) {
    }

    deleteDocument(): void {
        this.documentService.deleteDocument(this.documentId).subscribe(
            event => {
                    this.documentDeleted.emit();
            });
    }

}

<div style="width:800px; height :360px; " id="wiedervorlage">
    <div>
        <div>
            <h3 nxHeadline="subsection-medium" class="u-text-align-left bold">
                Wiedervorlage
            </h3>
        </div>
        <div fxLayout="column" fxLayoutAlign="space-between stretch">
            <nx-formfield nxLabel="Update-Datum" id="updatedatum">
                <input (dateChange)="checkDate()" nxDatefield nxInput [nxDatepicker]="myDatepicker" [(ngModel)]="wiedervorlage.updated" (ngModelChange)="onChangeDate()"/>
                <nx-datepicker-toggle [for]="myDatepicker" nxFormfieldSuffix></nx-datepicker-toggle>
                <nx-datepicker #myDatepicker></nx-datepicker>
            </nx-formfield>
        </div>
        <div *ngIf="checkDatum">
            <nx-message showIcon="true" nxContext="error">
                Das Datum der Wiedervorlage muss in der Zukunft liegen
            </nx-message>
        </div>
        <div fxLayout="column" fxLayoutAlign="space-between stretch">
            <nx-formfield class="container" nxLabel="">
    <textarea id="textField" nxInput
              class="textarea" style="min-height: 70px;"
              cdkTextareaAutosize="true"
              #errorHint="nxInput"
              [required]="true"
              [readonly]="false"
              (ngModelChange)="onChangeInput()"
              [placeholder]="itemVariable"
              [attr.maxLength]="500"
              [(ngModel)]="wiedervorlage.beschreibung"></textarea>
            </nx-formfield>
        </div>
        <div *ngIf="!(this.userinfoService.getUser().bensl.indexOf(this.item.erfasserBensl)>-1&&this.userinfoService.getUser().bensl.indexOf(this.item.mitarbeiterBensl)>-1)">
            <nx-checkbox [checked]="isCheckboxChecked()"
                         (click)="onCheckboxClicked($event)"
                         (checkboxChange)="onCheckboxChanged()"
                         class="selectCheckbox">
                <span *ngIf="this.userinfoService.getUser().bensl.indexOf(this.item.erfasserBensl)>-1"> Soll die Wiedervorlage dem Bearbeiter in der Historie angezeigt werden?
            </span>
                <span *ngIf="this.userinfoService.getUser().bensl.indexOf(this.item.mitarbeiterBensl)>-1"> Soll die Wiedervorlage dem Ersteller in der Historie angezeigt werden?
            </span>
                <span *ngIf="this.userinfoService.getUser().bensl.indexOf(this.item.mitarbeiterBensl)==-1&&this.userinfoService.getUser().bensl.indexOf(this.item.erfasserBensl)==-1"> Soll die Wiedervorlage dem Ersteller in der Historie angezeigt werden?
            </span>
            </nx-checkbox>
        </div>
    </div>
    <div class="u-text-center" style="margin-top: 10px">
        <div >
            <button type="button"
                    nxButton="primary"
                    (click)="close()" cdkFocusInitial>
                Abbrechen
            </button>
            <button nxButton="primary" style="margin-left: 20px"
                    type="button"
                    [disabled]="!inputDone||!inputDoneDate||wiedervorlage.beschreibung.length<1"
                    (click)="persist()">
                Übernehmen
            </button>
            <button nxButton="primary" style="margin-left: 20px"
                    type="button"
                    (click)="delete()">
                Löschen
            </button>
        </div>
    </div>
</div>

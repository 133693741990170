<div class="event-stream--item {{item?.question.entryType}} js-event-item">
    <div [class.highlight]="geproHistoryService.shouldBeHighlighted(item?.question)">
        <div fxLayout="row-reverse" fxLayoutAlign="start center">
            <nx-badge class="badge">Rückfrage zurückgezogen</nx-badge>
        </div>
        <div class="align-icon">
            <nx-icon [name]="item?.$icon" name="product-heart" nxTooltip="{{item?.question?.$iconExplanation}}"
                     size="s"></nx-icon>
            <div>
                <div class="event-stream--item-date d-inline-flex" style="vertical-align: 0">
                    {{geproHistoryService.printEventCreatorInfo(item?.question)}},
                    <app-timestamp [timestamp]="item?.question?.createdTs"></app-timestamp>
                </div>
                <div class="d-flex"></div>
                <div class="historyBody">
                    <div [innerHTML]="item?.question?.text" class="event-stream--item-note d-inline-flex"></div>

                </div>
            </div>
        </div>

    </div>
</div>

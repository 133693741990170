import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NxButtonModule} from '@allianz/ngx-ndbx/button';
import {NxCopytextModule} from '@allianz/ngx-ndbx/copytext';
import {NxHeadlineModule} from '@allianz/ngx-ndbx/headline';
import {NxModalModule} from '@allianz/ngx-ndbx/modal';
import {LszEmailModalComponent} from './lsz-email-modal.component';

@NgModule({
    declarations: [LszEmailModalComponent],
    exports: [
        LszEmailModalComponent
    ],
    imports: [
        CommonModule,
        NxModalModule,
        NxHeadlineModule,
        NxCopytextModule,
        NxButtonModule
    ]
})
export class LszEmailModalModule { }

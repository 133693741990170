import {Component, Input, OnInit} from '@angular/core';
import {GeproHistoryService} from '../../../services/gepro-history.service';

@Component({
  selector: 'app-gepro-history-answer',
  templateUrl: './gepro-history-answer.component.html',
  styleUrls: ['./gepro-history-answer.component.scss']
})
export class GeproHistoryAnswerComponent implements OnInit {

    @Input() item;

  constructor(public geproHistoryService: GeproHistoryService) { }

  ngOnInit() {
  }


}

import {Injectable} from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class CsvUtils{

    downloadCsv(name : string, content : any){
        console.log("content: " + content);
        var data, link;
        var csv = this.convertToCsv(content);
        if(csv == null) return;

        name = name || 'gepros.csv';
        var csvData = new Blob(['\ufeff'+csv], {type: 'text/csv;charset=utf-8;'})

        if(navigator.msSaveBlob){
            navigator.msSaveBlob(csvData,name);
        }
        else{
            data = window.URL.createObjectURL(csvData);
            link = document.createElement('a');
            link.setAttribute('href',data);
            link.setAttribute('download',name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    downloadCsvSimple(name : string, content : any[]){
        console.log("content: " + content);
        var data, link;
        var csv = this.convertReportingToCsv(content);
        if(csv == null) return;

        name = name || 'reporting.csv';
        var csvData = new Blob(['\ufeff'+csv], {type: 'text/csv;charset=utf-8;'})

        if(navigator.msSaveBlob){
            navigator.msSaveBlob(csvData,name);
        }
        else{
            data = window.URL.createObjectURL(csvData);
            link = document.createElement('a');
            link.setAttribute('href',data);
            link.setAttribute('download',name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    downloadCsvKW(name : string, content : any[]){
        console.log("content: " + content);
        var data, link;
        var csv = this.convertReportingToKWCsv(content);
        if(csv == null) return;

        name = name || 'reporting.csv';
        var csvData = new Blob(['\ufeff'+csv], {type: 'text/csv;charset=utf-8;'})

        if(navigator.msSaveBlob){
            navigator.msSaveBlob(csvData,name);
        }
        else{
            data = window.URL.createObjectURL(csvData);
            link = document.createElement('a');
            link.setAttribute('href',data);
            link.setAttribute('download',name);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        }
    }

    private convertToCsvSimple(data : any){
        const replacer = (key, value) => (value === null ? '' : value); // specify how you want to handle null values here
        const header = Object.keys(data[0]);
        const csv = data.map((row) =>
            header
                .map((fieldName) => JSON.stringify(row[fieldName], replacer))
                .join(',')
        );
        csv.unshift(header.join(','));
        const csvArray = csv.join('\r\n');

        const a = document.createElement('a');
        const blob = new Blob([csvArray], { type: 'text/csv' });
        const url = window.URL.createObjectURL(blob);

        a.href = url;
        a.download = 'reporting.csv';
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    }

    private convertToCsv(betData : any){
        let result, keys, columnDelimiter, lineDelimiter;
        let data: any;
        let keyG : string[] = ['id','geproTypeName', 'msn6', 'mitarbeiterName','publishTimestamp','changeTimestamp','statusTypeName'];
        let header : string[] = ['Id','Vorgang', 'Vermittler', 'Bearbeiter','Eingereicht','Geändert','Status'];
        data = betData ||null;
        if(data==null || !data.length){
            return null;
        }
        columnDelimiter = ';';
        lineDelimiter =  '\n';
        keys = keyG;
        result = header.join(columnDelimiter);
        result+=lineDelimiter;
        data.forEach(function(item){
            keys.forEach(function(key,ctr){
                if(ctr>0)result+=columnDelimiter;
                result+=item[key];
            });
            result+=lineDelimiter;
        });
        return result;
    }

    private convertReportingToCsv(content : any[]){
        let result, keys, columnDelimiter, lineDelimiter;
        let data: any;
        let keyG: string[] = ['Bezeichnung', 'Januar', 'Februar', 'Marz', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];
        let header: string[] = ['Bezeichnung', 'Januar', 'Februar', 'Marz', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];

        data = content || null;

        if (data == null || !data.length) {
            return null;
        }

        columnDelimiter = ';';
        lineDelimiter = '\n';
        keys = keyG;
        result = header.join(columnDelimiter);
        result += lineDelimiter;


        // Iterate over each item in data
        data.forEach(function (item) {
            // Create an array to hold the values for this row
            let rowValues = [];
            // Iterate over each key (column) in the header
            keys.forEach(function (key) {
                // Push the value for this key to the rowValues array
                rowValues.push(item[key] || ''); // Use empty string if value is undefined
            });
            // Join the rowValues array with the column delimiter and add it to the result
            result += rowValues.join(columnDelimiter);
            result += lineDelimiter;
        });

        return result;
    }

    private convertReportingToKWCsv(content : any[]){
        let result, keys, columnDelimiter, lineDelimiter;
        let data: any;
        let keyG: string[] = ['Bezeichnung'];
        for (let i = 1; i <= 52; i++) {
            keyG.push('KW' + i);
        }
        let header: string[] = ['Bezeichnung'];
        for (let i = 1; i <= 52; i++) {
            header.push('KW' + i);
        }
        data = content || null;

        if (data == null || !data.length) {
            return null;
        }

        columnDelimiter = ';';
        lineDelimiter = '\n';
        keys = keyG;
        result = header.join(columnDelimiter);
        result += lineDelimiter;


        // Iterate over each item in data
        data.forEach(function (item) {
            // Create an array to hold the values for this row
            let rowValues = [];
            // Iterate over each key (column) in the header
            keys.forEach(function (key) {
                // Push the value for this key to the rowValues array
                rowValues.push(item[key] || ''); // Use empty string if value is undefined
            });
            // Join the rowValues array with the column delimiter and add it to the result
            result += rowValues.join(columnDelimiter);
            result += lineDelimiter;
        });

        return result;
    }

}

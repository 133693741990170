import {Injectable} from '@angular/core';
import {PartialObserver, Subject, Subscription} from 'rxjs';
import {GeproChangeReferatRestTO} from '../../../index/service/Model/GeproChangeReferatRestTO';

@Injectable({
    providedIn: 'root'
})
export class MoveGeproReferatService {

    private _dialogOpenedSubject:Subject<GeproChangeReferatRestTO> = new Subject<GeproChangeReferatRestTO>();
    private _geproChangeReferatRequestedSubject: Subject<GeproChangeReferatRestTO> = new Subject<GeproChangeReferatRestTO>();

    constructor() {
    }

    public subscribeToDialogOpenedSubject(observer: PartialObserver<any>): Subscription {
        return this._dialogOpenedSubject.subscribe(observer);
    }

    public publishDialogOpened(geproChangeReferatRestTO: GeproChangeReferatRestTO): void {
        this._dialogOpenedSubject.next(geproChangeReferatRestTO);
    }

    public subscribeToGeproChangeReferatRequests(observer: PartialObserver<any>): Subscription {
        return this._geproChangeReferatRequestedSubject.subscribe(observer);
    }

    public publishGeproChangeReferatRequest(geproChangeReferatRestTO: GeproChangeReferatRestTO): void {
        this._geproChangeReferatRequestedSubject.next(geproChangeReferatRestTO);
    }
}

import {Injectable, OnDestroy} from '@angular/core';
import {GeproService} from '../components/index/service/gepro.service';
import {HttpParams} from '@angular/common/http';
import {UserMessagesService} from '../components/common/messages-area/messages-area/service/user-messages.service';
import {GeproRestTO} from '../components/index/service/Model/GeproRestTO';
import {SelectionModel} from '@angular/cdk/collections';
import {StatesService} from './states.service';
import {Filter} from '../components/work-list/gepro-filter/gepro-filter.component';
import {GeproListWithMessageRestTO} from '../components/index/service/Model/GeproListWithMessageRestTO';
import {DenyGeproService} from '../components/work-list/deny-gepro/service/deny-gepro.service';
import {WorkListMitarbeiterService} from '../components/index/service/work-list-mitarbeiter.service';
import {VisibilityService} from './visibility.service';
import {VorgangTabService} from '../components/index/service/vorgang-tab.service';
import {Subscription} from 'rxjs';
import {GeproResCSVTO} from "../components/index/service/Model/GeproRestCSVTO";
import {CsvUtils} from "../csvUtils.global";
import {GeproServiceScv} from "../components/index/service/geprocsv.service";
import {UserService} from "./user.service";
import {UserinfoService} from "../components/index/service/userinfo.service";

@Injectable({
    providedIn: 'root'
})
export class WorkListService implements OnDestroy {

    public totalQueriedElements = 0;
    loading: boolean = true;
    gepros: GeproRestTO[];
    geprosLsz: GeproRestTO[];
    geprosCSV: any;

    public selectedGepros = new SelectionModel<GeproRestTO>(true, []);

    public quickFilters: Array<Filter> = [];

    private page: number;
    private pageSize: number;
    private sortBy: string;
    private direction: string;

    private subscription: Subscription = new Subscription();

    constructor(private geproService: GeproService,
                private geproServiceCsv: GeproServiceScv,
                private userMessagesService: UserMessagesService,
                private userService: UserinfoService,
                private statesService: StatesService,
                private visibilityService: VisibilityService,
                private denyGeproService: DenyGeproService,
                private workListMitarbeiterService: WorkListMitarbeiterService,
                private csvutils : CsvUtils,
                private vorgangTabService: VorgangTabService) {
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public loadGeproForCurrentUserWithLastParams(): void {
        this.loadGeproForCurrentUser(this.page, this.pageSize, this.sortBy, this.direction);
    }

    loadGeproForCurrentUser(page: number, pageSize: number, sortBy: string, direction: string): void {
        this.page = page;
        this.pageSize = pageSize;
        this.sortBy = sortBy;
        this.direction = direction;
        if(this.userService.getTabValue()==='15'&&(this.userService.isRoleReferatsleiter()||this.userService.isAMVB())) {
            console.log("TAB Value drin: " + this.userService.getTabValue()+this.userService.isRoleReferatsleiter());
            this.subscription.add(this.geproService.getAllUndefinedLsz().subscribe(
                gepro => {
                    this.gepros = gepro;
                    this.totalQueriedElements = gepro.length;
                    this.loading=false;
                }, error => {
                    // this.userMessagesService.addErrorMessageString(error.error);
                }
            ));
        }
        else{
            this.subscription.add(this.geproService.queryIncompleteGepro(this.filterParams(), page, pageSize, sortBy, direction).subscribe(
                gepros => {
                    this.gepros = gepros.content;
                    this.totalQueriedElements = gepros.totalElements;
                    this.loading=false;
                }, error => {
                    // this.userMessagesService.addErrorMessageString(error.error);
                }
            ));
        }
    }

    loadCSVGeproForCurrentUser(sortBy: string, direction: string): any {
        if(this.userService.getTabValue()!='15') {
            this.page = 1;
            this.pageSize = 2000;
            this.sortBy = sortBy;
            this.direction = direction;
            this.geproServiceCsv.queryCompleateCsvGepro(this.filterParams(), 1, 2000, sortBy, direction).subscribe(
                (res) => {
                    this.csvutils.downloadCsv('gepro', res);
                }
            );
        }
    }

    private filterParams(): HttpParams {
        let filterParams = new HttpParams();

        for (const filter of this.quickFilters) {
            filterParams = filterParams.set(filter.key, filter.value);
        }

        return filterParams;
    }

    /** Whether the number of selected elements matches the total number of rows. */
    isAllSelected() {
        const numSelected = this.selectedGepros.selected.length;
        const numRows = this.gepros.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    toggleAll() {
        this.isAllSelected() ?
            this.selectedGepros.clear() :
            this.gepros.forEach(row => this.selectedGepros.select(row));
    }

    public authoriseSelectedGepro() {
        let requestsLeft = this.selectedGepros.selected.length;
        for (const geproRestTO of this.selectedGepros.selected) {
            this.subscription.add(this.geproService.authoriseGepros(geproRestTO.id, geproRestTO.mitarbeiterBensl).subscribe(_ => {
                requestsLeft--;
                if (requestsLeft === 0) {
                    this.selectedGepros.clear();
                    this.vorgangTabService.tabChangedSubject.next();
                    this.workListMitarbeiterService.updateMitarbeiterList();
                }
            }));
        }
    }

    handleMitarbeiterCardDropped(draggedGeproId, mitarbeiterId) {
        if (this.selectedGepros.selected.length > 0) {
            let geprosLeft = this.selectedGepros.selected.length;
            for (const geproRestTO of this.selectedGepros.selected) {
                this.subscription.add(this.geproService.authoriseGepros(geproRestTO.id, mitarbeiterId).subscribe(
                    _ => {
                        geprosLeft--;
                        if (geprosLeft === 0) {
                            this.vorgangTabService.tabChangedSubject.next();
                            this.workListMitarbeiterService.updateMitarbeiterList();
                        }
                    }
                ));
            }
            this.selectedGepros.clear();
        } else {
            this.subscription.add(this.geproService.authoriseGepros(draggedGeproId, mitarbeiterId).subscribe(
                _ => {
                    this.vorgangTabService.tabChangedSubject.next();
                    this.workListMitarbeiterService.updateMitarbeiterList();
                }
            ));
        }
    }


    public isAuthoriseButtonDisabled() {
        if (this.selectedGepros.isEmpty()) {
            return true;
        }

        let isNotRouted = false;
        let isNotAllowed = false;
        this.selectedGepros.selected.forEach(selectedGepro => {
            if (selectedGepro.mitarbeiterName == null || selectedGepro.mitarbeiterName === '') {
                isNotRouted = true;
                return;
            }

            if (!this.visibilityService.isVisible('assign', selectedGepro) || !this.visibilityService.isEnabled('assign', selectedGepro)) {
                isNotAllowed = true;
                return;
            }
        });
        if (isNotRouted || isNotAllowed) {
            return true;
        }
        return false;
    }

    public isDenyButtonDisabled() {
        if (this.selectedGepros.isEmpty()) {
            return true;
        }

        let isDenyDisabled = false;
        this.selectedGepros.selected.forEach(selectedGepro => {
            if (!this.visibilityService.isVisible('return', selectedGepro) &&
                !this.visibilityService.isEnabled('return', selectedGepro)) {
                isDenyDisabled = true;
                return isDenyDisabled;
            }
        });
        return isDenyDisabled;
    }

    public denySelectedGepros() {
        const geproListWithMessageRestTO: GeproListWithMessageRestTO = new GeproListWithMessageRestTO();
        geproListWithMessageRestTO.geproIdList = new Array<string>();
        for (const geproRestTO of this.selectedGepros.selected) {
            geproListWithMessageRestTO.geproIdList.push(geproRestTO.id);
        }
        this.denyGeproService.publishDialogOpened(geproListWithMessageRestTO);
    }
}

<form #form [formGroup]="msnVtnrFormGroup">
    <div fxLayout="row">
        <nx-formfield nxLabel="Vermittler" style="flex: 1">
            <input id="inMsnVtnr" nxInput type="text" (ngModelChange)="onTextChange($event)"
                   (keydown.enter)="queryForMsnVtnr()"
                   [placeholder]="required ? 'Bitte MSN oder VTNR eingeben' : 'MSN oder VTNR eingeben'"
                   formControlName="msnVtnr"/>
         </nx-formfield>
        <button nxButton="primary medium" class="nx-margin-left-xs size-height-12 msnvtnrinputsuche" type="button"
                [disabled]="sucheBtDisabled" (click)="queryForMsnVtnr()">
            Suche
        </button>
    </div>
    <div fxLayout="row">
        <nx-message *ngIf="showHinweis()"
                    nxContext="info"
                    showIcon="true"
                    class="nx-margin-top-0 nx-margin-bottom-m">
            <ng-template #loadingIndicator>
                <div fxLayout="row" fxLayoutAlign="start center">
                    <nx-spinner nxSize="medium"></nx-spinner>
                    <span class="nx-margin-left-s">Lade Hinweistext ...</span>
                </div>
            </ng-template>
            Um Rückfragen zu vermeiden, geben Sie bitte eine MSN oder VTNR ein und wählen diese aus, sofern vorhanden.
        </nx-message>
    </div>
    <div *ngIf="!getValueControl().valid">
        <nx-message showIcon="true" nxContext="error">
                Bitte mindestens eine gültige MSN_6, MSN_9, MSN_12 oder VTNR angeben.
        </nx-message>
    </div>

    <div class="table-loading-indicator" *ngIf="showLoadingIndicator">
        <nx-spinner nxSize="large"></nx-spinner>
    </div>
    <br>
    <div class="subtitle" *ngIf="required">
        Bitte wählen Sie mindestens eine MSN oder VTNR aus.
        <br>Das Absenden eines Vorganges ist nur nach Auswahl einer oder mehrerer MSN/VTNR möglich.
    </div>

    <app-msn6-tree-checklist></app-msn6-tree-checklist>
    <!--app-maklerstruktur-tree></app-maklerstruktur-tree-->
</form>

<ng-template #geproChangeReferatReasonTemplate>
    <div nxModalContent class="modal-content u-text-center">
        <h3 nxHeadline="subsection-large" class="nx-font-weight-light">Begründung</h3>
        <div class="nx-margin-bottom-m">
            <app-restricted-text-area #reasonText
                                      label="Bitte geben Sie an, warum Sie diesen Vorgang verschieben"
                                      [maxLength]="4000"
                                      [(ngModel)]="reason"></app-restricted-text-area>
        </div>
        <button nxButton="medium secondary" (click)="closeDialogAndClearValues()">Abbrechen</button>
        <button nxButton="medium" (click)="confirmed()" [disabled]="isMoveButtonDisabled() || reasonText.isInvalid()">
            Verschieben
        </button>
    </div>
</ng-template>

<nx-modal
    (nxClose)="closeDialogAndClearValues()"
    [nxBody]="geproChangeReferatReasonTemplate"
    [nxHideOnClickOutside]="false"
    [nxHideOnEsc]="false"
    [nxShowCloseIcon]="false"
    *ngIf="isDialogOpened">
</nx-modal>

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Endpoints} from '../../navigation/endpoints';
import {DefaultHttpService} from '../../../services/default-http.service';
import {SettingsService} from './settings.service';
import {map, skipWhile} from 'rxjs/operators';


@Injectable()
export class ApplicationInfoService {

    skipNull = () => <T>(source: Observable <T>): Observable<T> => source.pipe(skipWhile(value => value === null));

    constructor(private http: DefaultHttpService, private settingsService: SettingsService) {}

    public getBackendVersion(): Observable<any> {
        return this.http.get(Endpoints.VERSION);
    }

    public getNotice(): Observable<string> {
        return this.settingsService.getSetting('AppNotice').pipe(this.skipNull(), map(setting => setting.value));
    }
}

import {AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {CreateGeproService, Step} from '../../../services/create-gepro.service';
import {Subscription} from 'rxjs';
import {MatHorizontalStepper} from '@angular/material/stepper';
import {STEPPER_GLOBAL_OPTIONS} from '@angular/cdk/stepper';


@Component({
    selector: 'app-shell-stepper',
    templateUrl: './shell-stepper.component.html',
    styleUrls: ['./shell-stepper.component.css'],
    providers: [{
        provide: STEPPER_GLOBAL_OPTIONS, useValue: {showError: true}
    }]
})
export class ShellStepperComponent implements OnInit, AfterViewInit {

    @ViewChild(MatHorizontalStepper, {static: true})
    stepper: MatHorizontalStepper;

    multiSteps: Step[] = [];

    @Input() currentStep;

    @Input() nextDisabled;

    @Output() stepChanged = new EventEmitter();
    @Output() gotoStep = new EventEmitter();
    @Output() stepperInterface: EventEmitter<MatHorizontalStepper> = new EventEmitter();

    subscription: Subscription = new Subscription();


    constructor(public createGeproService: CreateGeproService) {
        this.multiSteps = this.createGeproService.getAllSteps();
    }

    ngOnInit() {
        this.subscription.add(this.createGeproService.multiStepObservable().subscribe((steps)=>{
            this.multiSteps = this.createGeproService.getAllSteps();
        }))
    }

    ngOnDestroy(){
        this.subscription.unsubscribe();
    }

    ngAfterViewInit(){
        this.stepperInterface.next(this.stepper);
    }

    changeStep(stepNumber: number) {
        const  currentStep = this.multiSteps[stepNumber];

        this.gotoStep.emit(currentStep);
    }

    stepperChange($event) {
        const currentStep = this.multiSteps[$event.selectedIndex];

        this.stepChanged.emit(currentStep);
    }

}

<ng-template #hinweiseDialog>
    <div class="u-text-center hinweiseDlgContent">
        <h3 nxHeadline="subsection-medium" class="nx-font-weight-light nx-margin-bottom-s">Allgemeine Anwendungsinformationen</h3>

        <nx-tab-group (selectedTabChange)="tabChanged($event)">
            <nx-tab label="Versionshinweise">
                <div nxCopytext="medium" class="u-text-left hinweiseTabContent">
                    <app-versionsinfo-component></app-versionsinfo-component>
                </div>
            </nx-tab>
            <nx-tab label="Ausblick">
                <div nxCopytext="medium" class="u-text-left hinweiseTabContent">
                    <app-ausblick-component></app-ausblick-component>
                </div>
            </nx-tab>
            <nx-tab label="Kontakt & Support">
                <div nxCopytext="medium" class="u-text-left hinweiseTabContent">
                <app-kontakt-component></app-kontakt-component>
            </div></nx-tab>
        </nx-tab-group>

        <button nxModalClose="Close" nxButton="small" type="button" class="nx-margin-top-xs nx-margin-bottom-2xs" cdkFocusInitial>Schließen</button>
    </div>
</ng-template>


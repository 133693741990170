<div class="container">
    <div fxLayout="row wrap" fxLayoutAlign="center center">
        <div class="table-container">
            <nx-formfield style="display: none">
                <input nxInput/>
            </nx-formfield>
            <table nxTable nxSort (sortChange)="sortTable($event)" class="nx-padding-top-l">
                <thead>
                <tr nxTableRow>
                    <ng-container *ngFor="let header of wrapper.getColumnNames()">
                        <th nxHeaderCell nxSortHeaderCell="{{header.sortValue}}">
                            {{header.displayValue}}
                        </th>
                    </ng-container>
                    <th class="cell-actions" nxHeaderCell></th>
                </tr>
                </thead>
                <tbody>
                <tr nxTableRow *ngFor="let row of wrapper.getTableData();">
                    <ng-container *ngFor="let cell of row.getRowValues();">
                        <td nxTableCell [ngClass]="{
                         'newRow': row.newlyCreated,
                         'edited': row.edited,
                         'deleted': row.deleted
                       }">

                            <input *ngIf="cell.type_input" nxInput [class.editable-field]="wrapper.isEditModeOn && cell.editable" [readonly]="!wrapper.isEditModeOn || row.deleted || !cell.editable" [(ngModel)]="cell.value"
                                   (keyup)="onKeyUpInInputField(row, cell)" [disabled]="wrapper.isEditModeOn && !cell.editable">
                            <ng-container *ngIf="cell.type_dropdown">
                                <input *ngIf="!wrapper.isEditModeOn || row.deleted || !cell.editable; else cellDropdown" [(ngModel)]="cell.value" [readonly]="1" nxInput>
                                <ng-template #cellDropdown>
                                    <nx-formfield>
                                        <nx-dropdown [(nxValue)]="cell.value"
                                                     [nxShowFilter]="true"
                                                     nxFilterPlaceholder="Suchen ..."
                                                     (selectionChange)="onKeyUpInInputField(row, cell);">
                                            <nx-dropdown-item *ngFor="let item of cell.getDropdownValues()" [nxValue]="item"> {{item}}
                                            </nx-dropdown-item>
                                        </nx-dropdown>
                                        <nx-error nxFormfieldError>
                                            Bitte wählen
                                        </nx-error>
                                    </nx-formfield>
                                </ng-template>
                            </ng-container>

                        </td>
                    </ng-container>
                    <td class="cell-actions" nxTableCell [ngClass]="{
                         'newRow': row.newlyCreated,
                         'edited': row.edited,
                         'deleted': row.deleted
                       }">
                        <p *ngIf="wrapper.isElementDeletionAllowed()" class="row-action-buttons" [hidden]="!wrapper.isEditModeOn">
                            <nx-icon name="product-cross" outline="true" size="xs"
                                     (click)="wrapper.rowDeletionRequested(row)" [nxPopoverTriggerFor]="popoverDelete" nxPopoverTrigger="hover">
                            </nx-icon>
                            <nx-popover #popoverDelete>
                                <div><span>Eintrag löschen</span></div>
                            </nx-popover>
                        </p>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
        <div *ngIf="wrapper.isEditModeOn" class="legend_container nx-margin-top-m">
            <nx-card class="legend">
                <h3 nxHeadline="subsection-small" class="nx-margin-bottom-s">Legende</h3>
                <div *ngFor="let entry of legendEntries()">
                    <div fxLayout="row" fxLayoutAlign="start center">
                        <div class="legend_colorbox nx-margin-right-xs {{ entry.class }}"></div>
                        <span>{{ entry.title }}</span>
                    </div>
                </div>
            </nx-card>
        </div>
    </div>
    <div class="nx-margin-s">
        <div *ngIf="wrapper.isEditModeOn" class="edit-mode-button-container">
            <button *ngIf="wrapper.isNewElementCreationAllowed()" class="addRowBtn" nxButton='secondary small-medium' (click)="addRowButtonClicked()">Eintrag hinzufügen</button>
            <div class="actionBtn">
                <button nxButton='secondary small-medium' (click)="discardButtonClicked()">
                    <ng-container *ngIf="wrapper.isChangesMade()">Verwerfen</ng-container>
                    <ng-container *ngIf="!wrapper.isChangesMade()">Abbrechen</ng-container>
                </button>
                <span *ngIf="isSaveButtonDisabled()" [nxPopoverTriggerFor]="popoverSave" nxPopoverTrigger="hover">
                    <button nxButton='primary small-medium' (click)="saveButtonClicked()" [disabled]="isSaveButtonDisabled()" class="nx-margin-left-s">
                        Speichern
                    </button>
                    <nx-popover #popoverSave>
                        <div><span [innerText]="wrapper.disabledSaveButtonMessage"></span></div>
                    </nx-popover>
                </span>
                <span *ngIf="!isSaveButtonDisabled()" class="actionBtn nx-margin-left-s">
                    <button nxButton='primary small-medium' (click)="saveButtonClicked()" [disabled]="isSaveButtonDisabled()">Speichern</button>
                </span>
            </div>
        </div>
        <div *ngIf="!wrapper.isEditModeOn">
            <button class="actionBtn" nxButton='primary small-medium' (click)="editButtonClicked()" [disabled]="isEditButtonDisabled()">Editieren</button>
        </div>
    </div>
</div>

<ng-template #modalDiscardDialog>
    <div nxModalContent class="u-text-center">
        <h3 nxHeadline="subsection-medium" class="headline">Alle Änderungen verwerfen?</h3>

        <button nxModalClose="Beibehalten" nxButton="medium secondary" class="nx-margin-right-s">Weiter bearbeiten</button>
        <button nxModalClose="Verwerfen" nxButton="medium" cdkFocusInitial>Änderungen verwerfen</button>
    </div>
</ng-template>


<app-editable-table-new-dialog [headerValues]="wrapper.getColumnNames()" [newValuesDataRow]="wrapper.getNewEditableTableRow()"></app-editable-table-new-dialog>

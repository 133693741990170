import {Component, Input, TemplateRef, ViewChild} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {NxDialogService, NxModalRef} from '@allianz/ngx-ndbx/modal';

export type Action = 'Save' | 'Discard';

@Component({
    selector: 'app-lsz-email-modal',
    templateUrl: './lsz-email-modal.component.html',
    styleUrls: ['./lsz-email-modal.component.css']
})
export class LszEmailModalComponent {

    @Input()
    showlszEMailModal: boolean;

    @ViewChild('lszEMailModalBody') templateRef: TemplateRef<any>;
    lszEMailModalBody: NxModalRef<any>;

    private readonly action$: Subject<Action> = new Subject();

    constructor(public dialogService: NxDialogService) {
    }

    modalResult(): Observable<Action> {
        return this.action$.asObservable();
    }

    handleResult(value: Action): void {
        this.showlszEMailModal = false;
        this.action$.next(value);
    }

    abort(): void {
        this.handleResult('Discard');
    }

    save(): void {
        this.handleResult('Save');
    }

    show(): void {
        this.lszEMailModalBody = this.dialogService.open(this.templateRef, { ariaLabel: 'Anfrage senden', disableClose: true  });
        this.lszEMailModalBody.afterClosed().subscribe(result => {
            if (result === 'Abort') {
                this.abort();
            } else if (result === 'Save') {
                this.save();
            }
            this.lszEMailModalBody.close();
        });
    }

}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Endpoints} from '../../navigation/endpoints';
import {DefaultHttpService} from '../../../services/default-http.service';

export interface VorgangTypeInfo {
    name: string;
    note: string;
    description: string;
    valid: boolean;
}

@Injectable()
export class GeproTypeService {

    constructor(
        public http: DefaultHttpService,
    ) {
    }

    public queryGeproTypeList(): Observable<any> {
        return this.http.get(Endpoints.GEPRO_TYPE_LIST);
    }

    public vorgangTypeInfos(): Observable<Array<VorgangTypeInfo>> {
        return this.http.get(Endpoints.SERVICE_GEPRO_TYPE_INFO);
    }

    public vorgangTypeInfoBy(id: number): Observable<VorgangTypeInfo> {
        return this.http.get(Endpoints.SERVICE_GEPRO_TYPE_INFO_BY_ID.replace('${typeId}', id.toString()));
    }
}

<h3 *ngIf="editedHinweise?.length === 0" nxHeadline="subsection-medium" class="nx-font-weight-light tabHeader">Aktuell sind keine Versionshinweise vorhanden.</h3>
<div class="container" *ngFor="let item of editedHinweise">
    <nx-expansion-panel id="versionshinweise" [expanded]="item==editedHinweise[0]">
        <nx-expansion-panel-header >
            <nx-expansion-panel-title class="margin-left">Versionshinweise vom {{ getVersionshinweisDatum(item) }}</nx-expansion-panel-title>
        </nx-expansion-panel-header>


        <div class="hinweis">
            <span [innerHTML]="item?.beschreibung"></span>
        </div>
        <div style="margin-top:20px" *ngIf="item.tickedDocuments.length>0">
            <span><b>Datei-Anhänge</b></span>
        </div>
        <div nxTableRow *ngFor="let document of item.tickedDocuments">
            <div id="tag" fxLayout="row" fxLayoutAlign="start center" class="tag nx-margin-top-2m">
                <span><nx-icon name="pdf" size='s' class="nx-margin-right-s pdficon"></nx-icon>
                    <nx-link (click)="downloadDocument(document)"> {{document.name}}</nx-link>
                </span>
            </div>
        </div>
    </nx-expansion-panel>
</div>


import {AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {DialogComponent} from '../dialog.component';
import {Subject} from 'rxjs';
import {Hinweis, HinweisService} from '../../../../index/service/hinweis.service';
import * as moment from 'moment';
import {DocumentService} from "../../../../index/service/document.service";

const TIMESTAMP_FORMAT = 'DD.MM.YYYY';

@Component({
    selector: 'app-versionshinweise-vorschau-dialog',
    templateUrl: './versionshinweise-vorschau-dialog.component.html',
    styleUrls: ['./versionshinweise-vorschau-dialog.component.css']
})
export class VersionshinweiseVorschauDialogComponent implements  AfterViewChecked{

    @Input()
    editedHinweise: Hinweis;
    @ViewChild(DialogComponent, {static: true})
    dialog: DialogComponent;
    @Output()
    readonly vorschauHinweis: Subject<Hinweis>;

    @Input()
    public isHinweisDialogOpen: boolean;

    constructor(private changeDetectorRef: ChangeDetectorRef,private hinweisService: HinweisService) {

    }


    close(): void {
        this.dialog.close();
    }

    show(versionshinweis: Hinweis): void {
        this.editedHinweise = versionshinweis;
        this.dialog.show();
    }

    ngAfterViewChecked(): void {
        this.changeDetectorRef.detectChanges();
    }

    public downloadDocument(hin: any) {
        this.hinweisService.getHinweisDocument(hin.id)
            .subscribe(
                data => {
                    this.downloadFile(data, hin);
                }
            );
    }

    private downloadFile(data: any, hin: any) {
        const element = document.createElement('a');
        element.href = URL.createObjectURL(data.file);
        element.download = hin.name;
        document.body.appendChild(element);
        element.click();
    }

    public getVersionshinweisDatum(editedHinweise: Hinweis): string {
        let fillIn = ' - ';
        if (editedHinweise && editedHinweise.updated !== null) {
            const dateAsString = moment(editedHinweise.updated).format(TIMESTAMP_FORMAT);
            fillIn = dateAsString;
        }
        return fillIn;
    }
}

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, FormControl, FormGroup, Validators} from '@angular/forms';
import {EditableTableNewEntryService} from '../service/editable-table-new-entry.service';
import {EditableTableHeader} from '../prototypes/EditableTableHeader';
import {EditableTableRow} from '../prototypes/EditableTableRow';
import {EditableTableCell} from '../prototypes/EditableTableCell';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-editable-table-new-dialog',
    templateUrl: './editable-table-new-dialog.component.html',
    styleUrls: ['./editable-table-dialog.component.scss']
})
export class EditableTableNewDialogComponent implements OnInit, OnDestroy {

    private accumulatedSubscription: Subscription = new Subscription();

    private errorMessages: Array<string>;

    newValuesForm: FormGroup;

    @Input()
    public headerValues: Array<EditableTableHeader>;

    public isDialogOpened: boolean;

    @Input()
    public newValuesDataRow: EditableTableRow;

    constructor(private editableTableNewEntryService: EditableTableNewEntryService) {}

    ngOnInit() {
        this.buildForm();
        this.accumulatedSubscription.add(this.editableTableNewEntryService.newEntryDialogOpenedSubject.subscribe(() => this.dialogOpened()));
    }

    ngOnDestroy(): void {
        this.accumulatedSubscription.unsubscribe();
    }

    private buildForm(): void {
        let group: any = {};
        for (let i = 0; i < this.headerValues.length; i++) {
            let header: EditableTableHeader = this.headerValues[i];
            let cell: EditableTableCell = this.newValuesDataRow.getRowValues()[i];
            let pattern: RegExp = this.newValuesDataRow.getValidationRegexpArray()[i];
            let formControl = new FormControl( header.sortValue);
            formControl.setValue("");
            formControl.setValidators(Validators.pattern(pattern));
            if (!cell.creatable) {
                formControl.disable();
            } else if (cell.editable && !cell.optional) { // keine Ahnung wieso die eigentlich korrekte RegExp hier nicht für ein gefülltes Feld sorgt...deshalb noch required Validator
                formControl.setValidators([Validators.pattern(pattern), Validators.required]);
            }

            group[header.sortValue] = formControl;
        }

        this.newValuesForm = new FormGroup(group);
        this.newValuesForm.setValidators(this.newValuesDataRow.getRowValidator());
        this.newValuesForm.markAsDirty(); //Der Autofokus macht sonst Probleme (ExpressionChangedAfterItHasBeenCheckedError)
    }

    public dialogOpened(): void {
        this.resetInputFields();
        this.isDialogOpened = true;

        setTimeout(_ => {
              document.getElementById("newEntryFormfield0").getElementsByTagName("input")[0].focus();
        });

    }

    private resetInputFields(): void {
        for (let header of this.headerValues) {
            let abstractControl = this.newValuesForm.get(header.sortValue);
            abstractControl.setValue(null);
            abstractControl.setErrors(null);
            abstractControl.markAsUntouched();
        }
    }

    public discardRowButtonClicked(): void {
        this.isDialogOpened = false;
    }

    public applyRowButtonClicked(): void {
        for (let i = 0; i < this.headerValues.length; i++) {
            let header = this.headerValues[i];
            let inputValue: any = this.newValuesForm.get(header.sortValue).value;
            if (inputValue) {
                let dataRow = this.newValuesDataRow.getRowValues()[i];
                dataRow.value = inputValue.toString();
                if (dataRow.shouldBeStoredInUppercase && dataRow.value) {
                    dataRow.value = dataRow.value.toUpperCase();
                }
            }
        }

        this.editableTableNewEntryService.newEntryCreated(this.newValuesDataRow);
        this.discardRowButtonClicked();
    }

    public isSaveButtonDisabled(): boolean {
        return !this.newValuesForm.touched || !this.newValuesForm.valid;
    }

    public isShowError(): boolean {
        this.errorMessages = new Array<string>();
        let errorMessagesArray = this.newValuesDataRow.getErrorMessagesArray();
        for (let i = 0; i < this.headerValues.length; i++) {
            let header = this.headerValues[i];
            let input: AbstractControl = this.newValuesForm.get(header.sortValue);
            if (input.touched && input.invalid) {
                this.errorMessages.push(errorMessagesArray[i]);
            }
        }

        if (this.errorMessages.length === 0 && this.newValuesForm.touched && this.newValuesForm.invalid) {
            const rowError = this.newValuesForm.getError('rowError');
            if (rowError) {
                this.errorMessages.push(rowError);
            }
        }

        return this.errorMessages.length > 0;
    }
}

import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';

import {UserData} from '../../config/globals/userData.global';
import {AuthenticationService} from '../../components/index/service/authentication-service';
import {Pages} from '../../components/navigation/pages';
import {UserinfoService} from '../../components/index/service/userinfo.service';
import {LoginService} from '../../components/index/service/login.service';

@Injectable({
    providedIn: 'root'
})
export class UserAuth implements CanActivate {

    constructor(private userData: UserData,
                private router: Router,
                private authenticationService: AuthenticationService,
                private userinfoService: UserinfoService,
                private loginService: LoginService) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        const url = this.extractUrlFrom(state);

        if (!this.isLoggedIn()) {
            console.log('user not logged in, forwarding to login page. Requested url', url);
            sessionStorage.setItem('deepLink', url);
            this.loginService.forwardToLoginPage();
            return false;
        }

        let allowed = false;

        if (url === Pages.PATH_NAME_ROUTING) { // admin-area
            allowed = this.maySelectNameRoutingAdministration();
        } else if (url === Pages.PATH_MSN_ROUTING) {
            allowed = this.maySelectMSNRoutingAdministration();
        } else if (url === Pages.PATH_MB_ROUTING) {
            allowed = this.maySelectMBRoutingAdministration();
        } else if (url === Pages.PATH_NOT_ROUTABLE) {
            allowed = this.maySelectNotRoutableAdministration();
        } else if (url === Pages.PATH_REFERAT_DATA) {
            allowed = this.maySelectReferatDataAdministration();
        } else if (url === Pages.PATH_NAME_REPORTING) {
            allowed = this.maySelectAdminPflegeAdministration();
        } else if (url === Pages.PATH_DOCUMENT_ADMINISTRATION) {
            allowed = this.hasAdminRights();
        } else if (url === Pages.PATH_ADMIN_ADMINISTRATION) {
            allowed = this.hasAdminRights();
        }  else if (url === Pages.PATH_ADMINISTRATION) {
            allowed = this.hasAdminRights();
        } else if (url === Pages.PATH_ANWENDUNGSINFO) {
            allowed = this.hasRedakteurRights();
        } else if (url === Pages.PATH_ANWENDUNGSINFO_AUSBLICK) {
            allowed = this.hasRedakteurRights();
        } else if (url === Pages.PATH_ANWENDUNGSINFO_VERSIONSHINWEISE) {
            allowed = this.hasRedakteurRights();
        } else if (url === Pages.PATH_WORKLIST) { // general area
            allowed = this.maySelectWorkList();
        } else if (url.startsWith(Pages.PATH_CREATE_GEPRO)) {
            allowed = this.maySelectNewGepro();
        } else if (url.startsWith(Pages.PATH_GEPRO_DETAILS)) {
            allowed = this.hasAnyNonAdminRights();
        } else if (url === Pages.PATH_MY_PROFILE) {
            allowed = this.hasAnyRights();
        } else if (url.startsWith(Pages.PATH_NOT_IMPLEMENTED)) { // errors and fallbacks
            allowed = this.hasAnyRights();
        } else if (url === Pages.PATH_NOT_FOUND) {
            allowed = this.hasAnyRights();
        }else if (url === Pages.PATH_LINKED) {
            allowed = this.hasAnyRights();
        }  else if (url === Pages.PATH_INDEX) {
            allowed = this.hasAllowedRole([UserinfoService.role_makler]);
        }

        if (!allowed) {
            console.log('access to', url, 'is forbidden');
            this.loginService.forwardToDefaultPage();
        }

        return allowed;
    }

    private isLoggedIn() {
        return this.userinfoService.getCurrentUserData() != null;
    }

    private extractUrlFrom(state: RouterStateSnapshot) {
        let url = state.url ? state.url : '';
        if (url.length > 0 && url.charAt(0) === '/') {
            url = url.slice(1);
        }

        return url;
    }

    maySelectNameRoutingAdministration(): boolean {
        // includes() is not supported by IE (but all other browsers do)
        return this.isAllowedMandant([UserinfoService.mandant_common, UserinfoService.mandant_sachLeben]) && this.hasAllowedRole([UserinfoService.role_admin]);
    }

    maySelectMSNRoutingAdministration(): boolean {
        return this.isAllowedMandant([UserinfoService.mandant_common, UserinfoService.mandant_sachLeben]) && this.hasAllowedRole([UserinfoService.role_admin]);
    }

    maySelectMBRoutingAdministration(): boolean {
        return this.isAllowedMandant([UserinfoService.mandant_kranken]) && this.hasAllowedRole([UserinfoService.role_admin]);
    }

    maySelectNotRoutableAdministration(): boolean {
        return this.hasAllowedRole([UserinfoService.role_admin]);
    }

    maySelectReferatDataAdministration(): boolean {
        return this.hasAllowedRole([UserinfoService.role_admin]);
    }

    maySelectDocumentAdministration(): boolean {
        return this.hasAllowedRole([UserinfoService.role_admin]);
    }

    maySelectAdminPflegeAdministration(): boolean {
        return this.hasAllowedRole([UserinfoService.role_admin]);
    }

    maySelectAnwndungsinfoAdministration(): boolean {
        return this.hasAllowedRole([UserinfoService.role_redakteur]);
    }

    maySelectWorkList(): boolean {
        return this.hasAllowedRole(
            [UserinfoService.role_referatsLeiter,
                UserinfoService.role_amvbMitarbeiter,
                UserinfoService.role_maklerBetreuer]
        );
    }

    maySelectReport(): boolean {
        return this.isAllowedMandant([UserinfoService.mandant_sachLeben]) && this.hasAllowedRole([UserinfoService.role_referatsLeiter]);
    }

    maySelectKapazitaetBearbeiten(): boolean {
        return this.isAllowedMandant([UserinfoService.mandant_sachLeben]);
    }

    maySelectNewGepro(): boolean {
        return this.hasAllowedRole(
            [UserinfoService.role_referatsLeiter,
                UserinfoService.role_amvbMitarbeiter,
                UserinfoService.role_maklerBetreuer]);
    }

    hasAdminRights(): boolean {
        return this.hasAllowedRole([UserinfoService.role_admin]);
    }

    hasRedakteurRights(): boolean {
        return this.hasAllowedRole([UserinfoService.role_redakteur]);
    }

    hasAnyNonAdminRights(): boolean {
        return this.hasAllowedRole(
            [UserinfoService.role_referatsLeiter,
                UserinfoService.role_amvbMitarbeiter,
                UserinfoService.role_maklerBetreuer,
                UserinfoService.role_makler]
        );
    }

    // returnUser(): string{
    //     return  this.userinfoService.getUser().bensl;
    // }

    hasAnyRights(): boolean {
        return this.hasAllowedRole(
            [UserinfoService.role_referatsLeiter,
                UserinfoService.role_amvbMitarbeiter,
                UserinfoService.role_maklerBetreuer,
                UserinfoService.role_admin,
                UserinfoService.role_makler]
        );
    }

    private hasAllowedRole(allowedRoles: string[]): boolean {
        if (!this.userinfoService.getCurrentUserData()) {
            return false;
        }

        return allowedRoles.some(role => this.userinfoService.getCurrentUserData().roles.indexOf(role) !== -1);
    }

    private isAllowedMandant(allowedMandant: string[]): boolean {
        if (!this.userinfoService.getCurrentUserData()) {
            return false;
        }

        return allowedMandant.some(mandant => this.userinfoService.getCurrentUserData().mandant.indexOf(mandant) !== -1);
    }
}

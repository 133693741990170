import {Injectable, OnDestroy} from '@angular/core';
import {Pages} from '../../navigation/pages';
import {AuthenticationService} from './authentication-service';
import {ActivatedRoute, Router} from '@angular/router';
import {UserinfoService} from './userinfo.service';
import {EnvironmentConfig, EnvironmentLoaderService} from '../../../services/environment-loader.service';
import {HttpErrorResponse} from '@angular/common/http';
import {Subscription} from 'rxjs';
import {StellvertreterService} from './stellvertreter.service';
import {StellvertreterTO} from './Model/StellvertreterTO';
import {UserMessagesService} from '../../common/messages-area/messages-area/service/user-messages.service';

@Injectable({
    providedIn: 'root'
})
export class LoginService implements OnDestroy {

    // Tells if Login Service knows if there is still be determined if the login screen
    loading = true;

    error: HttpErrorResponse = null;

    private envConfig: EnvironmentConfig;
    private subscription: Subscription = new Subscription();

    constructor(private authenticationService: AuthenticationService,
                private router: Router,
                private route: ActivatedRoute,
                private userInfoService: UserinfoService,
                private environmentLoaderService: EnvironmentLoaderService) {
        this.watchForConfig();
        this.watchForLogout();
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    private watchForConfig() {
        this.subscription.add(this.environmentLoaderService.getConfigObservable().subscribe(config => {
            if (config) {
                this.envConfig = config;
                console.log('got Environment:', config);
            }
        }));
    }

    watchForLogout() {
        this.subscription.add(this.authenticationService.isAuthenticated.subscribe((isAuthenticated) => {
            if (isAuthenticated === false) {
                this.autoLogin(); // Could be checkStage as well, but this is verifying the user was checked out properly
            }
        }));
    }

    async autoLogin() {
        this.loading = true;

        await this.userInfoService.getUserInformation().toPromise().then(user => {
            this.authenticationService.autologinDetected();
            this.postProcessLogin(user);
        }).catch((error: HttpErrorResponse) => {
            if (error.status === 401) {
                console.error('[401] User Not Logged In', error);
                this.forwardToLoginPage();
            } else {
                this.processError(error);
            }
        });
    }

    async localLoginByCredentials(username: string, password: string) {
        try {
            await this.authenticationService.login(username, password);
            const user = await this.userInfoService.getUserInformation().toPromise();
            this.postProcessLogin(user);
        } catch (error) {
            console.log(error);
            throw new Error('An error occured while loading user data.');
        }

    }

    postProcessLogin(userdata) {
        this.userInfoService.setCurrentUserData(userdata);
        this.userInfoService.getNotes()
        this.forwardToDefaultPage();
    }

    public forwardToDefaultPage() {
        const returnUrl = this.getDefaultPageOrReturnUrl();
        this.router.navigate([returnUrl]);
        this.loading = false;
    }

    public getDefaultPageOrReturnUrl() {
        let page = sessionStorage.getItem('deepLink');
        sessionStorage.removeItem('deepLink');
        if (!page) {
            page = Pages.PATH_WORKLIST;
            if (this.userInfoService.isRoleAdministrator() && !this.userInfoService.isAMVB()) {
                page = Pages.PATH_ADMINISTRATION;
            }
            if (this.userInfoService.isRoleMakler()) {
                page = Pages.PATH_INDEX;
            }
        }
        return this.route.snapshot.queryParams['returnUrl'] || '/' + page;
    }

    async forwardToLoginPage() {
        if (this.isStagedEnvironment()) {
            this.forwardToStagedLogin();
        } else {
            this.forwardToLocalLogin();
        }
    }

    isStagedEnvironment(): boolean {
        console.log('LTP URL set to ' + this.envConfig.loginTokenProviderUrl);
        return this.envConfig &&
            this.envConfig.loginTokenProviderUrl !== 'false' &&
            this.envConfig.loginTokenProviderUrl.indexOf('localhost') === -1;
    }

    private forwardToStagedLogin(): void {
        window.open(this.envConfig.loginTokenProviderUrl, '_self');
    }

    private forwardToLocalLogin() {
        this.router.navigate(['/' + Pages.PATH_LOGIN]);
        this.loading = false;
    }

    processError(error) {
        this.loading = false;
        this.error = error;
    }


}

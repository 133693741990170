import {Injectable} from '@angular/core';
import {GeproRestTO} from './Model/GeproRestTO';
import {EMPTY, Observable, Subject, Subscription, throwError} from 'rxjs';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Endpoints} from '../../navigation/endpoints';
import {DefaultHttpService} from '../../../services/default-http.service';
import {GeproDetails, GeproFile, GeproFiles} from '../../../interfaces/gepro.interfaces';
import {GeproChangeReferatRestTO} from './Model/GeproChangeReferatRestTO';
import {saveAs} from 'file-saver';
import {TickedDocumentRestTO} from './Model/TickedDocumentRestTO';
import {GeproMessageRestTO} from './Model/gepro-message-rest.to';
import {UserinfoService} from './userinfo.service';
import {catchError, map} from 'rxjs/operators';
import {GeproSearchRestTO} from './Model/GeproSearchRestTO';
import {GeproFreigabeTO} from './Model/GeproFreigabeTO';
import {GeproResCSVTO} from "./Model/GeproRestCSVTO";

export interface Gepros {
    content: Array<GeproRestTO>;
    totalElements: number;
}

export interface Reporting {
    rolediass: string;
    status: string;
}

export interface ReportingYear {
    rolediass: string;
    status: string;
    year: number;
    referat: string;
}
export interface ReportingAll {
     mbscount :number;
     mbkcount :number;
     mblcount :number;
     amvbcount :number;
     amvbrlcount :number;
}

export interface ReportingAbgeschlossen {
    geproId :number;
    geproStatus :string;
    createdTs :string;
    kw :number;
    count :number;
}

export interface ReportingAbgeschlossenAll {
    geproStatus :string;
    kw :number;
    count :number;
}
export interface ReportingAbgeschlossenMonthlyAll {
    geproStatus :string;
    geproType :string;
    month :number;
    monthName :string;
    count :number;
}

export interface ReportingMonthly {
    geproStatus :string;
    geproType :string;
    month :any[];
    monthName :any[];
    count :number;
    workTime :number;
    questions :number;
}
interface GeproDraft {
    id: string;
    typeId: number;
    geproInfos: string;
    zusatzInfo: string;
    tickedDocuments: Array<TickedDocumentRestTO>;
    maklerName: string;
}

interface GeproCSV {
    contentCSV: Array<GeproResCSVTO>;
    totalElements: number;
}

@Injectable({
    providedIn: 'root'
})
export class GeproService {

    private $geproFilesObservable: Subject<GeproFiles> = new Subject<GeproFiles>();
    $geproDetailsObservable: Subject<GeproDetails> = new Subject<GeproDetails>();

    $geproHistoryObservable: Subject<true> = new Subject<true>();

    private fileSubscription: Subscription;
    private _currentGepro: GeproRestTO;

    constructor(public http: DefaultHttpService,
                private httpClient: HttpClient,
                private readonly userInfoService: UserinfoService) {
    }

    ngOnDestroy(): void {
        this.unsubscribeFileDownload();
    }

    private unsubscribeFileDownload(): void {
        if (this.fileSubscription) {
            this.fileSubscription.unsubscribe();
        }
    }

    set currentGepro(value: GeproRestTO) {
        this._currentGepro = value;
    }

    /**
     * Create a new draft, that may be published.
     * @param gepro
     */
    public create(gepro: GeproRestTO): Observable<GeproRestTO> {
        let url = Endpoints.GEPROS;
        this.userInfoService.setIsWorkingOnNewVorgang(true);
        if (this.userInfoService.isCurrentUserStvUser()) {
            url = url.concat('?asStvVonBensl=', this.userInfoService.getCurrentStvUser().bensl);
        }
        return this.http.put<GeproRestTO>(url, this.toDraft(gepro));
    }

    /**
     * Update an existing draft.
     * @param gepro
     */
    public update(gepro: GeproRestTO): Observable<GeproRestTO> {
        let url = Endpoints.GEPRO_BY_ID.replace('${geproId}', gepro.id);
        if (this.userInfoService.isCurrentUserStvUser() && (!this.userInfoService.getIsWorkingOnRLTab()||this.userInfoService.getIsWorkingOnNewVorgang())) {
            url = url.concat('?asStvVonBensl=', this.userInfoService.getCurrentStvUser().bensl);
        }
        return this.http.post(url, this.toDraft(gepro));
    }

    /**
     * Publish a draft.
     * @param gepro
     */
    public publish(gepro: GeproRestTO): Observable<GeproRestTO> {
        let url = Endpoints.GEPRO_PUBLISH_BY_ID.replace('${geproId}', gepro.id);
        if (this.userInfoService.isCurrentUserStvUser()&& (!this.userInfoService.getIsWorkingOnRLTab()||this.userInfoService.getIsWorkingOnNewVorgang())) {
            url = url.concat('?asStvVonBensl=', this.userInfoService.getCurrentStvUser().bensl);
        }
        return this.http.post(url, this.toDraft(gepro));
    }

    private toDraft(gepro: GeproRestTO): GeproDraft {
        if (gepro) {
            return {
                id: gepro.id,
                typeId: gepro.geproType ? gepro.geproType.id : null,
                geproInfos: gepro.geproInfos,
                zusatzInfo: gepro.zusatzInfo,
                tickedDocuments: gepro.tickedDocuments,
                maklerName: gepro.maklerName
            };
        }
        return null;
    }

    public deleteGepro(geproId: string): Observable<any> {
        return this.http.post(Endpoints.DELETE_GEPRO.replace('${geproId}', geproId), '');
    }

    public queryIncompleteGepro(httpParams: HttpParams = null, page: number, pageSize: number, sortBy: string, direction: string): Observable<Gepros> {
        let query = this.buildQueryString(httpParams);
        let pageable = this.buildPageableString(page, pageSize, sortBy, direction);
        return this.http.get<Gepros>(`${Endpoints.GEPROS}?${pageable}${query}`);
    }

    private buildQueryString(httpParams: HttpParams): string {
        let query = '';
        if (httpParams && httpParams.keys().length > 0) {
            const filters = httpParams.keys().map(key => `${key}:${httpParams.get(key)}`).join(' AND ');
            query = `&query=${filters}`;
        }
        return query;
    }

    private buildPageableString(page: number, pageSize: number, sortBy: string, direction: string): string {
        let pageableString = '';
        if (page && page >= 1 && pageSize && pageSize >= 0 && sortBy && sortBy.length > 0) {
            pageableString = `page=${page - 1}&size=${pageSize}&sort=${sortBy},${direction}`;
        }

        return pageableString;
    }

    public authoriseGepros(geproId: string, benslToAuthorise: string): Observable<any> {
        let url = Endpoints.AUTHORIZE_GEPROS;
        if (this.userInfoService.isCurrentUserStvUser() && !this.userInfoService.getIsWorkingOnRLTab()) {
            url = url.concat('?asStvVonBensl=', this.userInfoService.getCurrentStvUser().bensl);
        }
        return this.http.post(url.replace('${geproId}', geproId)
            , { bensl: benslToAuthorise });
    }

    public savelsz(geproId: string, benslToAuthorise: string, geprolsz : string): Observable<any> {
        let url = Endpoints.LSZ_GEPRO_BY_ID;
        if (this.userInfoService.isCurrentUserStvUser() && !this.userInfoService.getIsWorkingOnRLTab()) {
            url = url.concat('?asStvVonBensl=', this.userInfoService.getCurrentStvUser().bensl);
        }
        return this.http.post(url.replace('${geproId}', geproId)
            , {geprolsz});
    }

    public saveGeproZusatzInfo(geproId: string, zusatzInfo: string): Observable<any> {
        let url = Endpoints.GEPRO_ZUSATZINFO_BY_ID.replace('${geproId}', geproId);
        if (this.userInfoService.isCurrentUserStvUser() && (!this.userInfoService.getIsWorkingOnRLTab() || this.userInfoService.getIsWorkingOnNewVorgang())) {
            url = url.concat('?asStvVonBensl=', this.userInfoService.getCurrentStvUser().bensl);
        }
        return this.http.post(url.replace('${geproId}', geproId)
            , {zusatzInfo});
    }

    public saveUnindefinedlsz(geproId: string, benslToAuthorise: string, msnLsz: string): Observable<any> {
        let url = Endpoints.UNINDEFINED_LSZ_GEPRO_BY_ID;
        if (this.userInfoService.isCurrentUserStvUser() && !this.userInfoService.getIsWorkingOnRLTab()) {
            url = url.concat('?asStvVonBensl=', this.userInfoService.getCurrentStvUser().bensl);
        }
        return this.http.post(url.replace('${geproId}', geproId)
            , { msnLsz });
    }

    public closeForever(geproId: string) : Observable<any> {
        let url = Endpoints.GEPRO_CLOSE_FOREVER_BY_ID;
        // if (this.userInfoService.isCurrentUserStvUser() && !this.userInfoService.getIsWorkingOnRLTab()) {
        //     url = url.concat('?asStvVonBensl=', this.userInfoService.getCurrentStvUser().bensl);
        // }
        var emptybody : string = "emptybody";
        return this.http.post(url.replace('${geproId}', geproId),emptybody);
    }

    public getAllUndefinedLsz(): Observable<GeproRestTO[]> {
        let url: string = Endpoints.UNDEFINEDLSZ;
        return this.http.get<GeproRestTO[]>(url);
    }

    public deauthoriseSelectedGepros(geproId: string): Observable<any> {
        return this.http.post(Endpoints.DEAUTHORIZE_GEPROS.replace('${geproId}', geproId), '');
    }

    public getGeprosForUser(mitarbeiterBensl: string): Observable<any> {
        // Nicht schön, aber kompatibel...
        const currentUser = this.userInfoService.getUser();
        if (mitarbeiterBensl && currentUser && currentUser.referatAsLeiter) {
            const pageable = this.buildPageableString(1, 2000, 'lastStatusChange', 'desc');
            const id = currentUser.referatAsLeiter.id;
            const query = `&query=mitarbeiterBensl:${mitarbeiterBensl} AND zugeordnetesReferat:${id}`;
            const staticQueryPart = ' AND (' +
                'statusType:ZUGETEILT ' +
                'OR statusType:WIRD_BEARBEITET ' +
                'OR statusType:RUECKFRAGE_GESTELLT ' +
                'OR statusType:RUECKFRAGE_BEANTWORTET ' +
                'OR statusType:ZUR_FREIGABE ' +
                'OR statusType:NACHBEARBEITUNG ' +
                'OR statusType:FREIGEGEBEN' +
                ')';
            return this.http.get<Gepros>(`${Endpoints.GEPROS}?${pageable}${query}${staticQueryPart}`)
                .pipe(map(gepros => gepros.content));
        }
        return EMPTY;
    }

    setNotificationAsRead(benslToAuthorise: string, geproId: string, read:string, noteid: any): Observable<any> {
        return this.http.post(Endpoints.NOTIFICATION_AS_READ.replace('${geproId}', geproId).replace('${read}', read).replace('${noteid}', noteid), { bensl: benslToAuthorise });
    }

    setNotificationsAllAsRead(benslToAuthorise: string, read:string): Observable<any> {
        return this.http.post(Endpoints.NOTIFICATION_ALL_READ.replace('${read}', read), { bensl: benslToAuthorise });
    }

    deleteNotification(benslToAuthorise: string, geproId: string, id: string): Observable<any> {
        return this.http.post(Endpoints.NOTIFICATION_DELETE.replace('${geproId}', geproId).replace('${id}', id), { bensl: benslToAuthorise });
    }

    getAllNotifications(benslToAuthorise: string): Observable<any> {
        return this.http.post(Endpoints.NOTIFICATION, { bensl: benslToAuthorise });
    }

    loadGeproDetails(geproId: string): Observable<GeproDetails> {
        let url: string = Endpoints.GEPRO_BY_ID.replace('${geproId}', geproId);
        if (this.userInfoService.isCurrentUserStvUser() && !this.userInfoService.getIsWorkingOnRLTab()) {
            url = url.concat('?asStvVonBensl=', this.userInfoService.getCurrentStvUser().bensl);
        }
        this.httpClient.get<GeproDetails>(url).toPromise().then((data) => {
            this.$geproDetailsObservable.next(data);
            this._currentGepro = data as any as GeproRestTO;
        }).catch((error) => {
                this.$geproDetailsObservable.next(null);
                this._currentGepro = null;
            }
        );
        return this.$geproDetailsObservable.asObservable();
    }

    loadGeproBy(geproId: string): Observable<GeproRestTO> {
        let url: string = Endpoints.GEPRO_BY_ID.replace('${geproId}', geproId);
        if (this.userInfoService.isCurrentUserStvUser() && !this.userInfoService.getIsWorkingOnRLTab()) {
            url = url.concat('?asStvVonBensl=', this.userInfoService.getCurrentStvUser().bensl);
        }
        return this.http.get<GeproRestTO>(url);
    }

    loadReporting(): Observable<number> {
        let url: string = Endpoints.REPORTING;
        return this.http.get<GeproRestTO>(url);
    }

    loadReporting_count(): Observable<any> {
        let url: string = Endpoints.REPORTING_COUNT;
        return this.http.get<GeproRestTO>(url, {responseType: 'text'}).pipe(
            map((res:any)=>{
                return res;}),
            catchError(err => throwError(err)));
    }

    searchGeproBy(geproId: string): Observable<GeproSearchRestTO> {
        return this.http.get<GeproSearchRestTO>(Endpoints.GEPRO_SEARCH_BY_ID.replace('${geproId}', geproId));
    }

    subscribeToGeproDetails(): Observable<GeproDetails> {
        return this.$geproDetailsObservable;
    }

    loadGeproFiles(geproId: string): Observable<GeproFiles> {
        let url: string = Endpoints.GEPRO_FILES_BY_ID.replace('${geproId}', geproId)
        if (this.userInfoService.isCurrentUserStvUser() && (!this.userInfoService.getIsWorkingOnRLTab()||this.userInfoService.getIsWorkingOnNewVorgang())) {
            url = url.concat('?asStvVonBensl=', this.userInfoService.getCurrentStvUser().bensl);
        }
        this.httpClient.get<any>(url).toPromise().then((data) => {
            this.$geproFilesObservable.next(data);
        });
        return this.$geproFilesObservable.asObservable();
    }

    subscribeToGeproFiles(): Observable<GeproFiles> {
        return this.$geproFilesObservable;
    }

    download(geproFile: GeproFile): void {
        let url = Endpoints.GEPRO_FILES_FILE_BY_ID
            .replace('${geproId}', geproFile.geproId.toString())
            .replace('${fileId}', geproFile.id.toString());

        if (this.userInfoService.isCurrentUserStvUser() && !this.userInfoService.getIsWorkingOnRLTab()) {
            url = url.concat('?asStvVonBensl=', this.userInfoService.getCurrentStvUser().bensl);
        }
        this.downloadBy(url, geproFile.type, geproFile.fileName);
    }



    createPdfFor(geproId: string): void {
        let url = Endpoints.GEPRO_PDF;
        if (this.userInfoService.isCurrentUserStvUser() && !this.userInfoService.getIsWorkingOnRLTab()) {
            url = url.concat('?asStvVonBensl=', this.userInfoService.getCurrentStvUser().bensl);
        }
        this.downloadBy(url.replace('${geproId}', geproId), 'application/pdf', `${geproId}.pdf`);
    }

    private downloadBy(url: string, accept: string, fileName: string): void {
        this.unsubscribeFileDownload();
        this.fileSubscription = this.httpClient.get(url, {responseType: 'blob', headers: {'Accept': accept}})
            .subscribe(blob => saveAs(blob, fileName));
    }

    deleteGeproFile(geproFile: GeproFile): void {
        const fileId = geproFile.id.toString();
        const url = Endpoints.GEPRO_FILES_FILE_BY_ID
            .replace('${geproId}', geproFile.geproId.toString())
            .replace('${fileId}', fileId);
        this.unsubscribeFileDownload();
        this.fileSubscription = this.httpClient.delete(url).subscribe(() => console.debug(`File ${fileId} deleted.`));
    }

    deleteAllGeproFiles(geproId: string): void {
        let url = Endpoints.GEPRO_FILES_BY_ID
            .replace('${geproId}', geproId);
        if (this.userInfoService.isCurrentUserStvUser() && !this.userInfoService.getIsWorkingOnRLTab()) {
            url = url.concat('?asStvVonBensl=', this.userInfoService.getCurrentStvUser().bensl);
        }
        this.unsubscribeFileDownload();
        this.fileSubscription = this.httpClient.delete(url).subscribe(() => console.debug(`Files for Gepro ${geproId} deleted.`));
    }

    postGeproMessage(geproId: string, message: string, recipient?: string): Promise<any> {
        let url: string;
        let body: any;
        if (recipient) {
            url = Endpoints.GEPRO_ENQUIRIES_BY_ID;
            body = {message: message, recipient: recipient};
        } else {
            url = Endpoints.GEPRO_MESSAGES_BY_ID;
            body = {message: message};
        }
        return this.httpClient.post(url.replace('${geproId}', geproId), body).toPromise();
    }

    startEdit(geproId): Promise<any> {
        let url = Endpoints.GEPRO_ACTION_EDIT_BY_ID;
        if (this.userInfoService.isCurrentUserStvUser() && !this.userInfoService.getIsWorkingOnRLTab()) {
            url = url.concat('?asStvVonBensl=', this.userInfoService.getCurrentStvUser().bensl);
        }
        return this.httpClient.post(url.replace('${geproId}', geproId), {}).toPromise();
    }



    markGepro(geproId): Promise<any> {
        return this.httpClient.post(Endpoints.GEPRO_MARK_BY_ID.replace('${geproId}', geproId), {}).toPromise();
    }


    watchHistoryAddedEvent(): Observable<true> {
        return this.$geproHistoryObservable.asObservable();
    }


    async addNote(message: string, geproId: any, recipient?: string): Promise<any> {
        const body = {
            message,
            recipient
        };
        let url = Endpoints.GEPRO_MESSAGES_BY_ID;
        if (this.userInfoService.isCurrentUserStvUser() && !this.userInfoService.getIsWorkingOnRLTab()) {
            url = url.concat('?asStvVonBensl=', this.userInfoService.getCurrentStvUser().bensl);
        }
        return this.postGeproHistoryContent(url, geproId, body);
    }

    async postQuestion(question: string, geproId: any, recipient: string): Promise<any> {
        const body = {
            message: question,
            recipient
        };
        let url = Endpoints.GEPRO_ENQUIRIES_BY_ID;
        if (this.userInfoService.isCurrentUserStvUser() && !this.userInfoService.getIsWorkingOnRLTab()) {
            url = url.concat('?asStvVonBensl=', this.userInfoService.getCurrentStvUser().bensl);
        }
        return this.postGeproHistoryContent(url, geproId, body);
    }

    async cancelQuestion(geproId: any): Promise<any> {
        let body = {};
        let url = Endpoints.SERVICE_GEPRO_ENQUIRIES_CANCEL;
        if (this.userInfoService.isCurrentUserStvUser() && !this.userInfoService.getIsWorkingOnRLTab()) {
            url = url.concat('?asStvVonBensl=', this.userInfoService.getCurrentStvUser().bensl);
        }
        return this.postGeproHistoryContent(url, geproId, body);
    }

    async postAnswer(answer: string, geproId: any, recipient: string): Promise<any> {
        let body = {message: answer, recipient: recipient};
        let url = Endpoints.GEPRO_REPLIES_BY_ID;
        if (this.userInfoService.isCurrentUserStvUser() && !this.userInfoService.getIsWorkingOnRLTab()) {
            url = url.concat('?asStvVonBensl=', this.userInfoService.getCurrentStvUser().bensl);
        }
        return this.postGeproHistoryContent(url, geproId, body);
    }

    private async postGeproHistoryContent(url: string, geproId: string, body: any): Promise<any> {
        const result = await this.httpClient.post(url.replace('${geproId}', geproId), body).toPromise();
        this.$geproHistoryObservable.next(true);

        return result;
    }


    public changeReferatForGepro(geproChangeReferatRestTO: GeproChangeReferatRestTO): Observable<any> {
        return this.http.post(Endpoints.CHANGE_GEPRO_REFERAT.replace('${geproId}', geproChangeReferatRestTO.geproId),
            geproChangeReferatRestTO);
    }

    public closeGepro(geproId: string): Observable<any> {
        let url = Endpoints.GEPRO_CLOSE_BY_ID;
        if (this.userInfoService.isCurrentUserStvUser() && !this.userInfoService.getIsWorkingOnRLTab()) {
            url = url.concat('?asStvVonBensl=', this.userInfoService.getCurrentStvUser().bensl);
        }

        return this.http.post(url.replace('${geproId}', geproId), {});
    }

    rejectById(geproId: string, reason: string): Observable<GeproDetails> {
        let url = Endpoints.GEPRO_ACTION_REJECT_BY_ID;
        if (this.userInfoService.isCurrentUserStvUser() && !this.userInfoService.getIsWorkingOnRLTab()) {
            url = url.concat('?asStvVonBensl=', this.userInfoService.getCurrentStvUser().bensl);
        }
        return this.httpClient.post<GeproDetails>(url.replace('${geproId}', geproId), {
            message: reason
        }); // TODO: Hier auch ein Promise?
    }

    public denyGepro(geproId: string, geproMessageRestTO: GeproMessageRestTO): Observable<any> {
        let url = Endpoints.GEPROS_DENY_BY_ID;
        if (this.userInfoService.isCurrentUserStvUser() && !this.userInfoService.getIsWorkingOnRLTab()) {
            url = url.concat('?asStvVonBensl=', this.userInfoService.getCurrentStvUser().bensl);
        }
        return this.http.post(url.replace('${geproId}', geproId), geproMessageRestTO);
    }

    forrelease(geproId: string, geproFreigabe: GeproFreigabeTO): Promise<any> {
        let url = Endpoints.GEPROS_FORRELEASE_BY_ID;
        if (this.userInfoService.isCurrentUserStvUser() && !this.userInfoService.getIsWorkingOnRLTab()) {
            url = url.concat('?asStvVonBensl=', this.userInfoService.getCurrentStvUser().bensl);
        }
        return this.http.post(url.replace('${geproId}', geproId), geproFreigabe).toPromise();
    }

    release(geproId: string, geproFreigabe: GeproFreigabeTO): Promise<any> {
        let url = Endpoints.GEPROS_RELEASE_BY_ID;
        if (this.userInfoService.isCurrentUserStvUser()&& (!this.userInfoService.getIsWorkingOnRLTab()||this.userInfoService.getIsWorkingOnNewVorgang())) {
            url = url.concat('?asStvVonBensl=', this.userInfoService.getCurrentStvUser().bensl);
        }
        return this.http.post(url.replace('${geproId}', geproId), geproFreigabe).toPromise();
    }

    processrelease(geproId: string, geproFreigabe: GeproFreigabeTO): Promise<any> {
        let url = Endpoints.GEPROS_PROCESSRELEASE_BY_ID;
        if (this.userInfoService.isCurrentUserStvUser() && !this.userInfoService.getIsWorkingOnRLTab()) {
            url = url.concat('?asStvVonBensl=', this.userInfoService.getCurrentStvUser().bensl);
        }
        return this.http.post(url.replace('${geproId}', geproId), geproFreigabe).toPromise();
    }

    denyforrelease(geproId: string): Promise<any> {
        let url = Endpoints.GEPROS_DENY_FORRELEASE_BY_ID;
        if (this.userInfoService.isCurrentUserStvUser() && !this.userInfoService.getIsWorkingOnRLTab()) {
            url = url.concat('?asStvVonBensl=', this.userInfoService.getCurrentStvUser().bensl);
        }
        return this.http.post(url.replace('${geproId}', geproId), {}).toPromise();
    }


    loadExplizitreporting(role: string, status: string): Observable<any> {
        let url: string = Endpoints.REPORTING_EXLPLIZIT;
        let reporting : Reporting;
        reporting ={
            rolediass :role,
            status:status
        }
        return this.http.post(url, reporting);
    }

    findAbgeschlossenReportingYear(role: string, status: string, year: number, referat: string): Observable<any> {
        let url: string = Endpoints.SERVICE_REPORTING_ABGESCHLOSSEN_KW;
        let reportingYear : ReportingYear;
        reportingYear ={
            rolediass :role,
            status:status,
            year:year,
            referat:referat
        }
        return this.http.post(url, reportingYear);
    }

    findLszReportingYear(role: string, status: string, year: number, referat: string): Observable<any> {
        let url: string = Endpoints.REPORTING_ALL_LSZ_YEAR;
        let reportingYear : ReportingYear;
        reportingYear ={
            rolediass :role,
            status:status,
            year:year,
            referat:referat
        }
        return this.http.post(url, reportingYear);
    }

    findAbgeschlossenReportingMonthly(role: string, status: string, year: number, referat: string): Observable<any> {
        let url: string = Endpoints.SERVICE_REPORTING_ABGESCHLOSSEN_MONTHLY;
        let reportingYear : ReportingYear;
        reportingYear ={
            rolediass :role,
            status:status,
            year:year,
            referat:referat
        }
        return this.http.post(url, reportingYear);
    }

    loadExplizitreportingAll(role: string, status: string): Observable<any> {
        let url: string = Endpoints.REPORTING_ALL;
        let reporting : Reporting;
        reporting ={
            rolediass :role,
            status:status
        }
        return this.http.post(url, reporting);
    }

    getFreigabeinfos(geproId: string): Observable<GeproFreigabeTO[]> {
        let url = Endpoints.GEPRO_FREIGABE;
        if (this.userInfoService.isCurrentUserStvUser() && !this.userInfoService.getIsWorkingOnRLTab()) {
            url = url.concat('?asStvVonBensl=', this.userInfoService.getCurrentStvUser().bensl);
        }
        return this.http.get<GeproSearchRestTO>(url.replace('${geproId}', geproId));
    }

    addFreigabeinfo(geproId: string, freigabeinfo: GeproFreigabeTO): Promise<any> {
        let url = Endpoints.GEPRO_FREIGABE;
        if (this.userInfoService.isCurrentUserStvUser() && !this.userInfoService.getIsWorkingOnRLTab()) {
            url = url.concat('?asStvVonBensl=', this.userInfoService.getCurrentStvUser().bensl);
        }
        return this.http.post(url.replace('${geproId}', geproId), freigabeinfo).toPromise();
    }

    communicateWithLSZ(message: string, geproId: string): Promise<any> {
        const body = {
            message,
        };
        let url = Endpoints.GEPRO_COMMUNICATE_WITH_LSZ;
        if (this.userInfoService.isCurrentUserStvUser() && !this.userInfoService.getIsWorkingOnRLTab()) {
            url = url.concat('?asStvVonBensl=', this.userInfoService.getCurrentStvUser().bensl);
        }
        return this.postGeproHistoryContent(url, geproId, body);
    }
}

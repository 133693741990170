<nx-card class="profile-card nx-margin-top-s nx-margin-left-s">
    <h1 nxHeadline="subsection-large" class="nx-font-weight-light">Mein Profil</h1>
</nx-card>
<nx-card class="profile-card nx-margin-left-s" role="Meine Profileinstellungen">
    <h2 nxHeadline="subsection-medium">Einstellungen</h2>

    <nx-tab-group class="profile_tabgroup" (selectedTabChange)="tabChanged($event)">
        <nx-tab label="Profil">
        <span>
            <p><b>BenSL</b></p>
            {{userinfoService.getCurrentUserData()?.bensl}}
        </span>
        <span>
            <p><b>Name</b></p>
            {{userinfoService.getCurrentUserData()?.name}}
        </span>
        <span>
            <p><b>E-mail</b></p>
            {{userinfoService.getCurrentUserData()?.mailAddress}}
        </span>
        <span>
            <p><b>Rollen</b></p>
            {{userinfoService.getMappedUserRoles()}}
            </span>
        <span *ngIf="userinfoService.getCurrentUserData()?.referatAsLeiter">
            <p *ngIf="isReferatsleiter() else stv"><b>Leiter von</b></p>
            <ng-template #stv><p><b>Stellvertreter von</b></p></ng-template>
            {{userinfoService.getCurrentUserData().referatAsLeiter.name}}
        </span>
        <span *ngIf="userinfoService.getCurrentUserData()?.referatAsMitarbeiter">
            <p><b>Mitarbeiter in</b></p>
            {{userinfoService.getCurrentUserData().referatAsMitarbeiter.name}}
        </span>
        </nx-tab>
        <nx-tab label="Stellvertretung" *ngIf="maySetStellvertreter()">
            <app-my-stellvertreter></app-my-stellvertreter>
        </nx-tab>
    </nx-tab-group>

</nx-card>

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GeproDetailComponent} from './gepro-detail/gepro-detail.component';
import {GeproHistoryComponent} from './gepro-history/gepro-history.component';
import {GeproComponent} from './gepro.component';
import {BaseComponent} from './base/base.component';
import {MenuComponent} from './menu/menu.component';
import {NxCopytextModule} from '@allianz/ngx-ndbx/copytext';
import {NxHeadlineModule} from '@allianz/ngx-ndbx/headline';
import {NxPaginationModule} from '@allianz/ngx-ndbx/pagination';
import {NxTabsModule} from '@allianz/ngx-ndbx/tabs';
import {NxProgressbarModule} from '@allianz/ngx-ndbx/progressbar';
import {NdbxDependenciesModule} from '../../ndbx-dependencies/ndbx-dependencies.module';
import {GeproMessageModule} from '../../gepro-message.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DiassCommonsModule} from '../common/diass-commons.module';
import {SaveDataModalModule} from '../common/modals/save-data-modal/save-data-modal.module';
import {MatTooltipModule} from '@angular/material/tooltip';
import {GeproCloseModalModule} from '../common/modals/gepro-close-modal/gepro-close-modal.module';
import {RejectModalModule} from '../common/modals/reject-modal/reject-modal.module';
import {FlexLayoutModule} from '@angular/flex-layout';
import {GeproCloseComponent} from './gepro-close/gepro-close.component';
import {GeproRejectComponent} from './gepro-reject/gepro-reject.component';
import {DenyGeproComponent} from '../work-list/deny-gepro/deny-gepro.component';
import {VorgangDocumentsModule} from './vorgang-documents/vorgang-documents.module';
import {VorgangMaklerModule} from './vorgang-makler/vorgang-makler.module';
import {GeproNoteComponent} from './gepro-note/gepro-note.component';
import {GeproQuestionComponent} from './gepro-question/gepro-question.component';
import {VisibilityService} from '../../services/visibility.service';
import {SharedModule} from '../../shared/shared.module';
import {GeproHistoryItemComponent} from './gepro-history-item/gepro-history-item.component';
import {GeproHistoryRueckfrageComponent} from './gepro-history-rueckfrage/gepro-history-rueckfrage.component';
import {GeproHistoryAnswerComponent} from './gepro-history-answer/gepro-history-answer.component';
import {TimestampModule} from '../common/timestamp/timestamp.module';
import {GeproHistoryRueckfrageCancelComponent} from './gepro-history-rueckfrage-cancel/gepro-history-rueckfrage-cancel.component';
import {DialogDms} from './dms-link-dialog/dialog.module';
import {GeproInfoComponent} from '../common/gepro-info/gepro-info.component';
import {LabeledContainerModule} from '../common/labeled-container/labeled-container.module';
import {GeproFreigabeComponent} from './gepro-freigabe/gepro-freigabe.component';
import {GeproHistoryFreigabeComponent} from './gepor-history-freigabe/gepro-history-freigabe.component';
import {GeproCommunicationComponent} from './gepro-communication/gepro-communication.component';
import {NxRadioToggleModule} from '@allianz/ngx-ndbx/radio-toggle';
import {GeproMsnTreeChecklistComponent} from "./gepro-msn-tree-checklist/gepro-msn-tree-checklist.component";
import {MatTreeModule} from "@angular/material/tree";
import {GeproMaklerstrukturTreeComponent} from "./gepromaklerstruktur-tree/gepro-maklerstruktur-tree.component";
import {GeproTreeChecklistExample} from "./gepro-msn-tree-checklist/gepro-tree-checklist-example";
import {GeproMsntreeNodeContentComponent} from "./gepro-maklerstruktur-node-content/gepro-msntree-node-content.component";
import {GeproMaklerstrukturNodeContentComponent} from "./gepro-maklerstruktur-node-content/gepro-maklerstruktur-node-content.component";
import {NxAccordionModule} from "@allianz/ngx-ndbx/accordion";
import {ScrollingModule} from "@angular/cdk/scrolling";
import {LszEmailModalModule} from '../common/modals/lsz-email-modal/lsz-email-modal.module';
import {CloseGeproForeverComponent} from "../work-list/deny-gepro/close-gepro-forever.component";

@NgModule({
    declarations: [
        BaseComponent,
        GeproDetailComponent,
        GeproHistoryComponent,
        GeproComponent,
        MenuComponent,
        GeproCloseComponent,
        GeproRejectComponent,
        DenyGeproComponent,
        CloseGeproForeverComponent,
        GeproNoteComponent,
        GeproQuestionComponent,
        GeproHistoryItemComponent,
        GeproHistoryRueckfrageComponent,
        GeproMsnTreeChecklistComponent,
        GeproHistoryRueckfrageCancelComponent,
        GeproMsntreeNodeContentComponent,
        GeproMaklerstrukturNodeContentComponent,
        GeproMaklerstrukturTreeComponent,
        GeproTreeChecklistExample,
        GeproHistoryAnswerComponent,
        GeproInfoComponent,
        GeproFreigabeComponent,
        GeproHistoryFreigabeComponent,
        GeproCommunicationComponent
    ],
    imports: [
        CommonModule,
        DiassCommonsModule,
        NdbxDependenciesModule,
        NxPaginationModule,
        NxProgressbarModule,
        GeproMessageModule,
        FormsModule,
        ReactiveFormsModule,
        SaveDataModalModule,
        MatTooltipModule,
        GeproCloseModalModule,
        LszEmailModalModule,
        RejectModalModule,
        FlexLayoutModule,
        VorgangDocumentsModule,
        VorgangMaklerModule,
        NxTabsModule,
        SharedModule,
        TimestampModule,
        NxHeadlineModule,
        NxCopytextModule,
        DialogDms,
        NxCopytextModule,
        LabeledContainerModule,
        NxRadioToggleModule,
        MatTreeModule,
        NxAccordionModule,
        ScrollingModule,
    ],
    exports: [
        DenyGeproComponent,
        CloseGeproForeverComponent,
        GeproInfoComponent
    ],
    providers: [
        VisibilityService
    ]
})
export class GeproModule {
}

<ng-template id="notification" #notificationDialog>
    <div>
        <div id="over">
        <div>
            <div>
        <span style="font-weight: bold;float:left">Nachrichten</span><br>
                </div>
            <div >
                <button class="wrapper nx-margin-bottom-0 " nxIconButton="tertiary small"
                        tabindex="0" type="button" style="margin-top: -25px;
    float: right;
    margin-right: -20px;"
                        (click)="close()">
                    <nx-icon name="close" size="m"
                             style="font-size: 20px; float:right"></nx-icon>
                </button>

            </div>
        </div>
        <div style="display: flex">
            <div >
                <button class="wrapper nx-margin-bottom-0 " nxIconButton="tertiary small"
                        tabindex="0" type="button"
                        (click)="setAlreadyNotReadAll()">
                        <nx-icon name="password-hide-o" size="s" style="font-size: 20px; padding: 5px; "></nx-icon>
                </button>
            </div>
            <div style="margin-top: 6px;">
                <span style=" color: #007ab3; font-weight: bold; margin-left: 5px; ">Alle ungelesen</span>
            </div>
            <div >
                <button class="wrapper nx-margin-bottom-0 " nxIconButton="tertiary small"
                        tabindex="0" type="button"
                        (click)="setAlreadyReadAll()">
                        <nx-icon name="password-show-o" size="s" style="font-size: 20px; padding: 5px; "></nx-icon>

                </button>
            </div>
            <div style="margin-top: 6px;">
                <span style=" color: #007ab3; font-weight: bold; margin-left: 5px; ">Alle gelesen</span>
            </div>

        </div>
        </div>
        <div id="bot">
        <table style="margin-left: -17px;
    margin-right: -17px;

    border: none;" cellspacing="0" cellpadding="0">

            <tbody  style="max-height: 400px !important;
    overflow-y: auto;
    display: block;">
            <tr style="padding: 5px; cursor:pointer;" *ngFor="let note of notification"
                [ngStyle]="{'background-color' : (note.gelesen=='true') ? 'white' : 'aliceblue'}" >
                <td style="padding: 5px"  (click)="goToGepro(note)" >
                    <div>
                        <div style="display: flex">
                            <div>
                                <span>{{note.status}} - {{rewriteTime(note.changed | date: 'short')}}</span></div>

                        </div>
                        <div style="display: flex">
                            <span style="color: #007ab3"><strong>{{note.geprotype}} , {{note.externgeproid}}
                                </strong></span>
                        </div>
                    </div>
                </td>
                <td >
                    <div style="display: flex">
                        <div [ngStyle]="{'display' : (note.gelesen=='false') ? 'flex' : 'none'}">
                            <button class="wrapper nx-margin-bottom-0 " nxIconButton="tertiary small"
                                    tabindex="0" type="button"
                                    (click)="setAlreadyRead(note)">
                                    <nx-icon name="password-hide-o" size="s"
                                             style="font-size: 20px; padding: 5px; "></nx-icon>
                            </button>

                        </div>
                        <div [ngStyle]="{'display' : (note.gelesen=='true') ? 'flex' : 'none'}">
                            <button class="wrapper nx-margin-bottom-0 " nxIconButton="tertiary small"
                                    tabindex="0" type="button"
                                    (click)="setNotReadRead(note)">
                                    <nx-icon name="password-show-o" size="s"
                                             style="font-size: 20px; padding: 5px; "></nx-icon>
                            </button>
                        </div>
                        <div >
                            <button class="wrapper nx-margin-bottom-0 " nxIconButton="tertiary small"
                                     tabindex="0" type="button"
                                    (click)="deleteNotification(note)">
                                    <nx-icon name="trash" size="s"
                                             style="font-size: 20px; padding: 5px; "></nx-icon>
                            </button>

                        </div>
                    </div>
                </td>
            </tr>
            </tbody>
        </table>
        </div>
        <!--        <button nxModalClose="Close" nxButton="small" type="button" class="nx-margin-top-xs nx-margin-bottom-2xs" cdkFocusInitial>Schließen</button>-->
    </div>

</ng-template>
<script>
    window.addEventListener('click', function (event) {
        if (!document.getElementById('notification').contains(event.target)) {
            this.hinweiseDialog.close();
        }
    });
</script>

export class FilterEntry {

    public displayName: string;
    public value: any;
    public count: number;

    constructor(displayName: string, value: any, count: number){
        this.displayName = displayName;
        this.value = value;
        this.count = count;
    }
}

export class EditableTableCell {

    public value: string;
    public editable: boolean = true;
    public creatable: boolean = true;
    public optional: boolean = false;
    public shouldBeStoredInUppercase: boolean = true;

    // weil Enums in Typescript nicht so toll sind
    public type_input: boolean = true;
    public type_dropdown: boolean = false;
    public type_checkbox: boolean = false;

    public getDropdownValues(): Array<string> {
        return null;
    }

    constructor(value: any, editable: boolean, creatable: boolean) {
        this.value = value;
        this.editable = editable;
        this.creatable = creatable;
    }
}

import {Component, forwardRef, Input, ViewChild} from '@angular/core';
import {AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR, ValidationErrors} from '@angular/forms';
import {NxInputDirective} from '@allianz/ngx-ndbx/input';

@Component({
    selector: 'app-restricted-text-area',
    templateUrl: './restricted-text-area.component.html',
    styleUrls: ['./restricted-text-area.component.css'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => RestrictedTextAreaComponent),
        multi: true
    }]
})
export class RestrictedTextAreaComponent implements ControlValueAccessor {

    @ViewChild('errorHint', { static: true })
    errorNgModel: NxInputDirective;

    @Input()
    required: boolean = false;
    @Input()
    readonly: boolean = false;
    @Input()
    disabled: boolean = false;
    @Input()
    maxLength: number = null;
    @Input()
    label: string;
    @Input()
    placeholder: string;
    @Input()
    minLines: number = 1;

    value: string;

    readonly pattern: RegExp = /^[\w\säöüÄÖÜß€$@&/?!,.:*+\-=()]*$/;
    readonly invalidPattern: RegExp = /[^\w\säöüÄÖÜß€$@&/?!,.:*+\-=()]/;

    onChange = (_) => {};
    onTouched = () => {};

    invalidChar(): string {
        const text = this.model || '';
        const pos = text.search(this.invalidPattern);
        return text.charAt(pos);
    }

    get model(): string {
        return this.value;
    }

    set model(value: string) {
        if (value !== this.value) {
            // SPARTA-1838 : bedingter Trennstrich
            const valueOhneTrennung = value.split('\u00AD').join('');
            if (!valueOhneTrennung.match(this.invalidPattern) &&
                valueOhneTrennung !== null && valueOhneTrennung !== '') {
                this.errorControl().setErrors(null);
            }
            this.value = valueOhneTrennung;
            this.onChange(valueOhneTrennung);
            if (this.value && this.value.length > 0) {
                this.errorControl().markAsTouched();
            }
        }
    }

    writeValue(value: any): void {
        this.value = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    contentLength(): number {
        const value = this.model || '';
        return value.length;
    }

    isEmpty(): boolean {
        return this.contentLength() === 0;
    }

    isInvalid(): boolean {
        return this.errorControl().invalid;
    }

    hasErrorPattern(): boolean {
        return this.errorControl().hasError('pattern');
    }

    hasErrorRequired(): boolean {
        return this.errorControl().hasError('required');
    }

    private errorControl(): AbstractControl | null {
        return this.errorNgModel.ngControl.control;
    }
}

import {AfterViewChecked, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {UserinfoService} from '../index/service/userinfo.service';

@Component({
    selector: 'app-my-profile',
    templateUrl: './my-profile.component.html',
    styleUrls: ['./my-profile.component.css']
})
export class MyProfileComponent implements OnInit, AfterViewChecked {

    public myProfileSelected: boolean = true;

    constructor(public userinfoService: UserinfoService,
                private changeDetectorRef: ChangeDetectorRef) {
    }

    ngOnInit() {
    }

    public tabChanged($event) {
        switch ($event.index) {
            case 0:
                this.myProfileSelected = true;
                break;
            case 1:
                this.myProfileSelected = false;
                break;
        }
    }

    ngAfterViewChecked(): void {
        this.changeDetectorRef.detectChanges();
    }

    public isReferatsleiter(): boolean {
        const user = this.userinfoService.getCurrentUserData();
        if (user && user.referatAsLeiter) {
            const referat = user.referatAsLeiter;
            return user.bensl === referat.referatsleiterBensl;
        }
        return false;
    }

    public maySetStellvertreter(): boolean {
       return this.userinfoService.isNotMakler();
    }
}

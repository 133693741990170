<div class="container" fxLayout="column" fxLayoutAlign="space-between stretch">
    <table *ngIf="(routes() | async) as rows" class="sized-table" nxTable nxSort (sortChange)="sortTable($event)">
        <thead>
            <tr nxTableRow>
                <th class="first-column"
                    nxHeaderCell
                    nxSortHeaderCell="{{ displayedColumns[0]?.key }}">
                    {{ displayedColumns[0]?.headerName }}
                </th>
                <th class="table-header-counter"
                    nxHeaderCell>
                    {{ displayedColumns[1]?.headerName }}
                    <span class="element-info">{{ totalElements }} insgesamt</span>
                </th>
            </tr>
        </thead>
        <tbody class="sized-table-body">
            <div class="loading-indicator" *ngIf="showLoadingIndicator">
                <nx-spinner nxSize="large"></nx-spinner>
            </div>
            <tr *ngFor="let row of rows" style="cursor: default" nxTableRow>
                <td *ngFor="let column of displayedColumns; let first = first"
                    nxTableCell
                    [ngClass]="{'first-column': first}">
                    {{ row[column.key] }}
                </td>
            </tr>
        </tbody>
    </table>
    <div fxLayout="row" fxLayoutAlign="center center">
        <nx-pagination
            *ngIf="showPagination"
            nxType="advanced"
            [nxPage]="currentPage"
            [nxPerPage]="elementsPerPage"
            [nxCount]="totalElements"
            (nxGoPrev)="previousPage()"
            (nxGoNext)="nextPage()"
            (nxGoPage)="goToPage($event)">
        </nx-pagination>
    </div>
</div>

import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {DenyGeproService} from './service/deny-gepro.service';
import {Subscription} from 'rxjs';
import {NxDialogService, NxModalRef} from '@allianz/ngx-ndbx/modal';

@Component({
    selector: 'app-close-gepro-forever',
    templateUrl: './close-gepro-forever.component.html',
    styleUrls: ['./close-gepro-forever.component.css']
})
export class CloseGeproForeverComponent implements OnInit, OnDestroy {

    private accumulatedSubscription: Subscription = new Subscription();


    @ViewChild('geproCloseForeverReasonTemplate') templateRef: TemplateRef<any>;
    templateDialogRef: NxModalRef<any>;

    constructor(
        private denyGeproService: DenyGeproService,
        public dialogService: NxDialogService) {
    }

    ngOnInit() {
       this.accumulatedSubscription.add( this.denyGeproService.subscribeToCloseForeverOpenedSubject({
           next: (request) => {
               this.templateDialogRef = this.dialogService.open(this.templateRef, {ariaLabel: 'Dieser Vorgang wird unwiderruflich abgeschlossen!', disableClose: true });
               this.templateDialogRef.afterClosed().subscribe(result => {
                   if (result === 'Abbrechen') {
                       this.closeDialogAndClearValues();
                   } else if (result === 'Weiter') {
                       this.confirmed();
                   }
                   this.templateDialogRef.close();
               });
           }
       }));
    }

    ngOnDestroy(): void {
        this.accumulatedSubscription.unsubscribe();
    }

    public closeDialogAndClearValues(): void {
    }

    public confirmed() {
        this.denyGeproService.nextForeverDialogOpened();
        this.closeDialogAndClearValues();
    }
}

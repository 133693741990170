import {Component, Input, OnInit, Output, ViewChild} from '@angular/core';
import {FilterEntry} from '../entity/filterEntry';
import {Subject} from 'rxjs';
import {NxDropdownComponent} from '@allianz/ngx-ndbx/dropdown';

@Component({
    selector: 'app-filter-dropdown',
    templateUrl: './filter-dropdown.component.html',
    styleUrls: ['./filter-dropdown.component.css']
})
export class FilterDropdownComponent implements OnInit {

    @Input()
    name: string;

    @Input()
    entries: Array<FilterEntry>;

    @Input()
    initialValues: Array<FilterEntry> = new Array<FilterEntry>();

    @Input()
    disabled: boolean = false;

    @Output()
    filterSelectionChanged: Subject<Array<FilterEntry>>;

    @Input()
    selectedFilters: Array<FilterEntry> = new Array<FilterEntry>();

    @ViewChild('filterDropdown', { static: true }) filterDropdown: NxDropdownComponent;

    constructor() {
        this.filterSelectionChanged = new Subject();
    }

    ngOnInit() {
        console.log();
    }

    onSelectionChanged() {
        this.filterSelectionChanged.next(this.selectedFilters);
    }

    public resetValue(){
        this.selectedFilters = this.initialValues.slice(0);
    }
}

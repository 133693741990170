import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {RejectModalComponent} from '../../common/modals/reject-modal/reject-modal.component';
import {EMPTY, Subscription} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {GeproService} from '../../index/service/gepro.service';

@Component({
    selector: 'app-gepro-reject',
    templateUrl: './gepro-reject.component.html',
    styleUrls: ['./gepro-reject.component.css']
})
export class GeproRejectComponent implements OnInit {

    @Input() geproId;

    @Output()
    refreshGepro = new EventEmitter();

    @ViewChild(RejectModalComponent, {read: RejectModalComponent, static: true}) rejectModal: RejectModalComponent;

    private rejectSubscription: Subscription;

    constructor(private geproService: GeproService) {
    }

    ngOnInit() {
        if (this.rejectModal.result()) {
            this.rejectSubscription = this.rejectModal.result().pipe(switchMap(value => {
                if (value.type === 'rejected') {
                    return this.geproService.rejectById(this.geproId, value.reason);
                }
                return EMPTY;
            })).subscribe(updatedGepro => {
                this.geproService.$geproDetailsObservable.next(updatedGepro);
                this.refreshGepro.emit();
            });
        }
    }

    ngOnDestroy(): void {
        if (this.rejectSubscription) {
            this.rejectSubscription.unsubscribe();
        }
    }


}

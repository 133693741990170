import {Injectable} from '@angular/core';
import {UserMessage} from '../entity/UserMessage';
import {Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserMessagesService {

    private _errorMessageSubject: Subject<UserMessage>;
    private _successMessageSubject: Subject<UserMessage>;
    private _infoMessageSubject: Subject<UserMessage>;
    private _successAreaClosedSubject: Subject<void>;
    private _errorAreaClosedSubject: Subject<void>;
    private _infoAreaClosedSubject: Subject<void>;

    constructor() {
        this._errorMessageSubject = new Subject();
        this._successMessageSubject = new Subject();
        this._infoMessageSubject = new Subject();
        this._successAreaClosedSubject = new Subject();
        this._errorAreaClosedSubject = new Subject();
        this._infoAreaClosedSubject = new Subject();
    }

    get errorMessageSubject(): Subject<UserMessage> {
        return this._errorMessageSubject;
    }

    get successMessageSubject(): Subject<UserMessage> {
        return this._successMessageSubject;
    }

    get infoMessageSubject(): Subject<UserMessage> {
        return this._infoMessageSubject;
    }

    get successAreaClosedSubject(): Subject<void> {
        return this._successAreaClosedSubject;
    }

    get errorAreaClosedSubject(): Subject<void> {
        return this._errorAreaClosedSubject;
    }

    get infoAreaClosedSubject(): Subject<void> {
        return this._infoAreaClosedSubject;
    }

    public addErrorMessage(error: UserMessage): void {
        this._errorMessageSubject.next(error);
    }

    public addSuccessMessage(success: UserMessage): void {
        this._successMessageSubject.next(success);
    }

    public addInfoMessage(success: UserMessage): void {
        this._infoMessageSubject.next(success);
    }

    public addErrorMessageString(error: string): void {
        this._errorMessageSubject.next(new UserMessage(error, true));
    }

    public addSuccessMessageString(success: string): void {
        this._successMessageSubject.next(new UserMessage(success, false));
    }

    public addInfoMessageString(info: string): void {
        this._infoMessageSubject.next(new UserMessage(info, false));
    }

}

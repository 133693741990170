import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

import {UserAuth} from './config/auth/user.auth';

import {AppPageNotFoundComponent} from './components/pagenotfound/app.pagenotfound.component';
import {WorkListComponent} from './components/work-list/work-list.component';
import {StartPageComponent} from './components/index/start_page/start-page.component';
import {LoginComponent} from './components/login/login.component';
import {Pages} from './components/navigation/pages';
import {GeproDetailComponent} from './components/gePro/gepro-detail/gepro-detail.component';
import {MyProfileComponent} from './components/my-profile/my-profile.component';
import {ShellComponent} from './components/create-gepro/shell/shell.component';
import {TypeComponent} from './components/create-gepro/type/type.component';
import {BrokerComponent} from './components/create-gepro/broker/broker.component';
import {DocumentsComponent} from './components/create-gepro/documents/documents.component';
import {OverviewComponent} from './components/create-gepro/overview/overview.component';
import {CanDeactivateShellGuard} from './config/activators/CanDeactivateShell.guard';
import {CreateGeproLoadingComponent} from './components/create-gepro/create-gepro-loading/create-gepro-loading.component';
import {LoginActivationGuard} from './guards/login-activation.guard';
import {LogoutComponent} from './components/logout/logout.component';
import {AppWeiterleitenComponent} from "./components/weiterleiten/app.weiterleiten.component";

const routes: Routes = [
    {path: Pages.PATH_LOGIN, component: LoginComponent, canActivate: [LoginActivationGuard]},
    {path: Pages.PATH_LOGOUT, component: LogoutComponent},
    {path: Pages.PATH_NOT_IMPLEMENTED, component: AppPageNotFoundComponent, canActivate: [UserAuth]},
    {path: Pages.PATH_INDEX, component: StartPageComponent, canActivate: [UserAuth]},
    {path: Pages.PATH_WORKLIST, component: WorkListComponent, canActivate: [UserAuth]},
    {path: Pages.PATH_NOT_FOUND, component: AppPageNotFoundComponent, canActivate: [UserAuth]},
    {path: Pages.PATH_LINKED, component: AppWeiterleitenComponent, canActivate: [UserAuth]},
    {
        path: Pages.PATH_CREATE_GEPRO,
        component: ShellComponent,
        canActivate: [UserAuth],
        canDeactivate: [CanDeactivateShellGuard],
        runGuardsAndResolvers: 'always',
        children: [
            {path: '', redirectTo: 'type', pathMatch: 'full'},
            {path: 'type', component: CreateGeproLoadingComponent},
            {path: 'type/:geproId', component: TypeComponent},
            {path: 'broker/:geproId', component: BrokerComponent},
            {path: 'documents/:geproId', component: DocumentsComponent},
            {path: 'overview/:geproId', component: OverviewComponent},
        ]
    },
    {
        path: Pages.PATH_ADMINISTRATION,
        loadChildren: () => import('./components/admin/admin.module').then(m => m.AdminModule),
        canActivate: [UserAuth]
    },
    {
        path: Pages.PATH_ANWENDUNGSINFO,
        loadChildren: () => import('./components/anwendungsinfo/anwendungsinfo.module').then(m => m.AnwendungsinfoModule),
        canActivate: [UserAuth]
    },
    {path: Pages.PATH_GEPRO_DETAILS + '/:geproId', component: GeproDetailComponent, canActivate: [UserAuth]},
    {path: Pages.PATH_MY_PROFILE, component: MyProfileComponent, canActivate: [UserAuth]},
    {path: '', redirectTo: '/' + Pages.PATH_LOGIN, pathMatch: 'full'}
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        preloadingStrategy: PreloadAllModules
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}

import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {EditableTableRow} from '../prototypes/EditableTableRow';

@Injectable({
    providedIn: 'root'
})
export class EditableTableNewEntryService {

    constructor() {
    }

    private _newEntrySubject = new Subject<EditableTableRow>();
    private _newEntryDialogOpenedSubject = new Subject<void>();

    public newEntryCreated(newItem: EditableTableRow): void {
        this._newEntrySubject.next(newItem);
    }

    get newEntrySubject(): Subject<EditableTableRow> {
        return this._newEntrySubject;
    }

    public newNameRoutingDialogOpened(): void {
        this._newEntryDialogOpenedSubject.next();
    }

    get newEntryDialogOpenedSubject(): Subject<void> {
        return this._newEntryDialogOpenedSubject;
    }
}

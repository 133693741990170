import {Injectable} from '@angular/core';
import {Observable, of, Subject, Subscription} from 'rxjs';
import {DocumentModel} from '../document.model';
import {FileService} from '../../../../../index/service/file.service';
import {catchError, map, switchMap} from 'rxjs/operators';
import {DocumentUploadValidatorService} from './document-upload-validator.service';

export interface DocumentIterator {
    hasNext(): boolean;
    next(): DocumentModel;
}

function isString(value) {
    return typeof value === 'string' || value instanceof String;
}

const ERROR_GENERIC = 'Datei konnte nicht hochgeladen werden.';

@Injectable()
export class DocumentUploadService {

    private readonly documentProcessed$: Subject<void>;
    private readonly upload$: Subject<DocumentModel>;
    private readonly uploadSub: Subscription;

    private iterator: DocumentIterator;
    private isBusy: boolean;

    constructor(private readonly fileService: FileService,
                private readonly documentValidator: DocumentUploadValidatorService) {
        this.documentProcessed$ = new Subject();
        this.upload$ = new Subject();
        this.uploadSub = this.upload$.pipe(
            map(document => documentValidator.validate(document)),
            switchMap(document => {
                if (document && document.status === 'UPLOADING') {
                    return this.fileService.uploadFile(document.vorgangId, document.file).pipe(
                        map(result => {
                            document.status = 'SUCCESS';
                            document.uploadedFile = result;
                            return of(document);
                        }),
                        catchError(e => {
                            document.status = 'FAILURE';
                            document.errorMessage = isString(e.error) ? e.error : ERROR_GENERIC;
                            return of(document);
                        })
                    );
                }
                return of(undefined);
            })
        ).subscribe(_ => {
            this.isBusy = false;
            this.documentProcessed$.next();
            this.start();
        });
    }

    ngOnDestroy(): void {
        this.uploadSub.unsubscribe();
    }

    setDocumentIterator(documentIterator: DocumentIterator): void {
        this.iterator = documentIterator;
    }

    private documentIterator(): DocumentIterator {
        return this.iterator || {
            hasNext(): boolean {
                return false;
            },
            next(): DocumentModel {
                return undefined;
            }
        };
    }

    documentProcessed(): Observable<void> {
        return this.documentProcessed$.asObservable();
    }

    start(): void {
        if (!this.isBusy && this.documentIterator().hasNext()) {
            const document = this.documentIterator().next();
            if (document) {
                this.isBusy = true;
                this.upload$.next(document);
            }
        }
    }

    isRunning(): boolean {
        return this.isBusy;
    }
}

import {
    ApplicationRef,
    Component,
    ComponentFactoryResolver,
    ComponentRef,
    ElementRef,
    EmbeddedViewRef,
    EventEmitter,
    Injector,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild
} from '@angular/core';
import {UploadButtonComponent} from '../upload-button/upload-button.component';
import {FileService} from '../../index/service/file.service';
import {HttpEventType, HttpResponse} from '@angular/common/http';
import {Subscription} from 'rxjs';
import {GeproFile} from '../../../interfaces/gepro.interfaces';

@Component({
    selector: 'app-gepro-upload-component',
    templateUrl: './gepro-upload.component.html',
    styleUrls: ['./gepro-upload.component.css']
})
export class GeproUploadComponent implements OnInit, OnDestroy {

    @Input()
    public isDisabled: boolean = false;

    @Output()
    public uploadSuccessful: EventEmitter<boolean> = new EventEmitter<boolean>();

    @ViewChild('fileUpload', {read: ElementRef, static: true}) fileUpload;


    public uploadFieldRefs: Array<ComponentRef<UploadButtonComponent>> = new Array<ComponentRef<UploadButtonComponent>>();

    private readonly subscription: Subscription = new Subscription();

    constructor(private resolver: ComponentFactoryResolver,
                private injector: Injector,
                private renderer: Renderer2,
                private appRef: ApplicationRef,
                private fileService: FileService) {
    }

    ngOnInit() {
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public showExisting(files: Array<GeproFile>): void {
        if (files) {
            files.forEach(file => this.createNewUploadInput().instance.setExisting(file));
        }
    }

    public createNewUploadInput(): ComponentRef<UploadButtonComponent> {
        const componentFactory = this.resolver.resolveComponentFactory(UploadButtonComponent);
        const componentRef = componentFactory.create(this.injector);
        componentRef.instance.ownComponentRef = componentRef;
        this.subscription.add(componentRef.instance.fileUploadRemoved.subscribe(event => {
            this.uploadFieldRefs.splice(this.uploadFieldRefs.indexOf(componentRef), 1);
            componentRef.destroy();
        }));
        this.subscription.add(componentRef.instance.multipleFilesSelected.subscribe(event => {
            var filesSelected: File[];
            filesSelected = componentRef.instance.filesSelected;

            filesSelected.forEach(selectedFile => {
                var newComponentRef: ComponentRef<UploadButtonComponent> = this.createNewUploadInput();
                newComponentRef.instance.setFileInUI(selectedFile);
            });

        }));
        this.appRef.attachView(componentRef.hostView);

        this.uploadFieldRefs.push(componentRef);

        const domElem = (componentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
        const mLi = this.renderer.createElement('div');
        this.renderer.appendChild(mLi, domElem);
        this.renderer.appendChild(this.fileUpload.nativeElement, mLi);

        return componentRef;
    }

    public clearFields() {
        this.uploadFieldRefs.forEach(componentRef => {
            componentRef.destroy();
        });
        this.uploadFieldRefs = new Array<ComponentRef<UploadButtonComponent>>();
    }

    public isLastUploadFieldFilled(): boolean {
        let isUploadFieldExisting = this.uploadFieldRefs.length == 0;
        return isUploadFieldExisting || (this.uploadFieldRefs.length > 0 && this.uploadFieldRefs[this.uploadFieldRefs.length - 1].instance.fileToUpload != null);
    }

    public hasFilesToUpload(): boolean {
        let hasFiles = false;
        this.uploadFieldRefs.forEach(componentRef => {
            if (componentRef.instance.fileToUpload && !componentRef.instance.statusFinished) {
                hasFiles = true;
            }
        });
        return hasFiles;
    }

    public checkUploads(): boolean {
        let areFileUploadsOk: boolean = true;

        this.uploadFieldRefs.forEach(componentRef => {
            areFileUploadsOk = areFileUploadsOk && !componentRef.instance.fileTooLarge;
        });

        let isAtLeastOneFileSelected: boolean = false;
        this.uploadFieldRefs.forEach(componentRef => {
            if (componentRef.instance.fileToUpload != null) {
                isAtLeastOneFileSelected = true;
            }
        });
        areFileUploadsOk = areFileUploadsOk && isAtLeastOneFileSelected;

        return areFileUploadsOk;
    }

    public onGeproSubmitted(){
        this.uploadFieldRefs.forEach(componentRef => {
            if (!componentRef.instance.statusFinished) {
                componentRef.instance.statusWaiting = true;
            }
        });
    }

    public startUpload(geproId: string){
        const firstRef = this.uploadFieldRefs.find(value => !value.instance.statusFinished && value.instance.fileToUpload != null);
        const uploadIndex = firstRef ? this.uploadFieldRefs.indexOf(firstRef) | 0 : this.uploadFieldRefs.length;
        this.uploadNextFile(geproId, uploadIndex);
    }

    private uploadNextFile(geproId: string, uploadIndex: number) {
        if (uploadIndex > 0) {
            this.uploadFieldRefs[uploadIndex - 1].instance.statusProcessing = false;
            this.uploadFieldRefs[uploadIndex - 1].instance.statusFinished = true;
        }
        if (uploadIndex < this.uploadFieldRefs.length) {
            this.uploadFieldRefs[uploadIndex].instance.statusWaiting = false;
            this.uploadFieldRefs[uploadIndex].instance.statusUploading = true;
        }

        if (uploadIndex < this.uploadFieldRefs.length) {
            if (this.uploadFieldRefs[uploadIndex].instance.fileToUpload != null) {
                this.subscription.add(this.fileService.postFile(geproId, this.uploadFieldRefs[uploadIndex].instance.fileToUpload, true).subscribe(
                    event => {// Via this API, you get access to the raw event stream.
                        // Look for upload progress events.
                        if (event.type === HttpEventType.UploadProgress.valueOf()) {
                            // This is an upload progress event.
                            this.uploadFieldRefs[uploadIndex].instance.uploadProgress = event.loaded / event.total;

                            if (event.loaded / event.total == 1) {
                                this.uploadFieldRefs[uploadIndex].instance.statusUploading = false;
                                this.uploadFieldRefs[uploadIndex].instance.statusProcessing = true;
                            }

                        } else if (event instanceof HttpResponse) {
                            this.uploadFieldRefs[uploadIndex].instance.uploadProgress = 1;
                            this.uploadNextFile(geproId, uploadIndex + 1);
                        }
                    },
                    error => this.onUploadFailure(uploadIndex, error)));
            } else {
                this.uploadNextFile(geproId, uploadIndex + 1);
            }
        } else {
            this.onSuccess();
        }
    }

    private onSuccess(): void {
        this.uploadSuccessful.emit(true);
    }

    private onUploadFailure(uploadIndex: number, error: any): void {
        console.log(error);
        this.uploadFieldRefs[uploadIndex].instance.fileError = true;
        this.uploadSuccessful.emit(false);
    };
}

import {Injectable} from '@angular/core';
import {HttpHeaders, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';
import {Endpoints} from '../../navigation/endpoints';
import {DefaultHttpService} from '../../../services/default-http.service';
import {GeproFilterStatisticsInterface} from './Model/GeproFilterStatisticsRestTO';
import {HEADER_NAME_RELATIONSHIP} from '../../../config/constants';


@Injectable({
    providedIn: 'root'
})
export class GeproFilterStatisticsService {

    constructor(private http: DefaultHttpService) {
    }

    public queryGeproStatisticsForFilters(httpParams: HttpParams = null, relationship: string = null): Observable<any> {
        let query = '';
        if (httpParams && httpParams.keys().length > 0) {
            const filters = httpParams.keys().map(key => `${key}:${httpParams.get(key)}`).join(' AND ');
            query = `&query=${filters}`;
        }

        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type':  'application/json'
            })
        };

        if (relationship != null) {
            httpOptions.headers = httpOptions.headers.set(HEADER_NAME_RELATIONSHIP, relationship);
        }

        return this.http.get<GeproFilterStatisticsInterface>(`${Endpoints.GEPROS_FILTERSTATISTICS}?${query}`, httpOptions);
    }
}

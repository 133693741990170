export class EditableTableHeader {

    public displayValue: string;
    public sortValue: string;

    constructor(displayValue: string, sortValue: string) {
        this.displayValue = displayValue;
        this.sortValue = sortValue;
    }
}

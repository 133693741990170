import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Endpoints} from '../../navigation/endpoints';
import {map} from 'rxjs/operators';


@Injectable()
export class DocumentService {

    constructor(
        public http: HttpClient
    ) {
    }

    public queryDocumentList(): Observable<any> {
        return this.http.get(Endpoints.GET_ALL_DOCUMENTS);
    }

    public getDocument(documentId: number): Observable<any> {
        const endpoint = Endpoints.DOCUMENTS;

        let headers = new HttpHeaders({'Content-Type': 'application/pdf'});
        let params = new HttpParams();
        params = params.append('documentId', documentId + '');


        return this.http.get(endpoint, {observe: 'response', headers: headers, params: params, responseType: 'blob'})
            .pipe(map((res) => {
                let data = {
                    file: new Blob([res.body], {type: res.headers.get('Content-Type')}),
                    filename: res.headers.get('File-Name')
                };
                return data;
            }));
    }

    public updateDocument(documentId: number, fileToUpload: File): Observable<any> {
        const endpoint = Endpoints.DOCUMENTS;
        const formData: FormData = new FormData();
        formData.append('documentId', documentId + '');
        formData.append('document', fileToUpload, fileToUpload.name);

        return this.http.post(endpoint, formData, {reportProgress: true, observe: 'events'});
    }

    public deleteDocument(documentId: number): Observable<any> {
        const endpoint = Endpoints.DOCUMENTS;
        let headers = new HttpHeaders( {'Content-Type': 'application/json'});
        let params = new HttpParams();
        params = params.append('documentId', documentId + '');

        return this.http.delete(endpoint, { headers: headers, params: params});
    }
}

import {Injectable, EventEmitter} from '@angular/core';
import {IEvent} from "../interfaces";

@Injectable()
export class SidebarNavEvent implements IEvent {

  sidebarNavEventEmit:EventEmitter<boolean> = new EventEmitter();

  constructor() {
  }

  fire(data:boolean):void {
    console.log('SidebarNavEvent fired whith value: ' + data);
    this.sidebarNavEventEmit.next(data);
  }
}

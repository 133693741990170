import {Component, Input, Output} from '@angular/core';
import {GeproFile} from '../../../../interfaces/gepro.interfaces';
import {SortDirection, SortEvent} from '@allianz/ngx-ndbx/table';
import {Subject} from 'rxjs';
import {Label} from '../../../common/validated-input-field/mediator/label';

function isString(value) {
    return typeof value === 'string' || value instanceof String;
}

const compare = (
    a: number | string | Date,
    b: number | string | Date,
    direction: SortDirection) => {
    a = isString(a) ? (a as string).toLowerCase() : a;
    b = isString(b) ? (b as string).toLowerCase() : b;
    return (a < b ? -1 : 1) * (direction === 'asc' ? 1 : -1);
};

@Component({
    selector: 'app-document-list',
    templateUrl: 'document-list.component.html',
    styleUrls: ['document-list.component.scss']
})
export class DocumentListComponent {

    @Input()
    documents: Array<GeproFile>;
    @Output()
    readonly documentDownload: Subject<GeproFile>;

    constructor() {
        this.documentDownload = new Subject();
    }

    documentsPresent(): boolean {
        const documents = this.documents || [];
        return documents.length > 0;
    }

    sortTable(sort: SortEvent) {
        const byEvent = (a, b) => {
            if (sort.active in a && sort.active in b) {
                return compare(a[sort.active], b[sort.active], sort.direction);
            }
            return 0;
        };
        this.documents = this.documents.sort(byEvent);
    }

    download(document: GeproFile): void {
        this.documentDownload.next(document);
    }

    creatorOf(document: GeproFile): string {
        if (document) {
            return Label.of({
                bensl: document.createdBy,
                fullName: document.fullName
            });
        }
        return '';
    }



}

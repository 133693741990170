import {NgModule} from '@angular/core';
// Imports Angular modules
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
// Imports Angular Materials modules
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatDialogModule} from '@angular/material/dialog';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatListModule} from '@angular/material/list';
import {MatMenuModule} from '@angular/material/menu';
import {MatNativeDateModule} from '@angular/material/core';
import {MatSelectModule} from '@angular/material/select';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {BrowserUtil} from './browser/browser.util';
import {AutosizeDirective} from './textarea-autosize/textarea-autosize.directive';

const ANGULAR_MODULES: any[] = [
    CommonModule, FormsModule, ReactiveFormsModule, HttpClientModule
];

const MATERIAL_MODULES: any[] = [
    MatButtonModule, MatCardModule, MatIconModule,
    MatListModule, MatMenuModule, MatTooltipModule,
    MatSlideToggleModule, MatInputModule, MatCheckboxModule,
    MatToolbarModule, MatSnackBarModule, MatSidenavModule,
    MatTabsModule, MatSelectModule, MatGridListModule, MatDialogModule,
    MatDatepickerModule, MatNativeDateModule
];

@NgModule({
    imports: [
        ANGULAR_MODULES,
        MATERIAL_MODULES,
    ],
    providers: [
        BrowserUtil
    ],
    declarations: [AutosizeDirective],
    exports: [
        ANGULAR_MODULES,
        MATERIAL_MODULES,
        AutosizeDirective
    ]
})
export class SharedModule {
}

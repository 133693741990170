import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MitarbeiterWorklistComponent} from './mitarbeiter-worklist/mitarbeiter-worklist.component';
import {MoveGeproReferatComponent} from './move-gepro-referat/move-gepro-referat.component';
import {NdbxDependenciesModule} from '../../ndbx-dependencies/ndbx-dependencies.module';
import {WorkListTabsComponent} from './work-list-tabs/work-list-tabs.component';
import {NxCopytextModule} from '@allianz/ngx-ndbx/copytext';
import {NxHeadlineModule} from '@allianz/ngx-ndbx/headline';
import {NxPaginationModule} from '@allianz/ngx-ndbx/pagination';
import {NxRadioToggleModule} from '@allianz/ngx-ndbx/radio-toggle';
import {NxTabsModule} from '@allianz/ngx-ndbx/tabs';
import {WorkListDropzoneComponent} from './work-list-dropzone/work-list-dropzone.component';
import {WorkListEmployeesComponent} from './work-list-employees/work-list-employees.component';
import {WorkListReferateComponent} from './work-list-referate/work-list-referate.component';
import {WorkListDragStartDirective} from './work-list-drag-start.directive';
import {DndSidebarComponent} from './dnd-sidebar/dnd-sidebar.component';
import {WorkListContextmenuCellComponent} from './work-list-contextmenu-cell/work-list-contextmenu-cell.component';
import {NxContextMenuModule} from '@allianz/ngx-ndbx/context-menu';
import {DiassCommonsModule} from '../common/diass-commons.module';
import {TimestampModule} from '../common/timestamp/timestamp.module';
import {CreateGeproModule} from '../create-gepro/create-gepro.module';
import {VorgangSearchComponent} from './vorgang-search/vorgang-search.component';
import {NxMaskModule} from '@allianz/ngx-ndbx/mask';
import {FlexModule} from '@angular/flex-layout';

@NgModule({
    declarations: [
        MitarbeiterWorklistComponent,
        MoveGeproReferatComponent,
        WorkListTabsComponent,
        WorkListDropzoneComponent,
        WorkListEmployeesComponent,
        WorkListReferateComponent,
        WorkListDragStartDirective,
        DndSidebarComponent,
        WorkListContextmenuCellComponent,
        VorgangSearchComponent
    ],
    imports: [
        CommonModule,
        NdbxDependenciesModule,
        DiassCommonsModule,
        NxTabsModule,
        NxPaginationModule,
        NxRadioToggleModule,
        NxContextMenuModule,
        TimestampModule,
        CreateGeproModule,
        NxHeadlineModule,
        NxMaskModule,
        FlexModule,
        NxCopytextModule
    ],
    exports: [
        MitarbeiterWorklistComponent,
        MoveGeproReferatComponent,
        WorkListTabsComponent,
        WorkListDropzoneComponent,
        WorkListEmployeesComponent,
        WorkListReferateComponent,
        WorkListDragStartDirective,
        DndSidebarComponent,
        WorkListContextmenuCellComponent,
        VorgangSearchComponent
    ]
})
export class WorkListModule {
}

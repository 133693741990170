<h2>Login</h2>
<br>
<div *ngIf="!localLogin" class="error">
    <h2>Sie werden zur Login-Seite weitergeleitet</h2>
    <h5>Falls die Weiterleitung nicht funktioniert, klicken Sie bitte <nx-link nxStyle="text">
        <a routerLink='{{getForwardPage()}}'>hier</a></nx-link></h5>
</div>

<div *ngIf="localLogin">
<div *ngIf="loginService.loading" class="loading">

    <nx-spinner nxSize="large"></nx-spinner>
    <p>
        Loading ...
    </p>
</div>
<div *ngIf="!loginService.loading && !loginService.error">
    <form class="form-example" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
        <div>
            <div>
                <nx-formfield id="username" nxLabel="Benutzer wählen">
                    <nx-dropdown id="usernameselection" formControlName="username"
                                 (selectionChange)="onSelectionChanged($event)"
                                 [nxShowFilter]="true"
                                 [nxFilterPlaceholder]="'Benutzer suchen...'">
                        <nx-dropdown-item *ngFor="let user of userList" [nxValue]="user"> {{user}}
                        </nx-dropdown-item>
                    </nx-dropdown>
                    <nx-error nxFormfieldError>
                        Bitte Benutzer auswählen.
                    </nx-error>
                </nx-formfield>
            </div>

            <div>
                <nx-formfield id="password" nxLabel="Password">
                    <input nxInput formControlName="password" type="password"/>
                    <nx-error nxFormfieldError>
                        Passwort bitte angeben!
                    </nx-error>
                </nx-formfield>
            </div>
            <nx-error appearance="text" *ngIf="loginError">
                Login fehlgeschlagen.
            </nx-error>
            <div>
                <button id="submitBt" nxButton [disabled]="submitted">Login</button>
            </div>
        </div>
    </form>
</div>

<div *ngIf="!loginService.loading && loginService.error" class="error">
    <h2>Sie haben keine Berechtigung für die Anwendung DIASS!</h2>
    <br>
    <h3>Kritischer Fehler bei der Anmeldung:</h3>
    <p>[{{loginService.error.status}}]  {{loginService.error.message}}</p>
    <p>{{loginService.error.error | json}}</p>
</div>
</div>

import {Component, Injectable, Input, OnInit, Output, Pipe, PipeTransform} from '@angular/core';
import {NgbDate, NgbDatepickerI18n, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {DatePipe} from '@angular/common';
import {Subject} from 'rxjs';
import * as moment from 'moment';
import {Moment} from 'moment';

const after = (one: NgbDateStruct, two: NgbDateStruct) =>
    !one || !two ? false : one.year === two.year ? one.month === two.month ? one.day === two.day
        ? false : one.day > two.day : one.month > two.month : one.year > two.year;

const I18N_VALUES = {
    'de': {
        weekdays: ['Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa', 'So'],
        months: ['Jan', 'Feb', 'Mär', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez'],
    }
};

// Define a service holding the language. You probably already have one if your app is i18ned. Or you could also
// use the Angular LOCALE_ID value
@Injectable()
export class I18n {
    language = 'de';
}

// Define custom service providing the months and weekdays translations
@Injectable()
export class CustomDatepickerI18n extends NgbDatepickerI18n {

    constructor(private _i18n: I18n) {
        super();
    }

    getWeekdayShortName(weekday: number): string {
        return I18N_VALUES[this._i18n.language].weekdays[weekday - 1];
    }
    getMonthShortName(month: number): string {
        return I18N_VALUES[this._i18n.language].months[month - 1];
    }
    getMonthFullName(month: number): string {
        return this.getMonthShortName(month);
    }

    getDayAriaLabel(date: NgbDateStruct): string {
        return `${date.day}.${date.month}.${date.year}`;
    }
}

@Pipe({
    name: 'dateFormat'
})
export class NgbDateFormatPipe extends DatePipe implements PipeTransform {
    dateFormat: string = 'dd.MM.yyyy';

    transform(value: NgbDate, args?: any): any {
        let date:Date = new Date();
        date.setFullYear(value.year, value.month -1, value.day);
        return super.transform(date, this.dateFormat);
    }
}

@Component({
    selector: 'app-filter-date',
    templateUrl: 'filter-date.component.html',
    styleUrls: ['filter-date.component.scss'],
    providers: [
        I18n,
        { provide: NgbDatepickerI18n, useClass: CustomDatepickerI18n },
        { provide: NgbDateFormatPipe, useClass: NgbDateFormatPipe }
    ]
})
export class FilterDateComponent implements OnInit {

    @Input() title: string = '';
    @Input() placeholder: string = '';
    @Input() filterId: string = '';
    @Input() minDate: any;
    @Input() maxDate: any;
    @Input() disabled: boolean = false;
    @Input() setFromDate: Date;
    @Input() setToDate: Date;

    @Output()
    dateRangeChanged: Subject<Date[]>;

    dateFromTo: any;

    hoveredDate: NgbDate;
    fromDate: NgbDate;
    toDate: NgbDate;
    fromDateMoment: Moment;
    toDateMoment: Moment;
    toggleDatepicker: boolean;

    constructor(private ngbDateFormatPipe: NgbDateFormatPipe) {
        this.dateRangeChanged = new Subject();
    }

    ngOnInit(): void {
        this.onDateSelection(this.dateToNgbDate(this.setFromDate));
        this.onDateSelection(this.dateToNgbDate(this.setToDate));
    }

    /**
     *  Add to inquiryOverview requestProxy and generate a visible Filter-Tag
     */
    updateFilter(fromNgbDate: NgbDate, toNgbDate: NgbDate) {
        const fromDate = new Date(fromNgbDate.year, fromNgbDate.month - 1, fromNgbDate.day);
        const toDate = new Date(toNgbDate.year, toNgbDate.month - 1, toNgbDate.day);
        this.dateRangeChanged.next([fromDate, toDate]);
        this.toggleDatepicker = false;
    }


    onDateSelection(date: NgbDate) {
        if (!this.fromDate && !this.toDate) {
            this.fromDate = date;
            this.fromDateMoment = moment(date);
        } else if (this.fromDate && !this.toDate && after(date, this.fromDate)) {
            this.toDate = date;
            this.toDateMoment = moment(date);
        } else {
            this.toDate = null;
            this.toDateMoment = null;
            this.fromDate = date;
            this.fromDateMoment = moment(date);
        }

        const fromDateStr: string = (this.fromDate) ? this.ngbDateFormatPipe.transform(this.fromDate) : '';
        const toDateStr: string = (this.toDate) ? this.ngbDateFormatPipe.transform(this.toDate) : '';

        this.dateFromTo = (toDateStr !== '') ? fromDateStr + ' - ' + toDateStr : fromDateStr;
        if (toDateStr !== '' && fromDateStr !== '') {
            this.updateFilter(this.fromDate, this.toDate);
        }
    }

    public resetValues(){
        this.fromDate = null;
        this.toDate = null;
        this.dateFromTo = null;

        this.toggleDatepicker = false;
    }

    isHovered = (date: NgbDate) => this.fromDate && !this.toDate && this.hoveredDate && date.after(this.fromDate) && date.before(this.hoveredDate);
    isInside = (date: NgbDate) => date.after(this.fromDate) && date.before(this.toDate);
    isRange = (date: NgbDate) => date.equals(this.fromDate) || date.equals(this.toDate) || this.isInside(date) || this.isHovered(date)
    isNotSelectable = (date: NgbDate) => date.before(this.minDate) || date.after(this.maxDate);


    dateToNgbDate(date: Date): NgbDate {
        if (date) {
            const ngbDate: NgbDate = new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
            return ngbDate;
        }
        return null;
    }
}

import {Component, EventEmitter, Input, Output, TemplateRef, ViewChild} from '@angular/core';
import {GeproRestTO} from '../../index/service/Model/GeproRestTO';
import {NxDialogService, NxModalRef} from '@allianz/ngx-ndbx/modal';
import {UserinfoService} from '../../index/service/userinfo.service';

@Component({
    selector: 'app-abort-modal',
    templateUrl: './abort-modal.component.html',
    styleUrls: ['./abort-modal.component.scss']
})
export class AbortModalComponent {

    @Input()
    gepro: GeproRestTO;

    @Input()
    titles: AbortModalTitles = {
        abortButtonText: 'Als Entwurf speichern',
        confirmButtonText: 'Vorgang löschen',
        textHeadline1: 'Möchten Sie die Erfassung abbrechen und',
        textHeadline2: 'den aktuellen Vorgang löschen?',
    };

    @Output()
    confirmed = new EventEmitter<string>();

    @Output()
    aborted = new EventEmitter<string>();

    @ViewChild('saveDraftModalBody') templateRef: TemplateRef<any>;
    saveDraftModalBody: NxModalRef<any>;

    workAsStv: boolean = false;

    redirectTo: string = '';
    modalOpen: boolean = false;


    constructor(public dialogService: NxDialogService,
          private readonly userInfoService: UserinfoService) {
        this.workAsStv = userInfoService.isCurrentUserStvUser();
    }


    keep() {
        this.aborted.emit(this.redirectTo);
        this.redirectTo = '';
    }

    delete() {
        this.confirmed.emit(this.redirectTo);
        this.redirectTo = '';
    }

    show() {
        this.saveDraftModalBody = this.dialogService.open(this.templateRef, { ariaLabel: 'Entwurf löschen oder beibehalten', disableClose: true  });
        this.modalOpen = true;
        this.saveDraftModalBody.afterClosed().subscribe(result => {
            if (result === 'Keep') {
                this.keep();
            } else if (result === 'Delete') {
                this.delete();
            }
            this.saveDraftModalBody.close();
        });
    }

    close() {
        this.saveDraftModalBody.close();
        this.modalOpen = false;
    }
}

export interface AbortModalTitles {
    textHeadline1: string;
    textHeadline2?: string;
    textSubheadline?: string;
    confirmButtonText: string;
    abortButtonText: string;
}

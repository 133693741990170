import {Component, OnInit} from '@angular/core';
import {CreateGeproService} from '../../../services/create-gepro.service';

@Component({
    selector: 'app-crea-gepro-loading',
    templateUrl: './create-gepro-loading.component.html',
    styleUrls: ['./create-gepro-loading.component.css']
})
export class CreateGeproLoadingComponent implements OnInit {

    constructor(public createGeproService: CreateGeproService) {
    }

    ngOnInit() {
        this.createGeproService.resetGeproObservables();
    }

}

import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {Endpoints} from '../../navigation/endpoints';
import {DefaultHttpService} from '../../../services/default-http.service';
import {UserinfoService} from './userinfo.service';
import {ReferateService} from './referate.service';
import {ModalSelection} from '../../common/modals/reject-modal/reject-modal.component';
import {take} from 'rxjs/operators';


@Injectable()
export class WorkListMitarbeiterService {

    private readonly list$: Subject<any> = new Subject<any>();

    constructor(private userinfoService: UserinfoService,
                private referateService: ReferateService) {
    }

    getMitarbeiterListObservable(): Observable<any> {
        return this.list$.asObservable();
    }

    updateMitarbeiterList() {
        if (this.userinfoService.isRoleReferatsleiter()) {
            this.referateService.getReferateList().pipe(take(1)).subscribe(
                data => {
                    let result = [];
                    const referatname = this.userinfoService.getCurrentUserData().referatAsLeiter.name;
                    data.forEach(item => {
                        if (item.referat.name === referatname) {
                            result = item.mitarbeiter;
                        }
                    });
                    this.list$.next(result);

                }
            );
        }
    }

}

<app-labeled-container label="Vermittler" *ngIf="(routes() | async) as names" [buttonTemplate]="buttonTemplate">
    <div *ngIf="names.length > 0 else msnView">
        <h4 id="maklerName" *ngFor="let name of names"
            nxHeadline="subsection-xsmall"
            class="nx-margin-bottom-0">
            {{ name.value }}
        </h4>
    </div>
    <ng-template #msnView>
        <h4 *ngIf="msnTable.isEmpty() && vtnrTable.isEmpty()"
            nxHeadline="subsection-xsmall"
            class="u-text-center nx-margin-bottom-0">
            Momentan sind keine Vermittler ausgewählt.
        </h4>
        <div [ngClass]="{'hidden': msnTable.isEmpty() && vtnrTable.isEmpty() }">

                <div class="vermittler" fxLayout="row" fxLayoutAlign="space-between stretch">
                    <app-type-table #msnTable
                                    id="smallMsnTable"
                                    class="growing-item"
                                    filter="MSN"
                                    [displayedColumns]="msnColumns"
                                    [geproId]="geproId"></app-type-table>
                    <div class="spacer"></div>
                    <app-type-table #vtnrTable
                                    id="smallVtnrTable"
                                    class="growing-item"
                                    filter="VTNR"
                                    [displayedColumns]="vtnrColumns"
                                    [geproId]="geproId"></app-type-table>
                </div>
                <div>
                   <button (click)="showSelectedMaklerModal()" [ngClass]="{'hidden':
                              !(msnTable?.hasMoreElements() | async)
                           && !(vtnrTable?.hasMoreElements() | async)
                        }" class="nx-margin-top-s nx-margin-bottom-0 edit-exception js-modal"
                           nxButton="tertiary small"
                           type="button">
                       Mehr anzeigen
                   </button>
                </div>
        </div>

        <ng-template #selectedMaklerModal>
            <div class="modal-content" nxModalContent>
                <h3 class="u-text-align-center" nxHeadline="subsection-medium">
                    MSN / VTNR - Auswahl
                </h3>

                <div>
                <div class="vermittler_modal" fxLayout="row" fxLayoutAlign="space-between stretch">
                    <app-type-table [displayedColumns]="msnColumns"
                                    [geproId]="geproId"
                                    class="growing-item"
                                    filter="MSN"
                                    id="elephantMsnTable"
                                    showPagination="true"></app-type-table>
                    <div class="spacer"></div>
                    <app-type-table [displayedColumns]="vtnrColumns"
                                    [geproId]="geproId"
                                    class="growing-item"
                                    filter="VTNR"
                                    id="elephantVtnrTable"
                                    showPagination="true"></app-type-table>
                </div>
                </div>

                <div>
                <button nxModalClose="Close"
                        class="vermittler_bt nx-margin-top-s"
                        nxButton="primary medium"
                        type="button" cdkFocusInitial>
                    Schließen
                </button>
                </div>

            </div>

        </ng-template>
    </ng-template>
</app-labeled-container>

<div *ngIf="isLoading(); else documentView"
     id="loadingHint" fxLayout="row" fxLayoutAlign="center center" class="nx-margin-bottom-m nx-margin-top-2m">
    <nx-spinner nxSize="medium" class="nx-margin-right-s"></nx-spinner>
    <span>Lade Dokumente ...</span>
</div>
<ng-template #documentView class="nx-margin-top-2m">
    <div *ngIf="hasUploads(); else empty"
         id="documents" fxLayout="row wrap" fxLayoutAlign="start center" class="nx-margin-bottom-m nx-margin-top-2m"
         [ngClass]="{'nx-margin-left-0 nx-margin-top-0 nx-margin-bottom-0': !deleteEnabled}">
        <app-document-tag *ngFor="let document of documents()"
                          [document]="document"
                          [deleteEnabled]="deleteEnabled"
                          (removeClicked)="remove(document)"
                          (downloadClicked)="download(document)"></app-document-tag>
    </div>
    <ng-template #empty>
        <div id="emptyHint" [ngClass]="{'empty-left nx-margin-top-0': !deleteEnabled}" class="empty nx-margin-bottom-m nx-margin-top-2m">Momentan sind keine Dokumente vorhanden.</div>
    </ng-template>
</ng-template>
<nx-message *ngIf="true"
            id="errorMsg"
            nxContext="info"
            showIcon="true">
    <strong>Hinweis:</strong> Bitte markieren Sie bei der Checkliste Pflichtdokumente Ihre hochgeladenen Inhalte.
</nx-message>

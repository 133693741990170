import {Component, Input} from '@angular/core';
import {ColumnDefinition} from '../../../common/paginated-table/paginated-table.component';
import {VorgangRouteService} from '../../../../services/vorgang-route.service';
import {AbstractRouteComponent} from '../abstract-route.component';

@Component({
    selector: 'app-type-table',
    templateUrl: './type-table.component.html',
    styleUrls: ['./type-table.component.css']
})
export class TypeTableComponent extends AbstractRouteComponent {

    @Input()
    displayedColumns: Array<ColumnDefinition>;
    @Input()
    showPagination: boolean;

    constructor(protected readonly routeService: VorgangRouteService) {
        super(routeService);
    }

    isEmpty(): boolean {
        return this.totalElements === 0;
    }
}

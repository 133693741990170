<ng-template #showMitarbeiterAL>
    <div nxModalContent class="u-text-center modal-content">
        <h4 nxHeadline="subsection-medium" class="js-headline">Arbeitsliste von {{mitarbeiter?.fullName}}</h4>
        <div class="table-container">
            <table nxSort nxTable (sortChange)="sortTable($event)">
                <thead>
                <tr nxTableRow>
                    <th nxHeaderCell>
                        <nx-checkbox
                            class="js-toggleAll"
                            (checkedChange)="toggleAll()"
                            [checked]="selectedGepros.hasValue() && isAllSelected()"
                            [indeterminate]="selectedGepros.hasValue() && !isAllSelected()">
                        </nx-checkbox>
                    </th>
                    <th nxHeaderCell nxSortHeaderCell="id">
                        ID
                    </th>
                    <th nxHeaderCell nxSortHeaderCell="geproType">
                        Vorgang
                    </th>
                    <th nxHeaderCell nxSortHeaderCell="maklerName">
                        Vermittler
                    </th>
                    <th style="width: 15%" nxHeaderCell nxSortHeaderCell="publishTimestamp">
                        Eingereicht
                    </th>
                    <th style="width: 15%" nxHeaderCell nxSortHeaderCell="changeTimestamp">
                        Geändert
                    </th>
                    <th style="width: 12.5%" nxHeaderCell nxSortHeaderCell="statusType">
                        Status
                    </th>
                </tr>
                </thead>
                <tbody>
                <tr nxTableRow *ngFor="let item of mitarbeiterGepros">
                    <td nxTableCell>
                        <nx-checkbox
                                (click)="$event.stopPropagation()"
                                (checkedChange)="selectedGepros.toggle(item)"
                                [checked]="selectedGepros.isSelected(item)"></nx-checkbox>
                    </td>
                    <td nxTableCell (click)="goToGepro(item)">{{item?.id}}</td>
                    <td class="cell-align-left js-geproType" nxTableCell (click)="goToGepro(item)">{{item.geproType.name}}</td>
                    <td class="cell-align-left" nxTableCell (click)="goToGepro(item)">{{ item?.maklerName? item.maklerName : item?.msn6 }}</td>
                    <td nxTableCell (click)="goToGepro(item)">
                        <app-timestamp [timestamp]="item?.publishTimestamp" [timestampStringTwoLines]="true"></app-timestamp>
                    </td>
                    <td nxTableCell (click)="goToGepro(item)">
                        <app-timestamp [timestamp]="item?.changeTimestamp" [timestampStringTwoLines]="true"></app-timestamp>
                    </td>
                    <td nxTableCell (click)="goToGepro(item)">
                        <nx-badge [vibrant]="isStatusTypeUnrouted(item.statusType)"
                                  [type]="item.statusType.badgeName"  [ngClass]="getBadgeClass(item.statusType)">{{item.statusType.displayName}}</nx-badge>
                    </td>
                </tr>
                </tbody>
            </table>
            <button class="btWithdraw js-withdraw" nxButton='primary small-medium nx-margin-top-s'
                    (click)="deauthoriseSelectedGepros()"
                    [disabled]="isDeauthoriseButtonDisabled()">
                Selektierte zurückziehen
            </button>
        </div>
    </div>
</ng-template>

<nx-modal
        (nxClose)="closeWorklistDialog()"
        [nxBody]="showMitarbeiterAL"
        [nxHideOnClickOutside]="false"
        [nxHideOnEsc]="false"
        *ngIf="isDialogOpen">
</nx-modal>

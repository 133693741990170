import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {DenyGeproService} from './service/deny-gepro.service';
import {Subscription} from 'rxjs';
import {GeproListWithMessageRestTO} from '../../index/service/Model/GeproListWithMessageRestTO';
import {NxDialogService, NxModalRef} from '@allianz/ngx-ndbx/modal';

@Component({
    selector: 'app-deny-gepro',
    templateUrl: './deny-gepro.component.html',
    styleUrls: ['./deny-gepro.component.css']
})
export class DenyGeproComponent implements OnInit, OnDestroy {

    private accumulatedSubscription: Subscription = new Subscription();

    reason: string = "";
    geproListWithMessageRestTO: GeproListWithMessageRestTO;

    @ViewChild('geproDenyReasonTemplate') templateRef: TemplateRef<any>;
    templateDialogRef: NxModalRef<any>;

    constructor(
        private denyGeproService: DenyGeproService,
        public dialogService: NxDialogService) {
    }

    ngOnInit() {
       this.accumulatedSubscription.add( this.denyGeproService.subscribeToDialogOpenedSubject({
           next: (request) => {
               this.geproListWithMessageRestTO = request;
               this.templateDialogRef = this.dialogService.open(this.templateRef, {ariaLabel: 'An Referatsleiter zurückweisen', disableClose: true });
               this.templateDialogRef.afterClosed().subscribe(result => {
                   if (result === 'Cancel') {
                       this.closeDialogAndClearValues();
                   } else if (result === 'Zurueckweisen') {
                       this.confirmed();
                   }
                   this.templateDialogRef.close();
               });
           }
       }));
    }

    ngOnDestroy(): void {
        this.accumulatedSubscription.unsubscribe();
    }

    public closeDialogAndClearValues(): void {
        this.reason = "";
        this.geproListWithMessageRestTO = null;
    }

    public confirmed() {
        this.geproListWithMessageRestTO.reason = { message: this.reason };
        this.denyGeproService.publishDenyReasonSubject(this.geproListWithMessageRestTO);
        this.closeDialogAndClearValues();
    }

    public isMoveButtonDisabled(): boolean {
        return this.reason.trim().length === 0;
    }
}

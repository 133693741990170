<nx-formfield [nxLabel]="label" class="container">
    <textarea id="textField" nxInput
              class="textarea"
              cdkTextareaAutosize="true"
              [cdkAutosizeMinRows]="minLines"
              #errorHint="nxInput"
              [required]="required"
              [readonly]="readonly"
              [disabled]="disabled"
              [placeholder]="placeholder"
              [attr.maxLength]="maxLength"
              [(ngModel)]="model"></textarea>
    <span id="infoHint" *ngIf="maxLength" nxFormfieldHint>
        <span *ngIf="isEmpty() else remaining">max. {{ maxLength }} Zeichen</span>
        <ng-template #remaining>
            <span>{{ maxLength - contentLength() }} Zeichen verbleibend</span>
        </ng-template>
    </span>
</nx-formfield>

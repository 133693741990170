export class Pages {
    public static readonly PATH_INDEX = "index";
    public static readonly PATH_LOGIN = "login";
    public static readonly PATH_LOGOUT = "logout";
    public static readonly PATH_NOT_IMPLEMENTED = "notImplemented";
    public static readonly PATH_WORKLIST = "workList";
    public static readonly PATH_NOT_FOUND = "404";
    public static readonly PATH_LINKED = "weiterleiten";
    public static readonly PATH_CREATE_GEPRO = "create-gepro";
    public static readonly PATH_CREATE_GEPRO_TYPE = Pages.PATH_CREATE_GEPRO+"type";
    public static readonly PATH_CREATE_GEPRO_MAKLER = Pages.PATH_CREATE_GEPRO+"type";
    public static readonly PATH_ADMINISTRATION = "administration";
    public static readonly PATH_ANWENDUNGSINFO = "anwendungsinfo";
    public static readonly PATH_ANWENDUNGSINFO_VERSIONSHINWEISE = Pages.PATH_ANWENDUNGSINFO + "/versionshinweise";
    public static readonly PATH_ANWENDUNGSINFO_AUSBLICK = Pages.PATH_ANWENDUNGSINFO + "/ausblick";
    public static readonly PATH_NAME_ROUTING = Pages.PATH_ADMINISTRATION + "/nameRouting";
    public static readonly PATH_NAME_REPORTING = Pages.PATH_ADMINISTRATION + "/reporting";
    public static readonly PATH_MSN_ROUTING = Pages.PATH_ADMINISTRATION + '/routing/msn';
    public static readonly PATH_MB_ROUTING = Pages.PATH_ADMINISTRATION + '/routing/mb';
    public static readonly PATH_NOT_ROUTABLE = Pages.PATH_ADMINISTRATION + '/notRoutable';
    public static readonly PATH_REFERAT_DATA = Pages.PATH_ADMINISTRATION + "/referatData";
    public static readonly PATH_MITARBEITER_REFERAT = Pages.PATH_ADMINISTRATION + "/mitarbeiterReferat";
    public static readonly PATH_DOCUMENT_ADMINISTRATION = Pages.PATH_ADMINISTRATION + "/documents";
    public static readonly PATH_ADMIN_ADMINISTRATION = Pages.PATH_ADMINISTRATION + "/admins";
    public static readonly PATH_GEPRO_DETAILS = 'gepro/details';
    public static readonly PATH_MY_PROFILE = 'myProfile';
}

<h3 nxHeadline="subsection-medium" class="nx-font-weight-light kontaktHeader">Sie haben noch Fragen? </h3>
<p nxCopytext="medium" class="nx-margin-bottom-xs">Wir freuen uns über Ihr Feedback und helfen gerne weiter.<br>
    Bitte wählen Sie eine passende Kontaktmöglichkeit:</p>

<div fxLayout="column" >
    <div fxLayout="row" class="container nx-margin-top-s">
            <span>
                <nx-icon name="product-help" outline="true" size="m" class="nx-margin-bottom-s"></nx-icon>
            </span>
            <div>
                <p nxCopytext="large" class="kontaktUeberschrift">
                    Etwas funktioniert nicht?
                </p>
                <p nxCopytext="medium" class="kontaktInfo">
                    Melden Sie technische Probleme über Ihre Hotline und lassen ein Ticket eröffnen.
                </p>
            </div>
    </div>
    <div fxLayout="row" class="container nx-margin-top-s">
        <span>
            <nx-icon name="product-help-question" outline="true" size="m" class="nx-margin-bottom-s"></nx-icon>
        </span>
        <div>
            <p nxCopytext="large" class="kontaktUeberschrift">
                Wir helfen weiter
            </p>
            <p nxCopytext="medium" class="kontaktInfo">
                Stellen Sie uns gerne Ihre fachlichen Fragen mit folgenden Angaben:<br>
                Vorgangs-Id, Browserversion und ggf. Screenshot.
            </p>
            <button id="supportEmailBt" nxButton="tertiary small" type="button"
                        class="nx-margin-bottom-0"
                        onclick="location.href='mailto:diass@allianz.de?subject=Support-Anfrage'">
                    <nx-icon name="arrow-right" class="nx-margin-right-2xs"></nx-icon>Support kontaktieren
            </button>
        </div>
    </div>
    <div fxLayout="row" class="container nx-margin-top-s">
        <span>
            <nx-icon name="product-bullhorn" outline="true" size="m" class="nx-margin-bottom-s"></nx-icon>
        </span>
        <div>
            <p nxCopytext="large" class="kontaktUeberschrift">
                Ihre Stimme zählt
            </p>
            <p nxCopytext="medium" class="kontaktInfo">
                Teilen Sie uns Ihre Anregungen oder Feedback mit.
            </p>
            <button id="feedbackEmailBt" nxButton="tertiary small" type="button"
                    class="nx-margin-bottom-0"
                    onclick="location.href='mailto:diass@allianz.de?subject=Feedback zu DIASS'">
                <nx-icon name="arrow-right" class="nx-margin-right-2xs"></nx-icon>Support kontaktieren
            </button>
        </div>
    </div>
</div>

<div class="tabansicht-container" [class.open]="showSidebar" *ngIf="userinfoService.isRoleReferatsleiter()">
    <nx-link>
        <a (click)="this.toggleSidebar()" (keydown.enter)="toggleSidebar()" class="sidebar-toggle-button" tabindex="0">
            <nx-icon name="share" size="s" style="color: white;"></nx-icon>
        </a>
    </nx-link>
    <div class="tabs">
        <nx-link>
            <nx-icon (click)="this.selectEmployeesCategory()" (keydown.enter)="this.selectEmployeesCategory()" [fill]="selectedCategory==='employees'" name="product-person" outline="true"
                     size="s" tabindex="0"></nx-icon>
        </nx-link>
        <nx-link>
            <nx-icon (click)="this.selectReferateCategory()" (keydown.enter)="this.selectReferateCategory()" [fill]="(selectedCategory==='referate')" name="product-house" outline="true"
                     size="s" tabindex="0"></nx-icon>
        </nx-link>
    </div>
    <div class="overflow-area">
        <app-work-list-employees [hidden]="selectedCategory!='employees'"></app-work-list-employees>
        <app-work-list-referate [hidden]="selectedCategory!='referate'"></app-work-list-referate>
    </div>
</div>

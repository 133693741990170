<div class="tab__content">
    <div class="nx-margin-top-m">
        <button *ngIf="showAddDmsLink()" nxButton="tertiary small-medium"
                (click)="dmsLink.show(dmslink?.freigabeinfo)">
            <nx-icon name="pen" class="nx-margin-right-s"></nx-icon>DMS-Link hinzufügen</button>
        <app-dms-link-dialog #dmsLink (saveLink)="addFreigabeDMSLink($event)"
                    (refreshFreigabeData)="loadGeproFreigabeInfos()"></app-dms-link-dialog>
        <button *ngIf="showShowDmsLink()" nxButton="tertiary small-medium"
                [disabled]="dmslink==null || dmslink?.freigabeinfo ===''"
                (click)="openDmsLink()">
            <nx-icon name="launch" class="nx-margin-right-s"></nx-icon>DMS-Link öffnen</button>
        <button *ngIf="showEditDmsLink()" nxButton="tertiary small-medium"
                (click)="dmsLink.show(dmslink?.freigabeinfo)">
            <nx-icon name="pen"></nx-icon></button>
    </div>

    <div *ngFor="let freigabeItem of freigabeMessages; let $index = index">
        <div class="freigabe--item {{freigabeItem?.freigabeinfotyp}} js-event-item">
            <div *ngIf="freigabeItem?.freigabeinfotyp == 'DRAFT'" fxLayout="row-reverse" fxLayoutAlign="start center">
                <nx-badge class="badge">Freigabeanforderung zurückgezogen</nx-badge>
            </div>
            <div class="freigabe">
                <nx-icon class="align-icon" name="{{freigabeItem?.freigabeinfotyp == 'REQUEST' || freigabeItem?.freigabeinfotyp == 'DRAFT' ? 'speech-bubble-dots-o' : 'chat-o'}}"
                         size="s" [title]="freigabeItem?.freigabeinfotyp">
                </nx-icon>
                <div>
                    <div class="freigabe--item-date d-inline-flex" style="vertical-align: 0">
                        {{getFreigabeCreatorInfo(freigabeItem)}}, <app-timestamp [timestamp]="freigabeItem?.createdTs"></app-timestamp>
                    </div>
                    <div class="d-flex"></div>
                    <div class="historyBody">
                        <div [innerHTML]="freigabeItem?.freigabeinfo" class="freigabe--item-note d-inline-flex"></div>
                    </div>
                </div>
            </div>
            <div>
            <button *ngIf="visibilityService.isEnabled('rejectrelease')
                    && freigabeItem.freigabeinfotyp == 'REQUEST' && $index === (freigabeMessages.length -1)"
                        class="nx-margin-bottom-0 bt-freigabe-cancel" nxButton="tertiary" type="button"
                        (click)="denyforrelease()">
                    <nx-icon name="close"></nx-icon>Freigabeanforderung zurückziehen
                </button>
            </div>
        </div>
    </div>

    <div *ngIf="!visibilityService.isEnabled('rejectrelease') && !visibilityService.isEnabled('forrelease')
        && latestGeproFreigabeRequestDraft != null && latestGeproFreigabeRequestDraft.freigabeinfo.trim() != ''"
         class="freigabe--item {{latestGeproFreigabeRequestDraft?.freigabeinfotyp}} js-event-item">
        <div fxLayout="row-reverse" fxLayoutAlign="start center">
            <nx-badge class="badge">Freigabeanforderung zurückgezogen</nx-badge>
        </div>
        <div class="freigabe">
            <nx-icon class="align-icon" name="speech-bubble-dots-o"
                     size="s" [title]="latestGeproFreigabeRequestDraft?.freigabeinfotyp">
            </nx-icon>
            <div>
                <div class="freigabe--item-date d-inline-flex" style="vertical-align: 0">
                    {{getFreigabeCreatorInfo(latestGeproFreigabeRequestDraft)}}, <app-timestamp [timestamp]="latestGeproFreigabeRequestDraft?.createdTs"></app-timestamp>
                </div>
                <div class="d-flex"></div>
                <div class="historyBody">
                    <div [innerHTML]="latestGeproFreigabeRequestDraft?.freigabeinfo" class="freigabe--item-note d-inline-flex"></div>
                </div>
            </div>
        </div>
    </div>


    <div *ngIf="visibilityService.isEnabled('forrelease') || processreleaseEnabled() || releaseEnabled() "
         class="nx-margin-bottom-m">
        <app-restricted-text-area [minLines]="5" [maxLength]="4000"
                                  [(ngModel)]="freigabeMessage"
                                  [label]="visibilityService.isEnabled('forrelease')?
                                            'Information zur Freigabeanforderung': 'Information zur Freigabe'"
                                  [ngClass]="visibilityService.isEnabled('forrelease')? null: 'nx-margin-left-2m'">
        </app-restricted-text-area>
    </div>
    <button *ngIf="visibilityService.isEnabled('forrelease')"
            [disabled]="isForreleaseDisabled()"
            nxButton="primary medium" class="js-enquiry-button-visibility sendBt"
            type="button"
            (click)="forrelease()">Freigabe anfordern
    </button>
    <!--button *ngIf="visibilityService.isEnabled('rejectrelease')"
            nxButton="primary medium" class="js-enquiry-button-visibility sendBt"
            type="button"
            (click)="denyforrelease()">Freigabeanforderung zurückziehen
    </button-->
    <button *ngIf="processreleaseEnabled()"
            [disabled]="freigabeMessage==null||freigabeMessage==''"
            nxButton="primary medium" class="js-enquiry-button-visibility sendBt nx-margin-right-m"
            type="button"
            (click)="processrelease()">Zur Nachbearbeitung
    </button>
    <button *ngIf="releaseEnabled()"
            [disabled]="freigabeMessage==null||freigabeMessage==''"
            nxButton="primary medium" class="js-enquiry-button-visibility sendBt"
            type="button"
            (click)="release()">Vorgang freigeben
    </button>
</div>

import {Component, Input, OnInit} from '@angular/core';
import {BuildInformationRestTO} from '../service/Model/BackendVersionRestTO';
import {AuthenticationService} from '../service/authentication-service';
import {ApplicationInfoService} from '../service/application-info.service';

@Component({
    selector: 'app-index-diass',
    templateUrl: './app-index-diass.component.html',
    styleUrls: ['./app-index-diass.component.scss']
})
export class AppIndexDiassComponent implements OnInit {

  appNotice: string;

  @Input()
  frontendVersion: string;

  @Input()
  backendInformation: BuildInformationRestTO;

  @Input()
  backendBuildDate: string;

  constructor(private authService: AuthenticationService,
              private appInfoService: ApplicationInfoService) {}

  ngOnInit() {
    this.authService.isAuthenticated.subscribe(auth => {
        if (auth === true) {
            this.appInfoService.getNotice().subscribe(appNotice => this.appNotice = appNotice);
        } else {
            this.appNotice = null;
        }
    });
  }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MyProfileComponent} from './my-profile.component';
import {NxCardModule} from '@allianz/ngx-ndbx/card';
import {NxHeadlineModule} from '@allianz/ngx-ndbx/headline';
import {NxTabsModule} from '@allianz/ngx-ndbx/tabs';
import {MyStellvertreterComponent} from './my-stellvertreter/my-stellvertreter.component';
import {NxFormfieldModule} from '@allianz/ngx-ndbx/formfield';
import {NxDropdownModule} from '@allianz/ngx-ndbx/dropdown';
import {NxIconModule} from '@allianz/ngx-ndbx/icon';
import {NxButtonModule} from '@allianz/ngx-ndbx/button';
import {NxTooltipModule} from '@allianz/ngx-ndbx/tooltip';

@NgModule({
    declarations: [MyProfileComponent, MyStellvertreterComponent],
    exports: [
        MyProfileComponent,

    ],
    imports: [
        CommonModule,
        NxCardModule,
        NxTabsModule,
        NxHeadlineModule,
        NxFormfieldModule,
        NxDropdownModule,
        NxIconModule,
        NxButtonModule,
        NxTooltipModule
    ]
})
export class MyProfileModule { }

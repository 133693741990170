<div fxLayout="row" fxLayoutAlign="start center">
    <nx-formfield id="gepro-Auswahl" nxLabel="Vorgang wählen *" style="flex: 1">
        <nx-dropdown #dropdown id="geproSelection" (selectionChange)="valueChanged()"
                     appFocus
                     [(ngModel)]="gepro.geproType"
                     (nxValueChange)="showNote($event)"
                     [compareWith]="geproTypeComparator"
                     [nxShowFilter]="true"
                     [nxFilterPlaceholder]="'Vorgang suchen...'">
            <nx-dropdown-item *ngFor="let gePro of validGeProList" [nxValue]="gePro"> {{gePro.name}}
            </nx-dropdown-item>
            <nx-dropdown-item *ngIf="validGeProList?.length === 0">
                <div fxLayout="row" fxLayoutAlign="start center">
                    <nx-spinner nxSize="medium"></nx-spinner><span style="margin-left: 1em">Lade ...</span>
                </div>
            </nx-dropdown-item>
        </nx-dropdown>
        <nx-error nxFormfieldError>
            Bitte Vorgang auswählen.
        </nx-error>
    </nx-formfield>
    <button #infoButton
            type="button"
            nxIconButton="tertiary"
            nxTooltip="Informationen zu den Vorgangsarten anzeigen"
            aria-label="info"
            class="info"
            (click)="openInfoDialog()">
        <nx-icon name="info-circle-o"></nx-icon>
    </button>
</div>
<nx-message *ngIf="errorMessageType.length > 0"
            id="errorMsgInhalt"
            nxContext="error"
            showIcon="true">
    <strong>Fehler:</strong> {{ errorMessageType }}
    <div *ngIf="errorMessageType_line2.length > 0"><li>{{errorMessageType_line2}}</div>
    <div *ngIf="errorMessageType_line3.length > 0"><li>{{errorMessageType_line3}}</div>
    <div *ngIf="errorMessageType_line4.length > 0">{{errorMessageType_line4}}</div>
</nx-message>

<nx-message *ngIf="dropdown.hasValue"
            nxContext="info"
            showIcon="true"
            class="nx-margin-top-0 nx-margin-bottom-m">
    <ng-template #loadingIndicator>
        <div fxLayout="row" fxLayoutAlign="start center">
            <nx-spinner nxSize="medium"></nx-spinner>
            <span class="nx-margin-left-s">Lade Hinweistext ...</span>
        </div>
    </ng-template>
    <span *ngIf="(selectedVorgangTypeInfo() | async) as typeInfo; else loadingIndicator" [innerHTML]="getTypeInfoNote(typeInfo)">
    </span>
</nx-message>
<ng-template #infoModal>
    <div class="u-text-center nx-padding-top-m" tabindex="0" appFocus>
        <h3 nxHeadline="subsection-medium" class="nx-margin-bottom-0 heading">
            Informationen zu allen Vorgangsarten
        </h3>
        <div fxLayout="column" fxLayoutAlign="start stretch" class="type-description">
            <ng-template #loadingIndicator>
                <div class="type-description-loading-indicator" fxLayout="row" fxLayoutAlign="center center">
                    <nx-spinner nxSize="large"></nx-spinner>
                    <span class="nx-margin-left-s">Lade Beschreibungen ...</span>
                </div>
            </ng-template>
            <div *ngIf="(vorgangTypeInfos() | async) as typeInfos; else loadingIndicator" class="nx-margin-m">
                <nx-message *ngIf="errorLoadingDescriptions; else descriptions"
                            showIcon="true"
                            nxContext="error">
                    Die Beschreibungen konnten nicht geladen werden.
                </nx-message>
                <ng-template #descriptions>
                    <div *ngFor="let typeInfo of validTypeInfos(typeInfos)">
                        <hr>
                        <app-type-description [vorgangTypeInfo]="typeInfo"></app-type-description>
                    </div>
                    <hr class="type-description-finish-line">
                </ng-template>
            </div>
            <div>
                <button nxModalClose="Close" nxButton="medium" type="button" cdkFocusInitial>
                    Schließen
                </button>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #modalGeproTypeChanged>
    <div nxModalContent class="u-text-center">
        <h3 nxHeadline="subsection-large" class="nx-font-weight-light">Änderung der Vorgangsart</h3>
        <p nxCopytext="normal">Bereits erbrachte Daten werden unwiderruflich gelöscht.</p>
        <button nxModalClose="Cancel" class="nx-margin-right-2xs"  nxButton="medium secondary" type="button">Abbrechen</button>
        <button nxModalClose="Continue"  nxButton="medium primary" type="button" cdkFocusInitial>Fortfahren</button>
    </div>
</ng-template>

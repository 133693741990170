// TODO: braucht man dieses Interface?
export interface GeproFilterStatisticsInterface {
    filterType:string;
    valueToCountMap: {[name: string] : number};
}

export class GeproFilterStatisticsRestTO {
    public static readonly FILTER_EINGANGSDATUM = "EINGANGSDATUM";
    public static readonly FILTER_GEPRO_ART = "GEPRO_ART";
    public static readonly FILTER_STATUS = "STATUS";
    public static readonly FILTER_BEARBEITER = "BEARBEITER";
    public static readonly FILTER_MAKLERHAUS = "MAKLERHAUS";
    public static readonly FILTER_LETZTE_AENDERUNG = "LETZTE_AENDERUNG";

    public static readonly KEY_MAX = "max";
    public static readonly KEY_MIN = "min";

    public filterType:string;
    public valueToCountMap:Map<string, number>;

    constructor (filterType?:string, valueToCountMap?:Map<string, number>) {
        this.filterType = filterType;
        this.valueToCountMap = valueToCountMap;
    }

    static deserialize(input:GeproFilterStatisticsInterface): GeproFilterStatisticsRestTO {
        let transformedValueToCountMap:Map<string, number> = new Map<string, number>();
        if (input.valueToCountMap) {
            // construct real object
            Object.keys(input.valueToCountMap).forEach(key => {
                transformedValueToCountMap.set(key, input.valueToCountMap[key])
            });
        }

        return new GeproFilterStatisticsRestTO(input.filterType, transformedValueToCountMap);
    }
}

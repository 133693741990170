import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {ISidebarNav} from '../../config/interfaces';
import {SidebarNavEvent} from '../../config/events/sidebarNav.event';
import {Pages} from '../navigation/pages';

@Component({
  selector: 'app-pagenotfound',
  templateUrl: './app.pagenotfound.component.html',
  styleUrls: ['./app.pagenotfound.component.scss']
})
export class AppPageNotFoundComponent implements OnInit,ISidebarNav {

  constructor(private router:Router,
              private sidebarNavEvent:SidebarNavEvent) {
  }

  showSidebarNav(flag:boolean) {
    this.sidebarNavEvent.fire(flag);
  }

  ngOnInit():void {
    this.showSidebarNav(false);
  }

  goToStartseite():void {
    this.showSidebarNav(true);
    this.router.navigate(['/' + Pages.PATH_INDEX]);
  }
}

<div *ngIf="geproHistoryService.shouldBeHighlighted(item)
        && (visibilityService.isVisible('reply') || visibilityService.isVisible('cancel'))" class="align-container">
    <button *ngIf="visibilityService.isVisible('reply')"
            (click)="answerQuestion(item)" [disabled]="!visibilityService.isEnabled('reply')" class="nx-margin-bottom-0 bt-rueckfrage-beantworten" nxButton="tertiary" type="button">
        <nx-icon name="chat-o"></nx-icon>&nbsp;Rückfrage beantworten
    </button>
    <button (click)="openCancelDialog()"
            *ngIf="visibilityService.isVisible('cancel')" [disabled]="!visibilityService.isEnabled('cancel')"
            class="nx-margin-bottom-0 bt-rueckfrage-beantworten" nxButton="tertiary" type="button">
        <nx-icon name="close"></nx-icon>&nbsp;Rückfrage zurückziehen
    </button>
</div>


<ng-template #zurueckziehenModalBody>
    <div class="u-text-center">
        <h3 nxHeadline="subsection-large" class="nx-font-weight-light">Wollen Sie die Rückfrage <br> zurückziehen?</h3>
        <button nxModalClose="CancelDialog" class="nx-margin-right-s" nxButton="secondary medium">Abbrechen</button>
        <button nxModalClose="CancelQuestion" nxButton="primary medium" cdkFocusInitial>Zurückziehen</button>
    </div>
</ng-template>


import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GeproFilterComponent} from './gepro-filter.component';
import {FlexLayoutModule} from '@angular/flex-layout';
import {NxCardModule} from '@allianz/ngx-ndbx/card';
import {NxDropdownModule} from '@allianz/ngx-ndbx/dropdown';
import {NxHeadlineModule} from '@allianz/ngx-ndbx/headline';
import {NxMessageModule} from '@allianz/ngx-ndbx/message';
import {NxRadioModule} from '@allianz/ngx-ndbx/radio-button';
import {NxSwitcherModule} from '@allianz/ngx-ndbx/switcher';
import {FilterDropdownComponent} from './filter-dropdown/filter-dropdown.component';
import {NxFormfieldModule} from '@allianz/ngx-ndbx/formfield';
import {FilterDateComponent} from './filter-date/filter-date.component';
import {NxMomentDateModule} from '@allianz/ngx-ndbx/moment-date-adapter';
import {NxInputModule} from '@allianz/ngx-ndbx/input';
import {NxDatefieldModule} from '@allianz/ngx-ndbx/datefield';
import {NxGridModule} from '@allianz/ngx-ndbx/grid';
import {FormsModule} from '@angular/forms';
import {NdbxDependenciesModule} from '../../../ndbx-dependencies/ndbx-dependencies.module';
import {DiassCommonsModule} from '../../common/diass-commons.module';
import {NxMaskModule} from '@allianz/ngx-ndbx/mask';
import {NgbDatepickerModule} from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    declarations: [GeproFilterComponent, FilterDropdownComponent, FilterDateComponent],
    exports: [
        GeproFilterComponent,
        FilterDropdownComponent,
        FilterDateComponent
    ],
    imports: [
        NdbxDependenciesModule,
        DiassCommonsModule,
        CommonModule,
        FormsModule,
        FlexLayoutModule,
        NxSwitcherModule,
        NxCardModule,
        NxHeadlineModule,
        NxFormfieldModule,
        NxDropdownModule,
        NxMessageModule,
        NxMomentDateModule,
        NxInputModule,
        NxDatefieldModule,
        NxGridModule,
        NxMaskModule,
        NgbDatepickerModule,
        NxRadioModule
    ]
})
export class GeproFilterModule { }

<ng-template #geproCloseModalBody>
    <div class="u-text-center">
        <h3 nxHeadline="subsection-large" class="nx-font-weight-light">Vorgang abschließen</h3>
        <p nxCopytext="normal">Hiermit bestätige ich, dass alle Eingaben getätigt wurden und korrekt geprüft wurden.
        <br>Der Vorgang kann nach dem Abschluss nicht wieder bearbeitet werden.
        <br>Bitte denken Sie daran, alle relevanten Dokumente und Anhänge abzulegen.</p>
        <button nxModalClose="Save" nxButton="medium" class="js-finish nx-margin-right-s" type="button">Abschließen</button>
        <button nxModalClose="Abort" nxButton="medium secondary" class="js-back" type="button">Zurück</button>
    </div>
</ng-template>

import {EditableTableCell} from './EditableTableCell';
import {ValidatorFn} from '@angular/forms';

export abstract class EditableTableRow {

    public deleted: boolean = false;
    public newlyCreated: boolean = false;
    public edited: boolean = false;

    private customErrorMessage: string;

    public abstract getRowValues(): Array<EditableTableCell>;
    public abstract getErrorMessagesArray(): Array<string>;
    public abstract getValidationRegexpArray(): Array<RegExp>;

    public abstract hasOneOfValuesChanged(): boolean;
    public abstract onKeyUpInInputField(cell: EditableTableCell);

    public isChanged(): boolean {
        return this.deleted || this.newlyCreated || this.hasOneOfValuesChanged();
    }

    public isValueAtPositionValid(position: number): boolean {
        if (position >= 0 && position < this.getRowValues().length) { // weitere Werte werden nicht validiert
            return this.getValidationRegexpArray()[position].test(this.getRowValues()[position].value);
        }

        return true;
    }

    public toggleRowForDeletion(): void {
        this.deleted = !this.deleted;
    }

    public isValid(): boolean {
        let result: boolean = true;

        for (let i = 0; i < this.getRowValues().length; i++) {
            result = result && this.isValueAtPositionValid(i);
        }

        return result;
    }

    public getRowValidator(): ValidatorFn {
        return control => null;
    }

    public hasCustomError(): boolean {
        return this.getCustomErrorMessage() != null && this.getCustomErrorMessage().trim() !== '';
    }

    public getCustomErrorMessage(): string {
        return this.customErrorMessage;
    }

    protected setCustomErrorMessage(message: string): void {
        this.customErrorMessage = message;
    }

    protected resetCustomErrorMessage(): void {
        this.customErrorMessage = null;
    }
}

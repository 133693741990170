import {EditableTableRow} from './EditableTableRow';
import {SortDirection, SortEvent} from '@allianz/ngx-ndbx/table';
import {EditableTableHeader} from './EditableTableHeader';
import {UserMessagesService} from '../../messages-area/messages-area/service/user-messages.service';
import {UserMessage} from '../../messages-area/messages-area/entity/UserMessage';

export abstract class EditableTableDataWrapper {

    public DISABLED_INVALID_DATA_MESSAGE = 'Bitte korrigieren Sie die Eingabe';
    public DISABLED_SAVE_NOCHANGES_MESSAGE = 'Sie haben keine Änderungen vorgenommen';
    public ERROR_QUERY_MESSAGE = 'Fehler bei der Abfrage der Daten vom Server';
    public ERROR_SAVE_MESSAGE = 'Beim Speichern ist ein Fehler aufgetreten';
    public SUCCESS_SAVE_MESSAGE = 'Erfolgreich gespeichert';

    public isEditModeOn: boolean = false;

    public disabledSaveButtonMessage: string;

    public abstract getTableData(): Array<EditableTableRow>;

    public abstract setTableData(newData: Array<EditableTableRow>): void;

    public abstract queryFreshDataFromServer(): void;

    public abstract getColumnNames(): Array<EditableTableHeader>;

    public abstract persistDataToServer(): void;

    public abstract getNewEditableTableRow(): EditableTableRow;

    public abstract isNewElementCreationAllowed(): boolean;

    public abstract isElementDeletionAllowed(): boolean;

    public abstract newElementCreated(element: EditableTableRow): void;

    constructor(protected userMessagesService: UserMessagesService) {
    }

    public immediatelyDeleteRow(row: EditableTableRow): void {
        let tableData: Array<EditableTableRow> = this.getTableData();
        let index = tableData.indexOf(row);
        tableData = (index > -1) ? [
            ...tableData.slice(0, index),
            ...tableData.slice(index + 1)
        ] : tableData;

        this.setTableData(tableData);
    }

    public rowDeletionRequested(row: EditableTableRow): void {
        row.newlyCreated ? this.immediatelyDeleteRow(row) : row.toggleRowForDeletion();
    }

    public discardChanges(): void {
        this.queryFreshDataFromServer();
    }

    public isChangesMade(): boolean {
        if (this.getTableData()) {
            for (let row of this.getTableData()) {
                if (row.isChanged()) {
                    return true;
                }
            }
        }

        return false;
    }

    public isDataValid(): boolean {
        for (let row of this.getTableData()) {
            if (!row.deleted && row.edited && !row.isValid()) {
                return false;
            }
        }

        return true;
    }

    public sortTableByPosition(sort: SortEvent, position: number) {
        this.setTableData(this.getTableData().sort((a, b) => {
            return this.compare(a.getRowValues()[position].value, b.getRowValues()[position].value, sort.direction);
        }));
    }

    protected compare(a: string, b: string, direction: SortDirection) {
        return (a < b ? -1 : 1) * (direction === 'asc' ? 1 : -1);
    }

    public showSuccess(message: string): void {
        this.userMessagesService.addSuccessMessage(new UserMessage(message, false));
    }

    public showError(message: string): void {
        this.userMessagesService.addErrorMessage(new UserMessage(message, true));
    }

    protected handleBackendError(error: any, message: string): void {
        let errorMessage: string = message;
        if (error.error) {
            errorMessage += ": " + error.error;
        }
        if (error.status) {
            errorMessage += " (HTTP Code " + error.status + ")"
        }
        this.userMessagesService.addErrorMessageString(errorMessage);
        console.log("Fehler aufgetreten", error);
    }
}

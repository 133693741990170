import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Endpoints} from '../../navigation/endpoints';
import {DefaultHttpService} from '../../../services/default-http.service';


@Injectable()
export class ReferateService {

  constructor(
      public http: DefaultHttpService) {}

    public getReferateList(): Observable<any> {
        return this.http.get(Endpoints.REFERATE_LIST);
  }

}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {Endpoints} from '../../navigation/endpoints';
import {map} from 'rxjs/operators';

export interface Wiedervorlage {
    id: number;
    bensl: string;
    beschreibung: string;
    geproid: string;
    checkbox: string;
    updated: Date;
    wiedervorlageDocuments: any;
}

export interface MultiPartFileObject{
    wiedervorlage: Wiedervorlage;
    files: FormData;
}


@Injectable()
export class WiedervorlageService {

    constructor(
        public http: HttpClient
    ) {
    }

    public getAllWiedervorlagen(): Observable<any> {
        return this.http.get(Endpoints.SERVICE_GET_ALL_WIEDERVORLAGEN);
    }

    public getWiedervorlage(id: string): Observable<any> {
        return this.http.get(Endpoints.SERVICE_GET_WIEDERVORLAGE+'/'+id);
    }

    public getWiedervorlageForHistory(id: string): Observable<any> {
        return this.http.get(Endpoints.SERVICE_GET_WIEDERVORLAGE_HISTORY+'/'+id);
    }

    public getWiedervorlageDocuments(id: number, geproid): Observable<any> {
        return this.http.get(Endpoints.SERVICE_GET_ALL_WIEDERVORLAGEN_DOCUMENT+'/'+id+'/'+geproid);
    }

    // public getHinweisDocumentsVersionsinfo(hinweisId: number): Observable<any> {
    //     return this.http.get(Endpoints.HINWEISE_DOCUMENTS_VERSIONSINFO+'/'+hinweisId);
    // }
    //
    // public getHinweisDocument(documentid: number): Observable<any> {
    //     const endpoint = Endpoints.HINWEISE_DOCUMENT;
    //
    //     let headers = new HttpHeaders({'Content-Type': 'application/pdf'});
    //     let params = new HttpParams();
    //     params = params.append('id', documentid + '');
    //
    //     return this.http.get(endpoint+'/'+documentid, {observe: 'response', headers: headers, responseType: 'blob'})
    //         .pipe(map((res) => {
    //             let data = {
    //                 file: new Blob([res.body], {type: res.headers.get('Content-Type')}),
    //                 filename: res.headers.get('File-Name')
    //             };
    //             return data;
    //         }));
    // }

    delete(wiedervorlage: Wiedervorlage): Observable<any> {
        return this.http.post(Endpoints.SERVICE_REMOVE_WIEDERVORLAGE+'/'+wiedervorlage.geproid,'');
    }

    checkDate(wiedervorlage: Wiedervorlage): Observable<any> {
        return this.http.post(Endpoints.SERVICE_CHECK_DATE_WIEDERVORLAGE, wiedervorlage);
    }

    removeDocument(docu: any): Observable<any> {
        return this.http.post(Endpoints.SERVICE_REMOVE_WIEDERVORLAGE_DOCUMENT+'/'+docu.id,'');
    }

    update(wiedervorlage: Wiedervorlage): Observable<any> {
        return this.http.post(Endpoints.SERVICE_UPDATE_WIEDERVORLAGE, wiedervorlage);
    }

    uploadDocument(id : any, geproid: any, bensl: any, file: File): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('id', id);
        formData.append('geproid', geproid);
        formData.append('bensl', bensl);
        formData.append('file', file);
        formData.append('name', file.name);
        return this.http.post(Endpoints.SERVICE_UPLOAD_DOCUMENT_WIEDERVORLAGE, formData);
    }

    create(wiedervorlage: Wiedervorlage): Observable<any> {
        return this.http.post(Endpoints.SERVICE_UPDATE_WIEDERVORLAGE, wiedervorlage);
    }

}

<div *ngIf="uploadEnabled" class="dropzone" (drop)="onDrop($event)" (dragover)="onDragOver($event)" (dragleave)="onDragLeave($event);">
    <div fxLayout="column" fxLayoutAlign="start stretch">
        <input #fileUpload multiple name="file" type="file" class="hidden"
               [accept]="acceptedFileTypes()"
               (change)="queueAll($event.target.files); fileUpload.value = null"/>
            <button nxButton="secondary large" type="button" (click)="fileUpload.click()" class="upload-button nx-margin-top-2m nx-margin-bottom-0">
                <nx-icon name="arrow-upload" class="nx-margin-right-2xs"></nx-icon>{{ buttonText }}
            </button>
        <p class="upload-info nx-margin-bottom-2s">oder Datei(en) hierher ziehen</p>
    </div>
</div>
<app-document-view [deleteEnabled]="uploadEnabled"></app-document-view>


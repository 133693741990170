import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SaveDataModalComponent} from './save-data-modal.component';
import {NxButtonModule} from '@allianz/ngx-ndbx/button';
import {NxCopytextModule} from '@allianz/ngx-ndbx/copytext';
import {NxHeadlineModule} from '@allianz/ngx-ndbx/headline';
import {NxModalModule} from '@allianz/ngx-ndbx/modal';

@NgModule({
    declarations: [SaveDataModalComponent],
    exports: [
        SaveDataModalComponent
    ],
    imports: [
        CommonModule,
        NxModalModule,
        NxHeadlineModule,
        NxCopytextModule,
        NxButtonModule
    ]
})
export class SaveDataModalModule { }

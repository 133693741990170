<nx-formfield [nxLabel]="label" class="container">
    <textarea id="textField" nxInput
              class="textarea"
              cdkTextareaAutosize="true"
              [cdkAutosizeMinRows]="minLines"
              #errorHint="nxInput"
              [required]="required"
              [readonly]="readonly"
              [disabled]="disabled"
              [placeholder]="placeholder"
              [pattern]="pattern"
              [attr.maxLength]="maxLength"
              [(ngModel)]="model"></textarea>
    <span id="infoHint" *ngIf="maxLength" nxFormfieldHint>
        <span *ngIf="isEmpty() else remaining">max. {{ maxLength }} Zeichen</span>
        <ng-template #remaining>
            <span>{{ maxLength - contentLength() }} Zeichen verbleibend</span>
        </ng-template>
    </span>
    <nx-error id="errorHint" nxFormfieldError appearance="message" showIcon="true">
        <span *ngIf="hasErrorPattern(); else requiredError">
            <strong>Fehler: </strong> Das Zeichen <strong>{{ invalidChar() }}</strong> ist nicht erlaubt.
        </span>
        <ng-template #requiredError>
            <span *ngIf="hasErrorRequired()">
                <strong>Fehler: </strong> Eingabe erforderlich.
            </span>
        </ng-template>
    </nx-error>
</nx-formfield>

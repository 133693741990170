import {Component, Input} from '@angular/core';
import * as moment from 'moment';

@Component({
    selector: 'app-timestamp',
    templateUrl: './timestamp.component.html',
    styleUrls: ['./timestamp.component.css']
})
export class TimestampComponent {

    @Input()
    timestamp: moment.Moment | Date | string | number | object;
    @Input()
    timestampStringTwoLines = false;

    isDefaultDate(date: moment.Moment | Date | string | number | object) : boolean {
        return moment(date).isSame(moment('1900-01-01T00:00:00.00Z', 'YYYY-MM-DDTHH:mm:ssZ'));
    }
}

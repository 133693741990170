import {
    AfterViewChecked,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    Input,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from '@angular/core';
import {NxDialogService, NxModalRef} from '@allianz/ngx-ndbx/modal';
import {GeproService} from "../components/index/service/gepro.service";
import {UserinfoService} from "../components/index/service/userinfo.service";
import {Router, RouterStateSnapshot} from "@angular/router";
import {GeproRestTO} from "../components/index/service/Model/GeproRestTO";
import {Pages} from "../components/navigation/pages";
import {delay} from "rxjs/operators";

@Component({
    selector: 'notificationpopup',
    templateUrl: './notificationpopup.html',
    styleUrls: ['./notificationpopup.scss']
})

export class NotificationpopupComponent implements AfterViewChecked {


    public tabSelected: number;
    public notification: any;
    @ViewChild('notificationDialog') templateRef: TemplateRef<any>;
    hinweiseDialog: NxModalRef<any>;


    constructor(private changeDetectorRef: ChangeDetectorRef, private geproService: GeproService, private userData: UserinfoService,
                public dialogService: NxDialogService, private router: Router) {
        this.tabSelected = 0;
    }

    close(): void {
        this.userData.isDialogOpen = false;
        this.hinweiseDialog.close();
    }

    ngAfterViewChecked(): void {
        this.changeDetectorRef.detectChanges();
    }

    public tabChanged($event) {
        this.tabSelected = $event.index;
    }

    goToGepro(item: any) {
        let commands: Array<any>;
        commands = ['/' + Pages.PATH_GEPRO_DETAILS, item.externgeproid];
        this.setAlreadyRead(item);
        this.userData.isDialogOpen = false;
        this.hinweiseDialog.close();
        // if (window.location.href.indexOf("gepro/details") != -1) {
        //     this.router.navigate(commands);
        // } else {
            this.goToAnotherGepro(item);
        // }
    }

    goToAnotherGepro(item: any) {
        let commands: Array<any>;
        commands = [Pages.PATH_LINKED];
        this.router.navigate(commands);

        setTimeout(() => {this.reload(item);}, 2000);
    }

    reload(item: any){
        let commands: Array<any>;
        commands = ['/' + Pages.PATH_GEPRO_DETAILS, item.externgeproid];
        this.router.navigate(commands);
    }

    setAlreadyRead(item: any) {
        this.geproService.setNotificationAsRead(this.userData.getCurrentUserData().bensl, item.externgeproid, 'true', item.id).subscribe(
            res => {
                this.notification = res;
                this.userData.getNotes();
            });
    }

    rewriteTime(timeItem: any): string {
        var today = new Date();
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var hh = String(today.getHours()).padStart(2, '0');
        var min = String(today.getMinutes()).padStart(2, '0');
        var yyyy = today.getFullYear().toString().substring(2, 4);
        var fullyear = today.getFullYear().toString();

        var today2 = (dd + '.' + mm + '.' + yyyy).toString();
        var hoursAndMin = timeItem.toString().split(', ')[1];
        var hours = hoursAndMin.split(':')[0];
        var andMin = hoursAndMin.split(':')[1];
        var day = timeItem.toString().split('.')[0];
        var month = timeItem.toString().split('.')[1];
        if (timeItem.indexOf(today2) > -1) {
            if (hours.indexOf(hh) > -1) {
                return 'vor ' + (parseInt(min) - parseInt(andMin)).toString() + ((parseInt(min) - parseInt(andMin)) > 1 ? ' Minuten' : ' Minute');
            } else {
                if ((parseInt(hh) - parseInt(hours)) >= 2) {
                    if(parseInt(min) - parseInt(andMin)>=0){
                        return 'vor etwa ' + (parseInt(hh) - parseInt(hours)).toString() +' Stunden';
                    }else {
                        return 'vor etwa ' + (parseInt(hh) - parseInt(hours)-1).toString() + ((parseInt(hh) - parseInt(hours)-1) > 1 ? ' Stunden' : ' Stunde');
                    }
                } else {
                    if ((parseInt(hh) - parseInt(hours)) == 1&&(parseInt(min) - parseInt(andMin)>=0)) {
                        return 'vor etwa ' + '1 Stunde';
                    } else {
                        return 'vor ' + ((60 - parseInt(andMin)) + parseInt(min)).toString() + ' Minuten';
                    }
                }
            }
        } else {
            if (month.indexOf(mm) > -1) {
                return (parseInt(dd) - 1 == day) ? 'vor 1 Tag' : ('vor ' + (parseInt(dd) - parseInt(day)).toString() + ' Tagen');
            } else if (parseInt(mm) - 1 == month) {
                return (parseInt(dd) == 1 && new Date(parseInt(fullyear), month, 0).getDate() == day) ? 'vor 1 Tag' :
                    ('vor ' + (new Date(parseInt(fullyear), month, 0).getDate() + (parseInt(dd) - parseInt(day))).toString() + ' Tagen');
            } else {
                return timeItem.toString();
            }
        }
    }

    setNotReadRead(item: any) {
        var user = this.userData.getCurrentStvUser() != null && this.userData.getCurrentStvUser().bensl != null ? this.userData.getCurrentStvUser().bensl : this.userData.getCurrentUserData().bensl;
        this.geproService.setNotificationAsRead(user, item.externgeproid, 'false', item.id).subscribe(
            res => {
                this.notification = res;
                this.userData.getNotes();
            });
    }

    setAlreadyReadAll() {
        var user = this.userData.getCurrentStvUser() != null && this.userData.getCurrentStvUser().bensl != null ? this.userData.getCurrentStvUser().bensl : this.userData.getCurrentUserData().bensl;
        this.geproService.setNotificationsAllAsRead(user, 'true').subscribe(
            res => {
                this.notification = res;
                this.userData.getNotes();
            });
    }

    setAlreadyNotReadAll() {
        var user = this.userData.getCurrentStvUser() != null && this.userData.getCurrentStvUser().bensl != null ? this.userData.getCurrentStvUser().bensl : this.userData.getCurrentUserData().bensl;
        this.geproService.setNotificationsAllAsRead(user, 'false').subscribe(
            res => {
                this.notification = res;
                this.userData.getNotes();
            });
    }

    deleteNotification(item: any) {
        var user = this.userData.getCurrentStvUser() != null && this.userData.getCurrentStvUser().bensl != null ? this.userData.getCurrentStvUser().bensl : this.userData.getCurrentUserData().bensl;
        this.geproService.deleteNotification(user, item.externgeproid, item.id).subscribe(
            res => {
                this.notification = res;
                this.userData.getNotes();
            });
    }

    public show(): void {
        var user = this.userData.getCurrentStvUser() != null && this.userData.getCurrentStvUser().bensl != null ? this.userData.getCurrentStvUser().bensl : this.userData.getCurrentUserData().bensl;
        this.geproService.getAllNotifications(user).subscribe(
            res => {
                this.notification = res;
            });
        this.hinweiseDialog = this.dialogService.open(this.templateRef, {ariaLabel: 'Nachrichten', disableClose: true});
        this.hinweiseDialog.afterClosed().subscribe(result => {
            if (result === 'Close') {
                this.close();
            }
            this.hinweiseDialog.close();
        });
    }
}

<div class="nx-margin-top-m" nxLayout="grid">
    <div nxRow>
        <div nxCol="4" *ngIf="filterVisibilityService.isStatusVisible()">
            <app-filter-dropdown id="statusfilter" [disabled]="filterVisibilityService.isStatusDisabled()"
                                 #statusDropdown (filterSelectionChanged)="statusTypeFilterChanged($event)"
                                 [entries]="statusFilterEntries" [initialValues]="allowedStatusFilters"
                                 [name]="'Status'"
                                 [selectedFilters]="filterStatusType">
            </app-filter-dropdown>
        </div>
        <div nxCol="4" *ngIf="filterVisibilityService.isGeproTypeVisible()">
            <app-filter-dropdown #geproDropdown (filterSelectionChanged)="geproTypeFilterChanged($event)"
                                 [disabled]="filterVisibilityService.isGeproTypeDisabled()" id="geprotypefilter"
                                 [entries]="geproTypeFilterEntries" [name]="'Vorgangsart'" [selectedFilters]="filterGeproType">
            </app-filter-dropdown>
        </div>
        <div nxCol="4" *ngIf="filterVisibilityService.isMsnVisible()">
            <app-filter-dropdown id="msnfilter" [disabled]="filterVisibilityService.isMsnDisabled()"
                                 #msn6Dropdown (filterSelectionChanged)="msnFilterChanged($event)"
                                 [entries]="msn6FilterEntries" [selectedFilters]="filterMsn6"
                                 [name]="'Vermittler / Maklerhaus'">
            </app-filter-dropdown>
        </div>
    </div>
    <div nxRow>
        <div nxCol="4" *ngIf="filterVisibilityService.isCreatedDateVisible()">
            <app-filter-date id="createddatefilter" [disabled]="filterVisibilityService.isCreatedDateDisabled()"
                             #createDateChooser (dateRangeChanged)="createDateRangeChanged($event)"
                             title="Eingangsdatum"
                             [maxDate]="transformMomentToString(maxCreatedDate)"
                             [minDate]="transformMomentToString(minCreatedDate)"
                             [setFromDate]="filter_createdMinDate"
                             [setToDate]="filter_createdMaxDate">
            </app-filter-date>
        </div>
        <div nxCol="4" *ngIf="filterVisibilityService.isModifiedDateVisible()">
            <app-filter-date id="changeddatefilter" [disabled]="filterVisibilityService.isModifiedDateDisabled()"
                             #modifiedDateChooser (dateRangeChanged)="modifiedDateRangeChanged($event)"
                             title="Letzte Änderung"
                             [maxDate]="transformMomentToString(maxModifiedDate)"
                             [minDate]="transformMomentToString(minModifiedDate)"
                             [setFromDate]="filter_modifiedMinDate"
                             [setToDate]="filter_modifiedMaxDate"
            ></app-filter-date>
        </div>
        <div nxCol="4" *ngIf="filterVisibilityService.isBearbeiterVisible()">
            <app-filter-dropdown #bearbeiterDropdown (filterSelectionChanged)="bearbeiterFilterChanged($event)" [selectedFilters]="filterBearbeiterName"
                                 [disabled]="filterVisibilityService.isBearbeiterDisabled()" [entries]="bearbeiterFilterEntries"
                                 id="bearbeiterfilter"
                                 [name]="'Bearbeiter'">
            </app-filter-dropdown>
        </div>
    </div>
</div>

import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {GeproService} from '../../index/service/gepro.service';
import {GeproFreigabeTO} from '../../index/service/Model/GeproFreigabeTO';
import {VisibilityService} from '../../../services/visibility.service';
import {GeproDetails} from '../../../interfaces/gepro.interfaces';
import {UserMessagesService} from '../../common/messages-area/messages-area/service/user-messages.service';
import {StringEncodeDecodeService} from '../../../services/string-encode-decode.service';
import {UserinfoService} from '../../index/service/userinfo.service';

@Component({
    selector: 'app-gepro-freigabe',
    templateUrl: './gepro-freigabe.component.html',
    styleUrls: ['./gepro-freigabe.component.scss']
})
export class GeproFreigabeComponent implements OnInit {

    @Input()
    geproDetails: GeproDetails;

    @Input()
    schouldBeDisabled: boolean;

    @Output()
    refreshGepro = new EventEmitter();

    geproId: string;
    freigabeMessages: Array<GeproFreigabeTO>;
    dmslink: GeproFreigabeTO;
    freigabeMessage: string = null;
    latestGeproFreigabeRequestDraft: GeproFreigabeTO = null;

    constructor(private geproService: GeproService,
                public visibilityService: VisibilityService,
                private userMessagesService: UserMessagesService,
                private stringEncodeDecodeService: StringEncodeDecodeService,
                private readonly userInfoService: UserinfoService) {
    }

    ngOnInit() {
        this.geproId = this.geproDetails.id;
        this.loadGeproFreigabeInfos();
    }

    async loadGeproFreigabeInfos() {
        const geproFreigabeSubscription: Subscription = new Subscription();
        geproFreigabeSubscription.add(this.geproService.getFreigabeinfos(this.geproId).subscribe(geproFreigabe => {
            if (geproFreigabe) {
                this.freigabeMessages = geproFreigabe.filter(item => item.freigabeinfotyp !== 'LINK');
                const geproFreigabeLink = geproFreigabe.filter(item => item.freigabeinfotyp === 'LINK');
                this.dmslink = geproFreigabeLink.length > 0 ? geproFreigabeLink[0] : null;

                //ein spezielles Handling falls der letzte Freigabeintrag ein DRAFT ist (je nach Action nur anzeigen oder editierbar machen)
                if (this.freigabeMessages.length > 0 && this.freigabeMessages[this.freigabeMessages.length - 1].freigabeinfotyp === 'DRAFT') {
                    this.freigabeMessage = this.freigabeMessages[this.freigabeMessages.length - 1].freigabeinfo;
                    this.latestGeproFreigabeRequestDraft = this.freigabeMessages[this.freigabeMessages.length - 1];
                    this.freigabeMessages.splice(this.freigabeMessages.length - 1, 1);
                } else {
                    this.freigabeMessage = '';
                    this.latestGeproFreigabeRequestDraft = null;
                }
            }
        }, (error) => {
            this.userMessagesService.addErrorMessageString('Fehler beim Laden der Freigabe-Informationen. ' + error.error.message);
        }));
    }

    async refreshLink() {
        const geproFreigabeSubscription: Subscription = new Subscription();
        geproFreigabeSubscription.add(this.geproService.getFreigabeinfos(this.geproId).subscribe(geproFreigabe => {
            if (geproFreigabe) {
                const geproFreigabeLink = geproFreigabe.filter(item => item.freigabeinfotyp === 'LINK');
                this.dmslink = geproFreigabeLink.length > 0 ? geproFreigabeLink[0] : null;
            }
        }));
    }

    async forrelease() {
        try {
            const geproFreigabe = new GeproFreigabeTO();
            geproFreigabe.freigabeinfo = this.freigabeMessage;
            geproFreigabe.freigabeinfotyp = 'REQUEST';
            await this.geproService.forrelease(this.geproId, geproFreigabe);
            this.loadGeproFreigabeInfos();
            this.refreshGepro.emit();
        } catch (error) {
            this.userMessagesService.addErrorMessageString('Fehler beim Erstellen der Freigabeanforderung. ' + error.error.message);
        }
    }

    async release() {
        try {
            const geproFreigabe = new GeproFreigabeTO();
            geproFreigabe.freigabeinfo = this.freigabeMessage;
            geproFreigabe.freigabeinfotyp = 'RELEASE';
            await this.geproService.release(this.geproId, geproFreigabe);
            this.loadGeproFreigabeInfos();
            this.refreshGepro.emit();
        } catch (error) {
            this.userMessagesService.addErrorMessageString('Fehler bei der Freigabe des Vorgangs. ' + error.error.message);
        }
    }

    async processrelease() {
        try {
            const geproFreigabe = new GeproFreigabeTO();
            geproFreigabe.freigabeinfo = this.freigabeMessage;
            geproFreigabe.freigabeinfotyp = 'REDO';
            await this.geproService.processrelease(this.geproId, geproFreigabe);
            this.loadGeproFreigabeInfos();
            this.refreshGepro.emit();
        } catch (error) {
            this.userMessagesService.addErrorMessageString('Fehler bei der Freigabe des Vorgangs. ' + error.error.message);
        }
    }

    async denyforrelease() {
        try {
            await this.geproService.denyforrelease(this.geproId);
            this.loadGeproFreigabeInfos();
            this.refreshGepro.emit();
        } catch (error) {
            this.userMessagesService.addErrorMessageString('Fehler beim Zurückziehen der Freigabeaufforderung. ' + error.error.message);
        }
    }

    async addFreigabeDMSLink(dmslink: string) {
        try {
            const geproFreigabe = new GeproFreigabeTO();
            geproFreigabe.freigabeinfo = dmslink;
            geproFreigabe.freigabeinfotyp = 'LINK';
            await this.geproService.addFreigabeinfo(this.geproId, geproFreigabe);
            this.refreshLink();
       } catch (error) {
            this.userMessagesService.addErrorMessageString('Fehler beim Speichern des DMS-Links. ' + error.error.message);
        }
    }

    openDmsLink() {
        var link = this.stringEncodeDecodeService.decodeString(this.dmslink.freigabeinfo);
        window.open(link, '_blank');
    }

    showAddDmsLink(): boolean {
        return this.visibilityService.isEnabled('forrelease') && (this.dmslink == null || this.dmslink.freigabeinfo === '');
    }

    showShowDmsLink(): boolean {
        return !this.visibilityService.isEnabled('forrelease') ||
            (this.visibilityService.isEnabled('forrelease') && (this.dmslink != null && this.dmslink.freigabeinfo !== ''));
    }

    showEditDmsLink(): boolean {
        return this.visibilityService.isEnabled('forrelease') && (this.dmslink != null && this.dmslink.freigabeinfo !== '');
    }

    getFreigabeCreatorInfo(freigabeInfo: GeproFreigabeTO) {
        let result: string;
        if (!freigabeInfo || !freigabeInfo.createdByFullname) {
            return '';
        }
        if (!freigabeInfo || !freigabeInfo.stvCreatedByFullname) {
            return freigabeInfo.createdByFullname;
        }
        result = 'Stellv. '.concat(freigabeInfo.createdByFullname).concat (' für ').concat(freigabeInfo.stvCreatedByFullname);

        return result;
    }

    isButtonDisabled() {
        return this.isForreleaseDisabled() || this.schouldBeDisabled;
    }

    isForreleaseDisabled(): boolean {
        return (this.freigabeMessage == null || this.freigabeMessage === '' ||
                this.dmslink == null || this.dmslink?.freigabeinfo === '');
    }

    processreleaseEnabled(): boolean {
        let result: boolean;
        result = this.visibilityService.isEnabled('processrelease');
        if (result === true) {
            const latestFreigabeMsg = this.freigabeMessages ? this.freigabeMessages[this.freigabeMessages?.length - 1] : null;
            if (latestFreigabeMsg?.freigabeinfotyp === 'REQUEST' &&
                latestFreigabeMsg?.createdByFullname === this.userInfoService.getCurrentUserData().name) {
                return false;
            }
        }
        return result;
    }


    releaseEnabled(): boolean {
        let result: boolean;
        result = this.visibilityService.isEnabled('release');
        if (result === true) {
            const latestFreigabeMsg = this.freigabeMessages ? this.freigabeMessages[this.freigabeMessages?.length - 1] : null;
            if (latestFreigabeMsg?.freigabeinfotyp === 'REQUEST' &&
                latestFreigabeMsg?.createdByFullname === this.userInfoService.getCurrentUserData().name) {
                return false;
            }
        }
        return result;
    }
}

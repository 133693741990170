<div class="wrapper">
    <app-shell-stepper (stepChanged)="stepChanged($event)" [currentStep]="currentStep" (gotoStep)="gotoStep($event)"
                       (stepperInterface)="setStepper($event)"
                       [nextDisabled]="nextDisabled|| (isLastStep() && createGeproService.hasValidationErrors())||gepro?.geproType?.name.length<2||(isSecondStep()&&(gepro?.maklerName==undefined&&gepro?.routes.length<1))||(isThirdStep()&&(gepro.geproType.requiredDocuments.length>gepro.tickedDocuments.length))"></app-shell-stepper>

    <h2 class="nx-margin-top-m nx-margin-bottom-m">
        <strong>Vorgangsart: </strong>{{ gepro?.geproType?.name ? gepro?.geproType?.name : 'in Vorbereitung' }},
        <strong>ID: </strong>{{gepro?.id ? gepro.id : 'in Vorbereitung'}}
    </h2>
    <h1>
        {{ gepro?.geproType?.category === 'NAME_MAKLER' && currentStep?.titleNeurevers ? currentStep?.titleNeurevers : currentStep?.title}}
    </h1>
    <h3 class="nx-margin-top-m" *ngIf="subtitle()!==''">{{ subtitle() }}</h3>
    <div class="h3-spacer" *ngIf="subtitle()==''"></div>
    <div class="nx-margin-bottom-l" [attr.aria-current]="currentStep.name" *ngIf="gepro?.id!=null&&gepro?.id!=undefined&&gepro?.id.length>1; else loadingTemplate">
        <router-outlet (activate)="onActivateRouter($event)"></router-outlet>
    </div>

    <div class="buttons">
        <nav [attr.aria-label]="'Schritt Navigation'">
            <button nxButton="secondary medium" class="nx-margin-right-2xs" type="button" (click)="previous()"
                    [disabled]="!getHasPrevious()">
                <nx-icon name="arrow-left" class="nx-margin-right-2xs"></nx-icon>
                Zurück
            </button>
            <button (click)="toggleAbortModalOpened();abortModalComponent.show()" nxButton="secondary medium" class="nx-margin-right-2xs"
                    type="button">
                <nx-icon name="close" class="nx-margin-right-2xs"></nx-icon>
                Abbrechen
            </button>
            <app-abort-modal #abortModalComponent (aborted)="navigateToUrlAfterSave($event)" (confirmed)="deleteGepro($event)"
                             [gepro]="gepro"></app-abort-modal>
            <button (click)="next()" *ngIf="!isLastStep(); else btSend" nxButton="primary medium" type="button"
                    [disabled]="nextDisabled || (isLastStep() && createGeproService.hasValidationErrors())||gepro?.geproType?.name.length<2||(isSecondStep()&&(gepro?.maklerName==undefined&&gepro?.routes.length<1))||(isThirdStep()&&(gepro.geproType.requiredDocuments.length>gepro.tickedDocuments.length))">
                <nx-icon name="arrow-right" class="nx-margin-right-2xs"></nx-icon>
                Weiter
                <nx-spinner *ngIf="nextAction"></nx-spinner>
                <nx-icon name="exclamation-circle" *ngIf="error" class="error-icon"></nx-icon>
            </button>
            <ng-template #btSend>
                <button (click)="send()" [disabled]="nextDisabled
                                              || (isLastStep() && !createGeproService.allStepsCompleted())"
                        nxButton="primary medium"
                        type="button">
                    <nx-icon name="arrow-right" class="nx-margin-right-2xs"></nx-icon>
                    Senden
                    <nx-spinner *ngIf="nextAction"></nx-spinner>
                    <nx-icon *ngIf="error" class="error-icon" name="exclamation-circle"></nx-icon>
                </button>
            </ng-template>
        </nav>
    </div>
</div>
<ng-template #loadingTemplate>
    <nx-spinner style="text-align: center;margin-left: auto;
    margin-right: auto;" nxSize="large"></nx-spinner>
</ng-template>

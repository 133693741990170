import {Component, forwardRef, Input, ViewChild} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {NxInputDirective} from '@allianz/ngx-ndbx/input';
import {StringEncodeDecodeService} from '../../../services/string-encode-decode.service';

@Component({
    selector: 'app-encoded-text-area',
    templateUrl: './encoded-text-area.component.html',
    styleUrls: ['./encoded-text-area.component.css'],
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => EncodedTextAreaComponent),
        multi: true
    }]
})
export class EncodedTextAreaComponent implements ControlValueAccessor {

    @ViewChild('errorHint', { static: true })
    errorNgModel: NxInputDirective;

    @Input()
    required: boolean = false;
    @Input()
    readonly: boolean = false;
    @Input()
    disabled: boolean = false;
    @Input()
    maxLength: number = null;
    @Input()
    label: string;
    @Input()
    placeholder: string;
    @Input()
    minLines: number = 1;

    value: string;

    onChange = (_) => {};
    onTouched = () => {};

    constructor(private stringEncodeDecodeService: StringEncodeDecodeService) {

    }

    get model(): string {
        if (this.value != null) {
            return this.stringEncodeDecodeService.decodeString(this.value);
        }
    }

    set model(value: string) {
        var b64 = this.stringEncodeDecodeService.encodeString(value);
        this.value = b64;
        this.onChange(b64);
    }

    writeValue(value: any): void {
        this.value = value;
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }

    contentLength(): number {
        const value = this.model || '';
        return value.length;
    }

    isEmpty(): boolean {
        return this.contentLength() === 0;
    }

    isBase64(str) {
        if (str ==='' || str?.trim() ===''){ return false; }
        try {
            return btoa(atob(str)) === str;
        } catch (err) {
            return false;
        }
    }
}

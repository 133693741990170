import {Directive, HostListener, Input} from '@angular/core';
import {GeproRestTO} from '../index/service/Model/GeproRestTO';
import {StatesService} from '../../services/states.service';
import {UserinfoService} from '../index/service/userinfo.service';

@Directive({
    selector: '[appWorkListDragStart]'
})
export class WorkListDragStartDirective {

    constructor(private statesService: StatesService,
                private userinfoService: UserinfoService) {
    }

    @Input() item;

    @HostListener('dragstart', ['$event']) onDragStart($event): void {
        $event.dataTransfer.setData('text', this.item.id);
        if (this.isAssignable(this.item) || this.isDenyable(this.item)) {
            $event.dataTransfer.setData('assignable', 'true');
        }
    }

    public isAssignable(gepro: GeproRestTO): boolean {
        return this.statesService.assignableStatusTypes.filter(state => state.name == gepro.statusType.name).length == 1;
    }

    public isDenyable(gepro: GeproRestTO): boolean {
        if (this.userinfoService.isRoleAmvbMA() && !this.userinfoService.isRoleReferatsleiter()
            && gepro.statusType.name != 'VORBEREITUNG'
            && gepro.statusType.name != 'ABGELEHNT'
            && gepro.statusType.name != 'ABGESCHLOSSEN') {
            return true;
        }
        return false;
    }
}

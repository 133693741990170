import {AbstractType} from './AbstractType';
import * as moment from 'moment';

export type FreigabeTyp = 'LINK' | 'REQUEST' | 'REDO' | 'RELEASE' | 'DRAFT';

export class GeproFreigabeTO extends AbstractType {
    createdTs?: moment.Moment;
    createdByFullname?: string;
    freigabeinfo: string;
    freigabeinfotyp: FreigabeTyp;
    stvCreatedByFullname?: string;

}


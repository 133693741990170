import { Injectable } from '@angular/core';
import {DefaultHttpService} from './default-http.service';
import {Observable} from 'rxjs';
import {Endpoints} from '../components/navigation/endpoints';

@Injectable({
  providedIn: 'root'
})
export class LoginInfoService {

  constructor(private readonly http: DefaultHttpService) {}

  getAll(): Observable<Array<LoginInfo>> {
      return this.http.get<Array<LoginInfo>>(Endpoints.LOGIN_INFO);
  }
}

export interface LoginInfo {
    bensl: string;
    department: string;
    fullName: string;
}

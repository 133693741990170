import {Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {ChangelogEntry, GeproHistoryService} from '../../../services/gepro-history.service';
import {VisibilityService} from '../../../services/visibility.service';
import {GeproService} from '../../index/service/gepro.service';
import {NxDialogService, NxModalRef} from '@allianz/ngx-ndbx/modal';

@Component({
    selector: 'app-gepro-history-rueckfrage',
    templateUrl: './gepro-history-rueckfrage.component.html',
    styleUrls: ['./gepro-history-rueckfrage.component.css']
})
export class GeproHistoryRueckfrageComponent implements OnInit {

    @Input() item;

    @Input() geproId;

    @Output()
    answerQuestionClicked = new EventEmitter();

    @Output()
    cancelQuestionClicked = new EventEmitter();

    @ViewChild('zurueckziehenModalBody') templateRef: TemplateRef<any>;
    zurueckziehenModalBody: NxModalRef<any>;


    constructor(public geproHistoryService: GeproHistoryService,
                public visibilityService: VisibilityService,
                public geproService: GeproService,
                public dialogService: NxDialogService) {
    }

    ngOnInit() {
    }

    answerQuestion(streamObj: ChangelogEntry): void {
        this.answerQuestionClicked.emit(streamObj);
    }

    openCancelDialog(): void {
        this.zurueckziehenModalBody = this.dialogService.open(this.templateRef, { ariaLabel: 'Rückfrage zurückziehen', disableClose: true });
        this.zurueckziehenModalBody.afterClosed().subscribe(result => {
            if (result === 'CancelQuestion') {
                this.cancelQuestion();
            }
            this.zurueckziehenModalBody.close();
        });
    }


    cancelQuestion(): void {
        this.geproService.cancelQuestion(this.geproId)
            .then((_: any) => {
                this.cancelQuestionClicked.emit();
            });
    }


}

import {Component, OnInit} from '@angular/core';
import {StatesService} from '../../../services/states.service';
import {WorkListService} from '../../../services/work-list.service';
import {GeproChangeReferatRestTO} from '../../index/service/Model/GeproChangeReferatRestTO';
import {MoveGeproReferatService} from '../move-gepro-referat/service/move-gepro-referat.service';
import {GeproListWithMessageRestTO} from '../../index/service/Model/GeproListWithMessageRestTO';
import {DenyGeproService} from '../deny-gepro/service/deny-gepro.service';

@Component({
    selector: 'app-work-list-dropzone',
    templateUrl: './work-list-dropzone.component.html',
    styleUrls: ['./work-list-dropzone.component.css']
})
export class WorkListDropzoneComponent implements OnInit {

    constructor(private statesService: StatesService,
                private workListService: WorkListService,
                private moveGeproReferatService: MoveGeproReferatService,
                private denyGeproService: DenyGeproService) {
    }

    ngOnInit() {
    }

    public onDragOver($event): void {
        if ($event.dataTransfer.types[1] == 'assignable') { //TODO: We could remove drop-forbidden as well as the dataTransfer assingable attribute as we are not allowing dragging anymore
            $event.target.closest(".nx-card").setAttribute('drop-active', 'true');
            $event.preventDefault();
        } else {
            $event.target.closest(".nx-card").setAttribute('drop-forbidden', 'true');
        }
    }

    public onDragLeave($event) {
        $event.target.closest(".nx-card").removeAttribute('drop-active');
        $event.target.closest(".nx-card").removeAttribute('drop-forbidden');
    }


    public onDragEnd($event): void {
        $event.preventDefault();
        $event.stopPropagation();

        $event.target.closest(".nx-card").removeAttribute('drop-active');

        let draggedGeproId = $event.dataTransfer.getData('text');
        let targetId = $event.target.closest('.js-cardfinder').getAttribute('id');
        let targetClass = $event.target.closest('.js-cardfinder').getAttribute('class');

        if (!draggedGeproId) {
            return;
        }

        if (targetClass.indexOf('mitarbeiterCard') >= 0) {
            this.workListService.handleMitarbeiterCardDropped(draggedGeproId, targetId);
        } else if (targetClass.indexOf('referatCard') >= 0) {
            this.handleReferatCardDropped(draggedGeproId, targetId);
        } else if (targetClass.indexOf('referatsleiterCard') >= 0) {
            this.handleDenyGepro(draggedGeproId);
        } else {
            console.error('Unbekanntes Drag&Drop Ziel!');
        }
    }

    private handleReferatCardDropped(draggedGeproId, referatId) {
        const geproChangeReferatRestTO: GeproChangeReferatRestTO = new GeproChangeReferatRestTO();
        geproChangeReferatRestTO.geproId = draggedGeproId;
        geproChangeReferatRestTO.referatId = referatId;

        this.moveGeproReferatService.publishDialogOpened(geproChangeReferatRestTO);
    }

    private handleDenyGepro(draggedGeproId) {
        const geproListWithMessageRestTO: GeproListWithMessageRestTO = new GeproListWithMessageRestTO();
        geproListWithMessageRestTO.geproIdList = new Array<string>();
        geproListWithMessageRestTO.geproIdList.push(draggedGeproId);

        this.denyGeproService.publishDialogOpened(geproListWithMessageRestTO);
    }
}

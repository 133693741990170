<div class="d-flex flex-column js-date-filter">
    <div class="input-group">
        <span class="form-span">
            <nx-label style="float:left;">{{title}}</nx-label>
            <nx-formfield appearance="outline" class="no-padding-label" nxFloatLabel="always">
                <input [ngModel]="dateFromTo" class="form-control datepicker-input-text" disabled  nxInput
                       placeholder="tt.mm.jjjj - tt.mm.jjjj">
            </nx-formfield>
        </span>
        <div class="input-group-append">
            <button [disabled]="disabled"
                (click)="toggleDatepicker=!toggleDatepicker" [nxTooltip]="'Zieltermin von - bis'" class="cursor"
                    nxButton='secondary small-medium' type="button">
                <nx-icon name="calendar" size="auto"></nx-icon>
            </button>
        </div>

        <ngb-datepicker #dp="ngbDatepicker" (select)="onDateSelection($event)" *ngIf="toggleDatepicker"
                        [dayTemplate]="t"
                        [displayMonths]="1"
                        [maxDate]="maxDate"
                        [minDate]="minDate"
                        class="datepicker-container"></ngb-datepicker>
        <ng-template #t let-date="date" let-focused="focused=false">
            <span (mouseenter)="hoveredDate = date"
                  (mouseleave)="hoveredDate = null"
                  [class.faded]="isHovered(date) || isInside(date)"
                  [class.focused]="focused"
                  [class.not-selectable]="isNotSelectable(date)"
                  [class.range]="isRange(date)"
                  class="custom-day">
            {{ date.day }}
            </span>
        </ng-template>
    </div>
</div>

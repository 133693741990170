import {Component, Input, OnInit} from '@angular/core';
import {GeproHistoryService} from '../../../services/gepro-history.service';
import {UserinfoService} from "../../index/service/userinfo.service";
import {GeproRestTO} from "../../index/service/Model/GeproRestTO";
import {Pages} from "../../navigation/pages";
import {Router} from "@angular/router";

@Component({
    selector: 'app-gepro-history-item',
    templateUrl: './gepro-history-item.component.html',
    styleUrls: ['./gepro-history-item.component.scss']
})
export class GeproHistoryItemComponent implements OnInit {

    @Input() item;
    duplicateUrl : string[];
    duplicateText : string;
    duplicateId : string;
    redirectUrl: string;
    VTNR_DUPLICATE :string = "ACHTUNG: Zu diesem Vorgang unter dieser VTNR existiert bereits ein Vorgang mit der ID:"
    constructor(public geproHistoryService: GeproHistoryService, private router: Router) {
    }

    ngOnInit() {
        if(this.item.text.indexOf(this.VTNR_DUPLICATE)!==-1){
            this.duplicateUrl = this.item.text.split(this.VTNR_DUPLICATE);
            this.duplicateText = this.VTNR_DUPLICATE;
            this.duplicateId = this.duplicateUrl[1].replace(",","").trim();
        }
    }

    goToGepro() {
        this.redirectUrl = Pages.PATH_WORKLIST;
        let commandsId: Array<any>;
        commandsId = ['/'+Pages.PATH_GEPRO_DETAILS, this.duplicateId];
        this.router.navigate(commandsId);
    }




}

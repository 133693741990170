import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GeproUploadComponent} from './components/common/gepro-upload-component/gepro-upload.component';
import {NxErrorModule} from '@allianz/ngx-ndbx/base';
import {NxFormfieldModule} from '@allianz/ngx-ndbx/formfield';
import {NxButtonModule} from '@allianz/ngx-ndbx/button';
import {NxInputModule} from '@allianz/ngx-ndbx/input';
import {NxIconModule} from '@allianz/ngx-ndbx/icon';

@NgModule({
    declarations: [
        GeproUploadComponent
    ],
    imports: [
        CommonModule,
        NxFormfieldModule,
        NxButtonModule,
        NxInputModule, NxErrorModule, NxIconModule
    ],
    exports: [
        GeproUploadComponent
    ]
})
export class GeproMessageModule {
}

import {Component, Input, OnInit} from '@angular/core';
import {ChangelogEntry, GeproHistoryService} from '../../../services/gepro-history.service';
import {GeproHistory} from '../../../interfaces/gepro.interfaces';

@Component({
  selector: 'app-gepro-history-freigabe',
  templateUrl: './gepro-history-freigabe.component.html',
  styleUrls: ['./gepro-history-freigabe.component.scss']
})
export class GeproHistoryFreigabeComponent implements OnInit {

    @Input()
    item: ChangelogEntry;

  constructor(public geproHistoryService: GeproHistoryService) { }

  ngOnInit() {
  }


}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RejectModalComponent} from './reject-modal.component';
import {NxButtonModule} from '@allianz/ngx-ndbx/button';
import {NxCopytextModule} from '@allianz/ngx-ndbx/copytext';
import {NxHeadlineModule} from '@allianz/ngx-ndbx/headline';
import {NxModalModule} from '@allianz/ngx-ndbx/modal';
import {NxInputModule} from '@allianz/ngx-ndbx/input';
import {GeproMessageModule} from '../../../../gepro-message.module';
import {MessageModule} from '../../message/message.module';
import {ReactiveFormsModule} from '@angular/forms';
import {RestrictedTextAreaModule} from '../../restricted-text-area/restricted-text-area.module';

@NgModule({
    declarations: [RejectModalComponent],
    exports: [
        RejectModalComponent
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NxHeadlineModule,
        NxCopytextModule,
        NxModalModule,
        NxButtonModule,
        NxInputModule,
        GeproMessageModule,
        MessageModule,
        RestrictedTextAreaModule
    ]
})
export class RejectModalModule { }

import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, Observable} from 'rxjs';
import {AuthenticationService} from '../../components/index/service/authentication-service';
import {LoginToken} from '../../shared/loginToken';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    private newTokenSubject: BehaviorSubject<LoginToken> = new BehaviorSubject<LoginToken>(null);

    constructor(public authenticationService: AuthenticationService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        return next.handle(req);

        /*if (this.authenticationService.tokenExpiresIn() < 30) {
            this.newTokenSubject.next(null);
            return this.authenticationService.login().pipe(
                switchMap((token: LoginToken) => {
                    this.authenticationService.setLoginToken(token);
                    this.newTokenSubject.next(token);
                    return next.handle(this.addToken(req, token));
                }));
        }

        if(this.authenticationService.loggedIn()){
            console.log(this.authenticationService.getLoginToken());
            return next.handle(this.addToken(req, this.authenticationService.getLoginToken()));
        }*/

    }

    addToken(req: HttpRequest<any>, token: LoginToken) {
        return req.clone({
            setHeaders: {
                'Content-Type':  'application/json',
                Authorization: `Bearer ${token.logintoken}`
            }
        });
    }
}

import {Directive, ElementRef, Input, Renderer2} from '@angular/core';
import {VisibilityService} from '../../services/visibility.service';

@Directive({
    selector: '[appDisable]'
})
export class DisableDirective {

    constructor(private renderer: Renderer2,
                private el: ElementRef,
                private visibilityService: VisibilityService) {
    }

    @Input() gepro;

    @Input() set appDisable(functionName: string) {
        const disabled = !this.visibilityService.isEnabled(functionName, this.gepro);
        if (disabled) {
            this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'disabled');
        } else {
            this.renderer.removeAttribute(this.el.nativeElement, 'disabled');
        }
    }

    @Input() set appDisableDependsOnMultiStatus(functionName: string[]) {
        var isRender : boolean;
        for(var i=0; i<functionName.length; i++){
            const disabled = !this.visibilityService.isEnabled(functionName[i], this.gepro);
            if (disabled) {
                isRender=true;
            }
        }
        if (isRender) {
            this.renderer.setAttribute(this.el.nativeElement, 'disabled', 'disabled');
        } else {
            this.renderer.removeAttribute(this.el.nativeElement, 'disabled');
        }
    }
}

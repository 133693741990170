import {
    AfterContentChecked,
    AfterViewChecked,
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    forwardRef,
    Input,
    OnInit,
    TemplateRef
} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'app-gepro-info',
    templateUrl: './gepro-info.component.html',
    styleUrls: ['./gepro-info.component.css'],

})
export class GeproInfoComponent implements AfterViewInit, AfterViewChecked {

    @Input()
    maxRowsInTextarea: number = 6;
    @Input()
    readonlyText: string;
    @Input()
    buttonTemplate: TemplateRef<any>;

    private actualTextLines: number;
    private numberOfLinesToShow: number;
    private expandedTextarea: boolean;
    private showExpandBt: boolean = false;

    constructor(private cdref: ChangeDetectorRef) { }

    ngAfterViewChecked() {
        this.cdref.detectChanges();
    }

    ngAfterViewInit(): void {
        this.actualTextLines = this.getMaxRows();
        if (this.actualTextLines > this.maxRowsInTextarea) {
            this.numberOfLinesToShow = this.maxRowsInTextarea;
            this.showExpandBt = true;
        } else {
            this.numberOfLinesToShow = this.actualTextLines;
        }
    }

    getMaxRows(): number {
        let taLineHeight = 24; // This should match the line-height in the CSS
        let taHeight = document.getElementById('geproInfos') !== null ?
            document.getElementById('geproInfos').scrollHeight : 0; // Get the scroll height of the textarea
        let numberOfLines = Math.floor(taHeight / taLineHeight);
        return numberOfLines;
    }

    expandReduceClicked() {
        this.expandedTextarea = !this.expandedTextarea;
        if (this.expandedTextarea) {
            this.numberOfLinesToShow = this.actualTextLines;
        } else {
            this.numberOfLinesToShow = this.maxRowsInTextarea;
        }
    }

}

import {Injectable, OnDestroy} from '@angular/core';
import {DocumentStorageService} from './internal/document-storage.service';
import {DocumentUploadService} from './internal/document-upload.service';
import {FileService} from '../../../../index/service/file.service';
import {VorgangIdService} from './internal/vorgang-id.service';
import {merge, Observable, of, Subscription} from 'rxjs';
import {DocumentUploadIterator} from './internal/document-upload.iterator';
import {catchError, map} from 'rxjs/operators';
import {DocumentModel, mapToSuccessfullyUploadedDocument} from './document.model';
import {DocumentUploadValidatorService} from './internal/document-upload-validator.service';
import {DocumentErrorService} from './internal/document-error.service';

export type UploadTrigger = 'manual' | 'auto';

@Injectable()
export class DocumentService implements OnDestroy {

    private readonly changes$: Observable<void>;
    private readonly uploadSub: Subscription;

    private loadingSub: Subscription;
    private loadingFlag: boolean;
    private uploadTrigger: UploadTrigger = 'auto';

    static acceptedFileTypes(): string {
        return DocumentUploadValidatorService.acceptedFileTypes();
    }

    /**
     * Textual representation of existing upload restrictions.
     */
    static uploadRestrictions(): string {
        const acceptedTypes = DocumentService.acceptedFileTypes().replace(/\./g, '').toUpperCase();
        const maxFileSize = DocumentUploadValidatorService.allowedFileSizeMb();
        return `Zulässige Dateiformate: ${ acceptedTypes }; maximale Datei-Größe: ${ maxFileSize } mb`;
    }

    constructor(private readonly fileService: FileService,
                private readonly vorgangIdService: VorgangIdService,
                private readonly documentStorageService: DocumentStorageService,
                private readonly documentUploadService: DocumentUploadService,
                private readonly documentErrorService: DocumentErrorService) {
        this.setupUploadService();
        this.changes$ = merge(this.documentUploadService.documentProcessed(), this.documentStorageService.onRemoved());
        this.uploadSub = this.documentStorageService.onChanged().subscribe(_ => {
            if (this.uploadTrigger === 'auto') {
                this.documentUploadService.start();
            }
        });
    }

    private setupUploadService(): void {
        const documentIterator = new DocumentUploadIterator(this.documentStorageService);
        this.documentUploadService.setDocumentIterator(documentIterator);
    }

    ngOnDestroy(): void {
        this.unsubscribeFileService();
        this.uploadSub.unsubscribe();
    }

    /**
     * Reset the service. Call this for a fresh start.
     */
    reset(): void {
        this.documentStorageService.reset();
        this.documentErrorService.reset();
    }

    /**
     * Load existing data from the backend. Will call {@link reset} before loading the data.
     */
    initialize(): void {
        this.reset();
        this.unsubscribeFileService();
        this.loadingFlag = true;
        this.loadingSub = this.fileService.getAllBy(this.vorgangIdService.currentId).pipe(
            map(result => result.content),
            map(uploads => uploads.map(file => mapToSuccessfullyUploadedDocument(file))),
            catchError(_ => {
                this.loadingFlag = false;
                return of([]);
            })
        ).subscribe(previousUploads => {
            this.loadingFlag = false;
            this.documentStorageService.setDocuments(previousUploads);
        });
    }

    private unsubscribeFileService(): void {
        if (this.loadingSub) {
            this.loadingSub.unsubscribe();
        }
    }

    isLoading(): boolean {
        return this.loadingFlag;
    }

    documents(): Array<DocumentModel> {
        return this.documentStorageService.documents();
    }

    hasUploadedDocuments(): boolean {
        return this.documentStorageService.findDocumentByStatus('SUCCESS') != null;
    }

    hasQueuedDocuments(): boolean {
        return this.documentStorageService.findDocumentByStatus('WAITING') != null;
    }

    addAll(documents: FileList): void {
        this.documentStorageService.addAll(documents);
    }

    remove(document: DocumentModel): void {
        this.documentStorageService.remove(document);
    }

    onChanges(): Observable<void> {
        return this.changes$;
    }

    /**
     * Set whether a file may be deleted after an upload or not.
     * @param deletable
     */
    setDeletableAfterUploadFlag(deletable: boolean): void {
        this.documentStorageService.setDeletableAfterUploadFlag(deletable);
    }

    setVorgangId(vorgangId: string): void {
        this.vorgangIdService.currentId = vorgangId;
    }

    vorgangId(): string {
        return this.vorgangIdService.currentId;
    }

    setCustomErrorMessage(errorMessage: string): void {
        this.documentErrorService.setCustomErrorMessage(errorMessage);
    }

    setUploadTriggerMode(uploadTrigger: UploadTrigger): void {
        this.uploadTrigger = uploadTrigger;
    }

    uploadTriggerMode(): UploadTrigger {
        return this.uploadTrigger;
    }

    onDocumentUploaded(): Observable<void> {
        return this.documentUploadService.documentProcessed();
    }

    onDocumentRemoved(): Observable<void> {
        return this.documentStorageService.onRemoved();
    }

    hasError(): boolean {
        return this.documentErrorService.hasError();
    }

    errorMessage(): string {
        return this.documentErrorService.errorMessage();
    }

    /**
     * Start the upload manually. Only use in conjunction with manual {@link uploadTriggerMode}.
     */
    start(): void {
        this.documentUploadService.start(); // Prüfung, ob triggerMode = manual?
    }

    isRunning(): boolean {
        return this.documentUploadService.isRunning();
    }
}

import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {UserinfoService} from '../index/service/userinfo.service';
import {Subscription} from 'rxjs';
import {AuthenticationService} from '../index/service/authentication-service';
import {Pages} from './pages';
import {UserAuth} from '../../config/auth/user.auth';
import * as moment from 'moment';
import {EnvironmentConfig, EnvironmentLoaderService} from '../../services/environment-loader.service';
import {GeproService} from '../index/service/gepro.service';

@Component({
    selector: 'app-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit, OnDestroy {


    @Output()
    public openHinweiseModal: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output()
    public openNotificationModal: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output()
    public closeNotificationModal: EventEmitter<boolean> = new EventEmitter<boolean>();

    public showHeaderNavigation: boolean;
    public showHeaderAdminNavigation: boolean;
    public showHeaderAnwendungsinfoNavigation: boolean;
    private envConfig: EnvironmentConfig;

    private readonly routerEventSubscription: Subscription;
    private readonly environmentConfigSubscription: Subscription;

    private PATH_NAME_ROUTING = Pages.PATH_NAME_ROUTING;
    private PATH_MB_ROUTING = Pages.PATH_MB_ROUTING;
    private PATH_MSN_ROUTING = Pages.PATH_MSN_ROUTING;
    private PATH_NOT_ROUTABLE = Pages.PATH_NOT_ROUTABLE;
    private PATH_REFERAT_DATA = Pages.PATH_REFERAT_DATA;
    private PATH_WORKLIST = Pages.PATH_WORKLIST;
    private PATH_NOT_IMPLEMENTED = Pages.PATH_NOT_IMPLEMENTED;
    private PATH_CREATE_GEPRO = Pages.PATH_CREATE_GEPRO;
    private PATH_ADMINISTRATION = Pages.PATH_ADMINISTRATION;
    private PATH_DOCUMENT_ADMINISTRATION = Pages.PATH_DOCUMENT_ADMINISTRATION;
    private PATH_ADMIN_ADMINISTRATION = Pages.PATH_ADMIN_ADMINISTRATION;
    private PATH_NAME_REPORTING = Pages.PATH_NAME_REPORTING;
    private PATH_ANWENDUNGSINFO = Pages.PATH_ANWENDUNGSINFO;
    private PATH_ANWENDUNGSINFO_VERSIONSHINWEISE = Pages.PATH_ANWENDUNGSINFO_VERSIONSHINWEISE;
    private PATH_ANWENDUNGSINFO_AUSBLICK = Pages.PATH_ANWENDUNGSINFO_AUSBLICK;

    private readonly carnival = this.isCarnivalToday();
    private readonly christmas = moment().month() === 11; // December
    public notification: any[] = [];
    constructor(private router: Router,
                private userinfoService: UserinfoService,
                private geproService: GeproService,
                public authService: AuthenticationService,
                public userAuth: UserAuth,
                private environmentLoaderService: EnvironmentLoaderService) {
        this.routerEventSubscription = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                const indexUrl = `/${Pages.PATH_INDEX}`;
                const loginUrl = `/${Pages.PATH_LOGIN}`;
                this.showHeaderNavigation = (event.urlAfterRedirects !== indexUrl) && (event.urlAfterRedirects !== loginUrl);
                this.showHeaderAdminNavigation = (event.urlAfterRedirects.indexOf('/administration/') != -1);
                this.showHeaderAnwendungsinfoNavigation = (event.urlAfterRedirects.indexOf('/anwendungsinfo/') != -1);
            }
        });
        this.environmentConfigSubscription = this.environmentLoaderService.getConfigObservable().subscribe(config => {
            if (config) {
                this.envConfig = config;
                console.log('got Environment:', config);
            }
        });
    }

    async ngOnInit() {
        this.userinfoService.getNotes();
    }

    isAdminOrInfo(): boolean {
        return this.showHeaderAnwendungsinfoNavigation || this.showHeaderAdminNavigation ;
    }

    ngOnDestroy(): void {
        this.routerEventSubscription.unsubscribe();
    }

    public logout() {
        sessionStorage.clear();
        this.router.navigate([Pages.PATH_LOGOUT]);
    }

    isChristmas(): boolean {
        return this.christmas;
    }

    isCarnival(): boolean {
        return this.carnival;
    }

    private isCarnivalToday(): boolean {
        const easterSunday = this.easterDate().endOf('day');
        const carnivalEnd = easterSunday.clone().subtract(47, 'days');
        const carnivalBegin = carnivalEnd.clone().subtract(6, 'days');
        return moment().isBetween(carnivalBegin, carnivalEnd);
    }

    private easterDate(): moment.Moment {
        const easterDate = () => { // https://stackoverflow.com/questions/1284314/easter-date-in-javascript
            const y = moment().year();
            const c = Math.floor(y / 100);
            const n = y - 19 * Math.floor(y / 19);
            const k = Math.floor((c - 17) / 25);
            let i = c - Math.floor(c / 4) - Math.floor((c - k) / 3) + 19 * n + 15;
            i = i - 30 * Math.floor((i / 30));
            i = i - Math.floor(i / 28) * (1 - Math.floor(i / 28) * Math.floor(29 / (i + 1)) * Math.floor((21 - n) / 11));
            let j = y + Math.floor(y / 4) + i + 2 - c + Math.floor(c / 4);
            j = j - 7 * Math.floor(j / 7);
            const l = i - j;
            const m = 3 + Math.floor((l + 40) / 44);
            const d = l + 28 - 31 * Math.floor(m / 4);

            return moment(new Date(y, m - 1, d));
        };
        return easterDate();
    }


    goToBerichtswesen() {
        const link = this.envConfig.reporting;
        window.open(link, '_blank');
    }

    navigateToIndex() {
        this.router.navigate([Pages.PATH_INDEX]);
    }

    callReleasenotes() {
        this.userinfoService.setUserInformationForReleaseNote();
        this.openHinweiseModal.emit(true);
    }

    callNotifications() {
        if (!this.userinfoService.isDialogOpen) {
            this.userinfoService.isDialogOpen = true;
            this.userinfoService.setUserInformationForReleaseNote();
            this.openNotificationModal.emit(true);
        } else if (this.userinfoService.isDialogOpen) {
            this.userinfoService.isDialogOpen = false;
            this.closeNotificationModal.emit(true);
        }
    }

    onKeyPress($event: KeyboardEvent) {
        if ($event.code === 'Space' || $event.code === 'Enter') {
            this.callReleasenotes();
        }
    }

}

import {Injectable} from '@angular/core';
import {Endpoints} from '../components/navigation/endpoints';
import {of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import * as moment from 'moment';
import {UserinfoService} from '../components/index/service/userinfo.service';

@Injectable({
    providedIn: 'root'
})
export class GeproHistoryService {

    private smallestQuestionDate;
    public smallestQuestion;

    constructor(private httpClient: HttpClient,
                private userInfoService: UserinfoService) {
    }

    resetQuestionInfos() {
        this.smallestQuestionDate = null;
        this.smallestQuestion = null;
    }

    shouldBeHighlighted(streamObj: ChangelogEntry): boolean {
        if (streamObj && streamObj.entryType === 'QUESTION') {
            if (moment(streamObj.createdTs).toString() === moment(this.smallestQuestionDate).toString()) {
                return true;
            }
        }

        return false;
    }

    public updateSmallestQuestionDate(streamObj: ChangelogEntry) {
        const currentDate = streamObj.createdTs.toString();
        if (this.smallestQuestionDate) {
            if (moment(this.smallestQuestionDate).isBefore(currentDate)) {
                this.smallestQuestionDate = currentDate;
                this.smallestQuestion = streamObj;
            }
        } else {
            this.smallestQuestionDate = currentDate;
            this.smallestQuestion = streamObj;
        }
    }

    async historyList(geproId: string, preview: boolean): Promise<any> {
        let url: string = Endpoints.GEPRO_CHANGELOG_BY_ID
            .replace('${geproId}', geproId)
            .replace('${preview}', String(preview));
        if (this.userInfoService.isCurrentUserStvUser() && !this.userInfoService.getIsWorkingOnRLTab()) {
            url = url.concat('&asStvVonBensl=', this.userInfoService.getCurrentStvUser().bensl);
        }

        const data = await this.httpClient.get<any>(url).toPromise();
        const history: Array<ChangelogEntry> = data;
        return of(history).toPromise();
    }

    printEventCreatorInfo(streamObj: ChangelogEntry) {
        if (!streamObj || !streamObj.creator || !streamObj.creator.fullName) {
            return '';
        }

        let result: string;
        if (!streamObj.stv || !streamObj.stv.fullName) {
            result = streamObj.creator.fullName;
        } else {
            result = 'Stellv. '.concat(streamObj.creator.fullName).concat (' für ').concat(streamObj.stv.fullName);
        }

        if (streamObj.recipient) {
            result = result.concat(' an ' ).concat(streamObj.recipient.fullName);
        }

        return result;
    }

}

export interface ChangelogEntry {
    createdTs: string;
    creator: { bensl: string, fullName: string, referatId: string };
    entryType: string;
    text: string;
    subtype?: string;
    $iconExplanation?: string;
    $icon?: string;
    question: ChangelogEntry;
    recipient?: { bensl: string, fullName: string, referatId: string };
    stv: { bensl: string, fullName: string, referatId: string };
}

import {Component, HostListener, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MaklerstrukturService} from '../../../../../services/maklerstruktur.service';
import {CreateGeproService} from '../../../../../services/create-gepro.service';
import {Subscription} from 'rxjs';
import {GeproRestTO} from '../../../../index/service/Model/GeproRestTO';
import {MaklerstrukturTreeComponent} from '../maklerstruktur-tree/maklerstruktur-tree.component';
import {MsnTreeChecklistComponent} from '../msn-tree-checklist/msn-tree-checklist.component';

@Component({
    selector: 'app-maklerstruktur-selection',
    templateUrl: './maklerstruktur-selection.component.html',
    styleUrls: ['./maklerstruktur-selection.component.css']
})
export class MaklerstrukturSelectionComponent implements OnInit, OnDestroy {

    // @ViewChild(MaklerstrukturTreeComponent, {static: true})
    // maklerstrukturTreeComponent: MaklerstrukturTreeComponent;

    @ViewChild(MsnTreeChecklistComponent, {static: true})
    maklerstrukturTreeComponent: MsnTreeChecklistComponent;


    private readonly msnVtnrPattern = '^([\\d]{9})|^([\\d]{2}/[\\d]{3}/[\\d]{4})';
    private readonly msnFormatPattern = '^[FfVvKkPpIiAaSs]/?\\d{4}(/?\\d{2}){0,2}';
    private readonly pattern = this.msnFormatPattern + '|' + this.msnVtnrPattern;
    private readonly vtnrFormatPattern = '^\\d.*';

    private subscription: Subscription = new Subscription();

    private selectedGepro: GeproRestTO;

    public msnVtnrFormGroup: FormGroup;
    private boundTextValue: string;

    showLoadingIndicator: boolean;
    sucheBtDisabled: boolean;

    private isRequired = false;

    constructor(private maklerstrukturService: MaklerstrukturService,
                private createGeproService:CreateGeproService) {
        this.msnVtnrFormGroup = new FormGroup({
                'msnVtnr': new FormControl(this.boundTextValue)
            }
        );
    }

    ngOnInit() {
        this.setupValidators();
        this.subscription.add(this.createGeproService.geproObservable().subscribe(value => this.onGeproChanged(value)));
        this.subscription.add(this.maklerstrukturService.maklerTreeFinishedSubject.subscribe(() => {
            this.showLoadingIndicator = false;
        }));
        this.subscription.add(this.maklerstrukturService.getMaklerTreeSubject().subscribe(tree => {
            if(tree == null){
                this.showLoadingIndicator = false;
            }
        }));
        this.sucheBtDisabled = true;
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    @Input()
    public set required(value: boolean) {
        this.isRequired = value;
        this.setupValidators();
    }

    public get required(): boolean {
        return this.isRequired;
    }

    private setupValidators(): void {
        const control = this.getValueControl();
        if (control) {
            control.clearValidators();
            const validators = [Validators.pattern(this.pattern)];
            if (this.isRequired) {
                validators.push(Validators.required);
            }
            control.setValidators(validators);
            control.markAsUntouched();
        }
    }

    private onGeproChanged(gepro: GeproRestTO) {
        this.selectedGepro = gepro;
        if (gepro && gepro.routes && gepro.routes.length > 0) {
            this.getValueControl().setValue(gepro.routes[0].root);
        }
    }

    onTextChange($event: any): void {
        let text: string = $event;
        if (text) {
            text = text.toLocaleUpperCase().trim();
            if (text.match(this.msnFormatPattern)) {
                text = this.formatText(text, [1, 6, 9], 12);
            } else if (text.match(this.vtnrFormatPattern)) {
                text = this.formatText(text, [2, 6], 11);
            }
            if (text !== this.getValueControl().value) {
                this.getValueControl().setValue(text);
            }
        }
        if (this.msnVtnrFormGroup.valid) {
            this.sucheBtDisabled = false;
        } else {
            this.sucheBtDisabled = true;
        }
    }

    private formatText(text: string, positions: Array<number>, maxlength: number): string {
        let formattedString = text.replace(/\//g, '');
        for (let pos of positions) {
            formattedString = this.checkAddStringAtPosition(formattedString, pos, '/');
        }

        if (formattedString.length > maxlength) {
            formattedString = formattedString.slice(0, maxlength);
        }

        return formattedString;
    }


    private checkAddStringAtPosition(text: string, pos: number, str: string): string {
        if (text.length > pos && text.charAt(pos) != str) {
            return text.slice(0, pos) + str + text.slice(pos);
        }
        return text;
    }

    queryForMsnVtnr(): void {
        if (this.msnVtnrFormGroup.valid) {
            let preparedMsnVtnr = this.prepareMsnVtnrForQuery(this.getValueControl().value);
            this.showLoadingIndicator = false;
            this.maklerstrukturTreeComponent.resetTree();
            this.maklerstrukturService.queryMaklerStrukturTree(preparedMsnVtnr, true);
        }
    }

    getValueControl() {
        return this.msnVtnrFormGroup.controls['msnVtnr'];
    }

    private prepareMsnVtnrForQuery(text: string): string {
        if (text.match(this.vtnrFormatPattern)) {
            return text.replace(/\//g, '');
        }
        return text;
    }

    showHinweis(): boolean {
        return this.selectedGepro && this.selectedGepro.geproType && this.selectedGepro.geproType.id === 28;
    }
}

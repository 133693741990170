import {EditableTableRow} from '../../../../common/editable-table/prototypes/EditableTableRow';
import {EditableTableCell} from '../../../../common/editable-table/prototypes/EditableTableCell';
import {NameRoutingRestTO} from '../../../../index/service/Model/NameRoutingRestTO';
import {ErrorMessages} from '../../../../common/ErrorMessages';
import {EditableTableDropdownCell} from '../../../../common/editable-table/prototypes/EditableTableDropdownCell';

export class NameRoutingEditableDataRow extends EditableTableRow {

    private originalValue: NameRoutingRestTO;

    private rowValues: Array<EditableTableCell>;
    private readonly regExpArray: Array<RegExp>;
    private errorMessagesArray: Array<string> = new Array<string>(ErrorMessages.PREFIX, ErrorMessages.REFERENT, "");

    private mitarbeiterValuesStrings: Array<string>;
    private benslToDisplayNameMap: Map<string, string> = new Map<string, string>();

    constructor(mitarbeiterValuesStrings: Array<string>, benslToDisplayNameMap: Map<string, string>, private existingPrefixList: string[]) {
        super();
        this.mitarbeiterValuesStrings = mitarbeiterValuesStrings;
        this.benslToDisplayNameMap = benslToDisplayNameMap;
        this.regExpArray = new Array<RegExp>(NameRoutingRestTO.PREFIX_REGEXP, new RegExp(".*"), new RegExp(".*"));
    }

    public toTransportObject(): NameRoutingRestTO {
        const transportObject = NameRoutingRestTO.deepClone(this.originalValue);
        transportObject.prefixStart = this.rowValues[0].value;
        transportObject.mitarbeiterBensl = this.getBenslFromDisplayName(this.rowValues[1].value);
        transportObject.newlyCreated = this.newlyCreated;
        transportObject.deleted = this.deleted;
        transportObject.edited = this.edited;

        return transportObject;
    }

    public setValuesFrom(nameRoutingRestTO: NameRoutingRestTO): void {
        this.originalValue = NameRoutingRestTO.deepClone(nameRoutingRestTO);
        this.setRowValuesFromOriginalObject();
    }

    getRowValues(): Array<EditableTableCell> {
        return this.rowValues;
    }

    public hasOneOfValuesChanged(): boolean {
        return this.rowValues[0].value !== this.originalValue.prefixStart ||
            this.rowValues[1].value !== this.benslToDisplayNameMap.get(this.originalValue.mitarbeiterBensl);
    }

    isChanged(): boolean {
        return this.deleted || this.newlyCreated || this.hasOneOfValuesChanged();
    }

    onKeyUpInInputField(cell: EditableTableCell) {
        //TODO Bensl per GD abfragen
    }

    private getBenslFromDisplayName(name: string): string {
        if (name) {
            return name.substr(0, name.indexOf(" ") != -1 ? name.indexOf(" ") : name.length);
        }
        return undefined;
    }

    private setRowValuesFromOriginalObject(): void {
        let bensl:string = this.originalValue.mitarbeiterBensl ? this.originalValue.mitarbeiterBensl : null;

        this.rowValues = new Array<EditableTableCell>();
        this.rowValues.push(new EditableTableCell(this.originalValue.prefixStart, true, true));
        this.rowValues.push(new EditableTableDropdownCell(this.benslToDisplayNameMap.get(bensl), this.mitarbeiterValuesStrings, false));
        this.rowValues.push(new EditableTableCell(this.originalValue.referat.name, false, false));
    }

    getErrorMessagesArray(): Array<string> {
        return this.errorMessagesArray;
    }

    getValidationRegexpArray(): Array<RegExp> {
        return this.regExpArray;
    }
}

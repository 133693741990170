<div class="input-container">
    <nx-progressbar [value]="uploadProgress"></nx-progressbar>
    <input #realInput type="file" name="file" id="real-input" (change)="readFile($event); realInput.value = null" size="50" accept=".pdf, .doc, .docx, .xls, .xlsx" multiple/>
    <button #browseBtn type="button" (click)="passClickEvent()" class="browse-btn">
        Durchsuchen
    </button>
    <span #fileInfo class="file-info js-file-info">Datei auswählen...</span>
</div>
<nx-icon name="close-circle-o" class="js-removeUploadField" size="s" (click)="removeUploadField()"></nx-icon>

<span>
    <nx-icon *ngIf="statusWaiting" name="clock-o" size="s"></nx-icon>
    <nx-icon *ngIf="statusUploading" name="arrow-upload" size="s"></nx-icon>
    <nx-icon *ngIf="statusProcessing" name="save-o" size="s"></nx-icon>
    <nx-spinner *ngIf="statusProcessing"></nx-spinner>
    <nx-icon *ngIf="statusFinished" name="check" size="s"></nx-icon>
</span>

<nx-error *ngIf="fileTooLarge" #errorFileTooLarge>
    Datei ist zu groß. (Max. 100MB)
</nx-error>
<nx-error *ngIf="fileError" #errorFileError>
    Upload fehlgeschlagen
</nx-error>

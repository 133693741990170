import {EditableTableDataWrapper} from '../../../../common/editable-table/prototypes/EditableTableDataWrapper';
import {NameRoutingRestTO} from '../../../../index/service/Model/NameRoutingRestTO';
import {NameRoutingEditableDataRow} from './NameRoutingEditableDataRow';
import {EditableTableHeader} from '../../../../common/editable-table/prototypes/EditableTableHeader';
import {GeproRoutingService} from '../../../../index/service/gepro-routing.service';
import {Injectable} from '@angular/core';
import {EditableTableRow} from '../../../../common/editable-table/prototypes/EditableTableRow';
import {UserMessagesService} from '../../../../common/messages-area/messages-area/service/user-messages.service';
import {MitarbeiterReferatMappingService} from '../../../../index/service/mitarbeiter-referat-mapping.service';
import {AdminService} from '../../../../index/service/admin.service';
import {UserTO} from '../../../../index/service/Model/UserTO';
import {ErrorMessages} from '../../../../common/ErrorMessages';

@Injectable()
export class NameRoutingEditableTableDataWrapper extends EditableTableDataWrapper {

    private tableData: Array<NameRoutingEditableDataRow>;
    private columnNames: Array<EditableTableHeader>;
    private existingPrefixList: string[] = [];

    private mitarbeiterValuesStrings: Array<string>;
    private benslToDisplayNameMap: Map<string, string> = new Map<string, string>();

    constructor(private mitarbeiterReferatDataService:MitarbeiterReferatMappingService,
                private adminService: AdminService,
                private geproRoutingService: GeproRoutingService, protected userMessagesService: UserMessagesService) {
        super(userMessagesService);
        this.buildTableColumnHeaders();
    }

    public queryFreshDataFromServer(): void {
        this.geproRoutingService.queryNameRoutingListGepro().subscribe(data => {
            this.userMessagesService.errorAreaClosedSubject.next();
            this.userMessagesService.successAreaClosedSubject.next();

            for (let nameRoutingRestTO of data) {
                this.existingPrefixList.push(nameRoutingRestTO.prefixStart);
            }

            this.adminService.getAllMitarbeiter().subscribe(mitarbeiterListData => {
                this.convertAndSetMitarbeiterValues(mitarbeiterListData);
                this.convertSortAndSetData(data);
            },e => {
                console.log(e);
                this.handleBackendError(e, this.ERROR_QUERY_MESSAGE);
            });

            this.isEditModeOn = false;
        }, (e) => {
            this.handleBackendError(e, this.ERROR_QUERY_MESSAGE);
        });
    }

    private convertAndSetMitarbeiterValues(mitarbeiterValues:Array<UserTO>): void {
        const arrayMitarbeiter = new Array<string>();
        this.benslToDisplayNameMap = new Map<string, string>();

        for (let mitarbeiter of mitarbeiterValues) {
            let mitarbeiterName = (mitarbeiter.surname && mitarbeiter.givenname) ? " (" + mitarbeiter.surname + ", " + mitarbeiter.givenname + ")" : "";
            this.benslToDisplayNameMap.set(mitarbeiter.bensl, mitarbeiter.bensl + mitarbeiterName);
            arrayMitarbeiter.push(mitarbeiter.bensl + mitarbeiterName);
        }

        this.mitarbeiterValuesStrings = arrayMitarbeiter;
    }

    public isDataValid(): boolean {
        return super.isDataValid() && this.checkPrefixForDuplicate();
    }

    private checkPrefixForDuplicate(): boolean {

        var currentPrefixes = [];
        this.tableData.forEach(function(row) {
            currentPrefixes.push(row.getRowValues()[0].value)
        });

        var set = new Set(currentPrefixes);

        if(currentPrefixes.length != set.size){
            this.showError(ErrorMessages.PREFIX_USED);
            return false;
        }

        return true;
    }

    getNewEditableTableRow(): EditableTableRow {
        let newRowObject = new NameRoutingEditableDataRow(this.mitarbeiterValuesStrings, this.benslToDisplayNameMap, this.existingPrefixList);
        newRowObject.setValuesFrom(new NameRoutingRestTO(null, null, null, null));

        newRowObject.newlyCreated = true;
        return newRowObject;
    }

    private buildTableColumnHeaders(): void {
        this.columnNames = new Array<EditableTableHeader>();
        this.columnNames.push(new EditableTableHeader('Buchstabenblock ab', 'prefixStart'));
        this.columnNames.push(new EditableTableHeader('Referent', 'mitarbeiter'));
        this.columnNames.push(new EditableTableHeader('Referat', 'referat'));
    }

    private sortTableData(): void {
        this.tableData = this.tableData.sort((a, b) => this.compare(a.getRowValues()[0].value, b.getRowValues()[0].value, 'asc'));
    }

    persistDataToServer(): void {
        const listToPersist = new Array<NameRoutingRestTO>();
        for (let row of this.getTableData()) {
            listToPersist.push(row.toTransportObject());
        }

        this.geproRoutingService.saveNameRoutingList(listToPersist).subscribe((data) => {
            this.showSuccess(this.SUCCESS_SAVE_MESSAGE);
            this.isEditModeOn = false;
            this.queryFreshDataFromServer();
        }, (e) => {
            this.handleBackendError(e, this.ERROR_SAVE_MESSAGE);
        });
    }

    getColumnNames(): Array<EditableTableHeader> {
        return this.columnNames;
    }

    getTableData(): Array<NameRoutingEditableDataRow> {
        return this.tableData;
    }

    setTableData(newData: Array<EditableTableRow>): void {
        this.tableData = <Array<NameRoutingEditableDataRow>> newData;
    }

    private convertSortAndSetData(nameRoutingRestTOList: Array<NameRoutingRestTO>): void {
        const convertedList = new Array<NameRoutingEditableDataRow>();

        if (nameRoutingRestTOList) {
            for (let nameRoutingRestTO of nameRoutingRestTOList) {
                let convertedObject = new NameRoutingEditableDataRow(this.mitarbeiterValuesStrings, this.benslToDisplayNameMap, this.existingPrefixList);
                convertedObject.setValuesFrom(nameRoutingRestTO);
                convertedList.push(convertedObject);
            }
        }

        this.tableData = convertedList;
        this.sortTableData();
    }

    newElementCreated(element: EditableTableRow): void {
        this.tableData.push(<NameRoutingEditableDataRow> element);
        this.sortTableData();
    }

    isNewElementCreationAllowed(): boolean {
        return true;
    }

    isElementDeletionAllowed(): boolean {
        return true;
    }
}

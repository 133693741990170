import {Component, OnInit} from '@angular/core';
import {AusblickService} from '../../components/index/service/ausblick.service';
import {AusblickTO} from '../../components/index/service/Model/AusblickTO';
import {StringEncodeDecodeService} from '../../services/string-encode-decode.service';

@Component({
    selector: 'app-ausblick-component',
    templateUrl: './ausblick.component.html',
    styleUrls: ['./ausblick.component.scss']
})
export class AusblickComponent implements OnInit {
    ausblick: AusblickTO;

    constructor(private ausblickService: AusblickService,
                private stringEncodeDecodeService: StringEncodeDecodeService,) {
    }

    ngOnInit() {
        this.ausblickService.getPublishedAusblick().subscribe(myAusblick => {
            this.ausblick = myAusblick;
            if (this.ausblick.text && this.ausblick.text != null) {
                const decodedAusblick = this.stringEncodeDecodeService.decodeString(this.ausblick.text);
                this.ausblick.decodedText = decodedAusblick;
            } else {
                this.ausblick.decodedText = '';
            }
            },
            error => {
                console.log(error);
            }
        );
    }






}

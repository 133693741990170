<div class="messages_wrapper">
    <nx-message *ngIf="errorMessages.length > 0" closable="true" (close)="errorClosed()" nxContext="error">
        Folgende Fehler sind aufgetreten:
        <ul *ngFor="let error of errorMessages">
            <li><strong>Fehler:</strong> {{ error.message }}</li>
        </ul>
    </nx-message>
    <nx-message *ngIf="successMessages.length > 0" closable="true" (close)="successClosed()" nxContext="success">
        <ul *ngFor="let success of successMessages">
            <li>{{success.message}}</li>
        </ul>
    </nx-message>
    <nx-message *ngIf="infoMessages.length > 0" closable="true" (close)="infoClosed()" nxContext="info">
        Hinweis:
        <ul *ngFor="let info of infoMessages">
            <li>{{info.message}}</li>
        </ul>
    </nx-message>
</div>

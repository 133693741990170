<app-restricted-text-area #comment
                          [label]="'Zusatzinfo (optional)'"
                          [maxLength]="40"
                          (ngModelChange)="commentChanged(comment.isInvalid(), $event)"
                          [(ngModel)]="zusatzInfoModel"></app-restricted-text-area>
<br>

<app-maklerstruktur-selection *ngIf="msnEnabled else neureversierung"
                              appFocus="input"
                              [required]="required()"></app-maklerstruktur-selection>

<ng-template #neureversierung>
    <app-restricted-text-area #textArea
                              #nameInput="ngModel"
                              label="Vermittler *"
                              placeholder="Bitte Vermittlername eingeben"
                              appFocus="textarea"
                              [required]="required()"
                              [maxLength]="255"
                              (ngModelChange)="selectionChange($event, !textArea.isInvalid())"
                              [(ngModel)]="gepro.maklerName"></app-restricted-text-area>
</ng-template>


import {Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {EditableTableDataWrapper} from './prototypes/EditableTableDataWrapper';
import {SortEvent} from '@allianz/ngx-ndbx/table';
import {EditableTableNewEntryService} from './service/editable-table-new-entry.service';
import {EditableTableRow} from './prototypes/EditableTableRow';
import {EditableTableCell} from './prototypes/EditableTableCell';
import {UserMessagesService} from '../messages-area/messages-area/service/user-messages.service';
import {Subscription} from 'rxjs';
import {NxDialogService, NxModalRef} from '@allianz/ngx-ndbx/modal';

interface LegendEntry {
    class: string;
    title: string;
}

@Component({
    selector: 'editable-table',
    templateUrl: './editable-table.component.html',
    styleUrls: ['./editable-table.component.scss'],
    providers: [EditableTableNewEntryService]
})
export class EditableTableComponent implements OnInit, OnDestroy {

    private accumulatedSubscription: Subscription = new Subscription();


    @Input()
    public wrapper: EditableTableDataWrapper;

    @ViewChild('modalDiscardDialog') templateRef: TemplateRef<any>;
    modalDiscardDialog: NxModalRef<any>;

    constructor(private editableTableNewEntryService: EditableTableNewEntryService,
                private userMessagesService: UserMessagesService,
                public dialogService: NxDialogService) {
    }

    ngOnInit() {
        this.accumulatedSubscription.add(this.editableTableNewEntryService.newEntrySubject.subscribe(element => this.wrapper.newElementCreated(element)));
        this.wrapper.queryFreshDataFromServer();
    }

    ngOnDestroy(): void {
        this.accumulatedSubscription.unsubscribe();
    }

    public sortTable(sort: SortEvent): void {
        let columnNames = this.wrapper.getColumnNames();
        for (let i = 0; i < columnNames.length; i++) {
            if (columnNames[i].sortValue == sort.active) {
                this.wrapper.sortTableByPosition(sort, i);
                break;
            }
        }
    }

    public editButtonClicked(): void {
        this.wrapper.isEditModeOn = !this.wrapper.isEditModeOn;
        this.wrapper.disabledSaveButtonMessage = this.wrapper.DISABLED_SAVE_NOCHANGES_MESSAGE;
    }

    public saveButtonClicked(): void {
        this.wrapper.persistDataToServer();
    }

    public addRowButtonClicked(): void {
        this.editableTableNewEntryService.newNameRoutingDialogOpened();
    }

    public discardButtonClicked(): void {
        if (this.wrapper.isChangesMade()) {
            this.modalDiscardDialog = this.dialogService.open(this.templateRef, { ariaLabel: 'Änderungen beibehalten oder verwerfen', disableClose: true  });
            this.modalDiscardDialog.afterClosed().subscribe(result => {
                if (result === 'Verwerfen') {
                    this.discardChanges();
                }
                this.modalDiscardDialog.close();
            });
        } else {
            this.wrapper.isEditModeOn = false;
        }
    }

    public onKeyUpInInputField(item: EditableTableRow, cell: EditableTableCell): void {
        if (!item.deleted && !item.isValid()) {
            this.wrapper.disabledSaveButtonMessage = this.wrapper.DISABLED_INVALID_DATA_MESSAGE;
            let message = this.wrapper.DISABLED_INVALID_DATA_MESSAGE;
            if (item.hasCustomError()) {
                message = item.getCustomErrorMessage();
            }
            this.wrapper.showError(message);
        } else {
            let rowValues = item.getRowValues();
            for (let i = 0; i < rowValues.length; i++) {
                let element = rowValues[i];
                if (element.shouldBeStoredInUppercase && element.value) {
                    rowValues[i].value = element.value.trim().toUpperCase();
                }
            }
            this.userMessagesService.errorAreaClosedSubject.next();
            item.onKeyUpInInputField(cell);
        }

        item.edited = !item.newlyCreated && item.isChanged();
    }

    public isEditButtonDisabled(): boolean {
        return this.wrapper.getTableData() == null;
    }

    public isSaveButtonDisabled(): boolean {
        return !this.wrapper.isChangesMade() || !this.wrapper.isDataValid();
    }

    public discardChanges(): void {
        this.wrapper.discardChanges();
        this.userMessagesService.errorAreaClosedSubject.next();
        this.userMessagesService.successAreaClosedSubject.next();
    }

    legendEntries(): Array<LegendEntry> {
        const legendEntries = [];
        if (this.wrapper.isNewElementCreationAllowed()) {
            legendEntries.push({ class: 'newRow', title: 'Wird neu angelegt' });
        }
        legendEntries.push({ class: 'edited', title: 'Wird editiert'});
        if (this.wrapper.isElementDeletionAllowed()) {
            legendEntries.push({ class: 'deleted', title: 'Wird gelöscht' });
        }
        return legendEntries;
    }
}

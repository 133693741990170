import {GeproFile} from '../../../../../interfaces/gepro.interfaces';

export type Status = 'SUCCESS' | 'FAILURE' | 'WAITING' | 'UPLOADING';

interface ValidationResult {
    status: Status;
    errorMessage?: string;
}

export interface DocumentModel extends ValidationResult {
    vorgangId: string;
    file: File;
    uploadedFile?: GeproFile;
    isDeletableAfterUpload?: boolean;
}

export function mapToSuccessfullyUploadedDocument(geproFile: GeproFile): DocumentModel {
    return {
        vorgangId: geproFile.geproId,
        file: mapToFile(geproFile),
        uploadedFile: geproFile,
        status: 'SUCCESS'
    };
}

function mapToFile(geproFile: GeproFile): File {
    return {
        name: geproFile.fileName,
        size: geproFile.size,
        lastModified: undefined,
        type: geproFile.type,

        arrayBuffer(): Promise<ArrayBuffer> {
            return undefined;
        }, stream(): ReadableStream<any> {
            return undefined;
        }, text(): Promise<string> {
            return undefined;
        },
        slice(start?: number, end?: number, contentType?: string): Blob {
            return undefined;
        }
    };
}

import {AfterViewChecked, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {CreateGeproService} from '../../../services/create-gepro.service';
import {BaseComponent} from '../base/base.component';
import {GeproRouteRestTO, RoutingType} from '../../index/service/Model/gepro-route-rest.to';
import {NgModel} from '@angular/forms';
import {MaklerstrukturSelectionComponent} from './maklerstruktur/maklerstruktur-selection/maklerstruktur-selection.component';

@Component({
    selector: 'app-broker',
    templateUrl: './broker.component.html',
    styleUrls: ['./broker.component.css']
})
export class BrokerComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewChecked {

    next = false;
    completed = false;
    zusatzInfoModel: string;
    pageName = 'Vermittler';

    msnEnabled: boolean = false;

    @ViewChild(MaklerstrukturSelectionComponent, {static: false}) private maklerstrukturSelectionComponent: MaklerstrukturSelectionComponent;
    @ViewChild('nameInput', {static: false}) private inputModel: NgModel;

    constructor(public createGeproService: CreateGeproService) {
        super(createGeproService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.createGeproService.validateTypeData(this.gepro);
        this.subscribeToGeproChanges();
    }

    commentChanged(isInvalid: boolean, value: string): void {
        const errors = [];
        let zusatzinfo = value;
        if (isInvalid) {
            console.log("zusatzinfo undefined");
            zusatzinfo = undefined;
            errors.push({ error: true });
        }
        this.gepro.zusatzInfo = zusatzinfo;
        console.log(this.gepro.zusatzInfo.toString());
        this.setValidationErrors(errors);
    }

    ngAfterViewChecked(): void {
        this.markErrors();
        let errors: boolean = this.getValidationErrors() && this.getValidationErrors().length > 0;
        let routes: boolean = this.gepro && this.gepro.routes && this.gepro.routes.length > 0;
        this.setIsCompleted(!errors && routes);
    }

    ngOnDestroy(): void {
        super.ngOnDestroy();
        // if (this.msnEnabled && this.maklerstrukturSelectionComponent.msnVtnrFormGroup && this.maklerstrukturSelectionComponent.msnVtnrFormGroup.errors) {
        //     this.setValidationErrors([this.maklerstrukturSelectionComponent.msnVtnrFormGroup.errors]);
        // } else if (this.required() && (!this.gepro || !this.gepro.routes || this.gepro.routes.length === 0)) {
        //     this.setValidationErrors([{error: true}]);
        //     this.setIsCompleted(false);
        // } else if (!this.msnEnabled && this.inputModel && this.inputModel.errors) {
        //     this.setValidationErrors([this.inputModel.errors]);
        //     this.setIsCompleted(false);
        // } else {
        //     this.setValidationErrors([]);
        //     this.createGeproService.setIsCompleted(this.pageName, true);
        // }
        this.createGeproService.validateBrokerData(this.gepro);
    }

    public required(): boolean {
        if (!this.gepro || !this.gepro.geproType || !this.gepro.geproType.requiredFields) {
            return true; // in doubt require routes ...
        }
        return this.gepro.geproType.requiredFields.routes;
    }

    /*
    Subscribes to gepro changes and saves the gepro when saveIntedWas Triggered (Is triggered when "Weiter" is clicked)
    */
    public callGeproObservables() {
        this.subscription.add(this.createGeproService.geproObservable().subscribe(data => {
            this.gepro = data;
            this.zusatzInfoModel = this.gepro ? this.gepro.zusatzInfo : undefined;
        }));

        this.subscription.add(this.createGeproService.saveIntentObservable().subscribe(() => {
            //this should work when the gepro was modified while the user was on page
            this.onSaveIntentReceived();
        }));
    }

    private onSaveIntentReceived() {
        this.createGeproService.saveGepro(this.gepro).then(() => {
            this.saveSuccessful.emit(true);
        });
    }

    private subscribeToGeproChanges() {
        this.subscription.add(this.createGeproService.geproObservable().subscribe(data => {
            if (data.geproType && (data.geproType.category === 'NAME_MAKLER')) {
                this.msnEnabled = false;
            } else {
                this.msnEnabled = true;
            }
        }));
    }

    selectionChange($event: any, isValid: boolean) {
        console.log('selectionChange with event', $event);
        if (!this.msnEnabled) {
            if ($event && isValid) { // = maklerName
                this.gepro.routes = [this.newGeproRoute('NAME', $event)];
            } else {
                this.gepro.routes = [];
            }
        }
    }

    private newGeproRoute(type: RoutingType, value: string): GeproRouteRestTO {
        let existingRoute = null;
        if (this.gepro.routes) {
            existingRoute = this.gepro.routes
                .find(existing => existing.routingType === type && existing.value === value);
        }

        return existingRoute ? existingRoute : {id: null, routingType: type, value: value};
    }

    private markErrors() {
        if (this.getValidationErrors().length > 0) {
            setTimeout(() => {
                if (this.maklerstrukturSelectionComponent && this.maklerstrukturSelectionComponent.msnVtnrFormGroup) {
                    this.maklerstrukturSelectionComponent.msnVtnrFormGroup.markAllAsTouched();
                } else if (this.inputModel) {
                    this.inputModel.control.markAllAsTouched();
                }
            }, 0);
        }
    }
}


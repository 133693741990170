<div class="card mitarbeiter-referat-ansicht-list">
    <nx-card [ngClass]="hasFullname(mitarbeiter) ? 'mitarbeiterCard js-cardfinder' : 'mitarbeiterCard_bensl js-cardfinder'" id="{{mitarbeiter.bensl}}"
             *ngFor="let mitarbeiter of mitarbeiterList"
             [nxTooltip]="showFullnameTooltip(mitarbeiter)">
        <app-work-list-dropzone *ngIf="hasFullname(mitarbeiter)" class="container">
            <b>{{getDisplayName(mitarbeiter)}}</b>
            <div *ngIf="mitarbeiter?.statistics?.openGeproCount>0" i18n><b>{{mitarbeiter?.statistics?.openGeproCount}}</b>&nbsp;{mitarbeiter?.statistics?.openGeproCount, plural, =1 {Vorgang} other {Vorgänge}}
            </div>
            <div *ngIf="mitarbeiter?.statistics?.openGeproCount===0">Keine Vorgänge
            </div>
        </app-work-list-dropzone>
        <button *ngIf="isFlyoutVisible() && hasFullname(mitarbeiter)"
                [disabled]="isFlyoutDisabled()"
            class="listIcon js-showmitarbeiteralbutton" nxIconButton='tertiary' aria-label="Show Arbeitsliste"
                (click)="showMitarbeiterALButtonClicked(mitarbeiter)">
            <nx-icon name="list" size="s"></nx-icon>
        </button>
    </nx-card>
</div>

<app-mitarbeiter-worklist (worklistClosed)='onWorklistClosed($event)' [isDialogOpen]="isMitarbeiterALDialogOpen"
                          [mitarbeiter]="selectedMitarbeiter"></app-mitarbeiter-worklist>


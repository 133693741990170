<div class="event-stream--item {{item?.entryType}} js-event-item">
    <div [class.highlight]="geproHistoryService.shouldBeHighlighted(item)">
        <div class="align-icon">
            <nx-icon name="product-heart" size="s" [name]="item?.$icon" [title]="item?.$iconExplanation"
                     nxTooltip="{{item?.$iconExplanation}}"></nx-icon>
            <div>
                <div class="event-stream--item-date d-inline-flex" style="vertical-align: 0">
                    {{geproHistoryService.printEventCreatorInfo(item)}}, <app-timestamp [timestamp]="item?.createdTs"></app-timestamp>
                </div>
                <div class="d-flex"></div>
                <div class="historyBody" *ngIf="item?.text.indexOf(VTNR_DUPLICATE)<0">
                    <div [innerHTML]="item?.text" class="event-stream--item-note d-inline-flex"></div>
                </div>
                <div class="historyBody event-stream--item-note d-inline-flex" *ngIf="item?.text.indexOf(VTNR_DUPLICATE)!==-1">
                    <span style="color:red" [innerHTML]="duplicateText" ></span>
                    <span style="font-weight: bold; color: darkred; margin-left: 10px" [innerHTML]="duplicateId"></span>
                </div>
            </div>
        </div>
        <ng-content></ng-content>
    </div>
</div>

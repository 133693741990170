import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LabeledContainerComponent} from './labeled-container.component';
import {FlexModule} from '@angular/flex-layout';
import {NxCardModule} from '@allianz/ngx-ndbx/card';
import {NxHeadlineModule} from '@allianz/ngx-ndbx/headline';
import {MatDividerModule} from '@angular/material/divider';


@NgModule({
    declarations: [LabeledContainerComponent],
    exports: [
        LabeledContainerComponent
    ],
    imports: [
        CommonModule,
        FlexModule,
        NxHeadlineModule,
        MatDividerModule,
        NxCardModule,
    ]
})
export class LabeledContainerModule {
}

import {DocumentModel} from '../document.model';

export class DocumentUploadValidatorService {

    private static ACCEPTED_FILE_TYPES: Array<string> = ['.pdf', '.doc', '.docx', '.xls', '.xlsx', '.msg', '.tif'];
    private static ALLOWED_FILE_SIZE_MB = 100; // 100 MB
    private static ALLOWED_FILE_SIZE_BYTE = 1024 * 1024 * DocumentUploadValidatorService.ALLOWED_FILE_SIZE_MB; // 100 MB
    private static ERROR_FILE_TYPE = 'Dateityp ist nicht zulässig. Folgende Typen sind zulässig: ' +
        `${DocumentUploadValidatorService.acceptedFileTypes()}.`;
    private static ERROR_FILE_SIZE = 'Datei ist zu groß für einen Upload. Die maximale Dateigröße beträgt ' +
        `${DocumentUploadValidatorService.ALLOWED_FILE_SIZE_MB} mb.`;

    static allowedFileSizeMb(): number {
        return DocumentUploadValidatorService.ALLOWED_FILE_SIZE_MB;
    }

    static acceptedFileTypes(): string {
        return DocumentUploadValidatorService.ACCEPTED_FILE_TYPES.join(', ');
    }

    validate(document: DocumentModel): DocumentModel {
        if (document) {
            const file = document.file;
            const name = (file.name || '');
            const index = name.lastIndexOf('.');
            const extension = name.substring(index);
            const size = file.size || 0;

            if (!DocumentUploadValidatorService.ACCEPTED_FILE_TYPES.includes(String(extension).toLowerCase())) {
                document.status = 'FAILURE';
                document.errorMessage = DocumentUploadValidatorService.ERROR_FILE_TYPE;
            } else if (size > DocumentUploadValidatorService.ALLOWED_FILE_SIZE_BYTE) {
                document.status = 'FAILURE';
                document.errorMessage = DocumentUploadValidatorService.ERROR_FILE_SIZE;
            } else {
                document.status = 'UPLOADING';
            }
            return document;
        }
        return undefined;
    }
}

import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {MaklerStrukturNodeRestTO} from "../../index/service/Model/MaklerStrukturNodeRestTO";
import {GeproRestTO} from "../../index/service/Model/GeproRestTO";
import {TodoItemFlatNode, TodoItemNode} from "./gepro-tree-checklist-example";
import {MaklerstrukturService} from "../../../services/maklerstruktur.service";
import {CreateGeproService} from "../../../services/create-gepro.service";
import {GeproRouteRestTO, RoutingType} from "../../index/service/Model/gepro-route-rest.to";
import {UploadInfo} from "../../../services/routing-upload.service";
import {GeproService} from "../../index/service/gepro.service";
import {UserinfoService} from "../../index/service/userinfo.service";
import {VorgangRouteService} from "../../../services/vorgang-route.service";
import {Pages} from "../../navigation/pages";
import {Router} from "@angular/router";

@Component({
    selector: 'gepro-msn6-tree-checklist',
    templateUrl: './gepro-msn-tree-checklist.component.html',
    styleUrls: ['./gepro-msn-tree-checklist.component.css']
})
export class GeproMsnTreeChecklistComponent implements OnInit, OnDestroy {

    @Input()
    geproLsz : string;
    @Input()
    geproId: string;
    @Input()
    msnLsz: string;
    @Input()
    callbackFunction: (args: any) => void;
    routes: Array<GeproRouteRestTO> = new Array<GeproRouteRestTO>();
    isSaveAvailable : boolean = false;
    nodeCounter : number = 0;
    maklerStrukturTree: MaklerStrukturNodeRestTO = null;
    maklerStrukturChanged: boolean = false;

    rootElementViolation: boolean = false;

    private selectedGepro: GeproRestTO;
    private subscription: Subscription = new Subscription();

    flatNodeMap = new Map<TodoItemFlatNode, TodoItemNode>();
    nestedNodeMap = new Map<TodoItemNode, TodoItemFlatNode>();
    treeControl: FlatTreeControl<TodoItemFlatNode>;
    treeFlattener: MatTreeFlattener<TodoItemNode, TodoItemFlatNode>;
    dataSource: MatTreeFlatDataSource<TodoItemNode, TodoItemFlatNode>;

    /** The selection for checklist */
    checklistSelection = new SelectionModel<TodoItemFlatNode>(true /* multiple */);

    /** statistics */
    strukturIdToRecursivelySelectedVtnrCountMap: Map<string, number> = new Map<string, number>();


    constructor(private maklerstrukturService: MaklerstrukturService,
                private geproService: GeproService,
                private userData: UserinfoService,
                private router: Router,
                private vorgangRouteService: VorgangRouteService,
                public createGeproService: CreateGeproService) {
        this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel,
            this.isExpandable, this.getChildren);
        this.treeControl = new FlatTreeControl<TodoItemFlatNode>(this.getLevel, this.isExpandable);
        this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    }

    ngOnInit() {
        this.subscription.add(this.maklerstrukturService.getMaklerTreeObservable().subscribe(_data => this.onMaklerStrukturTreeChange(_data)));
        this.subscription.add(this.createGeproService.geproObservable().subscribe(_data => this.handleGeproChanged(_data)));
    }

    ngOnDestroy(): void {
       this.subscription.unsubscribe();
    }

    resetTree(): void {
        this.maklerStrukturTree = null;
    }

    private handleGeproChanged(gepro: GeproRestTO): void {
        this.selectedGepro = gepro;

        if (this.selectedGepro && this.selectedGepro.routes && this.selectedGepro.routes.length > 0) { // query tree for any route element, because backend can deal with it
            let queryVtnrs: boolean = this.isVtnrsQueryRequired();
            this.maklerstrukturService.queryMaklerStrukturTree(this.selectedGepro.routes[0].root, queryVtnrs);
        }
    }

    private isVtnrsQueryRequired(): boolean {
        let retVal = false;
        this.selectedGepro.routes.some(route => {
            if (route.routingType == "VTNR") {
                retVal = true;
                return true;
            }
        });
        return retVal;
    }

     async changeReferatAndMsn(){
        try {
            if (this.selectedGepro.routes) {
           await this.vorgangRouteService.saveLszRoute(this.geproId, this.selectedGepro.routes).toPromise();
           await this.geproService.saveUnindefinedlsz(this.geproId, this.userData.getCurrentUserData().bensl, this.selectedGepro.routes[0].root).toPromise();
           this.strukturIdToRecursivelySelectedVtnrCountMap.clear();
                this.maklerStrukturTree.getFlatMapOfAllNodes().clear();
                this.dataSource.data.forEach(d => d.children.shift())
                this.treeControl.collapseAll();
                this.getMapOfAllNodes().forEach(flatnode => {
                        // this.checklistSelection.deselect(flatnode);
                        // this.checklistSelection.clear();
                        this.deleteSelectedNode(flatnode);
                        this.descendantsAllSelected(flatnode);
                });
                this.getMapOfAllNodes().clear()
           this.navigateToWorklist();
            }
        } catch (error) {
            console.error(error);
        }
    }

    goToGepro() {
        let commands: Array<any>;
        commands = ['/' + Pages.PATH_GEPRO_DETAILS+'/'+this.geproId];
        this.router.navigate(commands);
    }

    private onMaklerStrukturTreeChange(maklerStrukturTree: MaklerStrukturNodeRestTO): void {
        if (maklerStrukturTree) {
            this.maklerStrukturChanged = false;
            this.maklerStrukturTree = maklerStrukturTree;
            this.strukturIdToRecursivelySelectedVtnrCountMap.clear();
            const maklerTree = this.buildTreeForMSN6(maklerStrukturTree);
            const maklerTreeList = new Array<TodoItemNode>();
            maklerTreeList.push(maklerTree);
            this.dataSource.data = maklerTreeList;

            this.checklistSelection.clear();
            this.getMapOfAllNodes().forEach(flatnode => {
                if (this.isFlatnodeChecked(flatnode)) {
                    this.checklistSelection.select(flatnode);
                }
            });

            const myTodoItemFlatNode = this.getMapOfAllNodes().get(this.maklerstrukturService.getSearchValue());

            setTimeout(() => {
                if (this.maklerstrukturService.isSearchValueVTNR()) {
                    this.checklistSelection.select(myTodoItemFlatNode);
                    this.firstItemSelectionToggle(myTodoItemFlatNode);
                }
                this.treeControl.expandAll();
                if (myTodoItemFlatNode.level > 0 &&this.maklerstrukturService &&this.maklerstrukturService.getSearchedVtnrTreeComponent()) {
                    this.maklerstrukturService.getSearchedVtnrTreeComponent().elementRef.nativeElement.scrollIntoView();
                }
                if (this.selectedGepro && this.selectedGepro.routes && this.selectedGepro.routes.length > 0 && this.maklerStrukturTree) {

                        this.selectedGepro.routes.forEach(route => this.handleAddStatisticsForNode(this.getMapOfAllNodes().get(route.value)));

                }
            }, 300);
        } else {
            this.maklerStrukturTree = null;
            this.maklerStrukturChanged = true;
        }
    }

    buildTreeForMSN6(maklerStrukturTree: MaklerStrukturNodeRestTO): TodoItemNode {
        const  maklerNode = new TodoItemNode();
        maklerNode.item = maklerStrukturTree.strukturId;
        maklerNode.recursiveChildrenCount = maklerStrukturTree.recursiveChildrenCount;
        maklerNode.name = maklerStrukturTree.name;
        if (maklerStrukturTree.children) {
            maklerNode.children = new Array<TodoItemNode>();
            maklerStrukturTree.children.forEach(child => {
                maklerNode.children.push(this.
                buildTreeForMSN6(child));
            });
        }
        return maklerNode;
    }

    isFlatnodeChecked(flatnode: TodoItemFlatNode): boolean {
        return this.selectedGepro && this.selectedGepro.routes &&
            this.selectedGepro.routes.findIndex(route => route.value === flatnode.item) > -1;
    }

    public getMapOfAllNodes(): Map<string, TodoItemFlatNode> {
        const map = new Map<string, TodoItemFlatNode>();
        const list = this.treeControl.dataNodes;

        list.forEach(element => map.set(element.item, element));
        return map;
    }

    getLevel = (node: TodoItemFlatNode) => node.level;

    isExpandable = (node: TodoItemFlatNode) => node.expandable;

    getChildren = (node: TodoItemNode): TodoItemNode[] => node.children;

    hasChild = (_: number, _nodeData: TodoItemFlatNode) => _nodeData.expandable;


    /**
     * Transformer to convert nested node to flat node. Record the nodes in maps for later use.
     */
    transformer = (node: TodoItemNode, level: number) => {
        const existingNode = this.nestedNodeMap.get(node);
        const flatNode = existingNode && existingNode.item === node.item
            ? existingNode
            : new TodoItemFlatNode();
        flatNode.item = node.item;
        flatNode.level = level;
        flatNode.expandable = !!node.children?.length;
        flatNode.recursiveChildrenCount = node.recursiveChildrenCount;
        flatNode.name = node.name;
        this.flatNodeMap.set(flatNode, node);
        this.nestedNodeMap.set(node, flatNode);
        return flatNode;
    }

    navigateToWorklist() {
        this.router.navigate(['/workList']);
    }

    /** Whether all the descendants of the node are selected. */
    descendantsAllSelected(node: TodoItemFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected = descendants.length > 0 && descendants.every(child => {
            return this.checklistSelection.isSelected(child);
        });
        return descAllSelected;
    }

    /** Whether part of the descendants are selected */
    descendantsPartiallySelected(node: TodoItemFlatNode): boolean {
        const descendants = this.treeControl.getDescendants(node);
        const result = descendants.some(child => this.checklistSelection.isSelected(child));
        return result && !this.descendantsAllSelected(node);
    }

    /** Toggle the to-do item selection. Select/deselect all the descendants node */
    todoItemSelectionToggle(node: TodoItemFlatNode): void {
        this.updateNode(node, !this.checklistSelection.isSelected(node));
        this.checklistSelection.toggle(node);
        const descendants = this.treeControl.getDescendants(node);
        this.checklistSelection.isSelected(node)
            ? this.checklistSelection.select(...descendants)
            : this.checklistSelection.deselect(...descendants);

        // Force update for the parent
        descendants.forEach(child => this.checklistSelection.isSelected(child));
        // this.checkAllParentsSelection(node); // aktivieren wenn Parent ausgewählt werden soll sofern alle Children ausgewählt sind
    }

    firstItemSelectionToggle(node: TodoItemFlatNode): void {
        this.checklistSelection.toggle(node);
        const descendants = this.treeControl.getDescendants(node);
        this.checklistSelection.isSelected(node)
            ? this.checklistSelection.select(...descendants)
            : this.checklistSelection.deselect(...descendants);

        // Force update for the parent
        descendants.forEach(child => this.checklistSelection.isSelected(child));
        // this.checkAllParentsSelection(node); // aktivieren wenn Parent ausgewählt werden soll sofern alle Children ausgewählt sind
    }

    /** Toggle a leaf to-do item selection. Check all the parents to see if they changed */
    todoLeafItemSelectionToggle(node: TodoItemFlatNode): void {
        this.updateNode(node, !this.checklistSelection.isSelected(node));
        this.checklistSelection.toggle(node);
        // this.checkAllParentsSelection(node); // aktivieren wenn Parent ausgewählt werden soll sofern alle Children ausgewählt sind
    }

    /** Checks all the parents when a leaf node is selected/unselected */
    checkAllParentsSelection(node: TodoItemFlatNode): void {
        let parent: TodoItemFlatNode | null = this.getParentNode(node);
        while (parent) {
            this.checkRootNodeSelection(parent);
            parent = this.getParentNode(parent);
        }
    }

    /** Check root node checked state and change it accordingly */
    checkRootNodeSelection(node: TodoItemFlatNode): void {
        const nodeSelected = this.checklistSelection.isSelected(node);
        const descendants = this.treeControl.getDescendants(node);
        const descAllSelected = descendants.length > 0 && descendants.every(child => {
            return this.checklistSelection.isSelected(child);
        });
        if (nodeSelected && !descAllSelected) {
            this.checklistSelection.deselect(node);
        } else if (!nodeSelected && descAllSelected) {
            this.checklistSelection.select(node);
        }
    }

    /** Get the parent node of a node */
    getParentNode(node: TodoItemFlatNode): TodoItemFlatNode | null {
        const currentLevel = this.getLevel(node);

        if (currentLevel < 1) {
            return null;
        }

        const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

        for (let i = startIndex; i >= 0; i--) {
            const currentNode = this.treeControl.dataNodes[i];

            if (this.getLevel(currentNode) < currentLevel) {
                return currentNode;
            }
        }
        return null;
    }

    updateRoutes(): void {
        const myRoutes = new Array<GeproRouteRestTO>();
        this.checklistSelection.selected.forEach(selection => {
            const routingType: RoutingType = selection.item.indexOf('/') >= 0 ? 'MSN' : 'VTNR';
            const geproRouteRestTO = new GeproRouteRestTO(-1, routingType, selection.item);
            geproRouteRestTO.root = this.treeControl.dataNodes[0].item;
            myRoutes.push(geproRouteRestTO);
        });
        this.selectedGepro.routes = myRoutes;
    }

    updateNode(node: TodoItemFlatNode, state: boolean): void {
        if (state) {
            if (!this.checklistSelection.isSelected(node)) { // to avoid duplicate entries
                this.addSelectedNode(node);
            }
        } else {
            if (this.checklistSelection.isSelected(node)) {
                this.deleteSelectedNode(node);
            }
        }

        if (node.expandable) {
            const descendants = this.treeControl.getDescendants(node);
            descendants.filter(desc => desc.level === node.level + 1).forEach(descendant => this.updateNode(descendant, state));
        }
    }

    private addSelectedNode(node: TodoItemFlatNode): void {
        const myRoutes = new Array<GeproRouteRestTO>();
        const routingType: RoutingType = node.item.indexOf('/') >= 0 ? 'MSN' : 'VTNR';
        const geproRouteRestTO = new GeproRouteRestTO(-1, routingType, node.item);
        geproRouteRestTO.root = this.maklerStrukturTree.strukturId;
        myRoutes.push(geproRouteRestTO);
        this.selectedGepro.routes = myRoutes;
        this.handleAddStatisticsForNode(node);
        this.nodeCounter=this.nodeCounter+1;
    }

    private deleteSelectedNode(node: TodoItemFlatNode): void {
        this.selectedGepro.routes = this.selectedGepro.routes.filter(route => route.value !== node.item);
        this.handleDeleteStatisticForNode(node);
        this.nodeCounter=this.nodeCounter-1;
    }

    public incrementRecursiveCountForNode(node: TodoItemFlatNode): void {
        const count = this.strukturIdToRecursivelySelectedVtnrCountMap.get(node.item) ?
            this.strukturIdToRecursivelySelectedVtnrCountMap.get(node.item) : 0;
        this.strukturIdToRecursivelySelectedVtnrCountMap.set(node.item, count + 1);
    }

    public decrementRecursiveCountForNode(node: TodoItemFlatNode): void {
        const count = this.strukturIdToRecursivelySelectedVtnrCountMap.get(node.item) ?
            this.strukturIdToRecursivelySelectedVtnrCountMap.get(node.item) : 1;
        this.strukturIdToRecursivelySelectedVtnrCountMap.set(node.item, count - 1);
    }


    public getSelectedSubNodesCount(node: TodoItemFlatNode): number {
        return this.strukturIdToRecursivelySelectedVtnrCountMap.has(node.item)
            ? this.strukturIdToRecursivelySelectedVtnrCountMap.get(node.item)
            : 0;
    }

    public getSubNodesCount(node: TodoItemFlatNode): number {
        return node.recursiveChildrenCount;
    }

    public handleDeleteStatisticForNode(node: TodoItemFlatNode) {
        if (!node.expandable) {
            this.decrementRecursiveCountForNode(node);
            let parent: TodoItemFlatNode = this.getParentNode(node);
            while (parent != null) {
                this.decrementRecursiveCountForNode(parent);
                parent = this.getParentNode(parent);
            }
        }
    }

    public handleAddStatisticsForNode(node: TodoItemFlatNode) {
        if (!node.expandable) {
            this.incrementRecursiveCountForNode(node);
            let parent: TodoItemFlatNode = this.getParentNode(node);
            while (parent != null) {
                this.incrementRecursiveCountForNode(parent);
                parent = this.getParentNode(parent);
            }
        }
    }

    isHighlighted(node: TodoItemFlatNode): boolean {
        return this.maklerstrukturService.getSearchValue() === node.item;
    }

    doNothing($event) {
        $event.preventDefault();
        $event.stopPropagation();
        return false;
    }
}

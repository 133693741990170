import {Component, Input, OnInit} from '@angular/core';
import {GeproHistoryService} from '../../../services/gepro-history.service';

@Component({
  selector: 'app-gepro-history-rueckfrage-cancel',
  templateUrl: './gepro-history-rueckfrage-cancel.component.html',
  styleUrls: ['./gepro-history-rueckfrage-cancel.component.scss']
})
export class GeproHistoryRueckfrageCancelComponent implements OnInit {

    @Input() item;

  constructor(public geproHistoryService: GeproHistoryService) { }

  ngOnInit() {
  }

}

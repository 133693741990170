import {Injectable} from '@angular/core';
import {DefaultHttpService} from './default-http.service';
import {GeproRouteRestTO, RoutingType} from '../components/index/service/Model/gepro-route-rest.to';
import {Observable} from 'rxjs';
import {Endpoints} from '../components/navigation/endpoints';
import {UserinfoService} from '../components/index/service/userinfo.service';

export interface VorgangRoutes {
    content: Array<GeproRouteRestTO>;
    numberOfElements: number;
    totalElements: number;
}

export interface Page {
    number: number;
    size: number;
    sort?: string;
    order?: string;
}

@Injectable({
    providedIn: 'root'
})
export class VorgangRouteService {

    constructor(private readonly http: DefaultHttpService,
                private readonly userInfoService: UserinfoService) {}

    save(geproId: string, routes: Array<GeproRouteRestTO>): Observable<Array<GeproRouteRestTO>> {
        let url = this.prepareUrlWith(geproId);
        if (this.userInfoService.isCurrentUserStvUser()) {
            url = url.concat('?asStvVonBensl=', this.userInfoService.getCurrentStvUser().bensl);
        }
        return this.http.post(url, routes);
    }

    findAllBy(geproId: string, filter?: RoutingType, page?: Page): Observable<VorgangRoutes> {
        const url = this.prepareUrlWith(geproId);
        const queryParams = this.prepareQueryParametersWith(filter, page);
        let completeUrl = url.concat(queryParams);
        if (this.userInfoService.isCurrentUserStvUser() ) {
            completeUrl = completeUrl.concat('&asStvVonBensl=', this.userInfoService.getCurrentStvUser().bensl);
        }
        return this.http.get<VorgangRoutes>(completeUrl);
    }

    prepareUrlWith(geproId: string): string {
        let url: string = Endpoints.GEPRO_ROUTES_BY_ID.replace('${geproId}', geproId);


        return url;
    }

    saveLszRoute(geproId: string, routes: Array<GeproRouteRestTO>): Observable<Array<GeproRouteRestTO>> {
        let url = this.prepareUrlWithLsz(geproId);
        if (this.userInfoService.isCurrentUserStvUser()) {
            url = url.concat('?asStvVonBensl=', this.userInfoService.getCurrentStvUser().bensl);
        }
        return this.http.post(url, routes);
    }
    prepareUrlWithLsz(geproId: string): string {
        let url: string = Endpoints.GEPRO_ROUTES_BY_ID_LSZ.replace('${geproId}', geproId);


        return url;
    }

    prepareQueryParametersWith(filter: RoutingType, page: Page): string {
        const queryFilter = filter ? `filter=${filter}` : '';
        let pageFilter = '';
        if (page) {
            const sort = page.sort ? `&sort=${page.sort}${page.order ? `,${page.order}` : ''}` : '';
            pageFilter = `${queryFilter ? '&' : ''}page=${page.number}&size=${page.size}${sort}`;
        }
        return queryFilter || pageFilter ? `?${queryFilter}${pageFilter}` : '';
    }
}

<nx-message *ngIf="hasTypeStepErrors()" showIcon="true" nxContext="error">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <span><strong>Fehler:</strong> Bitte korrigieren Sie die Vorgangsart. </span>
        <button nxButton="tertiary small-medium" type="button" (click)="navigateToType()" class="document-error-button">
            <nx-icon name="edit" class="nx-margin-right-2xs"></nx-icon>Bearbeiten
        </button>
    </div>
</nx-message>
<nx-message *ngIf="hasBrokerStepErrors()" showIcon="true" nxContext="error">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <span><strong>Fehler:</strong> {{getBrokerErrorText()}} </span>
        <button nxButton="tertiary small-medium" type="button" (click)="navigateToBroker()" class="document-error-button">
            <nx-icon name="edit" class="nx-margin-right-2xs"></nx-icon>Bearbeiten
        </button>
    </div>
</nx-message>
<nx-message *ngIf="hasDocumentStepErrors()" showIcon="true" nxContext="error">
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <span><strong>Fehler:</strong> {{getInhalteErrorText()}} </span>
        <button nxButton="tertiary small-medium" type="button" (click)="navigateToDocuments()" class="document-error-button">
            <nx-icon name="edit" class="nx-margin-right-2xs"></nx-icon>Bearbeiten
        </button>
    </div>
</nx-message>

<!--div fxLayout="row" fxLayoutAlign="space-between stretch">
    <ng-template #editType>
        <button appFocus="button" (click)="navigateToType()" aria-label="gepro-type" nxIconButton="tertiary small" type="button"
                class="nx-margin-bottom-0">
            <nx-icon name="edit" size="s"></nx-icon>
        </button>
    </ng-template>
    <app-labeled-container label="Vorgangsart" [buttonTemplate]="editType" class="growing-item">
        <h4 nxHeadline="subsection-xsmall" class="nx-margin-bottom-0">{{ this.gepro?.geproType?.name }}</h4>
    </app-labeled-container>
</div-->

<div>
    <ng-template #editMakler>
        <button (click)="navigateToBroker()" aria-label="gepro-type" nxIconButton="tertiary small" type="button"
                class="nx-margin-bottom-0">
            <nx-icon name="edit" size="s"></nx-icon>
        </button>
    </ng-template>
    <app-vorgang-makler *ngIf="gepro?.id"
                        [geproId]="gepro?.id"
                        [buttonTemplate]="editMakler"></app-vorgang-makler>
</div>

<div>
    <ng-template #editNachricht>
        <button (click)="navigateToDocuments()" aria-label="gepro-type" nxIconButton="tertiary small" type="button"
                class="nx-margin-bottom-0">
            <nx-icon name="edit" size="s"></nx-icon>
        </button>
    </ng-template>
    <app-gepro-info [readonlyText]="gepro?.geproInfos" [maxRowsInTextarea]="6" [buttonTemplate]="editDocuments"></app-gepro-info>
</div>

<div fxLayout="row" fxLayoutAlign="space-between stretch">
    <ng-template #documents>
        <button appFocus="button" (click)="navigateToDocuments()" aria-label="gepro-type" nxIconButton="tertiary small" type="button"
                class="nx-margin-bottom-0">
            <nx-icon name="edit" size="s"></nx-icon>
        </button>
    </ng-template>
    <app-labeled-container label="Hochgeladene Dokumente" [buttonTemplate]="editDocuments" class="growing-item">
         <app-document-upload [initWithVorgangId]="gepro?.id" [uploadEnabled]="false"></app-document-upload>
    </app-labeled-container>

    <div class="nx-margin-xs"></div>

    <ng-template #editDocuments>
        <button (click)="navigateToDocuments()" aria-label="gepro-type" nxIconButton="tertiary small" type="button"
                class="nx-margin-bottom-0">
            <nx-icon name="edit" size="s"></nx-icon>
        </button>
    </ng-template>
    <app-labeled-container label="Bestätigte Inhalte" [buttonTemplate]="editDocuments" class="growing-item">
        <div *ngFor="let requiredDocument of gepro?.geproType?.requiredDocuments" class="requirement">
            <h4 nxHeadline="subsection-xsmall"
                class="nx-margin-bottom-0"
                fxLayout="row"
                fxLayoutAlign="start start">
                <nx-icon *ngIf="isDocumentChecked(requiredDocument.id); else notCheckedRequired"
                         class="check-icon"
                         name="check"
                         size="s"></nx-icon>
                <ng-template #notCheckedRequired>
                    <nx-icon class="error-icon" name="close" size="s"></nx-icon>
                </ng-template>
                <span class="nx-margin-left-xs">{{ requiredDocument?.name }}</span>
            </h4>
        </div>
        <div *ngFor="let optionalDocument of gepro?.geproType?.optionalDocuments" class="requirement">
            <h4 *ngIf="isDocumentChecked(optionalDocument.id)"
                nxHeadline="subsection-xsmall"
                class="nx-margin-bottom-0"
                fxLayout="row"
                fxLayoutAlign="start start">
                <nx-icon class="check-icon" name="check" size="s"></nx-icon>
                <span class="nx-margin-left-xs">{{ optionalDocument?.name }}</span>
            </h4>
        </div>
        <div *ngIf="gepro?.geproType?.requiredDocuments?.length === 0 && gepro?.tickedDocuments?.length === 0">
            <h4 nxHeadline="subsection-xsmall" class="nx-margin-bottom-0">Keine</h4>
        </div>
    </app-labeled-container>
</div>


<ng-template #maklerModalBody>
    <div>
        <div>
            <h3 class="nx-margin-bottom-0" nxHeadline="subsection-xsmall"><b>Betroffene Makler</b></h3>
        </div>
        <table nxTable>
            <thead>
            <tr nxTableRow>
                <th class="cell-status" nxHeaderCell>
                    MSN
                </th>
                <th class="cell-timestamp" nxHeaderCell>
                    Name
                </th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let route of getRoutes()" class="cell-checkbox tableRow" nxTableRow>
                <td nxTableCell>{{route.value}}</td>
                <td nxTableCell>THIS IS AN AWESOME NAME</td>
            </tr>
            </tbody>
        </table>
    </div>
</ng-template>

<ng-template #saveSuccessfulModal>
    <div class="u-text-center save-successful">
        <h3 nxHeadline="subsection-large" class="nx-font-weight-light">Der Vorgang {{this.gepro.id}} wurde<br>erfolgreich versendet.</h3>
        <button nxModalClose="navigateToWorklist" appFocus="button" (click)="navigateToWorklist()" nxButton="primary medium" type="button" cdkFocusInitial>
            Zurück zur Arbeitsliste
        </button>
    </div>
</ng-template>


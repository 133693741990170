import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {NameRoutingRestTO} from './Model/NameRoutingRestTO';
import {Endpoints} from '../../navigation/endpoints';
import {DefaultHttpService} from '../../../services/default-http.service';

@Injectable()
export class GeproRoutingService {

    constructor(public http: DefaultHttpService) {
    }

    public queryNameRoutingListGepro(): Observable<any> {
        return this.http.get(Endpoints.NAME_ROUTING_LIST);
    }

    public saveNameRoutingList(list: Array<NameRoutingRestTO>): Observable<any> {
        return this.http.post(Endpoints.NAME_ROUTING_LIST, list);
    }
}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PaginatedTableComponent} from './paginated-table.component';
import {NxTableModule} from '@allianz/ngx-ndbx/table';
import {NxPaginationModule} from '@allianz/ngx-ndbx/pagination';
import {NxSpinnerModule} from '@allianz/ngx-ndbx/spinner';
import {FlexLayoutModule} from '@angular/flex-layout';

@NgModule({
    declarations: [
        PaginatedTableComponent
    ],
    exports: [
        PaginatedTableComponent
    ],
    imports: [
        CommonModule,
        FlexLayoutModule,
        NxTableModule,
        NxPaginationModule,
        NxSpinnerModule
    ]
})
export class PaginatedTableModule { }

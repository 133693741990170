import {Injectable, Injector} from '@angular/core';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Endpoints} from '../../navigation/endpoints';
import {Router} from '@angular/router';
import {Pages} from '../../navigation/pages';
import {LoginService} from './login.service';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {


    private _loggedIn = false;

    set loggedIn(val) {
        this._loggedIn = val;
    }

    get loggedIn(){
        return this._loggedIn;
    }

    private isAuthenticated$ = new BehaviorSubject<boolean>(false);
    public isAuthenticated: Observable<boolean> = this.isAuthenticated$.asObservable();

    constructor(public http: HttpClient,
                private injector: Injector
    ) {
    }

    public async login(username, password) {

        const endpoint = Endpoints.LOGIN;

        let params = new HttpParams();
        params = params.set('username', username);
        params = params.set('password', password);

        const result = await this.http.post(endpoint, {}, {params}).toPromise();
        this.isAuthenticated$.next(true);
        this.loggedIn = true;
        return result;
    }

    public logout() {

        this.http.get(Endpoints.LOGOUT, {responseType: 'text'})
            .subscribe(() => {
                let router = this.injector.get(Router);
                this.loggedIn = false;
                this.isAuthenticated$.next(false);
                router.navigate(['/' + Pages.PATH_LOGIN]);
            }, error => {
                console.log(error);
            });

    }

    public autologinDetected() {
        this.loggedIn = true;
        this.isAuthenticated$.next(true);
    }
}

<div class="event-stream--item {{item?.question?.entryType}} js-event-item">
    <div [class.highlight]="geproHistoryService.shouldBeHighlighted(item?.question)">
        <div class="align-icon">
            <nx-icon name="speech-bubble-dots-o" size="s" nxTooltip="Rückfrage gestellt"></nx-icon>
            <div>
                <div class="event-stream--item-date d-inline-flex" style="vertical-align: 0">
                    {{geproHistoryService.printEventCreatorInfo(item?.question)}},
                    <app-timestamp [timestamp]="item?.question?.createdTs"></app-timestamp>
                </div>
                <div class="d-flex"></div>
                <div class="historyBody">
                    <div [innerHTML]="item?.question?.text" class="event-stream--item-note d-inline-flex"></div>

                </div>
            </div>
        </div>
        <div class="align-container answer-container js-answer-visible">
            <nx-icon name="chat-o" [size]="'s'" nxTooltip="{{item?.$iconExplanation}}"></nx-icon>
            <span>
                <span class="event-stream--item-date">{{geproHistoryService.printEventCreatorInfo(item)}},
                    <app-timestamp
                        [timestamp]="item?.createdTs"></app-timestamp>
                </span>
                <div class="event-stream--item-note d-inline-flex">{{item?.text}}</div>
            </span>
        </div>

    </div>
</div>

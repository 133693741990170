import {Injectable} from '@angular/core';
import {VisibilityService} from '../../../services/visibility.service';

@Injectable({
    providedIn: 'root'
})
export class GeproFilterVisibilityService {

    constructor(private visibilityService: VisibilityService) { }

    public isCreatorDisabled(): boolean {
        return !this.visibilityService.isEnabledForCurrentUser('filterErstellteVorgaenge');
    }

    public isCreatorVisible(): boolean {
        return this.visibilityService.isVisibleForCurrentUser('filterErstellteVorgaenge');
    }

    public isAssigneeDisabled(): boolean {
        return !this.visibilityService.isEnabledForCurrentUser('filterZugewieseneVorgaenge');
    }

    public isAssigneeVisible(): boolean {
        return this.visibilityService.isVisibleForCurrentUser('filterZugewieseneVorgaenge');
    }

    public isAllocatorDisabled(): boolean {
        return !this.visibilityService.isEnabledForCurrentUser('filterNichtZugewieseneVorgaenge');
    }

    public isAllocatorVisible(): boolean {
        return this.visibilityService.isVisibleForCurrentUser('filterNichtZugewieseneVorgaenge');
    }

    public isTodoDisabled(): boolean {
        return !this.visibilityService.isEnabledForCurrentUser('filterTodoVorgaenge');
    }

    public isTodoVisible(): boolean {
        return this.visibilityService.isVisibleForCurrentUser('filterTodoVorgaenge');
    }

    public isSentDisabled(): boolean {
        return !this.visibilityService.isEnabledForCurrentUser('filterSentVorgaenge');
    }

    public isSentVisible(): boolean {
        return this.visibilityService.isVisibleForCurrentUser('filterSentVorgaenge');
    }

    public isClosedDisabled(): boolean {
        return !this.visibilityService.isEnabledForCurrentUser('filterClosedVorgaenge');
    }

    public isClosedVisible(): boolean {
        return this.visibilityService.isVisibleForCurrentUser('filterClosedVorgaenge');
    }

    public isStatusDisabled(): boolean {
        return !this.visibilityService.isEnabledForCurrentUser('filterStatus');
    }

    public isStatusVisible(): boolean {
        return this.visibilityService.isVisibleForCurrentUser('filterStatus');
    }

    public isGeproTypeDisabled(): boolean {
        return !this.visibilityService.isEnabledForCurrentUser('filterVorgangsart');
    }

    public isGeproTypeVisible(): boolean {
        return this.visibilityService.isVisibleForCurrentUser('filterVorgangsart');
    }

    public isMsnDisabled(): boolean {
        return !this.visibilityService.isEnabledForCurrentUser('filterMaklerhaus');
    }

    public isMsnVisible(): boolean {
        return this.visibilityService.isVisibleForCurrentUser('filterMaklerhaus');
    }

    public isCreatedDateDisabled(): boolean {
        return !this.visibilityService.isEnabledForCurrentUser('filterEingangsdatum');
    }

    public isCreatedDateVisible(): boolean {
        return this.visibilityService.isVisibleForCurrentUser('filterEingangsdatum');
    }

    public isModifiedDateDisabled(): boolean {
        return !this.visibilityService.isEnabledForCurrentUser('filterLetzteAenderung');
    }

    public isModifiedDateVisible(): boolean {
        return this.visibilityService.isVisibleForCurrentUser('filterLetzteAenderung');
    }

    public isBearbeiterDisabled(): boolean {
        return !(this.visibilityService.isEnabledForCurrentUser('filterBearbeiterReferatsMitarbeiter') ||
            this.visibilityService.isEnabledForCurrentUser('filterBearbeiterSichSelbst'));
    }

    public isBearbeiterVisible(): boolean {
        return this.visibilityService.isVisibleForCurrentUser('filterBearbeiterReferatsMitarbeiter') ||
            this.visibilityService.isVisibleForCurrentUser('filterBearbeiterSichSelbst');
    }

    public isAnyUpperFilterVisible(): boolean {
        return this.isCreatorVisible() ||
            this.isAssigneeVisible() ||
            this.isAllocatorVisible();
    }

    public isAnyLowerFilterVisible(): boolean {
        return this.isStatusVisible() ||
            this.isGeproTypeVisible() ||
            this.isMsnVisible() ||
            this.isCreatedDateVisible() ||
            this.isModifiedDateVisible() ||
            this.isBearbeiterVisible();
    }

    public isAnyFilterVisible(): boolean {
        return this.isAnyUpperFilterVisible() ||
            this.isAnyLowerFilterVisible();
    }
}

import {Injectable} from '@angular/core';
import {StellvertreterService} from './stellvertreter.service';
import {UserMessagesService} from '../../common/messages-area/messages-area/service/user-messages.service';

@Injectable({
    providedIn: 'root'
})
export class InitialStvDataLoadingService {

    constructor(
        private stellvertreterService: StellvertreterService,
        private userMessageService: UserMessagesService
    ) {
    }


    checkStellvertreterDataOnAppInit(): Promise<any> {
        return new Promise<any>((resolve, reject) => {
             this.stellvertreterService.getAllStellvertreter().toPromise().then(stvList => {
                    const selectedStvList = stvList.filter(stv => stv.selectedAsStv === true);
                    selectedStvList.filter(selStv => selStv.fullname === null || selStv.fullname.trim() === '')
                        .forEach(nonameStv => this.userMessageService.addInfoMessageString('Der Mitarbeiter ' + nonameStv.bensl +
                            ' ist als Stellverteter ungültig. Bitte bereinigen Sie Ihre Stellverteter-Liste.'));
                    console.log('Stellvertreterdaten successfully resolved');
                    resolve();
                }).catch(error => {
                    console.log('Stellvertreterdaten could not be resolved', error);
                    resolve();
                });
        });
    }
}

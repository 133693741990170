import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {Subscription} from 'rxjs';
import {GeproService} from '../../index/service/gepro.service';
import {ChangelogEntry, GeproHistoryService} from '../../../services/gepro-history.service';
import {VisibilityService} from '../../../services/visibility.service';
import {NxDialogService, NxModalRef} from '@allianz/ngx-ndbx/modal';
import {UserinfoService} from "../../index/service/userinfo.service";
import {WiedervorlageService} from "../../index/service/wiedervorlage.service";

const EVENT_TYPES: any = {
    communication: 'COMMUNICATION',
    user: 'USER',
    system: 'SYSTEM'
};


@Component({
    selector: 'app-gepro-history',
    templateUrl: './gepro-history.component.html',
    styleUrls: ['./gepro-history.component.scss']
})
export class GeproHistoryComponent implements OnInit, OnDestroy {

    @Input() id: number | string;

    @Output()
    answerQuestionClicked = new EventEmitter();
    @Output()
    refreshGepro = new EventEmitter();
    @Output()
    lszEmailExists = new EventEmitter();

    stream: Array<ChangelogEntry> = [];
    visibleStream: Array<ChangelogEntry> = [];
    previewMode: boolean = true;

    private subscription: Subscription = new Subscription();

    @ViewChild('zurueckziehenSuccessModalBody') templateRef: TemplateRef<any>;
    zurueckziehenSuccessModalBody: NxModalRef<any>;

    constructor(private readonly geproService: GeproService,
                private readonly visibilityService: VisibilityService,
                public userinfoService: UserinfoService,
                private geproHistoryService: GeproHistoryService,
                public wiedervorlageService: WiedervorlageService,
                public dialogService: NxDialogService) {
    }

    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.geproHistoryService.resetQuestionInfos();

    }

    ngOnInit() {
        this.subscribeToHistoryAdded();
    }

    subscribeToHistoryAdded() {
        if (this.id) {
            this.subscription.add(this.geproService.subscribeToGeproDetails().subscribe((data) => {
                if (data) {
                    this.fetchHistory();
                }
            }));
        }

        this.subscription.add(this.geproService.watchHistoryAddedEvent().subscribe(val => {
            this.fetchHistory();
        }));
    }

    fetchHistory() {
        if (this.id) {
            this.geproHistoryService.historyList(this.id.toString(), this.previewMode).then((response) => {
                if (response) {
                    this.visibleStream = response.map((streamObj: ChangelogEntry) => {
                        this.mapHistoryEvent(streamObj);
                        return this.mapIcon(streamObj);
                    });
                } else {
                    this.visibleStream = [];
                }
                var visibleStream2 = [];
                for(var i = 0; i<this.visibleStream.length; i++ ){
                    console.log(this.visibleStream[i].text);
                    if (this.visibleStream[i].text.indexOf('Es wurde das Papierpost-Schriftstück angefordert.') === 0) {
                        this.lszEmailExists.emit();
                    }
                    if(this.visibleStream[i].text.indexOf('Wiedervorlagedatum wurde gesetzt: ')==-1){
                        visibleStream2.push(this.visibleStream[i]);
                    }
                    else if(this.userinfoService.getUser().bensl.indexOf(this.visibleStream[i].creator.bensl)>-1
                        &&this.visibleStream[i].text.indexOf('Wiedervorlagedatum wurde gesetzt: ')>-1){
                        console.log(1 + '   ' + this.userinfoService.getUser().bensl +  '   ' + this.visibleStream[i].creator.bensl);
                        this.visibleStream[i].text =this.visibleStream[i].text.replace('trueWiedervorlage', 'Wiedervorlage');
                        this.visibleStream[i].text = this.visibleStream[i].text.replace('falseWiedervorlage', 'Wiedervorlage');
                        visibleStream2.push(this.visibleStream[i]);
                    }
                        // else if(this.userinfoService.getUser().bensl.indexOf(this.visibleStream[i].stv.bensl)>-1
                        //     &&this.visibleStream[i].text.indexOf('Wiedervorlage')>-1) {
                        //     console.log(2 + '   ' + this.userinfoService.getUser().bensl +  '   ' + this.visibleStream[i].stv.bensl);
                        //     visibleStream2.push(this.visibleStream[i]);
                    // }
                    else if(this.visibleStream[i].text.indexOf('Wiedervorlagedatum wurde gesetzt: ')>-1&&this.visibleStream[i].text.indexOf('true')>-1){
                        this.visibleStream[i].text = this.visibleStream[i].text.replace('trueWiedervorlage', 'Wiedervorlage');
                        visibleStream2.push(this.visibleStream[i]);
                    }
                }
                this.visibleStream = visibleStream2;
            }).catch((error) => {
                console.log('fetch history error', error);
            });
        }
    }

    mapHistoryEvent(streamObj: ChangelogEntry): ChangelogEntry {

        if (streamObj.entryType === 'FILE') {
            streamObj.$iconExplanation = 'Dokument hinzugefügt';
            streamObj.$icon = 'file';
        }

        if (streamObj.entryType === 'QUESTION') {
            streamObj.$iconExplanation = 'Rückfrage gestellt';
            streamObj.$icon = 'speech-bubble-dots-o';

            this.geproHistoryService.updateSmallestQuestionDate(streamObj);
        }

        if (streamObj.entryType === 'ANSWER') {
            streamObj.$iconExplanation = 'Rückfrage beantwortet';
            streamObj.$icon = 'speech-bubble-dots-o';
        }

        if (streamObj.entryType === 'CANCEL') {
            streamObj.$iconExplanation = 'Rückfrage zurückgezogen';
            streamObj.$icon = 'speech-bubble-dots-o';
        }

        if (streamObj.entryType === 'MESSAGE') {
            streamObj.$iconExplanation = 'Notiz hinzugefügt';
            streamObj.$icon = 'edit';
        }

        if (streamObj.entryType === 'STATUS') {
            streamObj.$iconExplanation = 'Statusänderung';
            streamObj.$icon = 'refresh';
        }

        if (streamObj.entryType === 'AUTHORISE') {
            streamObj.$iconExplanation = 'Zuteilung';
            streamObj.$icon = 'arrow-last';
        }

        if (streamObj.entryType === 'RELEASEPROCESS') {
            streamObj.$iconExplanation = 'Information zur Nachbearbeitung';
            streamObj.$icon = 'launch';
        }

        if (streamObj.entryType === 'RELEASEREQUEST' || streamObj.entryType === 'RELEASEDRAFT' ||
            streamObj.entryType === 'RELEASECANCEL') {
            streamObj.$iconExplanation = 'Informationen zur Freigabeanforderung';
            streamObj.$icon = 'speech-bubble-dots-o';
        }

        if (streamObj.entryType === 'RELEASE') {
            streamObj.$iconExplanation = 'Informationen zur Freigabe';
            streamObj.$icon = 'chat-o';
        }

        return streamObj;
    }

    mapIcon(item: ChangelogEntry): ChangelogEntry {
        if (item.subtype) {
            switch (item.subtype) {
                case'ZURUECKGEWIESEN': {
                    item.$iconExplanation = 'Zurückgewiesen';
                    item.$icon = 'arrow-first';
                    break;
                }
                case 'ABGELEHNT': {
                    item.$iconExplanation = 'Abgelehnt';
                    item.$icon = 'close';
                    break;
                }
                case 'ABGESCHLOSSEN' : {
                    item.$iconExplanation = 'Abgeschlossen';
                    item.$icon = 'check';
                    break;
                }

            }
        }

        return item;

    }

    showMore() {
        this.previewMode = !this.previewMode;
        this.fetchHistory();
    }

    cancelQuestionSuccessful() {
        this.zurueckziehenSuccessModalBody = this.dialogService.open(this.templateRef, { ariaLabel: 'Rückfrage zurückgezogen', disableClose: true });
        this.zurueckziehenSuccessModalBody.afterClosed().subscribe(result => {
            if (result === 'CancelDialog') {
                this.zurueckziehenSuccessModalBody.close();
            }
        });
        this.refreshGepro.emit();
    }

    answerQuestion(streamObj: ChangelogEntry): void {
        this.answerQuestionClicked.emit(streamObj);
    }

    hasMoreElements(): boolean {
        return this.previewMode;
    }
}

export interface INote {
    parent?: any;
    ersteller?: any;
    erstellungsdatum?: object;
    inhalt: string;
    empfaengerRolle?: string;
}

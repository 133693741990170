import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {GeproRouteRestTO, RoutingType} from '../../index/service/Model/gepro-route-rest.to';
import {Page, VorgangRouteService} from '../../../services/vorgang-route.service';
import {filter, map, switchMap, tap} from 'rxjs/operators';
import {Input} from '@angular/core';
import {SortDirection, SortEvent} from '@allianz/ngx-ndbx/table';

interface PageUpdate {
    number: number;
    size: number;
    sort?: string;
    order?: string;
}

export abstract class AbstractRouteComponent {

    @Input()
    protected filter: RoutingType;

    showLoadingIndicator: boolean = true;
    currentPage: number;
    elementsPerPage: number;
    totalElements: number;

    private geproId_: string;
    private sortHeader: string;
    private sortDirection: SortDirection;
    private readonly page$: Subject<PageUpdate>;
    private readonly route$: Observable<Array<GeproRouteRestTO>>;
    private readonly moreElements$: Subject<boolean>;

    protected constructor(protected readonly routeService: VorgangRouteService) {
        this.currentPage = 1;
        this.elementsPerPage = 5;
        this.totalElements = 0;
        this.page$ = new BehaviorSubject(this.firstPage());
        this.moreElements$ = new BehaviorSubject(false);
        this.route$ = this.page$.pipe(
            filter(_ => this.geproId != null),
            switchMap(page => this.routeService.findAllBy(this.geproId, this.filter, page)),
            map(result => {
                this.totalElements = result.totalElements;
                return result.content;
            }),
            tap(() => {
                this.showLoadingIndicator = false;
                this.moreElements$.next(this.elementsPerPage < this.totalElements);
            })
        );
    }

    @Input()
    set geproId(geproId: string) {
        this.geproId_ = geproId;
        this.loadCurrentPage();
    }

    get geproId(): string {
        return this.geproId_;
    }

    hasMoreElements(): Observable<boolean> {
        return this.moreElements$.asObservable();
    }

    routes(): Observable<Array<GeproRouteRestTO>> {
        return this.route$;
    }

    sortTable(sort: SortEvent): void {
        this.sortHeader = sort.active;
        this.sortDirection = sort.direction;
        this.loadCurrentPage();
    }

    previousPage(): void {
        this.currentPage--;
        this.loadCurrentPage();
    }

    nextPage(): void {
        this.currentPage++;
        this.loadCurrentPage();
    }

    goToPage(pageNumber: number): void {
        this.currentPage = pageNumber;
        this.loadCurrentPage();
    }

    private loadCurrentPage(): void {
        this.load(this.pageOf(this.currentPage));
    }

    private load(page: Page): void {
        this.showLoadingIndicator = true;
        this.page$.next(page);
    }

    private firstPage(): Page {
        return this.pageOf(1);
    }

    private pageOf(number: number): Page {
        return { number: number - 1, size: this.elementsPerPage, sort: this.sortHeader, order: this.sortDirection };
    }
}

import {EditableTableCell} from './EditableTableCell';

export class EditableTableDropdownCell extends EditableTableCell{

    private values: Array<string>;

    constructor(value: string, values : Array<string>, shouldBeStoredInUppercase: boolean) {
        super(value, true, true);
        this.shouldBeStoredInUppercase = shouldBeStoredInUppercase;
        this.values = values;
        this.type_input = false;
        this.type_checkbox = false;
        this.type_dropdown = true;
    }

    getDropdownValues(): Array<string> {
        return this.values;
    }
}

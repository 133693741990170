import {Component, OnDestroy, OnInit} from '@angular/core';
import {MoveGeproReferatService} from './service/move-gepro-referat.service';
import {GeproChangeReferatRestTO} from '../../index/service/Model/GeproChangeReferatRestTO';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-move-gepro-referat',
    templateUrl: './move-gepro-referat.component.html',
    styleUrls: ['./move-gepro-referat.component.css']
})
export class MoveGeproReferatComponent implements OnInit, OnDestroy {

    private accumulatedSubscription: Subscription = new Subscription();

    isDialogOpened: boolean = false;
    reason: string = "";
    geproChangeReferatRestTO: GeproChangeReferatRestTO;

    constructor(private moveGeproReferatService: MoveGeproReferatService) {
    }

    ngOnInit() {
       this.accumulatedSubscription.add( this.moveGeproReferatService.subscribeToDialogOpenedSubject({
           next: (request) => {
               this.geproChangeReferatRestTO = request;
               this.isDialogOpened = true;
           }
       }));
    }

    ngOnDestroy(): void {
        this.accumulatedSubscription.unsubscribe();
    }

    public closeDialogAndClearValues(): void {
        this.isDialogOpened = false;
        this.reason = "";
        this.geproChangeReferatRestTO = null;
    }

    public confirmed() {
        this.geproChangeReferatRestTO.reason = { message: this.reason };
        this.moveGeproReferatService.publishGeproChangeReferatRequest(this.geproChangeReferatRestTO);
        this.closeDialogAndClearValues();
    }

    public isMoveButtonDisabled(): boolean {
        return this.reason.trim().length == 0;
    }
}

import {Component} from '@angular/core';

@Component({
    selector: 'app-dialog',
    templateUrl: './dialog.component.html',
    styleUrls: ['./dialog.component.css']
})
export class DialogComponent {

    open: boolean = false;

    show(): void {
        this.open = true;
    }

    close(): void {
        this.open = false;
    }
}

import {Component, OnInit} from '@angular/core';
import {UserinfoService} from '../index/service/userinfo.service';
import {AuthenticationService} from '../index/service/authentication-service';
import {CreateGeproService} from '../../services/create-gepro.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {


    public subscription: Subscription = new Subscription();

    constructor(private userinfoService: UserinfoService,
                public authService: AuthenticationService,
                public createGeproService: CreateGeproService) {
    }

    ngOnInit(): void {
        this.logout();
    }


    public logout() {
        this.authService.logout();
        this.userinfoService.removeCurrentUserData();
        this.userinfoService.resetCurrentUserStvInfo();
    }

}

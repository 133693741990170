<ng-template #newRoutingRuleBody>
    <div nxModalContent class="modal-content u-text-center">
        <h3 nxHeadline="subsection-medium" class="headline">Neuer Eintrag</h3>
        <div>
            <nx-message *ngIf="isShowError()" nxContext="error" showIcon="true">
                Folgende Fehler sind aufgetreten:
                    <ul *ngFor="let error of errorMessages">
                        <li>{{error}}</li>
                    </ul>
            </nx-message>
            <form [formGroup]="newValuesForm" *ngFor="let value of headerValues; let i = index;">
                <nx-formfield *ngIf="!newValuesDataRow.getRowValues()[i].type_dropdown; else newCellDropdown" id="newEntryFormfield{{i}}" nxLabel="{{value.displayValue}}">
                    <input class="newInputField" formControlName="{{value.sortValue}}" nxInput type="text"/>
                </nx-formfield>
                <ng-template #newCellDropdown>
                    <nx-formfield nxLabel="{{value.displayValue}}">
                        <nx-dropdown [(nxValue)]="newValuesDataRow.getRowValues()[i].value"
                                     [nxShowFilter]="true"
                                     nxFilterPlaceholder="Wählen ..."
                                     formControlName="{{value.sortValue}}"
                        >
                            <nx-dropdown-item *ngFor="let item of newValuesDataRow.getRowValues()[i].getDropdownValues()" [nxValue]="item">
                                {{item}}
                            </nx-dropdown-item>
                        </nx-dropdown>
                        <nx-error nxFormfieldError>
                            Bitte wählen
                        </nx-error>
                    </nx-formfield>
                </ng-template>
            </form>
        </div>
        <button nxButton="medium secondary" (click)="discardRowButtonClicked()">Abbrechen</button>
        <button nxButton="medium" (click)="applyRowButtonClicked()" [disabled]="isSaveButtonDisabled()">Hinzufügen</button>
    </div>
</ng-template>

<nx-modal
        (nxClose)="discardRowButtonClicked()"
        [nxBody]="newRoutingRuleBody"
        [nxHideOnClickOutside]="false"
        [nxHideOnEsc]="false"
        [nxShowCloseIcon]="false"
        *ngIf="isDialogOpened">
</nx-modal>

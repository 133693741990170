import {Component, Input} from '@angular/core';
import {VorgangTypeInfo} from '../../../index/service/gepro-type.service';

@Component({
    selector: 'app-type-description',
    templateUrl: './type-description.component.html',
    styleUrls: ['./type-description.component.css']
})
export class TypeDescriptionComponent {

    @Input()
    vorgangTypeInfo: VorgangTypeInfo;
}

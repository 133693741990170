import {Component, HostListener, OnDestroy, Output, TemplateRef, ViewChild} from '@angular/core';
import {GeproService} from '../../index/service/gepro.service';
import {EMPTY, Subject, Subscription} from 'rxjs';
import {GeproRestTO} from '../../index/service/Model/GeproRestTO';
import {catchError, switchMap} from 'rxjs/operators';
import {MaskConversionTypes} from '@allianz/ngx-ndbx/mask';
import {UserinfoService} from '../../index/service/userinfo.service';
import {GeproSearchRestTO} from '../../index/service/Model/GeproSearchRestTO';
import {NxDialogService, NxModalRef} from '@allianz/ngx-ndbx/modal';

@Component({
  selector: 'app-vorgang-search',
  templateUrl: './vorgang-search.component.html',
  styleUrls: ['./vorgang-search.component.css']
})
export class VorgangSearchComponent implements OnDestroy {

    public readonly mask = '0000-AAAAAA';
    public readonly separators = ['-'];
    public readonly convertType: MaskConversionTypes = 'upper';
    @Output()
    public readonly onLoad: Subject<GeproRestTO>;

    searchId: string = '';
    showNoGeproFoundModal: boolean;
    showGeproInfosModal: boolean;
    geproInfos: GeproSearchRestTO;

    private readonly id$: Subject<string>;
    private idSub: Subscription;

    @ViewChild('noGeproFoundModal') templateNotFoundRef: TemplateRef<any>;
    noGeproFoundModal: NxModalRef<any>;

    @ViewChild('geproInfosModal') templateInfosRef: TemplateRef<any>;
    geproInfosModal: NxModalRef<any>;


    constructor(private readonly geproService: GeproService,
                private readonly userInfoService: UserinfoService,
                public dialogService: NxDialogService) {
        this.geproInfos = null;
        this.onLoad = new Subject();
        this.id$ = new Subject();
        this.idSub = this.id$.pipe(
            switchMap(id => this.geproService.loadGeproBy(id).pipe(
                catchError(_ => {
                    if (this.userInfoService.isAMVB()) {
                        this.handleErrorOnOpenGepro();
                    } else {
                        this.loadNoGeproFoundModal();
                    }
                    return EMPTY;
                })
            ))
        ).subscribe(value => this.onLoad.next(value));
    }

    handleErrorOnOpenGepro() {
        this.geproService.searchGeproBy(this.searchId).subscribe(infos => {
            this.geproInfos = infos;
            if (this.geproInfos.statusType.name === 'VORBEREITUNG') {
                this.loadNoGeproFoundModal();
            } else {
                this.loadGeproInfosModal();
            }
        }, (e) => {
            this.loadNoGeproFoundModal();
        });
    }

    loadNoGeproFoundModal(): void {
        this.showNoGeproFoundModal = true;
        this.noGeproFoundModal = this.dialogService.open(this.templateNotFoundRef, { ariaLabel: 'Vorgang nicht gefunden', disableClose: true });
        this.noGeproFoundModal.afterClosed().subscribe(result => {
            if (result === 'Close') {
                this.noGeproFoundModal.close();
            }
        });
    }


    loadGeproInfosModal(): void {
        this.showGeproInfosModal = true;
        this.geproInfosModal = this.dialogService.open(this.templateInfosRef, { ariaLabel: 'Vorgang gefunden', disableClose: true });
        this.geproInfosModal.afterClosed().subscribe(result => {
            if (result === 'Close') {
                this.geproInfosModal.close();
            }
        });
    }

    ngOnDestroy(): void {
        this.idSub.unsubscribe();
    }

    @HostListener('keydown.enter', ['$event'])
    public onKeydownHandler(event: KeyboardEvent) {
        if (!this.showNoGeproFoundModal && !this.showGeproInfosModal) {
            this.loadVorgang();
        }
    }

    public loadVorgang(): void {
        if (this.searchId && this.searchId.trim().length > 8) {
            this.id$.next(this.searchId);
        }
    }

    public getMitarbeiterName(geproInfos: GeproSearchRestTO) {
        if (geproInfos.statusType.name === 'VORGESCHLAGEN') {
            return 'nicht zugeteilt';
        }
        return geproInfos.mitarbeiterName === null ? 'nicht zugeteilt' : geproInfos.mitarbeiterName;
    }
}

export class GeproFilterRequest {

    public static readonly FILTER_ASSIGNEE = 'filterAssignee';
    public static readonly FILTER_FREIGABE = 'filterFreigabe';
    public static readonly FILTER_NOT_ASSIGNED = 'filterNotAssigned';
    public static readonly FILTER_CREATOR = 'filterCreator';

    private _relationshipFilter: string;
    private _statusTypeFilters: Array<string>;

    constructor(relationshipFilter: string, statusTypeFilters: Array<string>) {
        this._relationshipFilter = relationshipFilter;
        this._statusTypeFilters = statusTypeFilters;
    }

    public getRelationshipFilter(): string {
        return this._relationshipFilter;
    }

    public setRelationshipFreigabeFilter() {
        this._relationshipFilter = GeproFilterRequest.FILTER_FREIGABE;
    }

    public setRelationshipAssigneeFilter() {
        this._relationshipFilter = GeproFilterRequest.FILTER_ASSIGNEE;
    }
    public setRelationshipAssignerFilter() {
        this._relationshipFilter = GeproFilterRequest.FILTER_NOT_ASSIGNED;
    }

    public setRelationshipCreatorFilter() {
        this._relationshipFilter = GeproFilterRequest.FILTER_CREATOR;
    }

    public getStatusTypeFilters(): Array<string> {
        return this._statusTypeFilters ? this._statusTypeFilters.slice(0) : null;
    }

    public setStatusTypeFilters(value: Array<string>) {
        this._statusTypeFilters = value ? value.slice(0) : null;
    }

    public static equals(o1: GeproFilterRequest, o2: GeproFilterRequest): boolean {
        if (o1 == o2) {
            return true;
        }
        if (o1 && !o2 || !o1 && o2) {
            return false;
        }

        return o1._relationshipFilter === o2._relationshipFilter &&
            this.isFilterArrayEquals(o1._statusTypeFilters, o2._statusTypeFilters);
    }

    private static isFilterArrayEquals(array1: string[], array2: string[]): boolean {
        array1 = array1.sort();
        array2 = array2.sort();

        return array1.length === array2.length && array1.every(function(value, index) { return value === array2[index]});
    }
}

import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpParams} from '@angular/common/http';
import {Endpoints} from '../../navigation/endpoints';
import {DefaultHttpService} from '../../../services/default-http.service';
import {GeproResCSVTO} from "./Model/GeproRestCSVTO";
import {map} from "rxjs/operators";

export interface GeproCSV {
    content: Array<GeproResCSVTO>;
    totalElements: number;
}


@Injectable({
    providedIn: 'root'
})
export class GeproServiceScv {

    constructor(public http: DefaultHttpService
               ) {
    }

    public queryCompleateCsvGepro(httpParams: HttpParams = null, page: number, pageSize: number, sortBy: string, direction: string): Observable<any> {
        let query = this.buildQueryString(httpParams);
        let pageable = this.buildPageableString(1, 2000, sortBy, direction);
        return this.http.get(`${Endpoints.GEPROS_CSV}?${pageable}${query}`).pipe(map(
            (res:any)=>{
                return res;
            }
        ));
    }

    private buildQueryString(httpParams: HttpParams): string {
        let query = '';
        if (httpParams && httpParams.keys().length > 0) {
            const filters = httpParams.keys().map(key => `${key}:${httpParams.get(key)}`).join(' AND ');
            query = `&query=${filters}`;
        }
        return query;
    }

    private buildPageableString(page: number, pageSize: number, sortBy: string, direction: string): string {
        let pageableString = '';
        if (page && page >= 1 && pageSize && pageSize >= 0 && sortBy && sortBy.length > 0) {
            pageableString = `page=${page - 1}&size=${pageSize}&sort=${sortBy},${direction}`;
        }

        return pageableString;
    }

}

import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TimestampComponent} from './timestamp.component';


@NgModule({
    declarations: [TimestampComponent],
    exports: [
        TimestampComponent
    ],
    imports: [
        CommonModule
    ]
})
export class TimestampModule { }

import {Injectable} from '@angular/core';
import {PartialObserver, Subject, Subscription} from 'rxjs';
import {GeproChangeReferatRestTO} from '../../../index/service/Model/GeproChangeReferatRestTO';
import {GeproListWithMessageRestTO} from '../../../index/service/Model/GeproListWithMessageRestTO';

@Injectable({
    providedIn: 'root'
})
export class DenyGeproService {

    private _dialogOpenedSubject:Subject<GeproListWithMessageRestTO> = new Subject<GeproListWithMessageRestTO>();
    private _denyReasonSubject: Subject<GeproListWithMessageRestTO> = new Subject<GeproListWithMessageRestTO>();
    private _closeForeverSubject: Subject<GeproListWithMessageRestTO> = new Subject<GeproListWithMessageRestTO>();
    private _closeForeverDialog: Subject<GeproListWithMessageRestTO> = new Subject<GeproListWithMessageRestTO>();

    constructor() {
    }

    public subscribeToDialogOpenedSubject(observer: PartialObserver<any>): Subscription {
        return this._dialogOpenedSubject.subscribe(observer);
    }

    public subscribeToCloseForeverOpenedSubject(observer: PartialObserver<any>): Subscription {
        return this._closeForeverDialog.subscribe(observer);
    }

    public publishDialogOpened(geproListWithMessageRestTO: GeproListWithMessageRestTO): void {
        this._dialogOpenedSubject.next(geproListWithMessageRestTO);
    }

    public closeForeverDialogOpened(): void {
        this._closeForeverDialog.next();
    }

    public subscribeToDenyReasonSubject(observer: PartialObserver<any>): Subscription {
        return this._denyReasonSubject.subscribe(observer);
    }

    public subscribeToCloseForeverSubject(observer: PartialObserver<any>): Subscription {
        return this._closeForeverSubject.subscribe(observer);
    }

    public nextForeverDialogOpened(): void {
        this._closeForeverSubject.next();
    }

    public publishDenyReasonSubject(geproListWithMessageRestTO: GeproListWithMessageRestTO): void {
        this._denyReasonSubject.next(geproListWithMessageRestTO);
    }
}

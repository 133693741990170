
<div fxLayout="row" fxLayoutAlign="start center">
    <div class="stvText">Hier können Sie Ihre Stellvertretung für Abwesenheiten festlegen.
        Die Stellvertretung kann Ihre Arbeitsliste einsehen und Vorgänge bearbeiten.
    </div>
    <div  nxCol="12,12,6" class="nx-margin-top-m">
        <nx-formfield nxLabel="Stellvertreter" class="stvList">
            <nx-dropdown [nxShowFilter]="true" nxFilterPlaceholder="Name des Stellvertreters"
                         (nxValueChange)="addStv($event)">
                <nx-dropdown-item *ngFor="let stv of stvListDropdown" [nxValue]="stv">  {{stv.fullname}} ({{stv.bensl}})</nx-dropdown-item>
            </nx-dropdown>
        </nx-formfield>
    </div>
    <div class="stvTextLabel nx-margin-top-m">Ausgewählte Stellvertreter</div>
    <div *ngIf="selectedStvList && selectedStvList.length>0" class="nx-margin-left-0 nx-margin-bottom-m">
        <div *ngFor="let selectedStv of selectedStvList">
                <div id="tag" fxLayout="row" fxLayoutAlign="start center" class="tag"
                     [ngClass]="{'tag_error': selectedStv.fullname.length === 0}"
                     [nxTooltip]="selectedStv.fullname.length === 0 ?
                        'Der Mitarbeiter ist als Stellvertreter ungültig. Bitte bereinigen Sie Ihre Stellvertreter-Liste!' : ''">
                    <span class="nx-margin-left-xs nx-margin-right-xs nx-margin-top-2xs nx-margin-bottom-xs rightalignbutton">
                        {{selectedStv.fullname}} ({{selectedStv.bensl}})</span>
                    <span>
                    <button id="removeButton"
                        nxIconButton="tertiary small"
                        aria-label="Stellvertreter {{selectedStv.fullname}} entfernen"
                            class="remove-button"
                        type="button" nxTooltip="Stellvertreter entfernen"
                            (click)="removeStv(selectedStv)">
                        <nx-icon size="s" name="close"></nx-icon>
                    </button>
                    </span>
                </div>
        </div>
    </div>
</div>


import {Component} from '@angular/core';
import {Router} from '@angular/router';
import {Pages} from '../../navigation/pages';
import {UserAuth} from '../../../config/auth/user.auth';

@Component({
    selector: 'app-basic-start-page',
    templateUrl: './start-page.component.html',
    styleUrls: ['./start-page.component.scss']
})
export class StartPageComponent {

    constructor() {
    }

}

import {FilterableTransportEntity} from './base/FilterableTransportEntity';
import {ReferatRestTO} from './ReferatRestTO';

export class NameRoutingRestTO extends FilterableTransportEntity {

    public static PREFIX_REGEXP: RegExp = new RegExp("^([a-zA-Z]+)$|^(\\d+)$");

    public id: number;
    public prefixStart: string;
    public mitarbeiterBensl: string;
    public referat: ReferatRestTO;

    constructor(id: number, prefixStart: string, mitarbeiterBensl: string, referat: ReferatRestTO) {
        super();
        this.id = id;
        this.prefixStart = prefixStart;
        this.mitarbeiterBensl = mitarbeiterBensl;
        this.referat = referat;
    }

    public static isValid(nameRoutingRestTO: NameRoutingRestTO): boolean {
        return nameRoutingRestTO.prefixStart && NameRoutingRestTO.PREFIX_REGEXP.test(nameRoutingRestTO.prefixStart.trim())
            && nameRoutingRestTO.mitarbeiterBensl && nameRoutingRestTO.mitarbeiterBensl.length > 0;
    }

    public static deepClone(o: NameRoutingRestTO): NameRoutingRestTO {
        const clone = new NameRoutingRestTO(o.id, o.prefixStart, o.mitarbeiterBensl, {...o.referat});

        return clone;
    }
}

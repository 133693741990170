import {AfterViewInit, Directive, ElementRef, Input} from '@angular/core';

@Directive({
    selector: '[appFocus]'
})
export class FocusDirective implements AfterViewInit {

    @Input('appFocus')
    selector: string;

    constructor(private readonly element: ElementRef) {}

    ngAfterViewInit(): void {
        setTimeout(() => { // Trigger focus on element
            const selector = this.selector || 'input';
            const nativeElement = this.element.nativeElement;
            const focusItem = nativeElement.querySelector(selector) || nativeElement;
            if (focusItem) {
                focusItem.focus();
            }
        }, 1);
    }
}

import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {LocationStrategy} from '@angular/common';

@Injectable()
export class UserData {
  data:any;

  constructor(private router:Router,
              private url:LocationStrategy) {
  }

  setData(data:any) {
    this.data = data;
    console.log("Current user data: " + JSON.stringify(this.data));
  }


  isKraft():boolean{
     return this.url.path().toString().indexOf("kraft")>-1?true:false;
  }

  getBensl():string{
    let bensl = null;
    if (this.data) {
      bensl = this.data.bensl;
    }

    if (!bensl) {
      const params = this.router.parseUrl(this.url.path()).queryParams;
      bensl = params['bensl'];
      this.setData(
        {
          bensl: bensl,
          vorname: params['vorname'],
          nachname: params['nachname'],
          email: params['email'],
          benart: params['benart'],
          csrf: params['csrf']
        });

    }

    return bensl;
  }
}

import {ChangeDetectorRef, Directive, Input, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {VisibilityService} from '../../services/visibility.service';
import {GeproService} from '../index/service/gepro.service';

@Directive({
    selector: '[appVisibility]'
})
export class VisibilityDirective implements OnInit {

    private functionName;

    constructor(private templateRef: TemplateRef<any>,
                private viewContainer: ViewContainerRef,
                private visibilityService: VisibilityService,
                private geproService: GeproService,
                private changes: ChangeDetectorRef) {
    }

    @Input() set appVisibility(functionName: string) {
        this.functionName = functionName;
        this.renderView();
    }

    ngOnInit(): void {
        this.geproService.subscribeToGeproDetails().subscribe(() => {
            this.renderView();
        });
    }

    renderView() {
        this.viewContainer.clear();
        if (this.visibilityService.isVisible(this.functionName)) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
        this.changes.markForCheck();
    }
}


import {Component, Input} from '@angular/core';

@Component({
    selector: 'app-document-link-button',
    templateUrl: './document-link-button.component.html',
    styleUrls: ['./document-link-button.component.css']
})
export class DocumentLinkButtonComponent {

    @Input()
    public documentLink: string;

    @Input()
    public disabled: boolean;

    constructor() {
    }

    public openDocumentLink() {
      window.open(this.documentLink);
    }
}

<ng-template #modalRejectGepro>
    <div nxModalContent class="u-text-center">
        <h3 nxHeadline="subsection-large" class="nx-font-weight-light">Hiermit lehne ich den Vorgang ab.</h3>
        <p nxCopytext="normal">
            Der Vorgang kann nach der Ablehnung nicht mehr bearbeitet werden.
            Bitte denken Sie daran, alle relevanten Dokumente und Anhänge abzulegen.
        </p>
        <div class="nx-margin-bottom-m">
            <app-restricted-text-area #reasonText
                                      id="reasonField"
                                      required="true"
                                      label="Begründung"
                                      [maxLength]="4000"
                                      [(ngModel)]="reason"></app-restricted-text-area>
        </div>
            <button nxModalClose="Aborted" nxButton="medium secondary" class="js-back nx-margin-right-s" type="button">
                Zurück
            </button>
            <button nxButton="medium"
                    type="button"
                    class="js-reject"
                    [disabled]="reasonText.isInvalid() || reasonText.isEmpty()"
                    nxModalClose="Rejected">
                Ablehnen
            </button>
    </div>
</ng-template>

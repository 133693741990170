import {AbstractType} from './AbstractType';
import {StatusTypeRestTO} from './StatusTypeRestTO';

export class StatusTypeListRestTO extends AbstractType {
    forStatusClosed: Array<StatusTypeRestTO>;
    forStatusInProgress: Array<StatusTypeRestTO>;
    forStatusToAssign: Array<StatusTypeRestTO>;
    forStatusToDo: Array<StatusTypeRestTO>;
    forStatusSent: Array<StatusTypeRestTO>;
    allVisibleStatusCreator: Array<StatusTypeRestTO>;
    allVisibleStatusAssigner: Array<StatusTypeRestTO>;
    allUndefinedLszStatusAssigner: Array<StatusTypeRestTO>;
    allVisibleStatusEditor: Array<StatusTypeRestTO>;
    allVisibleStatusRelease: Array<StatusTypeRestTO>;
}

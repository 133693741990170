import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {Endpoints} from '../../navigation/endpoints';


@Injectable()
export class AdminService {

    constructor(public http: HttpClient) {
    }

    public getAllAdmins(): Observable<any> {
        return this.http.get(Endpoints.GET_ALL_ADMINS);
    }

    public getAllRedakteure(): Observable<any> {
        return this.http.get(Endpoints.GET_ALL_REDAKTEURE);
    }

    public getAllMitarbeiter(): Observable<any> {
        return this.http.get(Endpoints.GET_ALL_MITARBEITER_ADMIN);
    }

}

import {Injectable} from '@angular/core';
import {DocumentStorageService} from './document-storage.service';

@Injectable()
export class DocumentErrorService {

    private static ERROR_HINT = 'Bitte entfernen Sie die fehlerhafte Datei, um den Vorgang fortzusetzen.';

    private customErrorMessage: string;

    constructor(private readonly documentStorage: DocumentStorageService) { }

    reset(): void {
        this.customErrorMessage = undefined;
    }

    hasError(): boolean {
        return this.errorMessage() != null && this.errorMessage() !== '';
    }

    /**
     * Show error messages of documents. These take precedence over custom error messages.
     */
    errorMessage(): string {
        const failedUpload = this.documentStorage.findDocumentByStatus('FAILURE');
        if (failedUpload) {
            const hasWaiting = this.documentStorage.findDocumentByStatus('WAITING') != null;
            const hint = hasWaiting ? DocumentErrorService.ERROR_HINT : '';
            return `${failedUpload.errorMessage} ${hint}`.trim();
        }
        return this.customErrorMessage;
    }

    setCustomErrorMessage(errorMessage: string): void {
        this.customErrorMessage = errorMessage;
    }
}

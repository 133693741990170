import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DocumentDownloadButtonComponent} from './document-download-button/document-download-button.component';
import {EditableTableComponent} from './editable-table/editable-table.component';
import {UserMessagesAreaComponent} from './messages-area/messages-area/user-messages-area.component';
import {NdbxDependenciesModule} from '../../ndbx-dependencies/ndbx-dependencies.module';
import {EditableTableNewDialogComponent} from './editable-table/editable-table-new-dialog/editable-table-new-dialog.component';
import {NxAccordionModule} from '@allianz/ngx-ndbx/accordion';
import {NxCopytextModule} from '@allianz/ngx-ndbx/copytext';
import {NxHeadlineModule} from '@allianz/ngx-ndbx/headline';
import {DocumentDeleteButtonComponent} from './document-delete-button/document-delete-button.component';
import {FileUploadButtonComponent} from './file-upload-button/file-upload-button.component';
import {DocumentLinkButtonComponent} from './document-link-button/document-link-button.component';
import {VisibilityDirective} from '../gePro/visibility.directive';
import {DisableDirective} from '../gePro/disable.directive';
import {RestrictedTextAreaModule} from './restricted-text-area/restricted-text-area.module';
import {FlexModule} from '@angular/flex-layout';
import {UploadButtonComponent} from './upload-button/upload-button.component';
import {NxProgressbarModule} from '@allianz/ngx-ndbx/progressbar';

@NgModule({
    declarations: [
        DocumentDownloadButtonComponent,
        DocumentDeleteButtonComponent,
        VisibilityDirective,
        DisableDirective,
        FileUploadButtonComponent,
        DocumentLinkButtonComponent,
        EditableTableComponent,
        EditableTableNewDialogComponent,
        UserMessagesAreaComponent,
        UploadButtonComponent
    ],
    imports: [
        CommonModule,
        NdbxDependenciesModule,
        NxHeadlineModule,
        NxCopytextModule,
        NxAccordionModule,
        FlexModule,
        NxProgressbarModule
    ],
    exports: [
        RestrictedTextAreaModule,
        DocumentDownloadButtonComponent,
        DocumentDeleteButtonComponent,
        FileUploadButtonComponent,
        DocumentLinkButtonComponent,
        EditableTableComponent,
        UserMessagesAreaComponent,
        EditableTableNewDialogComponent,
        UploadButtonComponent,
        VisibilityDirective,
        DisableDirective
    ]
})
export class DiassCommonsModule {
}

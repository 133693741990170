<ng-template #geproCloseForeverReasonTemplate>
    <div nxModalContent class="modal-content u-text-center">
        <h3 nxHeadline="subsection-large" class="nx-font-weight-light">Dieser Vorgang wird unwiderruflich abgeschlossen!</h3>
        <h4 nxHeadline="subsection-large" class="nx-font-weight-light">Bei Betätigung diesen Buttons, werden Vorgänge unwiderruflich als GePro-Art Weiterleitung ohne eine gültige MSN/VTNR geschlossen. Bitte nutzen Sie diesen Button nur für Vorgänge, die nicht in DIASS gehören</h4>
        <button nxModalClose="Abbrechen" nxButton="medium secondary">Abbrechen</button>
        <button nxModalClose="Weiter" nxButton="medium">
            Weiter
        </button>
    </div>
</ng-template>


import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {DocumentUploadComponent} from './document-upload.component';
import {NxFormfieldModule} from '@allianz/ngx-ndbx/formfield';
import {NxInputModule} from '@allianz/ngx-ndbx/input';
import {NxMessageModule} from '@allianz/ngx-ndbx/message';
import {NxSpinnerModule} from '@allianz/ngx-ndbx/spinner';
import {NxButtonModule} from '@allianz/ngx-ndbx/button';
import {NxIconModule} from '@allianz/ngx-ndbx/icon';
import {ReactiveFormsModule} from '@angular/forms';
import {DocumentViewComponent} from './document-view/document-view.component';
import {FlexModule} from '@angular/flex-layout';
import {DocumentTagComponent} from './document-view/document-tag/document-tag.component';
import {NxTooltipModule} from '@allianz/ngx-ndbx/tooltip';
import {DocumentStorageService} from './services/internal/document-storage.service';
import {DocumentErrorService} from './services/internal/document-error.service';
import {DocumentUploadService} from './services/internal/document-upload.service';
import {DocumentUploadValidatorService} from './services/internal/document-upload-validator.service';
import {DocumentService} from './services/document.service';
import {VorgangIdService} from './services/internal/vorgang-id.service';
import {NxLinkModule} from '@allianz/ngx-ndbx/link';
import {DiassCommonsModule} from '../../../common/diass-commons.module';

@NgModule({
    declarations: [DocumentUploadComponent, DocumentViewComponent, DocumentTagComponent],
    exports: [
        DocumentUploadComponent
    ],
    imports: [
        CommonModule,
        NxFormfieldModule,
        NxInputModule,
        NxMessageModule,
        NxButtonModule,
        NxIconModule,
        ReactiveFormsModule,
        FlexModule,
        NxSpinnerModule,
        NxTooltipModule,
        NxLinkModule,
        DiassCommonsModule
    ],
    providers: [
        DocumentStorageService,
        DocumentErrorService,
        DocumentUploadService,
        DocumentUploadValidatorService,
        VorgangIdService,
        DocumentService
    ]
})
export class DocumentUploadModule {
}

<app-labeled-container label="Ihre Nachricht" [buttonTemplate]="buttonTemplate" class="growing-item" >
    <h4 *ngIf="readonlyText?.length <= 0"
        nxHeadline="subsection-xsmall"
        class="u-text-center nx-margin-bottom-0">
        Momentan ist keine Nachricht vorhanden.
    </h4>
    <div *ngIf="readonlyText?.length > 0">
        <nx-formfield>
                <textarea tabindex="-1" nxInput readonly class="readonlyNachricht" id="geproInfos"
                          cdkTextareaAutosize
                          [cdkAutosizeMaxRows]="numberOfLinesToShow">{{readonlyText}}</textarea>
        </nx-formfield>
        <button class="expandButton nx-margin-bottom-2xs nx-margin-top-xs" *ngIf="showExpandBt"
                nxButton="tertiary small" type="button" (click)="expandReduceClicked()">
            {{expandedTextarea ? '... weniger anzeigen' : '... mehr anzeigen'}}
        </button>
    </div>
</app-labeled-container>

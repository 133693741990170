import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {VorgangMaklerComponent} from './vorgang-makler.component';
import {LabeledContainerModule} from '../../common/labeled-container/labeled-container.module';
import {FlexModule} from '@angular/flex-layout';
import {TypeTableComponent} from './type-table/type-table.component';
import {PaginatedTableModule} from '../../common/paginated-table/paginated-table.module';
import {NxButtonModule} from '@allianz/ngx-ndbx/button';
import {NxHeadlineModule} from '@allianz/ngx-ndbx/headline';
import {NxLinkModule} from '@allianz/ngx-ndbx/link';
import {NxModalModule} from '@allianz/ngx-ndbx/modal';
import {NxPaginationModule} from '@allianz/ngx-ndbx/pagination';
import {NxSpinnerModule} from '@allianz/ngx-ndbx/spinner';
import {NxTableModule} from '@allianz/ngx-ndbx/table';

@NgModule({
    declarations: [VorgangMaklerComponent, TypeTableComponent],
    imports: [
        CommonModule,
        LabeledContainerModule,
        FlexModule,
        PaginatedTableModule,
        NxLinkModule,
        NxTableModule,
        NxHeadlineModule,
        NxModalModule,
        NxButtonModule,
        NxSpinnerModule,
        NxPaginationModule
    ],
    exports: [
        VorgangMaklerComponent
    ]
})
export class VorgangMaklerModule {
}

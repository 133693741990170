import {Component, OnInit} from '@angular/core';
import {UserinfoService} from '../../index/service/userinfo.service';
import {ReferatDataService} from '../../index/service/referat-data.service';
import {ReferatRestTO} from '../../index/service/Model/ReferatRestTO';

@Component({
    selector: 'app-work-list-referate',
    templateUrl: './work-list-referate.component.html',
    styleUrls: ['./work-list-referate.component.css']
})
export class WorkListReferateComponent implements OnInit {

    public referatList: ReferatRestTO[];

    constructor(private userinfoService: UserinfoService,
                private referatDataService: ReferatDataService) {
    }

    ngOnInit() {
        if (this.userinfoService.isRoleReferatsleiter()) {
            this.referatDataService.queryReferatList().subscribe(
                data => {
                    this.referatList = this.filterOutOwnReferatName(data);
                }
            );
        }
    }

    private filterOutOwnReferatName(unfiltered: Array<ReferatRestTO>): Array<ReferatRestTO> {
        const referateWithoutOwnReferat: Array<ReferatRestTO> = new Array<ReferatRestTO>();
        if (unfiltered && this.userinfoService.getCurrentUserData().referatAsLeiter && this.userinfoService.getCurrentUserData().referatAsLeiter.name) {
            const ownReferatName: string = this.userinfoService.getCurrentUserData().referatAsLeiter.name;
            for (let referat of unfiltered) {
                if (referat.name != ownReferatName) {
                    referateWithoutOwnReferat.push(referat);
                }
            }
        }
        return referateWithoutOwnReferat;
    }


}

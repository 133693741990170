<ng-template #saveDraftModalBody>
    <div class="u-text-center">
        <h3 nxHeadline="subsection-large" class="nx-font-weight-light">
            {{titles.textHeadline1}}
            <br *ngIf="titles.textHeadline2">{{titles.textHeadline2}}
        </h3>
        <p *ngIf="titles.textSubheadline" nxCopytext="normal">
            {{titles.textSubheadline}}
        </p>
    </div>
    <div class="actions">
        <button [disabled]="workAsStv" nxModalClose="Delete" nxButton="medium secondary" type="button">{{titles?.confirmButtonText}}</button>
        <button nxModalClose="Keep" nxButton="medium primary" type="button" cdkFocusInitial>{{titles?.abortButtonText}}</button>
    </div>
</ng-template>


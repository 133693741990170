export interface Labelable {
    bensl: string;
    fullName?: string;
}

export class Label {

    static of(value: Labelable): string {
        const fullName: string = value.fullName || 'Nicht ermittelbar';
        return fullName.trim();
    }
}

import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-kontakt-component',
    templateUrl: './kontakt.component.html',
    styleUrls: ['./kontakt.component.scss']
})
export class KontaktComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }






}

<div fxLayout="row" fxLayoutAlign="start center">
    <nx-formfield class="grow-item hide-label" nxLabel="Suche nach ID">
        <input nxInput
               id="id-search"
               name="id-search"
               autocomplete="on"
               placeholder="Suche nach ID"
               [title]="'Suche nach ID'"
               nxTooltip="Bitte die vollständige ID eingeben."
               [nxMask]="mask"
               [separators]="separators"
               [nxConvertTo]="convertType"
               [(ngModel)]="searchId"/>
    </nx-formfield>
    <button id="search-button"
            nxIconButton="tertiary"
            aria-label="search"
            type="button"
            [title]="'Suche starten'"
            class="nx-margin-left-xs nx-margin-bottom-0"
            (click)="loadVorgang()">
        <nx-icon name="search"></nx-icon>
    </button>
</div>

<ng-template #geproInfosModal>
    <div class="u-text-center" fxLayout="column" fxLayoutAlign="start center">
        <h3 nxHeadline="subsection-large" class="nx-font-weight-light">
            Zu Ihrer Eingabe {{geproInfos.id}} wurde folgender Vorgang gefunden:
        </h3>
                <p nxCopytext="normal" class="u-text-left">
                <span class="geproInfo">Vorgangsart:</span><b>{{geproInfos.geproType.name}}</b> <br>
                <span class="geproInfo">Status des Vorgangs:</span><b>{{geproInfos.statusType.displayName}}</b> <br>
                <span class="geproInfo">Referat der Bearbeitung:</span><b>{{geproInfos.zugeordnetesReferat? geproInfos.zugeordnetesReferat.name : 'nicht zugeteilt'}}</b> <br>
                <span class="geproInfo">Name des Bearbeiters:</span><b>{{ getMitarbeiterName(geproInfos) }}</b> <br>
                </p>
        <button nxModalClose="Close" nxButton="primary medium" class="js-back" type="button">
            Zurück zur Arbeitsliste
        </button>
    </div>
</ng-template>

<ng-template #noGeproFoundModal>
    <div class="u-text-center" fxLayout="column" fxLayoutAlign="start center">
        <h3 nxHeadline="subsection-large" class="nx-font-weight-light">
            Zu Ihrer Eingabe {{searchId}} kann kein Vorgang gefunden werden
        </h3>
        <p nxCopytext="normal">
            Bitte überprüfen Sie Ihre Eingabe.
        </p>
        <button nxModalClose="Close" nxButton="primary medium" class="js-back" type="button">
            Zurück zur Arbeitsliste
        </button>
    </div>
</ng-template>

import {Injectable} from '@angular/core';

declare var safari: any;
declare var InstallTrigger: any;

@Injectable()
export class BrowserUtil {

  constructor() {}



  setInputFocus(elementId: string, timeout?: number) {
    const element = <HTMLElement> document.querySelector(elementId);
    if (element && !timeout) {
      element.focus();
    } else if (element && timeout > 0) {
      setTimeout(() => {
        element.focus();
      }, timeout);
    }
  }

  downloadFile(url: string) {
    window.open(url);
  }

}

<div id="tag" fxLayout="row" fxLayoutAlign="start center" class="tag"
     [nxTooltip]="tagTooltip()"
     [ngClass]="{ 'tag-failure': isFailure(), 'tag-withoutborder': !deleteEnabled}">
    <nx-icon *ngIf="isQueued()"
             id="queueIcon"
             size="s"
             name="clock"
             class="nx-margin-left-s"
             nxTooltip="In Warteschlange"></nx-icon>
    <nx-spinner *ngIf="isUploading()"
                id="uploadSpinner"
                nxSize="medium"
                class="nx-margin-left-s"
                nxTooltip="Wird hochgeladen"></nx-spinner>
    <span class="nx-margin-left-s nx-margin-right-s" [ngClass]="{'nx-margin-left-0 rightalignbutton': !deleteEnabled}">
        <nx-link (click)="download()"> {{ label() }}</nx-link>
   </span>
    <span><button *ngIf="deleteEnabled" id="removeButton"
            nxIconButton="tertiary small"
            aria-label="remove"
            type="button"
            class="remove-button"
            nxTooltip="Datei entfernen"
            [ngClass]="{ 'remove-button-failure': isFailure(), 'hidden': isHidden() }"
            (click)="remove()">
        <nx-icon size="s" name="close"></nx-icon>
    </button></span>
    <span><button id="downloadButton"
            nxIconButton="tertiary small"
            aria-label="download"
            type="button"
            class="remove-button"
            nxTooltip="Datei herunterladen"
            [ngClass]="{ 'remove-button-failure': isFailure(), 'hidden': isHidden() }"
            (click)="download()">
        <nx-icon size="s" name="arrow-download"></nx-icon>
    </button> </span>

</div>

import {AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild} from '@angular/core';
import {NxDialogService, NxModalRef} from '@allianz/ngx-ndbx/modal';

@Component({
    selector: 'hinweisepopup',
    templateUrl: './hinweisepopup.html',
    styleUrls: ['./hinweisepopup.scss']
})

export class HinweiseComponent implements  AfterViewChecked {


    public tabSelected: number;

    @ViewChild('hinweiseDialog') templateRef: TemplateRef<any>;
    hinweiseDialog: NxModalRef<any>;


    constructor(private changeDetectorRef: ChangeDetectorRef,
                public dialogService: NxDialogService)  {
        this.tabSelected = 0;
    }

    close(): void {
        this.tabSelected = 0;
    }

    ngAfterViewChecked(): void {
        this.changeDetectorRef.detectChanges();

    }

    public tabChanged($event) {
        this.tabSelected = $event.index;
    }

    public show(): void {
            this.hinweiseDialog = this.dialogService.open(this.templateRef, { ariaLabel: 'Allgemeine Anwendungsinformationen', disableClose: true  });
            this.hinweiseDialog.afterClosed().subscribe(result => {
                if (result === 'Close') {
                    this.close();
                }
                this.hinweiseDialog.close();
            });
        }
}

export class ErrorMessages {

     public static readonly BENSL: string = "Ein Bensl muss 1-12 Zeichen lang sein. Erlaubt sind nur Buchstaben und Zahlen.";
     public static readonly REFERENT: string = "Ein Referent muss ausgewählt werden.";
     public static readonly PREFIX: string = "Ein Buchstabenblock muss 1-10 Zeichen lang sein. Erlaubt sind nur Buchstaben oder Zahlen (keine Mischformen).";
     public static readonly PREFIX_USED: string = "Der eingetragene Buchstabenblock ist bereits in Verwendung.";
     public static readonly REFERAT: string = "Ein Referatsname muss 1-25 beliebige Zeichen enthalten.";
     public static readonly REFERAT_DATA_IDENTICAL: string = "Die Leitung und Stellvertretung eines Referats muss sich unterscheiden.";

}

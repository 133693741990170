import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DocumentService} from '../../index/service/document.service';
import {HttpEventType, HttpResponse} from '@angular/common/http';

@Component({
    selector: 'file-upload-button',
    templateUrl: './file-upload-button.component.html',
    styleUrls: ['./file-upload-button.component.css']
})
export class FileUploadButtonComponent {

    @Input()
    private documentId: number;
    @Input()
    public disabled: boolean;

    @Output()
    documentUploaded = new EventEmitter();

    @ViewChild('fileInput', {read: ElementRef, static: false})
    fileInput: ElementRef<any>;

    constructor(private documentService: DocumentService) {
    }

    openFileInputDialog() {
        this.fileInput.nativeElement.click();
    }

    onFileUpload(files: FileList): void {
        this.documentService.updateDocument(this.documentId, files[0]).subscribe(event => {// Via this API, you get access to the raw event stream.
            if (event.type === HttpEventType.UploadProgress.valueOf()) {
                console.log("wird hochgeladen")
            } else if (event instanceof HttpResponse) {
                this.documentUploaded.emit();
            }
        });
    }

}

import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DocumentModel} from '../../services/document.model';

@Component({
    selector: 'app-document-tag',
    templateUrl: './document-tag.component.html',
    styleUrls: ['./document-tag.component.css']
})
export class DocumentTagComponent {

    @Input()
    public document: DocumentModel;

    @Input()
    deleteEnabled: boolean = true;

    @Output()
    public readonly removeClicked: EventEmitter<void> = new EventEmitter();

    @Output()
    public readonly downloadClicked: EventEmitter<void> = new EventEmitter();

    label(): string {
        return `${this.fileName()} (${this.formattedFileSize()})`;
    }

    private fileName(): string {
        if (this.document && this.document.file) {
            return this.document.file.name;
        }
        return undefined;
    }

    private formattedFileSize(): string {
        const kbSize = 1024;
        const mbSize = kbSize * kbSize;
        const fileSize = this.fileSize();

        if (fileSize >= mbSize) {
            return this.round(fileSize / mbSize) + ' mb';
        } else if (fileSize >= kbSize) {
            return this.round(fileSize / kbSize) + ' kb';
        } else {
            return fileSize + ' b';
        }
    }

    private fileSize(): number {
        if (this.document && this.document.file) {
            return this.document.file.size;
        }
        return -1;
    }

    private round(value: number): number {
        return Math.round(value * 10) / 10; // round to one decimal place
    }

    remove(): void {
        this.removeClicked.emit();
    }

    download(): void {
        this.downloadClicked.emit();
    }

    isFailure(): boolean {
        return this.document && this.document.status === 'FAILURE';
    }

    isUploading(): boolean {
        return this.document && this.document.status === 'UPLOADING';
    }

    isQueued(): boolean {
        return this.document && this.document.status === 'WAITING';
    }

    isSuccess(): boolean {
        return this.document && this.document.status === 'SUCCESS';
    }

    isHidden(): boolean {
        return this.isUploading() || this.isSuccessfulUploadWithoutDeletion();
    }

    private isSuccessfulUploadWithoutDeletion(): boolean {
        return this.isSuccess() && !this.document.isDeletableAfterUpload;
    }

    /**
     * Tooltip for tag, only on success and when remove button not visible.
     */
    tagTooltip(): string {
        if (this.isSuccessfulUploadWithoutDeletion()) {
            return 'Erfolgreich hochgeladen';
        }
        return undefined;
    }
}

import {Component, OnInit} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {MatIconRegistry} from '@angular/material/icon';
import {SidebarNavEvent} from '../../config/events/sidebarNav.event';
import {Router} from '@angular/router';
import {UserData} from '../../config/globals/userData.global';
import {ApplicationInfoService} from './service/application-info.service';
import {BuildInformationRestTO} from './service/Model/BackendVersionRestTO';
import * as moment from 'moment';
import {AuthenticationService} from './service/authentication-service';
import * as npm from '../../../../package.json';
import {InitialStvDataLoadingService} from './service/initial-stv-data-loading.service';

@Component({
    selector: 'app-index',
    templateUrl: './app.index.component.html',
    styleUrls: ['./app.index.component.scss'],
})
export class AppIndexComponent implements OnInit {

    public showSidebarNav: boolean;
    public showAdminSidebarNav: boolean;
    public backendInformation: BuildInformationRestTO;
    public backendBuildDate: string;

    constructor(private _iconRegistry: MatIconRegistry,
                private _domSanitizer: DomSanitizer,
                private applicationInfoService: ApplicationInfoService,
                private userData: UserData,
                private sidebarNavEvent: SidebarNavEvent,
                private router: Router,
                private authenticationService: AuthenticationService,
                private initialStvLoadingService: InitialStvDataLoadingService) {
        this.showSidebarNav = false;
        this.showAdminSidebarNav = false;
    }

    ngOnInit(): void {

        this.sidebarNavEvent.sidebarNavEventEmit.subscribe(
            (flag: boolean) => {
                setTimeout((_: number) => this.showSidebarNav = flag);
            });

        this.getBackendVersion();
        this.checkStvData();
    }

    getFrontendVersion(): string {
        return npm.version;
    }

    getBackendVersion() {
        this.authenticationService.isAuthenticated.subscribe((isAuthenticated) => {

            if (isAuthenticated === true) {
                this.applicationInfoService.getBackendVersion().subscribe(backendInfo => {
                    this.backendInformation = backendInfo;
                    this.backendBuildDate = this.parseAndSetBuildDateString(this.backendInformation.gitBuildTimeMillis);
                });
            }
        });
    }

    private parseAndSetBuildDateString(dateTimeMillis: number): string {
        return moment(this.backendInformation.gitBuildTimeMillis).utcOffset('+0200').format('DD.MM.YYYY HH:mm').toString();
    }

    checkStvData() {
        this.initialStvLoadingService.checkStellvertreterDataOnAppInit();
    }
}

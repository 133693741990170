import {Injectable} from '@angular/core';
import {Observable, of} from 'rxjs';
import {HttpHeaders} from '@angular/common/http';
import {Endpoints} from '../../navigation/endpoints';
import {DefaultHttpService} from '../../../services/default-http.service';
import {GeproFiles} from '../../../interfaces/gepro.interfaces';
import {UserinfoServiceMock} from './userinfo.service.mock';
import {UserinfoService} from './userinfo.service';


@Injectable()
export class FileService {

    constructor(public http: DefaultHttpService,
                private userInfoService: UserinfoService) {
    }

    postFile(geproId: string, fileToUpload: File, isNewGepro: boolean): Observable<any> {
        const formData: FormData = new FormData();
        formData.append('file', fileToUpload, fileToUpload.name);
        formData.append('isNewGepro', isNewGepro + '');

        return this.http.post(Endpoints.UPLOAD_FILE.replace('${geproId}', geproId), formData, {reportProgress: true, observe: 'events'});
    }

    uploadFile(geproId: string, fileToUpload: File): Observable<any> {
        let url = Endpoints.UPLOAD_FILE;
        const formData: FormData = new FormData();
        formData.append('file', fileToUpload, fileToUpload.name);
        if (this.userInfoService.isCurrentUserStvUser() && !this.userInfoService.getIsWorkingOnRLTab()) {
            url = url.concat('?asStvVonBensl=', this.userInfoService.getCurrentStvUser().bensl);
        }
        return this.http.post(url.replace('${geproId}', geproId), formData, {});
    }

    getAllBy(geproId: string): Observable<GeproFiles> {
        if (geproId) {
            let url: string = Endpoints.GEPRO_FILES_BY_ID.replace('${geproId}', geproId)
            if (this.userInfoService.isCurrentUserStvUser() &&  (!this.userInfoService.getIsWorkingOnRLTab()||this.userInfoService.getIsWorkingOnNewVorgang())) {
                url = url.concat('?asStvVonBensl=', this.userInfoService.getCurrentStvUser().bensl);
            }
            return this.http.get<GeproFiles>(url);
        }
        return of({ content: [] });
    }

    removeFile(geproId: number, fileId: number): Observable<any> {
        const httpOptions = {
            headers: new HttpHeaders({
            })
        };

        return this.http.post(Endpoints.UPLOADED_FILES.replace('${geproId}', geproId.toString()), fileId, httpOptions);
    }
}

import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {TodoItemFlatNode} from "../gepro-msn-tree-checklist/gepro-tree-checklist-example";
import {GeproMaklerstrukturService} from "../../../services/gepromaklerstruktur.service";

@Component({
    selector: 'gepro-msntree-node-content',
    templateUrl: './gepro-msntree-node-content.component.html',
    styleUrls: ['./gepro-msntree-node-content.component.css']
})
export class GeproMsntreeNodeContentComponent implements OnInit {

    @Input()
    isMsn: boolean;

    @Input()
    node: TodoItemFlatNode;

    @Input()
    recursivelySelectedCount: number;

    highlight: boolean;

    constructor(private maklerstrukturService: GeproMaklerstrukturService,
                public elementRef: ElementRef) {
    }

    ngOnInit(): void {
        this.maklerstrukturService.nodeContentComponentCreatedTree(this);
    }

    getBorder(): string {
        return this.highlight ? '3px solid #007ab3' : '';
    }
}

import {Component, Input} from '@angular/core';
import {GeproTypeRestTO} from '../../../index/service/Model/GeproTypeRestTO';
import {DocumentRestTO} from '../../../index/service/Model/DocumentRestTO';

type ConfirmationState = 'REQUIRED' | 'OPTIONAL_CHECKED' | 'OPTIONAL_UNCHECKED';

interface DocumentInfo {
    name: string;
    state: ConfirmationState;
}

@Component({
    selector: 'app-confirmed-documents',
    templateUrl: './confirmed-documents.component.html',
    styleUrls: ['./confirmed-documents.component.css']
})
export class ConfirmedDocumentsComponent {

    open: boolean;

    @Input()
    geproType: GeproTypeRestTO;

    @Input()
    confirmedDocumentIds: Array<number> = [];

    constructor() {}

    confirmedDocuments(): Array<DocumentInfo> {
        let documents = [];
        if (this.geproType) {
            const reqD = this.prepare(this.geproType.requiredDocuments);
            const optD = this.prepare(this.geproType.optionalDocuments, false);
            documents = documents.concat(reqD, optD);
        }
        return documents.filter(document => document.state !== 'OPTIONAL_UNCHECKED');
    }

    private prepare(documents: Array<DocumentRestTO>, required: boolean = true): Array<DocumentInfo> {
        return (documents || []).map(document => this.newDocumentInfo(document.name, this.stateOf(document.id, required)))
    }

    private newDocumentInfo(name: string, state: ConfirmationState): DocumentInfo {
        return {
            name: name,
            state: state
        };
    }

    private stateOf(id: number, required: boolean): ConfirmationState {
        if (required) {
            return 'REQUIRED';
        }
        const index = this.confirmedDocumentIds.indexOf(id);
        return index > -1 ? 'OPTIONAL_CHECKED' : 'OPTIONAL_UNCHECKED';
    }
}

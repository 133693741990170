import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ShellComponent} from './shell/shell.component';
import {TypeComponent} from './type/type.component';
import {BrokerComponent} from './broker/broker.component';
import {DocumentsComponent} from './documents/documents.component';
import {OverviewComponent} from './overview/overview.component';
import {RouterModule} from '@angular/router';
import {NxProgressStepperModule} from '@allianz/ngx-ndbx/progress-stepper';
import {NxAccordionModule} from '@allianz/ngx-ndbx/accordion';
import {NxButtonModule} from '@allianz/ngx-ndbx/button';
import {NxCheckboxModule} from '@allianz/ngx-ndbx/checkbox';
import {NxCopytextModule} from '@allianz/ngx-ndbx/copytext';
import {NxDropdownModule} from '@allianz/ngx-ndbx/dropdown';
import {NxHeadlineModule} from '@allianz/ngx-ndbx/headline';
import {NxLinkModule} from '@allianz/ngx-ndbx/link';
import {NxMessageModule} from '@allianz/ngx-ndbx/message';
import {NxModalModule} from '@allianz/ngx-ndbx/modal';
import {NxSpinnerModule} from '@allianz/ngx-ndbx/spinner';
import {NxFormfieldModule} from '@allianz/ngx-ndbx/formfield';
import {PearlErrorDirective} from './pearl-error.directive';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NxIconModule} from '@allianz/ngx-ndbx/icon';
import {ShellStepperComponent} from './shell-stepper/shell-stepper.component';
import {NxInputModule} from '@allianz/ngx-ndbx/input';
import {DiassCommonsModule} from '../common/diass-commons.module';
import {GeproMessageModule} from '../../gepro-message.module';
import {VorgangDocumentsModule} from '../gePro/vorgang-documents/vorgang-documents.module';
import {FlexModule} from '@angular/flex-layout';
import {NxTableModule} from '@allianz/ngx-ndbx/table';
import {VorgangMaklerModule} from '../gePro/vorgang-makler/vorgang-makler.module';
import {NxTooltipModule} from '@allianz/ngx-ndbx/tooltip';
import {LabeledContainerModule} from '../common/labeled-container/labeled-container.module';
import {AbortModalComponent} from './abort-modal/abort-modal.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MaklerstrukturSelectionComponent} from './broker/maklerstruktur/maklerstruktur-selection/maklerstruktur-selection.component';
import {MaklerstrukturTreeComponent} from './broker/maklerstruktur/maklerstruktur-tree/maklerstruktur-tree.component';
import {MaklerstrukturNodeContentComponent} from './broker/maklerstruktur/maklerstruktur-node-content/maklerstruktur-node-content.component';
import {CreateGeproLoadingComponent} from './create-gepro-loading/create-gepro-loading.component';
import {TypeDescriptionComponent} from './type/type-description/type-description.component';
import {TimestampModule} from '../common/timestamp/timestamp.module';
import {DocumentUploadModule} from './documents/document-upload/document-upload.module';
import {FocusDirective} from './focus.directive';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {MatInputModule} from '@angular/material/input';
import {TreeChecklistExample} from './broker/maklerstruktur/msn-tree-checklist/tree-checklist-example';
import {MatTreeModule} from '@angular/material/tree';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import {MsnTreeChecklistComponent} from './broker/maklerstruktur/msn-tree-checklist/msn-tree-checklist.component';
import {MsntreeNodeContentComponent} from './broker/maklerstruktur/maklerstruktur-node-content/msntree-node-content.component';
import {MatStepperModule} from '@angular/material/stepper';
import {GeproModule} from '../gePro/gepro.module';

@NgModule({
    declarations: [
        ShellComponent,
        TypeComponent,
        BrokerComponent,
        DocumentsComponent,
        OverviewComponent,
        PearlErrorDirective,
        FocusDirective,
        ShellStepperComponent,
        AbortModalComponent,
        MaklerstrukturSelectionComponent,
        MaklerstrukturTreeComponent,
        MaklerstrukturNodeContentComponent,
        CreateGeproLoadingComponent,
        TypeDescriptionComponent,
        TreeChecklistExample,
        MsnTreeChecklistComponent,
        MsntreeNodeContentComponent,
    ],
    imports: [
        ScrollingModule,
        CommonModule,
        RouterModule,
        NxProgressStepperModule,
        NxButtonModule,
        NxFormfieldModule,
        NxDropdownModule,
        NxSpinnerModule,
        ReactiveFormsModule,
        FormsModule,
        NxIconModule,
        NxLinkModule,
        NxTableModule,
        NxInputModule,
        NxCheckboxModule,
        NxModalModule,
        NxHeadlineModule,
        NxCopytextModule,
        DiassCommonsModule,
        GeproMessageModule,
        VorgangDocumentsModule,
        VorgangMaklerModule,
        FlexModule,
        NxMessageModule,
        NxTooltipModule,
        NxModalModule,
        NxHeadlineModule,
        NxCopytextModule,
        LabeledContainerModule,
        MatProgressSpinnerModule,
        NxAccordionModule,
        LabeledContainerModule,
        TimestampModule,
        DocumentUploadModule,
        MatInputModule,
        MatTreeModule,
        MatCheckboxModule,
        MatIconModule,
        MatStepperModule,
        GeproModule
    ],
    exports: [ShellComponent, TypeComponent, BrokerComponent, DocumentsComponent, OverviewComponent, AbortModalComponent, MsntreeNodeContentComponent]
})
export class CreateGeproModule {
}
